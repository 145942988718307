import { ToastContainer, Slide } from 'react-toastify';

import styled from 'styled-components/macro';

import CloseIcon from './icons/closeIcon.svg';

import * as S from './styled';

const StyledContainer = styled(ToastContainer)`
  .Toastify__toast {
    padding: 10px 15px 10px 12px;
    z-index: 110;
    background: var(--highlight);
    color: var(--object-primary);
    border: 1px solid var(--border);
  }
  .Toastify__toast-body {
    padding: 0;
    display: flex;
    align-items: start;
  }
  .Toastify__toast-icon {
    margin-top: 3px;
  }
  .Toastify__close-button {
    svg {
      width: 25px;
      height: 25px;
      fill: var(--object-secondary);
    }
  }
`;

export const Notification = () => {
  return (
    <StyledContainer
      hideProgressBar
      position="bottom-right"
      transition={Slide}
      closeButton={() => <S.CloseButton src={CloseIcon} />}
    />
  );
};
