import { useContext, useEffect } from 'react';
import { ProtectedArea } from 'shared/components';

import { TabType } from 'features/Transactions/types';
import * as S from 'features/Transactions/view/styled';
import AppContext from 'shared/contexts/AppContext';
import styled from 'styled-components';
import { getInitialTab } from 'App';
import { Transactions } from 'features/Transactions/view/components/Transactions';

const Wrapper = styled.div`
  width: 100%;
  display: grid;
  height: 100%;
  grid-template-rows: 40px 54px;
  grid-template-columns: minmax(min-content, 1fr) 280px;
  max-width: 1585px;
`;

export const TransactionsPage = () => {
  const { user, scrollToTop, transactionsTab, setTransactionsTab } =
    useContext(AppContext);

  useEffect(() => {
    scrollToTop();
    setTransactionsTab(getInitialTab(user?.permissions));
  }, []);

  return (
    <ProtectedArea displayMessage every={['accounts.view_account']}>
      <ProtectedArea
        displayMessage
        every={['accounts.view_account']}
      >
        <Wrapper>
          <S.Tabs>Queue</S.Tabs>
          <Transactions />
        </Wrapper>
      </ProtectedArea>
    </ProtectedArea>
  );
};
