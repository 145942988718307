import { memo } from 'react';

import * as S from 'features/Transactions/view/styled';

export const TransactionsHeader = memo(() => (
  <S.TransactionsHeaderWrapper>
    <S.Header>
      <S.HeaderRow>
        <S.DateCol>Date</S.DateCol>
        <S.TxCol>TX ID</S.TxCol>
        <S.FromCol>From</S.FromCol>
        <S.ToCol>To</S.ToCol>
        <S.AmountCol>Amount</S.AmountCol>
        <S.ConfirmationsCol>Confirmations</S.ConfirmationsCol>
        <S.StatusCol>Status</S.StatusCol>
      </S.HeaderRow>
    </S.Header>
  </S.TransactionsHeaderWrapper>
));
