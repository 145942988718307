import { PropsWithChildren } from 'react';

import * as S from 'features/Transactions/view/styled';

interface NotFoundProps {
  active: boolean;
  paddingTop?: boolean;
  shrinked?: boolean;
}

export const NotFound = ({
  active,
  children,
  paddingTop = true,
  shrinked = false,
}: PropsWithChildren<NotFoundProps>) => {
  if (!active) return null;

  return (
    <S.NotFoundWrapper style={{ paddingTop: paddingTop ? '54px' : '0px' }}>
      <S.NotFound style={{ padding: shrinked ? '28.5px 30px' : '80px 30px' }}>{children}</S.NotFound>
    </S.NotFoundWrapper>
  );
};
