import { AccountType, CurrencyType } from 'shared/types';
import {
  IconArrowWrap,
  IconArrow,
  ExchangeIconMin,
} from '../../components/ExchangeAbstract';
import { AccountAliases } from './AccountAliases';
import { AccountBalanceStatus } from './AccountBalanceStatus';
import { AccountExchangeIcon } from './AccountExchangeIcon';
import { AccountNameLabel } from './AccountNameLabel';
import { AccountTags } from './AccountTags';
import { AccountViolations } from './AccountViolations';
import { useContext, useMemo } from 'react';
import { ReactComponent as SvgLink } from 'shared/assets/icons/icon-external-link.svg';

import { getViolatedCurrencies } from 'features/Transfer/functions';
import * as S from './AccountStyled';
import { AccountHints } from './AccountHints';
import { AccountIncomingLimit } from './AccountIncomingLimit';
import Tippy from '@tippyjs/react';
import { IconButton, SvgIcon } from '@mui/material';
import { getCoinsInfoById } from 'shared/constants/coins';
import { useTransfer } from 'features/Transfer/context/TransferContext';
import { info } from 'console';
import AppContext from 'shared/contexts/AppContext';

interface FiatMethodHeaderProps {
  method: string;
  opened: boolean;
  toggle: any;
  balanceUpdatedAt: string;
}

export const FiatMethodHeader = ({
  method,
  opened,
  toggle,
  balanceUpdatedAt,
}: FiatMethodHeaderProps) => {
  const { appSettings } = useContext(AppContext);
  const { from } = useTransfer();
  const coinInfo = getCoinsInfoById(
    from?.wallet?.currency,
    appSettings.currencies_icons,
  );
  return (
    <S.AccountHeader style={{ width: 'calc(100% + 15px)' }}>
      <S.AccountHeaderTop onClick={toggle}>
        <IconArrowWrap rotateIcon={opened}>
          <IconArrow />
        </IconArrowWrap>
        <ExchangeIconMin
          style={{
            backgroundImage: `url(${coinInfo?.logo_url})`,
          }}
          disabled={false}
        />
        <S.AccountTitle>
          <S.AccountName>
            <AccountNameLabel name={method} />
          </S.AccountName>
          <AccountBalanceStatus
            hasSubAccounts={false}
            isBalanceOutdated={false}
            balanceUpdatedAt={balanceUpdatedAt}
          />
        </S.AccountTitle>
      </S.AccountHeaderTop>
    </S.AccountHeader>
  );
};
