import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { format, parseISO } from 'date-fns';

import * as S from '../../styled';
import AppContext from 'shared/contexts/AppContext';
import { Position } from '../../../types';
import { StatusType } from 'shared/types';
import { formatAccount } from 'shared/helpers/format';
import { IconInfo } from '../Filters/styled';
import Tippy from '@tippyjs/react';
import { getExchangeInfoById } from 'shared/constants/exchanges';
import { PositionRow } from './PositionRow';
import {
  IconArrow,
  IconArrowWrap,
} from 'features/Transfer/view/components/ExchangeAbstract';
import { useToggle } from 'shared/hooks/useToggle';

interface PositionAccountProps {
  name: string;
  exchange: string;
  positions: any[];
  viewInfo: any;
}

export const PositionAccount = ({
  name,
  exchange,
  positions,
  viewInfo,
}: PositionAccountProps) => {
  const { appSettings, user, accounts } = useContext(AppContext);
  const [isOpen, toggleIsOpen] = useToggle(true);

  const exchangeInfo = getExchangeInfoById(exchange);

  const onClick = (e: React.MouseEvent<HTMLDivElement>) => {
    toggleIsOpen();
    e.stopPropagation();
  };

  return (
    <S.PositionAccountRow>
      <S.PositionAccountName onClick={onClick}>
        <IconArrowWrap rotateIcon={isOpen}>
          <IconArrow />
        </IconArrowWrap>
        <S.PositionAccountExchangeLogo
          style={{ backgroundImage: `url(${exchangeInfo?.logo_url})` }}
        />
        {name}
      </S.PositionAccountName>
      {isOpen &&
        positions?.map((position) => (
          <PositionRow
            viewInfo={viewInfo}
            key={position.external_id}
            position={position}
          />
        ))}
    </S.PositionAccountRow>
  );
};
