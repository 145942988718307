import { Box, Typography } from '@mui/material';
import PrefixedEthHashInfo from '../EthHashInfo';

import * as S from './styled';

const SendToBlock = ({ address, title = 'Recipient' }: { address: string; title?: string }) => {
  return (
    <S.Container>
      <S.Text>
        {title}
      </S.Text>

      <S.TextData>
        <PrefixedEthHashInfo address={address} shortAddress={false} hasExplorer showCopyButton />
      </S.TextData>
    </S.Container>
  );
};

export default SendToBlock;
