import { ReactElement } from 'react';
import { shortenAddress } from 'features/SafeApp/helpers/formatters';
import addressBookStore from 'features/Multisig/mobx/AdressBookStore';
import { Typography } from '@mui/material';
import ExplorerLink from '../TokenExplorerLink';
import Copy from 'shared/components/Copy';
import { useCurrentChain } from 'features/SafeApp/hooks/useChains';
import { _getChainId } from 'features/Multisig/helpers';
import { ethers } from 'ethers';

import * as S from './styled';

type EthHashInfoProps = {
  address: string
  chainId?: string
  name?: string | null
  showAvatar?: boolean
  showCopyButton?: boolean
  prefix?: string
  showPrefix?: boolean
  copyPrefix?: boolean
  shortAddress?: boolean
  customAvatar?: string
  hasExplorer?: boolean
  avatarSize?: number
};

const EthHashInfo = ({
  address,
  prefix = '',
  showPrefix,
  shortAddress = true,
  name,
  showCopyButton,
  hasExplorer,
}: EthHashInfoProps): ReactElement => {
  const shouldPrefix = ethers.utils.isAddress(address);

  return (
    <S.Container>
      <S.NameRow>
        {name && (
          <Typography variant="body2" component="div" textOverflow="ellipsis" overflow="hidden" title={name}>
            {name}
          </Typography>
        )}

        <S.AddressRow>
          <Typography fontWeight="inherit" fontSize="inherit">
            {showPrefix && shouldPrefix && prefix && <b>{prefix}:</b>}
            <S.MobileAddress>{address}</S.MobileAddress>
            <S.DesktopAddress>{address}</S.DesktopAddress>
          </Typography>

          {showCopyButton && (
            <Copy text={address} />
          )}

          {hasExplorer && <ExplorerLink address={address} />}
        </S.AddressRow>
      </S.NameRow>
    </S.Container>
  );
};

const PrefixedEthHashInfo = ({
  showName = true,
  ...props
}: EthHashInfoProps & { showName?: boolean }): ReactElement => {
  const currentChainId = _getChainId();
  const chain = useCurrentChain();
  const addressBook = addressBookStore.selectAddressBookByChain(currentChainId);

  const name = showName ? props.name || addressBook[props.address] : undefined;

  return (
    <EthHashInfo
      prefix={chain?.shortName}
      showPrefix={true}
      copyPrefix={true}
      {...props}
      name={name}
    />
  );
};

export default PrefixedEthHashInfo;
