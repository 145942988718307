import { CurrencyType } from 'shared/types';
import { getCoinsInfoById } from 'shared/constants/coins';
import { useEffect, useState, useRef, Fragment } from 'react';

import { CurrencySearch } from '../CurrencySearch';

import * as S from './styled';
import { SettingsStoreInstance } from 'services';

function CurrencySelector({
  availableCurrencies,
  chosenCurrency,
  handleChangeCurrency,
  handleResetCurrency,
  initialCurrenciesState,
}: {
  availableCurrencies: CurrencyType[];
  chosenCurrency: CurrencyType[];
  initialCurrenciesState: CurrencyType[];
  handleChangeCurrency: any;
  handleResetCurrency: any;
}) {
  const diff = function (a1: CurrencyType[], a2: CurrencyType[]) {
    return a1
      .filter((i) => !a2.includes(i))
      .concat(a2.filter((i) => !a1.includes(i))).length;
  };

  const mergeAndRemoveDuplicates = (
    arr1: CurrencyType[],
    arr2: CurrencyType[],
    arr3: CurrencyType[],
  ) => {
    const combinedArr = [...arr3, ...arr1, ...arr2];
    const result: CurrencyType[] = [];
    combinedArr.forEach((item) => {
      if (!result.some((resultItem) => resultItem === item)) {
        result.push(item);
      }
    });
    return result;
  };

  const sortedCurrencies = [...availableCurrencies].sort((a, b) =>
    a > b ? 1 : -1,
  );
  const smartCurrencies = mergeAndRemoveDuplicates(
    chosenCurrency,
    sortedCurrencies,
    initialCurrenciesState,
  );

  const currencyItemWidth = 59; // Ширина элемента S.CurrencyItem
  const [itemsPerRow, setItemsPerRow] = useState<number>(15);
  const containerRef = useRef<HTMLDivElement>(null);
  const [chosenInSearch, setChosenInSearch] = useState<CurrencyType[]>([]);
  const { appSettings } = SettingsStoreInstance;

  useEffect(() => {
    const updateItemsPerRow = () => {
      if (containerRef.current) {
        const containerWidth =
          containerRef.current.getBoundingClientRect().width - 200;
        const calculatedItemsPerRow = Math.floor(
          containerWidth / currencyItemWidth,
        );

        if (calculatedItemsPerRow !== itemsPerRow) {
          setItemsPerRow(calculatedItemsPerRow);
        }
      }
    };

    const resizeObserver = new ResizeObserver(updateItemsPerRow);

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, [containerRef, itemsPerRow]);

  const mainCurrencies = smartCurrencies.slice(0, itemsPerRow);
  const altCurrencies = smartCurrencies.slice(itemsPerRow);

  const renderCurrencies = (currenciesList: CurrencyType[]) => {
    return currenciesList.map((availableCurrency, index) => {
      const isLastNonInitialChosenCurrency =
        chosenCurrency
          .filter((c) => !initialCurrenciesState.includes(c))
          .slice(-1)[0] === availableCurrency;
      const allChosenCurrenciesAreInitial = chosenCurrency.every((c) =>
        initialCurrenciesState.includes(c),
      );
      const isLastInitialCurrency =
        initialCurrenciesState.slice(-1)[0] === availableCurrency;
      const startDelimiterPos = initialCurrenciesState.length - 1;

      const shouldShowDelimiter =
        ((chosenCurrency.length > 0 &&
          isLastNonInitialChosenCurrency &&
          index > startDelimiterPos) ||
          (chosenCurrency.length === 0 && index === startDelimiterPos) ||
          (allChosenCurrenciesAreInitial && isLastInitialCurrency)) &&
        currenciesList.length - 1 !== index;

      return (
        <Fragment key={availableCurrency}>
          <S.CurrencyItem
            onClick={() => handleChangeCurrency(availableCurrency)}
            className={
              chosenCurrency.includes(availableCurrency) ? 'active' : ''
            }
            isLastActive={
              chosenCurrency[chosenCurrency.length - 1] === availableCurrency
            }
          >
            {getCoinsInfoById(availableCurrency, appSettings.currencies_icons)
              ?.logo_url ? (
              <S.CurrencyLogo
                style={{
                  backgroundImage: `url(${
                    getCoinsInfoById(
                      availableCurrency,
                      appSettings.currencies_icons,
                    )?.logo_url
                  })`,
                }}
              />
            ) : null}
            {availableCurrency}
          </S.CurrencyItem>
          {shouldShowDelimiter && <S.Delimiter></S.Delimiter>}
        </Fragment>
      );
    });
  };

  return (
    <S.CurrencySelectWrap ref={containerRef}>
      <S.ResetFilter
        onClick={() => handleResetCurrency()}
        active={diff(chosenCurrency, initialCurrenciesState) > 0}
      >
        Reset filter
      </S.ResetFilter>

      <S.List>
        {renderCurrencies(mainCurrencies)}

        {chosenInSearch.length > 0 && (
          <S.CurrencyCounter>+{chosenInSearch.length}</S.CurrencyCounter>
        )}
      </S.List>
      <CurrencySearch
        setChosenInSearch={setChosenInSearch}
        currencies={altCurrencies}
        chosenInSearch={chosenInSearch}
        chosenCurrency={chosenCurrency}
        handleChangeCurrency={handleChangeCurrency}
      />
    </S.CurrencySelectWrap>
  );
}

export { CurrencySelector };
