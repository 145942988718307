import { FC, useState } from 'react';
import * as S from './styled';

interface CollapsedCardProps {
  collapseId: string;
  icon: any;
  title: string;
  children: any;
}

export const CollapsedCard: FC<CollapsedCardProps> = ({
  collapseId,
  icon: Icon,
  title,
  children,
}) => {
  const [collapsedList, setCollapsedList] = useState<{
    [key: string]: boolean | any;
  }>(JSON.parse(localStorage.getItem('collapsed') || '{}'));
  const [collapsed, setCollapsed] = useState(
    collapsedList[collapseId] || false,
  );

  function handleSetCollapsed() {
    setCollapsed(!collapsed);
    const updList = collapsedList;
    updList['' + collapseId] = !collapsed;
    localStorage.setItem('collapsed', JSON.stringify(updList));
    setCollapsedList(updList);
  }

  return (
    <S.CardWrapper
      onClick={handleSetCollapsed}
      isActive={collapsed}
    >
      <S.CardHeader>
        <S.CardHeaderText>
          <Icon />
          {title}
        </S.CardHeaderText>
        <S.CollapseButton
          rotateIcon={collapsed}
        >
          <S.Dropdown />
        </S.CollapseButton>
      </S.CardHeader>
      <S.CardValue isActive={collapsed}>{children}</S.CardValue>
    </S.CardWrapper>
  );
};
