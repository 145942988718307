import styled from 'styled-components/macro';
export const PlaceholderWrap = styled.div<{ width?: string; height?: string }>`
  height: ${({ height }) => height || '240px'};
  width: ${({ width }) => width || '100%'};
  width: 100%;
  padding: 0 20px;
  margin: 20px 0;
`;

export const BorderWrap = styled.div`
  border: 1px solid #a3b1c6;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Text = styled.div`
  font-weight: 400;
  font-size: 18px;
  color: #a3b1c6;
`;
