import { action, makeObservable, observable } from 'mobx';

import { requestPost } from 'shared/axios';

class ProfileStore {
  constructor() {
    makeObservable(this);
  }

  @observable secretCodeFull = '';
  @observable secretCode = '';
  @observable error = '';

  @action.bound public async getOrResetSecretCode() {
    try {
      const response = await requestPost('/auth/hotp/');

      // const data: { secret?: string; error?: string } = await rawData.json();
      //
      //   if (data.secret) {
      //     setSecretCodeFull(data.secret || '');
      //
      //     const extracted = queryString.extract(data.secret);
      //     const parts = queryString.parse(extracted);
      //
      //     setSecretCode(String(parts.secret) || '');
      //   }
      //
      //   if (data.error) {
      //     setError(data.error);
      //   }
      //
      //   getUserInfo();

    } catch (e) {
      // console.log('getOrResetSecretCode error:', e);
    }
  }
}

export const ProfileStoreInstance = new ProfileStore();
