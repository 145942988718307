import type { ReactElement } from 'react';

import * as S from '../styled';

const InvalidTransaction = (): ReactElement => {
  return (
    <S.ErrorWrapper>
      <S.ErrorHeader>
        <S.ErrorIcon />
        <S.ErrorTitle>Transaction error</S.ErrorTitle>
      </S.ErrorHeader>
      <br />
      <S.ErrorMessage>
        This Safe App initiated a transaction which cannot be processed. Please get in touch with the developer of this
        Safe App for more information.
      </S.ErrorMessage>
    </S.ErrorWrapper>
  );
};

export default InvalidTransaction;
