import styled from 'styled-components';
import { Link as MuiLink, Typography as MuiTypography, SvgIcon as MuiSvgIcon } from '@mui/material';

export const Container = styled.div`
  background-color: #ffe6ea;
  color: #028d4c;
  padding: 16px;
  margin: 6px 0;
  border-radius: 4px;
`;

export const Message = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
`;

export const Link = styled(MuiLink)`
  vertical-align: baseline;
  text-decoration: underline;
`;

export const Typography = styled(MuiTypography)`
  &.details {
    margin-top: 8px;
    color: #a1a3a7;
    word-break: break-word;
  }
`;
