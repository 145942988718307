import * as S from 'features/Transactions/view/styled';
import * as LoaderStyle from 'shared/components/Loader/styled';
import { useContext } from 'react';
import { FC, useEffect, useState } from 'react';
import InfiniteScroll from 'shared/modules/InfiniteScroll';
import AppContext from 'shared/contexts/AppContext';
import { useWindowSize } from 'shared/hooks/useWindowSize';
import styled from 'styled-components';
import { Loader as InlineLoader } from 'shared/components/Loader';

interface ListProps {
  row: any;
  data?: any[];
  isInitialLoading: boolean;
  isLoading: boolean;
  isFetching: boolean;
}

const InlineLoaderWrapper = styled.div`
  width: 100%;
  height: 48.5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
interface TransactionsLoaderProps {
  isInitialLoading: boolean;
  isLoading: boolean;
}

export const Loader = ({
  isInitialLoading,
  isLoading,
}: TransactionsLoaderProps) => {
  if (isInitialLoading)
    return (
      <LoaderStyle.InitialLoaderWrap small={false} position="relative">
        <LoaderStyle.Logo
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.5 } }}
          exit={{ opacity: 0, transition: { duration: 0.5 } }}
        />
      </LoaderStyle.InitialLoaderWrap>
    );
  if (isLoading) return <InlineLoader position="fixed" />;

  return null;
};

export const List: FC<ListProps> = ({
  row: Row,
  data = [],
  isLoading,
  isInitialLoading,
  isFetching,
}) => {
  const { ref } = useContext(AppContext);
  const { width } = useWindowSize();

  return (
    <S.ListWrapper>
      <Loader isInitialLoading={isInitialLoading} isLoading={isLoading} />
      <S.Table
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 0.5 } }}
        exit={{ opacity: 0, transition: { duration: 0.5 } }}
      >
        <S.TransactionsList>
          {data.map((tx) => (
            <Row key={tx.id} tx={tx} />
          ))}
        </S.TransactionsList>
      </S.Table>
    </S.ListWrapper>
  );
};
