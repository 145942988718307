class LocalStorageStore {
  constructor() {}

  protected setValue<T>(key: string, value: T) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  protected getValue<T>(key: string): Nullable<T> {
    try {
      const value = localStorage.getItem(key);
      if (value !== null) {
        const initial = JSON.parse(value);
        return initial || null;
      }
      return null;
    } catch (e) {
      console.error('Get localStorage value error:', e);
      return null;
    }
  }
}

export const LocalStorageStoreInstance = new LocalStorageStore();
