import React from 'react';
import styled, { css, keyframes } from 'styled-components/macro';
import Circle from 'shared/components/Сircle';

import { ReactComponent as SvgCopy } from 'shared/assets/icons/icon-copy-stroke.svg';
import { ReactComponent as SvgExternal } from 'shared/assets/icons/icon-external-link.svg';
import { getExplorerInfo } from 'pages/MultisigPage/utilities';
import Copy from 'shared/components/Copy';

const Wrap = styled.div`
  width: 100%;
  display: flex;
  padding: 0;
  align-items: center;
`;
const Data = styled.div`
  flex: 1;
  padding: 0 0 0 10px;
`;
const Name = styled.div`
  width: 100%;
  font-size: 12px;
  line-height: 15px;
`;
const Address = styled.div`
  font-size: 12px;
  line-height: 15px;
  color: var(--object-secondary);
`;
const Tools = styled.div`
  display: flex;
  justify-content: space-between;
  width: 50px;
`;

const IconCss = css`
  width: 20px;
  height: 20px;
  fill: currentColor;
`;

const IconExternal = styled(SvgExternal)`
  ${IconCss}
  stroke: currentColor;
  fill: none;
`;

const StyledCopy = styled(Copy)`
  margin: 0;
`;

export const Button = styled.div`
  border: 0;
  background: none;
  margin: 0;
  color: var(--object-secondary);
  transition: all 0.2s;
  cursor: pointer;

  &:hover {
    color: var(--object-primary);
  }

  & + & {
    margin: 0 0 0 10px;
  }
`;

function AddresBar(props: any) {
  const { name, address, tools, size } = props;
  const { url } = getExplorerInfo(address);
  return (
    <Wrap>
      <Circle size={size || 30} seed={address} />
      <Data>
        <Name>{name}</Name>
        <Address>{address}</Address>
      </Data>
      {tools ? (
        <Tools>
          <StyledCopy text={address} width="20px" height="20px" />

          <a style={{ display: 'block' }} href={url} target="_blank">
            <Button>
              <IconExternal />
            </Button>
          </a>
        </Tools>
      ) : null}
    </Wrap>
  );
}
export default AddresBar;
