import { useState, useRef, useEffect, useContext } from 'react';
import styled from 'styled-components/macro';
import useOnClickOutside from 'use-onclickoutside';

import AppContext from 'shared/contexts/AppContext';
import Circle from 'shared/components/Сircle';
import Copy from 'shared/components/Copy';

import * as S from './styled/header';
import * as T from './styled/transactions';
import { observer } from 'mobx-react-lite';
import { useWindowSize } from 'shared/hooks/useWindowSize';
import { CTSafeInfo } from '../models';
import { sameString } from '../helpers';
import {
  getExplorerInfo,
  getHashedExplorerUrl,
} from 'pages/MultisigPage/utilities';
import Tippy from '@tippyjs/react';
import { SafeStoreInstance } from '../mobx/SafeStore';
import SendTransaction from './components/SendTransaction';

import { MultisigTransactionsStoreInstance } from '../mobx/MultisigTransactionsStore';

const StyledCopy = styled(Copy)`
  margin: 2px 0 2px 10px;
`;

const ExploreEtherscanButton = observer(({ value }: { value: string }) => {
  const { url } = getExplorerInfo(value);
  return (
    <a style={{ display: 'flex' }} href={url} target="_blank">
      <T.Button>
        <T.IconExternal />
      </T.Button>
    </a>
  );
});

const formatBalance = (balance: string) =>
  `${parseFloat(balance).toFixed(1)} USD`;

const MultisigHeader = observer(() => {
  const { getDropdownSafes, getHeaderSafes } = SafeStoreInstance;
  const { setActiveTransaction } = MultisigTransactionsStoreInstance;

  const [isMainSafeDropdown, setIsMainSafeDropdown] = useState(false);
  const [isWalletDropdown, setIsWalletDropdown] = useState(false);
  const [isSendModalOpen, setIsSendModalOpen] = useState(false);
  const { openModal, step, multisigTab } = useContext(AppContext);
  // @ts-ignore
  const [dropdownSafes, setDropdownSafes] = useState<CTSafeInfo[]>([]);
  // @ts-ignore
  const [headerSafes, setHeaderSafes] = useState<CTSafeInfo[]>([]);
  const dropdownRef = useRef(null);
  const walletDropdownRef = useRef(null);

  const {
    selectedSafe,
    selectSafe,
    safes,
    onboard,
    userAccount: account,
    wallet,
    selectedChain,
  } = SafeStoreInstance;
  const { width } = useWindowSize();

  useEffect(() => {
    if (width) {
      // @ts-ignore
      setDropdownSafes(getDropdownSafes(width));
      // @ts-ignore
      setHeaderSafes(getHeaderSafes(width));
    }
  }, [width, safes, selectedSafe, account]);

  async function connect() {
    try {
      const connected = await onboard?.walletSelect();
      if (connected) await onboard?.walletCheck();
      localStorage.setItem('isWalletConnected', 'true');
    } catch (ex) {
      console.log(ex);
    }
  }

  async function disconnect() {
    try {
      onboard?.walletReset();
      localStorage.setItem('isWalletConnected', 'false');
    } catch (ex) {
      console.log(ex);
    }
  }

  function handleCloseSafeDropdown() {
    if (isMainSafeDropdown) {
      setIsMainSafeDropdown(false);
    }
  }
  function handleCloseWalletDropdown() {
    if (isWalletDropdown) {
      setIsWalletDropdown(false);
    }
  }

  function handleNewTransaction() {
    setIsSendModalOpen(true);
    openModal({
      title: 'Send funds',
      width: '560px',
      titleRightText: `Step ${step} of 2`,
      component: () => <SendTransaction />,
    });
  }

  useOnClickOutside(dropdownRef, () => handleCloseSafeDropdown());
  useOnClickOutside(walletDropdownRef, () => handleCloseWalletDropdown());

  const hasSelectedSafe = selectedSafe;
  const isOwner =
    hasSelectedSafe &&
    selectedSafe?.owners.findIndex(({ value }) =>
      sameString(String(account), value),
    ) !== -1;

  return (
    <S.Header style={{ minWidth: multisigTab === 'apps' ? '600px' : '1010px' }}>
      <S.MainSafe ref={dropdownRef}>
        {headerSafes?.map((item) => {
          return (
            <S.MainSafeCurrentItem
              key={item?.address.value}
              onClick={() => selectSafe(item)}
              current={selectedSafe?.address.value === item?.address.value}
            >
              <Circle seed={item?.address.value} size={40} />
              <S.MainSafeWrap>
                <S.MainSafeName>{item?.address.name}</S.MainSafeName>
                <S.MainSafeAddress>
                  {' '}
                  {item?.address.value?.slice(0, 5)}...
                  {item?.address.value?.slice(-5)}
                </S.MainSafeAddress>
                <S.MainSafeBalance>
                  {formatBalance(item?.balance)}
                </S.MainSafeBalance>
              </S.MainSafeWrap>
              <S.Tools>
                <StyledCopy text={item?.address.value} />
                <S.ArrowWrap onClick={(e) => e.stopPropagation()}>
                  <S.IconBookmark />
                </S.ArrowWrap>
              </S.Tools>
            </S.MainSafeCurrentItem>
          );
        })}
        {dropdownSafes?.length ? (
          <S.MainSafeDropdownIcon
            onClick={(e) => {
              setIsMainSafeDropdown(!isMainSafeDropdown);
              e.stopPropagation();
            }}
          >
            <S.Tools>
              <S.ArrowWrap opened={isMainSafeDropdown}>
                <S.IconArrow />
              </S.ArrowWrap>
            </S.Tools>
          </S.MainSafeDropdownIcon>
        ) : null}
        {isMainSafeDropdown ? (
          <S.Dropdown>
            {dropdownSafes?.map((item) => {
              return (
                <S.MainSafeItem
                  onClick={() => selectSafe(item)}
                  key={item?.address.value}
                >
                  <Circle seed={item?.address.value} size={40} />
                  <S.MainSafeWrap>
                    <S.MainSafeName>{item?.address.name}</S.MainSafeName>
                    <S.MainSafeAddress>
                      {item?.address?.value.slice(0, 5)}...
                      {item?.address?.value.slice(-5)}
                    </S.MainSafeAddress>
                    <S.MainSafeBalance>
                      {formatBalance(item?.balance)}
                    </S.MainSafeBalance>
                  </S.MainSafeWrap>
                  <S.Tools>
                    <Copy text={item?.address.value} />
                    <S.ArrowWrap onClick={(e) => e.stopPropagation()}>
                      <S.IconBookmark />
                    </S.ArrowWrap>
                  </S.Tools>
                </S.MainSafeItem>
              );
            })}
          </S.Dropdown>
        ) : null}
      </S.MainSafe>
      <S.TransactionButtonWrap>
        {hasSelectedSafe && (
          <S.Button
            disabled={!isOwner}
            onClick={() => {
              if (isOwner) {
                handleNewTransaction();
              }
            }}
          >
            {isOwner ? 'New transaction' : 'read only'}
          </S.Button>
        )}
      </S.TransactionButtonWrap>
      <S.Wallet ref={walletDropdownRef}>
        <S.WalletWrap onClick={() => setIsWalletDropdown(!isWalletDropdown)}>
          {account ? (
            <S.WalletConneted>
              <S.IconMetaMusk />
              <S.WalletInfoWrap>
                <S.WalletName>{account}</S.WalletName>
                <S.WalletAddressWrap>
                  <Circle size={10} seed={account} />
                  <S.WalletAddress title={account}>
                    {account?.slice(0, 5)}...
                    {account?.slice(-5)}
                  </S.WalletAddress>
                </S.WalletAddressWrap>
              </S.WalletInfoWrap>
            </S.WalletConneted>
          ) : (
            <>
              <S.WalletAvatar>
                <S.IconLink />
              </S.WalletAvatar>
              <S.WalletInfoWrap>
                <S.WalletName>Not connected</S.WalletName>
                <S.WalletConnect>Connect wallet</S.WalletConnect>
              </S.WalletInfoWrap>
            </>
          )}
          <S.ArrowWrap opened={isWalletDropdown}>
            <S.IconArrow />
          </S.ArrowWrap>
        </S.WalletWrap>
        {isWalletDropdown ? (
          <S.WalletDropdown>
            <S.WalletDropdownWrap>
              {account ? (
                <>
                  <S.WalletBody>
                    <S.WalletConnetedLogo>
                      <Circle size={40} seed={account} />
                    </S.WalletConnetedLogo>
                    <S.WalletConnetedTools>
                      <S.WalletConnetedAddress>
                        {account?.slice(0, 5)}...
                        {account?.slice(-5)}
                        <S.AddressTools>
                          <Copy width="14px" height="14px" text={account} />
                          <Tippy
                            theme="transparent"
                            content={
                              <div>
                                View on{' '}
                                {
                                  new URL(getHashedExplorerUrl(account))
                                    .hostname
                                }
                              </div>
                            }
                          >
                            <a
                              style={{ display: 'flex' }}
                              href={getHashedExplorerUrl(account)}
                              target="_blank"
                            >
                              <T.Button>
                                <T.IconExternal />
                              </T.Button>
                            </a>
                          </Tippy>
                        </S.AddressTools>
                      </S.WalletConnetedAddress>
                    </S.WalletConnetedTools>
                    <S.WalletConnetedRow>
                      <S.WalletConnetedRowLabel>
                        Wallet
                      </S.WalletConnetedRowLabel>
                      <S.WalletConnetedRowValue>
                        {wallet.name}
                      </S.WalletConnetedRowValue>
                    </S.WalletConnetedRow>
                    <S.WalletConnetedRow>
                      <S.WalletConnetedRowLabel>
                        Connected network
                      </S.WalletConnetedRowLabel>
                      <S.WalletConnetedRowValue>
                        {selectedChain?.chainName}
                      </S.WalletConnetedRowValue>
                    </S.WalletConnetedRow>
                  </S.WalletBody>
                  <S.WalletFooter>
                    <S.DisconnectButton onClick={disconnect}>
                      disconnect wallet
                    </S.DisconnectButton>
                  </S.WalletFooter>
                </>
              ) : (
                <S.WalletFooter>
                  <S.Button onClick={connect}>connect wallet</S.Button>
                </S.WalletFooter>
              )}
            </S.WalletDropdownWrap>
          </S.WalletDropdown>
        ) : null}
      </S.Wallet>
    </S.Header>
  );
});

export default MultisigHeader;
