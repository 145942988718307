import { NonPayableTransactionObject } from '@gnosis.pm/safe-web3-lib/dist/typechain/src/web3-v1/v1.1.1/types';
import { GnosisSafe } from 'shared/types/contracts/gnosis_safe';

import { EthSignerArgs, TxArgs } from '../../models';
import { getEIP712Signer, SigningTxArgs } from './EIP712Signer';
import { ethSigner } from './ethSigner';
import semverSatisfies from 'semver/functions/satisfies';
import { getWeb3ReadOnly } from './getWeb3';

import { notify } from 'shared/components/Notification/notify';

export const getTransactionHash = async ({
   baseGas,
   data,
   gasPrice,
   gasToken,
   nonce,
   operation,
   refundReceiver,
   safeInstance,
   safeTxGas,
   sender,
   to,
   valueInWei,
 }: TxArgs): Promise<string> => {
  const txHash = await safeInstance.methods
    .getTransactionHash(to, valueInWei, data, operation, safeTxGas, baseGas, gasPrice, gasToken, refundReceiver, nonce)
    .call({
      from: sender,
    });

  return txHash;
};

export const getApprovalTransaction = (safeInstance: GnosisSafe, txHash: string): NonPayableTransactionObject<void> => {
  try {
    return safeInstance.methods.approveHash(txHash);
  } catch (err) {
    console.error(`Error while approving transaction: ${err}`);
    notify({
      type: 'error',
      header: 'Transaction failed',
      text: `Error while approving transaction: ${err}`,
    });
    throw err;
  }
};

export const getExecutionTransaction = ({
    baseGas,
    data,
    gasPrice,
    gasToken,
    operation,
    refundReceiver,
    safeInstance,
    safeTxGas,
    sigs,
    to,
    valueInWei,
  }: TxArgs): NonPayableTransactionObject<boolean> => {
  try {
    return safeInstance.methods.execTransaction(
      to,
      valueInWei,
      data,
      operation,
      safeTxGas,
      baseGas,
      gasPrice,
      gasToken,
      refundReceiver,
      sigs,
    );
  } catch (err) {
    console.error(`Error while creating transaction: ${err}`);
    notify({
      type: 'error',
      header: 'Transaction failed',
      text: `${err.message}`,
    });
    throw err;
  }
};

const SIGNERS = {
  EIP712_V3: getEIP712Signer('v3'),
  EIP712_V4: getEIP712Signer('v4'),
  EIP712: getEIP712Signer(),
  ETH_SIGN: ethSigner,
};

const isPairingModule = () => false;

// hardware wallets support eth_sign only
// eth_sign is only supported by safes >= 1.1.0
type SupportedSigners = typeof SIGNERS[keyof typeof SIGNERS][];
const getSupportedSigners = (isHW: boolean, safeVersion: string): SupportedSigners => {
  // v1 of desktop pairing only supports eth_sign
  if (isPairingModule()) {
    return [SIGNERS.ETH_SIGN];
  }

  const safeSupportsEthSigner = semverSatisfies(safeVersion, '>=1.1.0');

  const signers: SupportedSigners = isHW ? [] : [SIGNERS.EIP712_V3, SIGNERS.EIP712_V4, SIGNERS.EIP712];

  if (safeSupportsEthSigner) {
    signers.push(SIGNERS.ETH_SIGN);
  }

  return signers;
};

export const tryOffChainSigning = async (
  safeTxHash: string,
  txArgs: Omit<SigningTxArgs & EthSignerArgs, 'safeVersion' | 'safeTxHash'>,
  isHW: boolean,
  safeVersion: string,
): Promise<string | undefined> => {
  let signature;

  const signerByWallet = getSupportedSigners(isHW, safeVersion);
  for (const signingFunc of signerByWallet) {
    try {
      signature = await signingFunc({ ...txArgs, safeTxHash, safeVersion });

      break;
    } catch (err) {
      // if (isWalletRejection(err)) {
        // user rejected, exit
        console.error('Error while creating transaction', err);
        notify({
          type: 'warning',
          header: 'Transaction rejection failed',
          text: `${err.message}`,
        });
        throw err;
      // }
      // continue to the next signing method
    }
  }

  return signature;
};

export const getUserNonce = async (userAddress: string): Promise<number> => {
  const web3 = getWeb3ReadOnly();
  try {
    return await web3.eth.getTransactionCount(userAddress, 'pending');
  } catch (error) {
    return Promise.reject(error);
  }
};
