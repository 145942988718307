import { memo } from 'react';

import * as S from 'features/Transactions/view/styled';
import {
  ColAccount,
  ColCoin,
  ColDeposit,
  ColExchange,
  TableHeaderTitle,
} from 'features/Whitelist/view/styled';

interface WhitelistsHeaderProps {
  tab: string;
}

export const WhitelistsHeader = memo(({ tab }: WhitelistsHeaderProps) => (
  <S.TransactionsHeaderWrapper>
    <S.Header>
      <S.HeaderRow>
        <ColDeposit>
          <TableHeaderTitle>Deposit address</TableHeaderTitle>
        </ColDeposit>
        <ColCoin>
          <TableHeaderTitle>Coin</TableHeaderTitle>
        </ColCoin>
        <ColExchange>
          <TableHeaderTitle>Network</TableHeaderTitle>
        </ColExchange>
        {tab === 'whitelists' && (
          <ColAccount>
            <TableHeaderTitle>Account</TableHeaderTitle>
          </ColAccount>
        )}
        {tab === 'whitelists' && (
          <ColExchange>
            <TableHeaderTitle>Exchange</TableHeaderTitle>
          </ColExchange>
        )}
        {tab === 'unbound' && (
          <ColAccount>
            <TableHeaderTitle>Alias</TableHeaderTitle>
          </ColAccount>
        )}
      </S.HeaderRow>
    </S.Header>
  </S.TransactionsHeaderWrapper>
));
