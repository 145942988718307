import { useIsOpen } from 'features/Transfer/context/AccountContext';
import { getExchangeInfoById } from 'shared/constants/exchanges';
import { AccountType, ColumnSide, ExchangeAccountsType } from 'shared/types';
import * as S from './ColumnStyled';
import Tippy from '@tippyjs/react';
import { ExchangeIcon } from '../components/ExchangeAbstract';
import { useTransfer } from 'features/Transfer/context/TransferContext';
import { Account } from './Account/AccountItem';
import { AccountsStoreInstance } from 'services';

interface ExchangeItemProps {
  side: ColumnSide;
  exchange: ExchangeAccountsType;
}

export const ExchangeItem = ({ side, exchange }: ExchangeItemProps) => {
  const { fromAccountsIds, toAccountsIds } = useTransfer();
  const exchangeInfo = getExchangeInfoById(exchange.exchange);
  const [opened, toggle] = useIsOpen(side, 'exchange', exchange.exchange);
  const { exchangesStatuses } = AccountsStoreInstance;
  const accountsIds = side === 'from' ? fromAccountsIds : toAccountsIds;

  const statusData = exchangesStatuses.find(
    (status) => status.exchange === exchangeInfo?.api_id,
  );
  const exchangeStatus = statusData?.ok;

  return (
    <S.Row key={exchange.exchange}>
      <S.ExchangeTop onClick={toggle}>
        {exchangeInfo?.logo_url ? (
          <ExchangeIcon
            style={{
              backgroundImage: `url(${exchangeInfo.logo_url})`,
            }}
            disabled={false}
          />
        ) : null}
        <S.ExchangeName>{exchange.exchange}</S.ExchangeName>
        {!exchangeStatus && statusData && (
          <Tippy
            theme="transparent"
            placement="top"
            content="Some functionality could be unavailable now"
          >
            <S.WarningLogo />
          </Tippy>
        )}
      </S.ExchangeTop>
      {opened && Array.isArray(exchange.accounts) ? (
        <div tw="w-full">
          {exchange.accounts
            .filter((acc: AccountType) => acc.master_id === null)
            .sort((a, b) => (a.name < b.name ? -1 : 1))
            .map((exchangeAccount: any, idx: number) => (
              <Account
                key={exchangeAccount.id}
                account={exchangeAccount}
                exchange={exchange}
                side={side}
              />
            ))}
        </div>
      ) : null}
    </S.Row>
  );
};
