import Tippy from '@tippyjs/react';
import { ExchangeIconMin } from '../../components/ExchangeAbstract';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useTransfer } from 'features/Transfer/context/TransferContext';
import {
  AccountType,
  ColumnSide,
  CurrencyType,
  ExchangeApiIdType,
  FiatAddressType,
  WalletType,
  isCurrencyFiat,
} from 'shared/types';
import {
  getThreshold,
  isDepositAddressAvailable,
} from 'shared/helpers/transaction';
import AppContext from 'shared/contexts/AppContext';
import { QrModal } from 'features/Profile/view/components/QrModal';
import { AddressManagement } from 'shared/components';
import { getCoinsInfoById } from 'shared/constants/coins';
import { calculateCurrentThreshold } from 'features/Transfer/functions';

import * as S from './WalletStyled';
import { SettingsStoreInstance } from 'services';

interface FiatWalletItemProps {
  wallet: FiatAddressType;
}

export const FiatWalletItem = ({ wallet }: FiatWalletItemProps) => {
  const {
    isFromWallet,
    from,
    to,
    couldAddTransaction,
    couldAddTransactionProposal,
    changeFrom,
    changeTo,
    useGetWalletsTo,
  } = useTransfer();
  const { appSettings } = SettingsStoreInstance;
  const { setCurrentAccNode } = useContext(AppContext);
  const [domNode, setDomNode] = useState(null);
  const onClick = changeTo;
  const isActive = wallet?.id === to?.account?.id;
  const coinInfo = getCoinsInfoById(
    from?.wallet?.currency,
    appSettings.currencies_icons,
  );

  const selectAccount = () => {
    onClick({
      exchangeId: 'FIAT',
      accountId: wallet.id,
      walletId: wallet.wallet,
      currencyId: from?.wallet?.currency,
      isFiatWallet: true,
    });
    setTimeout(() => {
      // @ts-ignore
      domNode?.scrollIntoView({
        inline: 'center',
        block: 'center',
        behavior: 'smooth',
      });
    }, 0);
  };

  const onRefChange = useCallback(
    (node: any) => {
      setDomNode(node);
      if (isFromWallet && wallet?.id === to?.wallet?.id) {
        setCurrentAccNode(node);
      }
    },
    [isFromWallet, wallet, to?.wallet, setCurrentAccNode],
  );

  return (
    <S.Wallet>
      <S.WalletItemWrapper style={{ width: 'calc(100% + 25px)' }}>
        <S.AccountRow
          style={{ height: '34px', width: 'calc(100% - 25px)' }}
          ref={onRefChange}
          className={isActive ? 'active' : ''}
          onClick={selectAccount}
        >
          <ExchangeIconMin
            disabled={false}
            style={{
              backgroundImage: `url(${
                coinInfo?.logo_url ||
                (isActive
                  ? '/i/coins/unknown-active.svg'
                  : '/i/coins/unknown.svg')
              })`,
            }}
          />
          <S.CurrencyIso
            style={{
              width: 'unset',
              minWidth: 'unset',
              maxWidth: 'unset',
            }}
            selected={isActive}
          >
            {wallet.alias}
          </S.CurrencyIso>
        </S.AccountRow>
      </S.WalletItemWrapper>
    </S.Wallet>
  );
};
