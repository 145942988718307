import { Positions } from 'features/Positions/view/components/Positions';
import { ProtectedArea } from 'shared/components';

export const PositionsPage = () => {
  return (
    <ProtectedArea displayMessage every={['accounts.view_account']}>
      <Positions />
    </ProtectedArea>
  );
};
