import styled, { css } from 'styled-components/macro';
import { ReactComponent as SvgClose } from 'shared/assets/icons/icon-close.svg';

import { ReactComponent as SvgSettings } from 'shared/assets/icons/icon-settings.svg';
import { ReactComponent as SvgLimit } from 'shared/assets/icons/icon-threshold.svg';
import { ExchangeIconMin, IconArrow } from '../../components/ExchangeAbstract';

export const AccountWrap = styled.section<{ opened: boolean }>`
  margin: 0 0 10px 0;
  padding: 0 10px 0 30px;

  &:hover {
    ${IconArrow},
    ${ExchangeIconMin} {
      opacity: 1;
    }
  }

  ${(props) =>
    props.opened &&
    css`
      ${IconArrow},
      ${ExchangeIconMin} {
        opacity: 1;
      }
    `};
`;
const IconCss = css`
  width: 15px;
  height: 15px;
  fill: var(--object-secondary);
  stroke: var(--object-secondary);
  margin-left: 10px;
  cursor: pointer;
  position: absolute;
  right: -25px;
  margin-top: -25px;
  pointer-events: auto;
  cursor: pointer;

  &:hover {
    fill: var(--accent-primary);
    stroke: var(--accent-primary);
  }
`;
export const IconQr = styled(SvgSettings)<{ isActive: boolean }>`
  ${IconCss}
  display: ${({ isActive }) => (isActive ? 'block' : 'none')};
`;

export const AccountHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 2px 5px 8px 5px;
`;

export const AccountTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

export const AccountName = styled.div`
  cursor: pointer;
  line-height: 14px;
  font-size: 12px;
  font-weight: 400;
  user-select: none;
`;

export const AccountBalanceStatus = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 0 5px;
`;

export const AccountBalanceUpdated = styled.div<{ isOutdated?: boolean }>`
  margin: 0 0 0 5px;
  font-size: 12px;
  font-weight: 400;
  color: var(--object-secondary);
  white-space: nowrap;
`;

export const IconBalanceWarning = styled(SvgClose)`
  width: 10px;
  height: 10px;
  fill: var(--warning);
`;

export const AccWalletAvailable = styled.span`
  font-size: 12px;
  line-height: 12px;
  color: var(--object-primary);
`;

export const AccWalletTotal = styled.span`
  font-size: 12px;
  line-height: 12px;
  color: var(--object-secondary);
`;

export const WalletItemWrapper = styled.div`
  width: calc(100% + 40px);

  &:hover {
    & > svg {
      display: block;
    }
  }
`;

export const CurrencyIso = styled.div<{
  selected?: boolean;
  isViolated?: boolean;
}>`
  display: flex;
  align-items: center;
  width: 50px;
  min-width: 50px;
  max-width: 50px;
  text-align: left;
  font-size: 12px;

  ${(props) =>
    props.selected &&
    css`
      color: var(--base);

      & > span > svg {
        stroke: var(--base);
      }
    `};

  ${(props) =>
    props.isViolated &&
    css`
      color: var(--error);
    `};
`;

export const AccountRow = styled.div<{
  disabled?: boolean;
  isViolated?: boolean;
}>`
  width: calc(100% - 40px);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 2px 14px;
  margin: 0;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0);
  user-select: none;

  &:last-child {
    margin-bottom: 0;
  }

  ${({ isViolated }) =>
    isViolated &&
    css`
      &:before {
        left: 6px;
        position: absolute;
        content: '';
        width: 2px;
        height: 28px;
        background-color: var(--error) !important;
      }
    `}

  &:hover {
    ${(props) =>
      !props.disabled &&
      css`
        border: 1px solid var(--accent-primary);
      `};
  }
  &.active {
    background: var(--accent-primary);
    border: 1px solid var(--accent-primary);
    color: var(--base);

    ${({ isViolated }) =>
      isViolated &&
      css`
        &:before {
          left: 6px;
          position: absolute;
          content: '';
          width: 2px;
          height: 28px;
          background-color: var(--base);
        }
      `}

    ${AccWalletTotal} {
      opacity: 0.7;
    }
    ${AccWalletAvailable}, ${CurrencyIso}, ${AccWalletTotal} {
      color: var(--dark-bg);
    }
  }

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
      cursor: default;

      ${Currency} > span {
        cursor: pointer;
      }
    `};
`;

export const Wallets = styled.div<{ hasSubAccounts: boolean }>`
  ${(props) =>
    props.hasSubAccounts &&
    css`
      padding: 0 0 0 25px;
    `}
  position: relative;

  ${(props) =>
    props.hasSubAccounts &&
    css`
      &:before {
        content: '';
        position: absolute;
        left: 9px;
        top: 0;
        bottom: 0;
        width: 2px;
        background: var(--background);
      }
    `}
`;

export const Wallet = styled.div`
  padding: 5px 0;
  background: var(--highlight);
  border-radius: 5px;
  margin: 0 0 5px 0;
`;

export const WalletWrap = styled.div`
  padding: 0 5px;
`;

export const WalletHeader = styled.header<{ opened: boolean }>`
  display: flex;
  padding: 5px;
  margin: 0;
  align-items: center;
  cursor: pointer;

  ${(props) =>
    props.opened &&
    css`
      margin: 0 0 3px 0;
    `};
`;

export const WalletTypeName = styled.div`
  cursor: pointer;
  line-height: 14px;
  font-size: 12px;
  font-weight: 400;
  user-select: none;
`;

export const Currency = styled.div<{
  disabled?: boolean;
  hidden?: boolean;
  selected?: boolean;
}>`
  width: auto;
  flex: 1;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  font-family: var(--font-monospaced);
  color: var(--object-primary);

  ${(props) =>
    props.selected &&
    css`
      color: var(--base);
    `};
`;

export const CurrencyTooltip = styled.span`
  font-size: 12px;
  line-height: 14px;
  font-family: var(--font-monospaced);
`;
export const LimitIcon = styled(SvgLimit)`
  margin-left: 5px;
  cursor: pointer;
  stroke: var(--error) !important;

  &:focus {
    outline: none;
  }
`;

export const HintText = styled.p`
  font-size: 8px;
  line-height: 9px;
  text-align: right;
  color: var(--object-primary);
  margin: 0;
  padding: 0;

  &:first-child {
    color: var(--object-secondary);
  }
`;
