import { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { UserStoreInstance } from 'services';
import { SettingCard } from './components/SettingCard';
import { OtpCard } from './components/OtpCard';
import { PaymentLimitCard } from './components/PaymentLimitCard';
import { MultisigGroupsCard } from './components/MultisigGroupsCard';
import { ThemeToggle } from 'shared/components/ThemeToggle';

const IS_MPC_GROUP_ENABLED = !['exantech.multik.io', 'ihodl.multik.io', 'demo.exan.tech', 'demo.multik.io'].includes(window.location.hostname);

import * as S from './styled';

export const Profile = observer(() => {
  const { user } = UserStoreInstance;

  if (!user) return null;
  const { email, vote_weight, has_hotp_secret } = user;

  return (
    <S.ProfileWrapper
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      exit={{ opacity: 0, transition: { duration: 0.5 } }}
    >
      <OtpCard has_hotp={has_hotp_secret} />
      <SettingCard
        title="EMAIL"
        value={email}
        icon={S.IconEmail}
        readOnly={true}
        dataId="email"
      />
      <SettingCard
        title="VOTE WEIGHT"
        value={vote_weight}
        errorMessage={
          !vote_weight
            ? 'Contact the administrator to set vote weight'
            : undefined
        }
        icon={S.IconVoteWeight}
        dataId="weight"
      />
      <PaymentLimitCard title="PAYMENT LIMIT" />
      {IS_MPC_GROUP_ENABLED && <MultisigGroupsCard title="MULTISIG GROUPS" />}
      <ThemeToggle />
    </S.ProfileWrapper>
  );
});
