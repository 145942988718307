import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import { requestPost } from 'shared/axios';
import { QrModal } from '../components/QrModal';
import AppContext from 'shared/contexts/AppContext';

export const useCreateGroupMutation = () => {
  const { openModal } = useContext(AppContext);
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['create-group'],
    mutationFn: async (data: any) => {
      return await requestPost('/mpc/groups/', data);
    },
    onSuccess: (data) => {
      // @ts-ignore
      if (!data.error) {
        openModal({
          title: 'Group created',
          component: () => (
            <QrModal
              encoded={JSON.stringify(data.data)}
              comment={
                <>
                  Scan QR code with <br />
                  <a>Multik Multisignature</a>
                </>
              }
            />
          ),
        });
        queryClient.invalidateQueries({
          queryKey: ['multisig-groups'],
        });
      } else {
        // display error
      }
    },
  });
};
