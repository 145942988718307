// @ts-nocheck
import { AccountType } from 'shared/types';
export const accounts: AccountType[] = [
  {
    id: 1,
    name: 'Account 1',
    exchange: 'BITFINEX',
    master_id: null,
    is_multisig: false,
    hint: 'test hint',
    balance_updated_at: new Date().toISOString(),
    is_balance_outdated: false,
    sgx_aliases: [
      'Alias_1',
      'Alias_2',
      'Alias_3_long-long-long_superlongalias-000000000000000000000000001',
    ],
    wallets: [
      {
        id: 1,
        currency: 'BTC',
        total: '15.45',
        available: '11.7388299',
        deposit_address: '0xBITFINEXxBTCx000000000000000000000000001',
        type: 'funding',
      },
      {
        id: 2,
        currency: 'EURS',
        total: '1353481.23',
        available: '1353481.23',
        deposit_address: '0xBITFINEXxEURSx00000000000000000000000002',
        type: 'margin',
      },
      {
        id: 3,
        currency: 'ETH',
        total: '2440',
        available: '2440',
        deposit_address: '0xBITFINEXxETHx000000000000000000000000003',
        type: 'exchange',
      },
    ],
  },
  {
    id: 2,
    name: 'Account 2',
    exchange: 'BITSTAMP',
    master_id: null,
    is_multisig: false,
    hint: 'Super long hint with maximum length possible blah blah blah blah end',
    balance_updated_at: null,
    is_balance_outdated: false,
    sgx_aliases: ['Alias', 'Alias21'],
    wallets: [
      {
        id: 4,
        currency: 'BTC',
        total: '0.3',
        available: '0.3',
        deposit_address: '0xBITSTAMPxBTCx000000000000000000000000004',
        type: null,
      },
      {
        id: 5,
        currency: 'ETH',
        total: '2',
        available: '2',
        deposit_address: '0xBITSTAMPxETHx000000000000000000000000005',
        type: null,
      },
    ],
  },
  {
    id: 3,
    name: 'Account 3',
    exchange: 'DERIBIT',
    master_id: null,
    hint: '',
    is_multisig: false,
    balance_updated_at: null,
    is_balance_outdated: false,
    sgx_aliases: [],
    wallets: [],
  },
  {
    id: 4,
    name: 'Account 4',
    exchange: 'DERIBIT',
    master_id: null,
    is_multisig: false,
    hint: 'Super long hint with maximum length possible blah blah blah blah end',
    balance_updated_at: new Date().toISOString(),
    is_balance_outdated: true,
    sgx_aliases: [],
    wallets: [
      {
        id: 6,
        currency: 'BTC',
        total: '10.3222',
        available: '10.3222',
        deposit_address: '0xDERIBITxBTCx0000000000000000000000000006',
        type: null,
      },
      {
        id: 7,
        currency: 'ETH',
        total: '240',
        available: '240',
        deposit_address: '0xDERIBITxETHx0000000000000000000000000007',
        type: null,
      },
    ],
  },
  {
    id: 5,
    name: 'Account 5',
    exchange: 'BINANCE',
    master_id: null,
    is_multisig: false,
    hint: '',
    balance_updated_at: null,
    is_balance_outdated: false,
    sgx_aliases: [],
    wallets: [
      {
        id: 8,
        currency: 'USDT',
        total: '300',
        available: '300',
        deposit_address: '0xBINANCExUSDTx000000000000000000000000008',
        type: null,
      },
    ],
  },
  {
    id: 6,
    name: 'Account 6',
    exchange: 'GOZO',
    master_id: null,
    is_multisig: false,
    hint: '',
    balance_updated_at: new Date().toISOString(),
    is_balance_outdated: false,
    sgx_aliases: [],
    wallets: [
      {
        id: 9,
        currency: 'EURS',
        total: '0.00000000',
        available: '0.00000000',
        deposit_address: '0xGOZOxEURSx000000000000000000000000000009',
        type: null,
      },
    ],
  },
  {
    id: 7,
    name: 'Account 7',
    exchange: 'LMAX',
    master_id: null,
    is_multisig: false,
    hint: '',
    balance_updated_at: null,
    is_balance_outdated: false,
    sgx_aliases: [],
    wallets: [
      {
        id: 10,
        currency: 'BTC',
        total: '0.00000000',
        available: '0.00000000',
        deposit_address: '0xLMAXxBTCx0000000000000000000000000000010',
        type: null,
      },
      {
        id: 11,
        currency: 'EURS',
        total: '100000',
        available: '100000',
        deposit_address: '0xLMAXxEURSx000000000000000000000000000011',
        type: null,
      },
      {
        id: 12,
        currency: 'EURS',
        total: '1000',
        available: '1000',
        deposit_address: '0xLMAXxEURSx000000000000000000000000000012',
        type: 'exchange',
      },
    ],
  },
  {
    id: 8,
    name: 'Account_1_test_long_name_overlow-hidden_test_in_UI_transfer',
    exchange: 'BITFINEX',
    master_id: 1,
    hint: '',
    balance_updated_at: null,
    is_multisig: false,
    is_balance_outdated: false,
    sgx_aliases: [],
    wallets: [
      {
        id: 13,
        currency: 'BTC',
        total: '5.12',
        available: '5.12',
        deposit_address: '0xBITFINEXxBTCx000000000000000000000000013',
        type: 'funding',
      },
      {
        id: 14,
        currency: 'ETH',
        total: '6.12',
        available: '6.12',
        deposit_address: '0xBITFINEXxETHx000000000000000000000000014',
        type: 'funding',
      },
      {
        id: 15,
        currency: 'EURS',
        total: '7.12',
        available: '7.12',
        deposit_address: '0xBITFINEXxEURSx00000000000000000000000015',
        type: 'funding',
      },
      {
        id: 16,
        currency: 'BTC',
        total: '1.23424',
        available: '1.23424',
        deposit_address: '0xBITFINEXxBTCx000000000000000000000000016',
        type: 'margin',
      },
      {
        id: 17,
        currency: 'ETH',
        total: '10.23424',
        available: '10.23424',
        deposit_address: '0xBITFINEXxETHx000000000000000000000000017',
        type: 'margin',
      },
      {
        id: 18,
        currency: 'EURS',
        total: '102.23424',
        available: '102.23424',
        deposit_address: '0xBITFINEXxEURSx00000000000000000000000018',
        type: 'margin',
      },
      {
        id: 19,
        currency: 'BTC',
        total: '5',
        available: '5',
        deposit_address: '0xBITFINEXxBTCx000000000000000000000000019',
        type: 'exchange',
      },
      {
        id: 20,
        currency: 'ETH',
        total: '50',
        available: '50',
        deposit_address: '0xBITFINEXxETHx000000000000000000000000020',
        type: 'exchange',
      },
      {
        id: 21,
        currency: 'EURS',
        total: '500',
        available: '500',
        deposit_address: '0xBITFINEXxEURSx00000000000000000000000021',
        type: 'exchange',
      },
    ],
  },
  {
    id: 9,
    name: 'Account 7 subaccount',
    exchange: 'LMAX',
    is_multisig: false,
    master_id: 7,
    hint: '',
    balance_updated_at: null,
    is_balance_outdated: false,
    sgx_aliases: [],
    wallets: [
      {
        id: 22,
        currency: 'USDT',
        total: '45',
        available: '45',
        deposit_address: '0xLMAXxUSDTx000000000000000000000000000022',
        type: null,
      },
      {
        id: 22,
        currency: 'BUSD',
        total: '45',
        available: '45',
        deposit_address: '0xLMAXxBUSDx000000000000000000000000000022',
        type: null,
      },
    ],
  },
  {
    id: 10,
    name: 'Account 10',
    exchange: 'BINANCE',
    is_multisig: false,
    master_id: null,
    hint: '',
    balance_updated_at: null,
    is_balance_outdated: false,
    sgx_aliases: [],
    wallets: [
      {
        id: 23,
        currency: 'BTC',
        total: '2700',
        available: '2700',
        deposit_address: '0xBINANCExUSDTx000000000000000000000000023',
        type: 'funding',
      },
      {
        id: 24,
        currency: 'ETH',
        total: '436',
        available: '436',
        deposit_address: '0xBINANCExETHx0000000000000000000000000024',
        type: 'funding',
      },
      {
        id: 25,
        currency: 'EURS',
        total: '27000',
        available: '27000',
        deposit_address: '0xBINANCExEURSx000000000000000000000000025',
        type: 'funding',
      },
      {
        id: 26,
        currency: 'BTC',
        total: '2700',
        available: '2700',
        deposit_address: '0xBINANCExUSDTx000000000000000000000000026',
        type: 'exchange',
      },
      {
        id: 27,
        currency: 'ETH',
        total: '436',
        available: '436',
        deposit_address: '0xBINANCExETHx0000000000000000000000000027',
        type: 'exchange',
      },
      {
        id: 28,
        currency: 'EURS',
        total: '27000',
        available: '27000',
        deposit_address: '0xBINANCExEURSx000000000000000000000000028',
        type: 'exchange',
      },
      {
        id: 32,
        currency: 'AAVE',
        total: '700',
        available: '700',
        deposit_address: '0xBINANCExAAVEx000000000000000000000000032',
        type: 'funding',
      },
    ],
  },
  {
    id: 11,
    name: 'Account 11',
    exchange: 'BITFINEX',
    master_id: null,
    is_multisig: false,
    hint: '',
    balance_updated_at: null,
    is_balance_outdated: false,
    sgx_aliases: [],
    wallets: [
      {
        id: 29,
        currency: 'EUR',
        total: '300',
        available: '300',
        deposit_address: '0xBITFINEXxEURx000000000000000000000000029',
        type: 'funding',
      },
      {
        id: 30,
        currency: 'USD',
        total: '500',
        available: '500',
        deposit_address: '0xBITFINEXxUSDx000000000000000000000000030',
        type: 'funding',
      },
      {
        id: 31,
        currency: 'AAVE',
        total: '7500',
        available: '7500',
        deposit_address: '0xBITFINEXxAAVEx00000000000000000000000031',
        type: 'funding',
      },
      {
        id: 33,
        currency: 'AAVE',
        total: '700',
        available: '700',
        deposit_address: '0xBITFINEXxAAVEx00000000000000000000000033',
        type: 'funding',
      },
    ],
  },
];
