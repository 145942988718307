/* eslint-disable */
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { format, parseISO } from 'date-fns';

import * as S from '../../styled';
import AppContext from 'shared/contexts/AppContext';
import { Position } from '../../../types';
import { StatusType } from 'shared/types';
import {
  _removeTrailingZeros,
  formatAccount,
  formatNumberWithoutExponent,
} from 'shared/helpers/format';
import { IconInfo } from '../Filters/styled';
import Tippy from '@tippyjs/react';
import { CurrenciesStoreInstance } from 'services';
import Decimal from 'decimal.js-light';

interface PositionRowProps {
  position: Position;
  viewInfo: any;
}

export const PositionRow = ({ position, viewInfo }: PositionRowProps) => {
  const { rates } = useContext(AppContext);
  const { getCurrencyPrecision, getCurrencyValue } = CurrenciesStoreInstance;

  const {
    external_id,
    pair,
    type,
    size,
    price_entry,
    time_entry,
    origin_funding,
    origin_pnl_unrealized,
    mark_price,
    pnl_currency,
  } = position;

  const timeEntryDate = time_entry
    ? format(parseISO(time_entry), 'yyyy-MM-dd')
    : '-';
  const timeEntryTime = time_entry
    ? format(parseISO(time_entry), 'HH:mm:ss')
    : '-';

  return (
    <S.Row key={external_id} data-id={external_id}>
      <S.PositionIntro>
        <S.DateCol>{pair}</S.DateCol>
        <S.TxCol>
          {type === 'LONG' ? <S.IconLong /> : <S.IconShort />}
          {type}
        </S.TxCol>
        <S.FromCol>
          {Number(size) >= 1 && Number(size) <= -1
            ? parseInt(size)
            : _removeTrailingZeros(size)}
        </S.FromCol>
        <S.ToCol>
          {Number(price_entry) > 100
            ? Math.round(Number(price_entry))
            : Number(price_entry).toLocaleString('en-US', {
                maximumFractionDigits: 20,
                minimumFractionDigits: 0,
              })}{' '}
          USD
        </S.ToCol>
        <S.AmountCol>
          {time_entry ? (
            <>
              <div>{timeEntryDate}</div>
              <span style={{ color: 'var(--object-secondary)' }}>
                {timeEntryTime}
              </span>{' '}
            </>
          ) : (
            <div style={{ paddingLeft: '26px' }}>-</div>
          )}
        </S.AmountCol>
        <S.ConfirmationsCol
          style={{
            color:
              Number(origin_funding) < 0 ? 'var(--error)' : 'var(--success)',
          }}
        >
          {getCurrencyValue({
            // @ts-ignore
            currency: pnl_currency,
            value: new Decimal(String(Number(origin_funding))).toFixed(),
            // @ts-ignore
            minprecision: getCurrencyPrecision(pnl_currency),
          })}{' '}
          {pnl_currency}
        </S.ConfirmationsCol>
        <S.StatusCol>
          {mark_price
            ? getCurrencyValue({
                // @ts-ignore
                currency: viewInfo?.id,
                value: new Decimal(size)
                  .mul(mark_price)
                  .mul(
                    viewInfo.currency === 'EUR'
                      ? rates?.[viewInfo?.currency]?.['USD']
                      : 1,
                  )
                  .div(viewInfo?.divisor)
                  .toFixed(),
                // @ts-ignore
                minprecision: getCurrencyPrecision(viewInfo?.id),
              })
            : getCurrencyValue({
                // @ts-ignore
                currency: viewInfo?.id,
                value: new Decimal(size)
                  .mul(price_entry)
                  .plus(origin_pnl_unrealized)
                  .mul(rates?.[viewInfo?.currency]?.['USD'])
                  .div(viewInfo?.divisor)
                  .toFixed(),
                // @ts-ignore
                minprecision: getCurrencyPrecision(viewInfo?.id),
              })}
          {viewInfo?.divisor_label} {viewInfo?.currency}
        </S.StatusCol>
      </S.PositionIntro>
    </S.Row>
  );
};
