import { toJS } from 'mobx';
import { SafeStoreInstance } from 'features/Multisig';

const useSafeAddress = () => {
  const { selectedSafe } = toJS(SafeStoreInstance);
  const fullAddress = selectedSafe?.address.value || '';

  return fullAddress as string;
};

export default useSafeAddress;
