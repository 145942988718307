import { useContext, useEffect, useState } from 'react';

import { PositionsHeader } from './PositionsHeader';
import { PositionRow } from './PositionRow';
import { PositionsFilters } from './PositionsFilters';
import { List } from '../shared/List';
import { NotFound } from '../shared/NotFound';
import { ListWrapper } from '../../styled';

import _ from 'lodash';

import { usePositions } from '../hooks/usePositions';
import { PositionExchange } from './PositionExchange';
import { ReactComponent as SvgUsd } from 'shared/assets/icons/views/logo-usd.svg';
import { useFilters } from 'shared/hooks/useFilters';
import AppContext from 'shared/contexts/AppContext';

export const Positions = () => {
  const { rates } = useContext(AppContext);
  const [getFilters, setFilters] = useFilters('positions_filters');
  const filters = getFilters();
  const [search, setSearch] = useState(filters.search ? filters.search : '');
  const { data, isLoading, isInitialLoading, refetch } = usePositions(search);
  const [viewInfo, setViewInfo] = useState({
    id: 'usd',
    label: 'USD',
    currency: 'USD',
    currency_label: '$',
    currency_icon: <SvgUsd />,
    divisor: 1,
    divisor_label: null,
  });

  const onSubmit = (query: string) => {
    setSearch(query);
  };

  const [isAsideButtonChecked, setIsAsideButtonChecked] = useState('unset');

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1300);

  useEffect(() => {
    function handleResize() {
      if (isAsideButtonChecked === 'unset') {
        setIsMobile(window.innerWidth < 1300);
      }
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isAsideButtonChecked]);

  const isEmpty = Object.keys(data).length === 0;

  return (
    <>
      <ListWrapper
        style={{ paddingBottom: isEmpty ? '48.5px' : '0px' }}
        isMobile={isMobile}
        isAsideButtonChecked={isAsideButtonChecked}
      >
        <PositionsHeader />
        <List
          isInitialLoading={isInitialLoading}
          isLoading={isLoading || Object.keys(rates).length === 0}
          isFetching={false}
          row={PositionExchange}
          data={data}
          viewInfo={viewInfo}
        />
        <NotFound paddingTop={false} active={!isLoading && isEmpty}>
          No positions found
        </NotFound>
      </ListWrapper>
      {/* TODO: add otp modal */}
      <PositionsFilters
        setViewInfo={setViewInfo}
        viewInfo={viewInfo}
        search={search}
        setSearch={setSearch}
        disabled={isLoading}
        onSubmit={onSubmit}
        isMobile={isMobile}
        isAsideButtonChecked={isAsideButtonChecked}
        setIsAsideButtonChecked={setIsAsideButtonChecked}
        setIsMobile={setIsMobile}
      />
    </>
  );
};
