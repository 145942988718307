import { useEffect, useMemo } from 'react';
import type { BigNumber } from 'ethers';
import type Safe from '@safe-global/safe-core-sdk';
import { encodeSignatures } from '../services/encodeSignatures';
import type { SafeTransaction } from '@safe-global/safe-core-sdk-types';
import { OperationType } from '@safe-global/safe-core-sdk-types';
import useAsync from 'features/Multisig/view/hooks/useAsync';
import useSafeAddress from 'features/SafeApp/hooks/useSafeAddress';
import { useWeb3ReadOnly } from 'features/SafeApp/helpers/web3';
import useWallet from 'features/SafeApp/hooks/useWallet';
import { useSafeSDK } from 'features/SafeApp/coreSDK/safeCoreSDK';
import useIsSafeOwner from 'features/SafeApp/hooks/useIsSafeOwner';
import { Errors, logError } from 'shared/helpers/exceptions';
import { _getChainId } from 'features/Multisig/helpers';
import chains from 'features/SafeApp/constants/chains';

const getEncodedSafeTx = (safeSDK: Safe, safeTx: SafeTransaction, from?: string): string => {
  const EXEC_TX_METHOD = 'execTransaction';

  return safeSDK
    .getContractManager()
    .safeContract.encode(EXEC_TX_METHOD, [
      safeTx.data.to,
      safeTx.data.value,
      safeTx.data.data,
      safeTx.data.operation,
      safeTx.data.safeTxGas,
      0,
      safeTx.data.gasPrice,
      safeTx.data.gasToken,
      safeTx.data.refundReceiver,
      encodeSignatures(safeTx, from),
    ]);
};

const incrementByPercentage = (value: BigNumber, percentage: number): BigNumber => {
  return value.mul(100 + percentage).div(100);
};

const useGasLimit = (
  safeTx?: SafeTransaction,
): {
  gasLimit?: BigNumber
  gasLimitError?: Error
  gasLimitLoading: boolean
} => {
  const safeSDK = useSafeSDK();
  const web3ReadOnly = useWeb3ReadOnly();
  const safeAddress = useSafeAddress();
  const wallet = useWallet();
  const walletAddress = wallet?.address;
  const isOwner = useIsSafeOwner();
  const currentChainId = _getChainId();

  const encodedSafeTx = useMemo<string>(() => {
    if (!safeTx || !safeSDK || !walletAddress) {
      return '';
    }
    return getEncodedSafeTx(safeSDK, safeTx, isOwner ? walletAddress : undefined);
  }, [safeSDK, safeTx, walletAddress, isOwner]);

  const operationType = useMemo<number>(
    () => (safeTx?.data.operation == OperationType.DelegateCall ? 1 : 0),
    [safeTx?.data.operation],
  );

  const [gasLimit, gasLimitError, gasLimitLoading] = useAsync<BigNumber>(() => {
    if (!safeAddress || !walletAddress || !encodedSafeTx || !web3ReadOnly) return;

    return web3ReadOnly.estimateGas({
      to: safeAddress,
      from: walletAddress,
      data: encodedSafeTx,
      type: operationType,
    })
    .then((gasLimit) => {
        // Due to a bug in Nethermind estimation, we need to increment the gasLimit by 30%
        // when the safeTxGas is defined and not 0. Currently Nethermind is used only for Gnosis Chain.
        if (currentChainId === chains.gno) {
          const incrementPercentage = 30; // value defined in %, ex. 30%
          const isSafeTxGasSetAndNotZero = !!safeTx?.data?.safeTxGas;
          if (isSafeTxGasSetAndNotZero) {
            return incrementByPercentage(gasLimit, incrementPercentage);
          }
        }

        return gasLimit;
      });
  }, [safeAddress, walletAddress, encodedSafeTx, web3ReadOnly, operationType]);

  useEffect(() => {
    if (gasLimitError) {
      logError(Errors._612, gasLimitError.message);
    }
  }, [gasLimitError]);

  return { gasLimit, gasLimitError, gasLimitLoading };
};

export default useGasLimit;
