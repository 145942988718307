import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  html,
  body {
    height: 100%;
    margin: 0;
    padding: 0;
    min-height: 100%;
  }
  body {
    display: flex;
    overflow-y: overlay;
    overflow-x: hidden;
    background: var(--background);
    color: var(--object-primary);
    font-family: var(--font-main);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  #root {
    display: flex;
    width: 100%;
  }
  #root {
    min-height: 100%;
    flex: 1;
  }
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none; // Yeah, yeah everybody write about it
  }

  input[type='number'],
  input[type="number"]:hover,
  input[type="number"]:focus {
      appearance: none;
      -moz-appearance: textfield;
  }
  p {
    margin: 0;
    & + p {
      margin: 10px 0 0 0;
    }
    
  }

  .tippy-box[data-theme~='transparent'] {
    background-color: transparent;
  }  
  .tippy-box[data-theme~='transparent'] > .tippy-content {
    padding:0px;
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.1));
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    padding: 5px;
    border-radius: 5px;
    background-color: var(--base);
    color: var(--object-primary);
    position: relative;
    width: fit-content;
  }  
  .tippy-box[data-theme~='transparent'] > .tippy-arrow {
    z-index: 999999;
    position: absolute !important;
    transform: rotate(45deg) !important;
    width: 8px !important;
    height: 8px !important;
    background-color: var(--base) !important;
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.1)) !important;
  }

  .tippy-box[data-theme~='transparent'] > .tippy-arrow::before {
    display: none !important;
  }
  .tippy-box[data-theme~='transparent'][data-placement^='top'] > .tippy-arrow {
    top: calc(100% - 4px) !important;
    left: calc(50% - 4px) !important;
  }
  .tippy-box[data-theme~='transparent'][data-placement^='bottom'] > .tippy-arrow {
    top: -4px !important;
    left: calc(50% - 4px) !important;
  }
  .tippy-box[data-theme~='transparent'][data-placement^='left'] > .tippy-arrow {
    top: calc(50% - 4px) !important;
    right: -4px !important;
  }
  .tippy-box[data-theme~='transparent'][data-placement^='right'] > .tippy-arrow {
    top: calc(50% - 4px)!important;
    left: -4px !important;
  }
  .react-datepicker__tab-loop {
    position: absolute;
  }
  .react-datepicker {
    border: 1px solid var(--border);
  }
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
    border-bottom-color: var(--background);
  }
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    border-bottom-color: var(--border);
  }
  .react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover {
    background: var(--accent-secondary);
    color: var(--dark-bg);
  }
  .react-datepicker-popper  {
    z-index: 9999999 !important;
  }
  .react-datepicker__header {
    background-color: var(--background);
    border: 0;
  }
  .react-datepicker__input-container input {
    max-width: 95px;
    min-width: 95px;
    padding: 5px 8px;
    background: none;
    border: 1px solid var(--border);
    font-size: 12px;
    border-radius: 5px;
    line-height: 20px;
    width: 100%;

    &:focus-visible,
    &:focus {
      outline: var(--accent-primary) solid 1px;
    }
  }
  .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    max-width: 24px;
    font-size: 12px;
    line-height: 24px;
    margin: 2px;
    user-select: none;
    color: var(--object-primary);
  }
  .react-datepicker__day-name {
    opacity: .8;
    color: var(--object-primary);
  }
  .react-datepicker__header {
    padding-top: 6px;
    border-radius: 4px 4px 0 0;
  }
  .react-datepicker__navigation--next {
    border-left-color: var(--accent-primary);
  }
  .react-datepicker__navigation--previous {
    border-right-color: var(--accent-primary);
  }
  .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
    color: var(--accent-primary);
    font-weight: 700;
    font-size: 14px;
  }
  .react-datepicker__month-container {
    background: var(--base);
    border-radius: 4px;
  }
  .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
    background-color: var(--accent-primary);
    color: var(--dark-bg);
  }
  .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
    background-color: var(--accent-primary);
    color: var(--dark-bg);
  }

  @keyframes highlight {
    0%, 75% {box-shadow: inset 0 0 0 2px #2749f1;}
    100% {box-shadow: inset 0 0 0 2px transparent;}
  }

  .highlighted-border {
    animation-name: highlight;
    animation-duration: 4s;
  }

   /* For WebKit (Safari, Chrome) */
  ::-webkit-scrollbar {
    background-color: var(--border);
  }

  ::-webkit-scrollbar-track {
    background-color: var(--base);
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--border);
    border: 3px solid transparent;
    border-radius: 9px;
    background-clip: content-box;
  }
  
  ::-webkit-scrollbar-corner {
    background-color: var(--base);
  }

`;
