import { useContext, useEffect, useState } from 'react';
import * as Scroll from 'react-scroll';
import {
  Link,
  Button,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from 'react-scroll';
import * as S from './styled';
import Articles from './../content/articles';

import AppContext from 'shared/contexts/AppContext';
import { CollapsedCard } from '../components/CollapsedCard';
import { ManagementApp } from '../components/ManagementApp';

type TabType = 'all' | 'exchange' | 'other';

const HELPDESK_TABS: { title: string; alias: TabType; content: any }[] = [
  {
    title: 'All',
    alias: 'all',
    content: Articles,
  },
  {
    title: 'Exchange settings',
    alias: 'exchange',
    content: Articles.filter((item) => item.category === 'exchange'),
  },
  {
    title: 'Other',
    alias: 'other',
    content: Articles.filter((item) => item.category === 'other'),
  },
];

export const Helpdesk = () => {
  const { appSettings } = useContext(AppContext);

  useEffect(() => {
    scrollSpy.update();
  }, []);

  const addSpace = () => {
    const sections = document.querySelectorAll<HTMLElement>('.tab_section');
    if (sections) {
      const lastestSection = sections[sections.length - 1];
      if (lastestSection?.clientHeight < window.innerHeight) {
        const margin = window.innerHeight - lastestSection.clientHeight - 70;
        lastestSection.style.marginBottom = margin + 'px';
      }
    }
  };

  useEffect(() => {
    addSpace;
    window.addEventListener('resize', addSpace, false);
  }, [HELPDESK_TABS]);

  return (
    <S.Wrap
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      exit={{ opacity: 0, transition: { duration: 0.5 } }}
    >
      <S.Header>
        <S.AnchorList>
          {HELPDESK_TABS.map((item) => {
            const count = item.content.length;
            return (
              <S.Anchor
                key={item.alias}
                to={item.alias}
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                isDynamic={true}
              >
                <span>{item.title}</span>&nbsp;{count}
              </S.Anchor>
            );
          })}
        </S.AnchorList>
      </S.Header>
      {HELPDESK_TABS.map((item) => {
        return (
          <S.Section
            key={item?.alias}
            name={item?.alias}
            className={item?.alias + ' tab_section'}
          >
            {item?.alias != 'all' ? (
              <div style={{ width: '100%' }}>
                <S.SectionTitle>
                  <S.IconDoc />
                  {item.title}
                </S.SectionTitle>
                {item?.alias === 'exchange' &&
                appSettings.application_direct_link &&
                appSettings.application_playmarket_link ? (
                  <CollapsedCard
                    collapseId="store"
                    title="GET THE APP"
                    icon={S.IconDevice}
                  >
                    <ManagementApp
                      direct={appSettings.application_direct_link}
                      playmarket={appSettings.application_playmarket_link}
                    />
                  </CollapsedCard>
                ) : null}
                {item.content ? (
                  <S.ArticlesGrid>
                    {item.content.map((article: any, index: number) => {
                      return (
                        <S.ArticlesItem key={index}>
                          <S.ArticleHeader>
                            <S.ArticleTitle>{article.title}</S.ArticleTitle>
                          </S.ArticleHeader>
                          <S.ArticleDescription>
                            {article.description}
                          </S.ArticleDescription>
                          <S.ArticleLink href={article.url} target="_blank" />
                        </S.ArticlesItem>
                      );
                    })}
                  </S.ArticlesGrid>
                ) : null}
              </div>
            ) : null}
          </S.Section>
        );
      })}
    </S.Wrap>
  );
};
