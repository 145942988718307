import { useEffect } from 'react';
import { SafeAppData } from '@gnosis.pm/safe-react-gateway-sdk';
import { getSafeApps } from '@gnosis.pm/safe-react-gateway-sdk';
import { Errors, logError } from 'shared/helpers/exceptions';
import { AsyncResult } from 'features/Multisig/view/hooks/useAsync';
import useAsync from 'features/Multisig/view/hooks/useAsync';
import { trimTrailingSlash } from '../helpers/url';

const useSafeAppFromBackend = (url: string, chainId: string): AsyncResult<SafeAppData> => {
  const [backendApp, error, loading] = useAsync(async () => {
    if (!chainId) return;

    // We do not have a single standard for storing URLs, it may be stored with or without a trailing slash.
    // But for the request it has to be an exact match.
    const retryUrl = url.endsWith('/') ? trimTrailingSlash(url) : `${url}/`;
    let response = await getSafeApps(chainId, { url });
    if (!response[0]) {
      response = await getSafeApps(chainId, { url: retryUrl });
    }

    return response?.[0];
  }, [chainId, url]);

  useEffect(() => {
    if (error) {
      logError(Errors._900, error.message);
    }
  }, [error]);

  return [backendApp, error, loading];
};

export { useSafeAppFromBackend };
