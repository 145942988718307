import styled, { css } from 'styled-components/macro';
import { motion } from 'framer-motion';

export const ModalWrap = styled.div<{ background?: string }>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--base);
  z-index: 99;
  overflow: auto;

  ${(props) =>
    props.background === 'gray' &&
    css`
      background-color: var(--background);
    `};
`;
export const ModalPanel = styled(motion.div)``; // required for motion and tw prop
export const ModalContentWrap = styled.div`
  padding: 0;
  background-color: var(--background);
`;
export const ModalContent = styled.div`
  padding: 30px;
  margin: 30px 0 0 0;
  background: var(--base);
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  flex: 1;
`;
export const ModalTitle = styled.h1<{ status?: string }>`
  color: var(--object-secondary);
  font-size: 36px;
  font-weight: 300;
  margin: 0;
  line-height: 43px;
  width: 100%;

  span {
    font-weight: bold;
    color: var(--success);
  }

  ${(props) =>
    props.status === 'failed' &&
    css`
      span {
        color: var(--error);
      }
    `};
`;
export const ModalBodyWrap = styled.div`
  padding: 25px 0;
  width: 100%;
`;
