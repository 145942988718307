import axios, { AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import { API_URL } from 'shared/constants/app';
import { AuthStoreInstance } from 'services';

export const interceptors = () => {
  axios.interceptors.request.use((config: AxiosRequestConfig) => {
    if (typeof config.headers?.NOINTERCEPT !== 'undefined') {
      return config;
    }

    let headers: AxiosRequestHeaders | undefined = config.headers;

    const { url, method = '' } = config;
    config.url = `${API_URL}${url}`;

    if (typeof headers?.noToken === 'undefined') {
      const appToken = localStorage.getItem('appToken') ?? null;
      headers = {
        ...headers,
        authorization: `Token ${appToken}`,
      };
    } else {
      delete headers.noToken;
    }

    if (['POST', 'PATCH'].includes(method)) {
      headers.contentType = 'application/json';
    }

    return {
      ...config,
      headers,
    };
  });

  axios.interceptors.response.use((response) => response, (error) => {
    if (error?.response?.status === 401) {
      AuthStoreInstance.signOut();
    }
    return Promise.reject(error);
  });
};
