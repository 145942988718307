// @ts-nocheck
import { StatusType, TransactionType } from 'shared/types';

export const transactions: TransactionType[] = [
  {
    id: 284382,
    updated_at: '2022-04-04T14:12:58Z',
    deposit_address: '0x511B0fE21847Adf96021C8A3BaEDafb3c9354f0C',
    currency: 'USDC',
    amount: '449735.00000000',
    fee: null,
    account_from: null,
    account_to: {
      id: 1,
      name: 'Bitfinex Gozo Exchange Main',
      exchange: 'BITFINEX',
    },
    tx_id: '0x670a10ee72ec170c2345deabcfd2ae10cc70b38561c16407b14cf93be42f6dba',
    external_id: null,
    exchange_gate_id: null,
    withdrawal_status: null,
    withdrawal_executed_at: null,
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: null,
    description: '',
  },
  {
    id: 284381,
    updated_at: '2022-04-04T14:11:28Z',
    deposit_address: '0x511B0fE21847Adf96021C8A3BaEDafb3c9354f0C',
    currency: 'EURS',
    amount: '449735.00000000',
    fee: null,
    account_from: null,
    account_to: {
      id: 1,
      name: 'Bitfinex Gozo Exchange Main',
      exchange: 'BITFINEX',
    },
    tx_id: '0x7705476d37174d5c7baad7654966e0d9d0b242122cdf9c7fb7f670a8a6491e2c',
    external_id: null,
    exchange_gate_id: null,
    withdrawal_status: null,
    withdrawal_executed_at: null,
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: null,
    description: '',
  },
  {
    id: 284380,
    updated_at: '2022-04-01T10:40:26Z',
    deposit_address: '0x6277f6034086dbf25ac2683aa42688d2e53119e4',
    currency: 'USDC',
    amount: '500000.00000000',
    fee: null,
    account_from: null,
    account_to: {
      id: 7,
      name: 'iHodl Bitstamp',
      exchange: 'BITSTAMP',
    },
    tx_id: '0x9d95390951ab1f8e8c469c1dd19e362631199107ce103bb6d6840366d367189f',
    external_id: null,
    exchange_gate_id: null,
    withdrawal_status: null,
    withdrawal_executed_at: null,
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: null,
    description: '',
  },
  {
    id: 284379,
    updated_at: '2022-04-01T10:26:05Z',
    deposit_address: '0x6F3F68525E5EdaD6F06f8b0EaE0DD7B9F695aF13',
    currency: 'EURS',
    amount: '419996.45090000',
    fee: '3.54910000',
    account_from: {
      id: 1,
      name: 'Bitfinex Gozo Exchange Main',
      exchange: 'BITFINEX',
    },
    account_to: {
      id: 12,
      name: '0x6F3F iHodl Safe',
      exchange: 'WALLETS',
    },
    tx_id: '0x0631ec5080c85f01347f90839b6587f9f1c33a5c0966a37ccf054561e4f5c867',
    external_id: '18109871',
    exchange_gate_id: '1623',
    withdrawal_status: 'Completed',
    withdrawal_executed_at: '2022-04-01T10:26:05Z',
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: null,
    description: '',
  },
  {
    id: 284378,
    updated_at: '2022-03-31T16:24:02Z',
    deposit_address: '0x6277f6034086Dbf25aC2683aA42688D2e53119e4',
    currency: 'ETH',
    amount: '199.00000000',
    fee: '0.00000000',
    account_from: {
      id: 1,
      name: 'Bitfinex Gozo Exchange Main',
      exchange: 'BITFINEX',
    },
    account_to: {
      id: 7,
      name: 'iHodl Bitstamp',
      exchange: 'BITSTAMP',
    },
    tx_id: null,
    external_id: '18104723',
    exchange_gate_id: '1622',
    withdrawal_status: 'Canceled',
    withdrawal_executed_at: null,
    deposit_status: null,
    status: StatusType.CANCELED,
    comment: '',
    created_by: 'ak@exante.eu',
    description: '',
  },
  {
    id: 284377,
    updated_at: '2022-03-31T14:35:59Z',
    deposit_address: '35kVfHwEejHig8qbP8KVpVweebSSh2oEmU',
    currency: 'BTC',
    amount: '1.10630212',
    fee: null,
    account_from: null,
    account_to: {
      id: 7,
      name: 'iHodl Bitstamp',
      exchange: 'BITSTAMP',
    },
    tx_id: '5cbf905e1d5bb63bad583e46a0a08badf46c4fb79323315237910abc674001d8',
    external_id: null,
    exchange_gate_id: null,
    withdrawal_status: null,
    withdrawal_executed_at: null,
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: null,
    description: '',
  },
  {
    id: 284376,
    updated_at: '2022-03-31T12:49:13Z',
    deposit_address: '0x6277f6034086Dbf25aC2683aA42688D2e53119e4',
    currency: 'ETH',
    amount: '1.00000000',
    fee: '0.00000000',
    account_from: {
      id: 1,
      name: 'Bitfinex Gozo Exchange Main',
      exchange: 'BITFINEX',
    },
    account_to: {
      id: 7,
      name: 'iHodl Bitstamp',
      exchange: 'BITSTAMP',
    },
    tx_id: '0x438ba99732222b3c613c0c037f94a1f068da44e876332c4bc013da5a87e5e706',
    external_id: '18102810',
    exchange_gate_id: '1619',
    withdrawal_status: 'Completed',
    withdrawal_executed_at: '2022-03-31T12:00:47Z',
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: 'ak@exante.eu',
    description: '',
  },
  {
    id: 284375,
    updated_at: '2022-03-31T04:59:44Z',
    deposit_address: '0x0427933133113C016C398202352307539430c900',
    currency: 'USDT',
    amount: '400000.00000000',
    fee: '0.00000000',
    account_from: {
      id: 10,
      name: 'iHodl Binance',
      exchange: 'BINANCE',
    },
    account_to: {
      id: 13,
      name: 'iHodl new Safe Treasury',
      exchange: 'WALLETS',
    },
    tx_id: '0xe6bff1375f1b6c2f067aa173995b6fd96ef025ed26044e15807e552c41edfeba',
    external_id: '408efc11ac154903956c2e966b7b397c',
    exchange_gate_id: '1618',
    withdrawal_status: 'Completed',
    withdrawal_executed_at: '2022-03-31T04:59:44Z',
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: 'giv@exante.eu',
    description: 'Liquidity',
  },
  {
    id: 284374,
    updated_at: '2022-03-29T15:53:56Z',
    deposit_address: '35kVfHwEejHig8qbP8KVpVweebSSh2oEmU',
    currency: 'BTC',
    amount: '2.99960000',
    fee: '0.00040000',
    account_from: {
      id: 1,
      name: 'Bitfinex Gozo Exchange Main',
      exchange: 'BITFINEX',
    },
    account_to: {
      id: 7,
      name: 'iHodl Bitstamp',
      exchange: 'BITSTAMP',
    },
    tx_id: 'a5cf87a440911682021d33ba0838c61a0b52f4a4aba7a75c98be7b1023602378',
    external_id: '18089943',
    exchange_gate_id: '1615',
    withdrawal_status: 'Completed',
    withdrawal_executed_at: '2022-03-29T15:30:14Z',
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: null,
    description: '',
  },
  {
    id: 284373,
    updated_at: '2022-03-23T11:16:53Z',
    deposit_address: '0x0427933133113C016C398202352307539430c900',
    currency: 'USDT',
    amount: '500000.00000000',
    fee: '0.00000000',
    account_from: {
      id: 10,
      name: 'iHodl Binance',
      exchange: 'BINANCE',
    },
    account_to: {
      id: 13,
      name: 'iHodl new Safe Treasury',
      exchange: 'WALLETS',
    },
    tx_id: '0xe28309e2ec720bc9c627c39de5a0da77de068153e5d2ef89a6fb2ba79efc1d10',
    external_id: 'a8bbe391b86d4e5199f7ce52eaf4178c',
    exchange_gate_id: '1614',
    withdrawal_status: 'Completed',
    withdrawal_executed_at: '2022-03-23T11:16:53Z',
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: 'giv@exante.eu',
    description: 'liquidity management',
  },
  {
    id: 284372,
    updated_at: '2022-03-21T02:42:03Z',
    deposit_address: '0x6277f6034086dbf25ac2683aa42688d2e53119e4',
    currency: 'ETH',
    amount: '2.05090768',
    fee: null,
    account_from: null,
    account_to: {
      id: 7,
      name: 'iHodl Bitstamp',
      exchange: 'BITSTAMP',
    },
    tx_id: '0x57db38bf97e62ea280cb5f2a9871d493e43880ed5adb2ae1d27af7f20108152a',
    external_id: null,
    exchange_gate_id: null,
    withdrawal_status: null,
    withdrawal_executed_at: null,
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: null,
    description: '',
  },
  {
    id: 284371,
    updated_at: '2022-03-09T13:00:16Z',
    deposit_address: '0x0427933133113C016C398202352307539430c900',
    currency: 'ETH',
    amount: '50.00000000',
    fee: '0.00000000',
    account_from: {
      id: 1,
      name: 'Bitfinex Gozo Exchange Main',
      exchange: 'BITFINEX',
    },
    account_to: {
      id: 13,
      name: 'iHodl new Safe Treasury',
      exchange: 'WALLETS',
    },
    tx_id: '0x98a29eb5388de0956c1d5b09aed6c18ded115ab9d4b09f294c2b4dedacab31a4',
    external_id: '17970839',
    exchange_gate_id: '1611',
    withdrawal_status: 'Completed',
    withdrawal_executed_at: '2022-03-09T13:00:16Z',
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: 'ge@exante.eu',
    description: '',
  },
  {
    id: 284370,
    updated_at: '2022-03-02T10:53:05Z',
    deposit_address: '0x0427933133113C016C398202352307539430c900',
    currency: 'USDT',
    amount: '500000.00000000',
    fee: '0.00000000',
    account_from: {
      id: 10,
      name: 'iHodl Binance',
      exchange: 'BINANCE',
    },
    account_to: {
      id: 13,
      name: 'iHodl new Safe Treasury',
      exchange: 'WALLETS',
    },
    tx_id: '0x11268c7dd312eabb5d78aa178124b920f0967020c891840365e3abdf8caba656',
    external_id: 'b5a6212ef6ad40aa84360e904821a621',
    exchange_gate_id: '1610',
    withdrawal_status: 'Completed',
    withdrawal_executed_at: '2022-03-02T10:53:05Z',
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: 'giv@exante.eu',
    description: 'Liquidity management',
  },
  {
    id: 284369,
    updated_at: '2022-03-01T02:46:47Z',
    deposit_address: '35kVfHwEejHig8qbP8KVpVweebSSh2oEmU',
    currency: 'BTC',
    amount: '0.00226383',
    fee: null,
    account_from: null,
    account_to: {
      id: 7,
      name: 'iHodl Bitstamp',
      exchange: 'BITSTAMP',
    },
    tx_id: '11d242198af65495db75ec1c94e5ebb053651ce848ca0deb64675d26151b5816',
    external_id: null,
    exchange_gate_id: null,
    withdrawal_status: null,
    withdrawal_executed_at: null,
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: null,
    description: '',
  },
  {
    id: 284383,
    updated_at: '2022-02-28T14:07:42Z',
    deposit_address: '0x1dc7668579bfbb1b9982354764e9e85398b06feb',
    currency: 'ETH',
    amount: '100.00000000',
    fee: '0.00500000',
    account_from: {
      id: 7,
      name: 'iHodl Bitstamp',
      exchange: 'BITSTAMP',
    },
    account_to: null,
    tx_id: '0xb0cac5a8c7ecaa5d77020cb8f2c275571f95cb2d98d8234880c1b2136d6dfa95',
    external_id: '14018430',
    exchange_gate_id: '1604',
    withdrawal_status: 'Completed',
    withdrawal_executed_at: '2022-02-28T14:07:42Z',
    deposit_status: null,
    status: StatusType.SENT,
    created_by: null,
    description: '',
  },
  {
    id: 284368,
    updated_at: '2022-02-28T09:54:46Z',
    deposit_address: '0x6F3F68525E5EdaD6F06f8b0EaE0DD7B9F695aF13',
    currency: 'USDT',
    amount: '19313200.00000000',
    fee: '3.82760000',
    account_from: {
      id: 1,
      name: 'Bitfinex Gozo Exchange Main',
      exchange: 'BITFINEX',
    },
    account_to: {
      id: 12,
      name: '0x6F3F iHodl Safe',
      exchange: 'WALLETS',
    },
    tx_id: '0xa3c65085ff05573bfbdf9dcf12a452bb9d2dce1f3801919baf21efc79b92bd05',
    external_id: '17915384',
    exchange_gate_id: '1601',
    withdrawal_status: 'Completed',
    withdrawal_executed_at: '2022-02-28T09:54:46Z',
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: null,
    description: '',
  },
  {
    id: 284367,
    updated_at: '2022-02-24T13:16:40Z',
    deposit_address: '0x0427933133113C016C398202352307539430c900',
    currency: 'USDT',
    amount: '300000.00000000',
    fee: '0.00000000',
    account_from: {
      id: 10,
      name: 'iHodl Binance',
      exchange: 'BINANCE',
    },
    account_to: {
      id: 13,
      name: 'iHodl new Safe Treasury',
      exchange: 'WALLETS',
    },
    tx_id: '0xbd3eed380507d2bfaf6891eba5538deaa4d976cfdb126d8b29022099a344a0f6',
    external_id: 'd2dfe1a6bcd54bc0bc34e5771f2f7782',
    exchange_gate_id: '1598',
    withdrawal_status: 'Completed',
    withdrawal_executed_at: '2022-02-24T13:16:40Z',
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: 'giv@exante.eu',
    description: 'Liquidity purpose',
  },
  {
    id: 284366,
    updated_at: '2022-02-22T15:11:15Z',
    deposit_address: '0x0427933133113C016C398202352307539430c900',
    currency: 'USDT',
    amount: '400000.00000000',
    fee: '0.00000000',
    account_from: {
      id: 10,
      name: 'iHodl Binance',
      exchange: 'BINANCE',
    },
    account_to: {
      id: 13,
      name: 'iHodl new Safe Treasury',
      exchange: 'WALLETS',
    },
    tx_id: '0xc2a31ab35becc3ff829083cd826668db521ca94b1d364bf79a4f6881d421a477',
    external_id: '8a8de59d354243eab1efa0b905682862',
    exchange_gate_id: '1597',
    withdrawal_status: 'Completed',
    withdrawal_executed_at: '2022-02-22T15:11:15Z',
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: 'giv@exante.eu',
    description: 'Liquidity',
  },
  {
    id: 284365,
    updated_at: '2022-02-17T14:27:13Z',
    deposit_address: '0x511B0fE21847Adf96021C8A3BaEDafb3c9354f0C',
    currency: 'ETH',
    amount: '1000.00000000',
    fee: null,
    account_from: null,
    account_to: {
      id: 1,
      name: 'Bitfinex Gozo Exchange Main',
      exchange: 'BITFINEX',
    },
    tx_id: '0x44ce7bcb7e34e9deb733a4d5a2caafa99c93ed855dcb470bba2b743c2ed65fb9',
    external_id: null,
    exchange_gate_id: null,
    withdrawal_status: null,
    withdrawal_executed_at: null,
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: null,
    description: '',
  },
  {
    id: 284364,
    updated_at: '2022-02-09T07:59:45Z',
    deposit_address: '0x0427933133113C016C398202352307539430c900',
    currency: 'USDT',
    amount: '500000.00000000',
    fee: '0.00000000',
    account_from: {
      id: 10,
      name: 'iHodl Binance',
      exchange: 'BINANCE',
    },
    account_to: {
      id: 13,
      name: 'iHodl new Safe Treasury',
      exchange: 'WALLETS',
    },
    tx_id: '0x12d9cd8eac625b84f668375e8a676e95635b5cb1c0aff3e1e6c8266b375d402c',
    external_id: '23561c6bda91402ea420435e032a30c0',
    exchange_gate_id: '1595',
    withdrawal_status: 'Completed',
    withdrawal_executed_at: '2022-02-09T07:59:45Z',
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: 'giv@exante.eu',
    description: 'Liquidity',
  },
  {
    id: 284346,
    updated_at: '2022-01-31T08:36:13Z',
    deposit_address: '0x511B0fE21847Adf96021C8A3BaEDafb3c9354f0C',
    currency: 'ETH',
    amount: '1000.00000000',
    fee: null,
    account_from: null,
    account_to: {
      id: 1,
      name: 'Bitfinex Gozo Exchange Main',
      exchange: 'BITFINEX',
    },
    tx_id: '0xe4fe4056db0013c78bf3c116ac18974bd137acfa85ab034d59cbe7dd8ac5332f',
    external_id: null,
    exchange_gate_id: null,
    withdrawal_status: null,
    withdrawal_executed_at: null,
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: null,
    description: '',
  },
  {
    id: 284345,
    updated_at: '2022-01-28T10:27:50Z',
    deposit_address: '0x0427933133113C016C398202352307539430c900',
    currency: 'USDT',
    amount: '500000.00000000',
    fee: '0.00000000',
    account_from: {
      id: 10,
      name: 'iHodl Binance',
      exchange: 'BINANCE',
    },
    account_to: {
      id: 13,
      name: 'iHodl new Safe Treasury',
      exchange: 'WALLETS',
    },
    tx_id: '0x76f6676c44324ccb9d51c24e315a7b163715913a9a764918ae29a5c7150ea42c',
    external_id: '8cfa12cb3a4944a6a1384875a2646f3a',
    exchange_gate_id: '1575',
    withdrawal_status: 'Completed',
    withdrawal_executed_at: '2022-01-28T10:27:50Z',
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: 'giv@exante.eu',
    description: '',
  },
  {
    id: 284344,
    updated_at: '2022-01-21T12:02:28Z',
    deposit_address: '0x511B0fE21847Adf96021C8A3BaEDafb3c9354f0C',
    currency: 'ETH',
    amount: '1000.00000000',
    fee: null,
    account_from: null,
    account_to: {
      id: 1,
      name: 'Bitfinex Gozo Exchange Main',
      exchange: 'BITFINEX',
    },
    tx_id: '0x5932cfd0fab8c3f397d343dddbc9936883af62061a43cbb5723768438268ffe8',
    external_id: null,
    exchange_gate_id: null,
    withdrawal_status: null,
    withdrawal_executed_at: null,
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: null,
    description: '',
  },
  {
    id: 284343,
    updated_at: '2022-01-21T10:45:13Z',
    deposit_address: '0x511B0fE21847Adf96021C8A3BaEDafb3c9354f0C',
    currency: 'ETH',
    amount: '1000.00000000',
    fee: null,
    account_from: null,
    account_to: {
      id: 1,
      name: 'Bitfinex Gozo Exchange Main',
      exchange: 'BITFINEX',
    },
    tx_id: '0xb6dda65bc3446a32b1cec220e8c02b807b8566260f41e9c931b96872ea85049f',
    external_id: null,
    exchange_gate_id: null,
    withdrawal_status: null,
    withdrawal_executed_at: null,
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: null,
    description: '',
  },
  {
    id: 284342,
    updated_at: '2022-01-18T14:58:41Z',
    deposit_address: '0x0427933133113C016C398202352307539430c900',
    currency: 'USDT',
    amount: '800000.00000000',
    fee: '0.00000000',
    account_from: {
      id: 10,
      name: 'iHodl Binance',
      exchange: 'BINANCE',
    },
    account_to: {
      id: 13,
      name: 'iHodl new Safe Treasury',
      exchange: 'WALLETS',
    },
    tx_id: '0x651b1d0cd5b0dcaf56a7e7a8a2310917b5dbc72670855518b71cfdee7776a4bd',
    external_id: '9abf60e4b3774bac8026c4d18ba11ad6',
    exchange_gate_id: '1572',
    withdrawal_status: 'Completed',
    withdrawal_executed_at: '2022-01-18T14:58:41Z',
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: 'giv@exante.eu',
    description: '',
  },
  {
    id: 284341,
    updated_at: '2022-01-12T10:38:18Z',
    deposit_address: '0x21dbc2b64bba23a6f02cc07a3798be3849175c8b',
    currency: 'ETH',
    amount: '800.00000000',
    fee: null,
    account_from: null,
    account_to: {
      id: 10,
      name: 'iHodl Binance',
      exchange: 'BINANCE',
    },
    tx_id: '0x5479dbfab781872a884463a10d61bf05a61ca75746c354e116cd4c47957b765f',
    external_id: null,
    exchange_gate_id: null,
    withdrawal_status: null,
    withdrawal_executed_at: null,
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: null,
    description: '',
  },
  {
    id: 284340,
    updated_at: '2022-01-11T12:48:37Z',
    deposit_address: '0x0427933133113C016C398202352307539430c900',
    currency: 'USDT',
    amount: '400000.00000000',
    fee: '0.00000000',
    account_from: {
      id: 10,
      name: 'iHodl Binance',
      exchange: 'BINANCE',
    },
    account_to: {
      id: 13,
      name: 'iHodl new Safe Treasury',
      exchange: 'WALLETS',
    },
    tx_id: '0x8f0632bc9bc7cd1b0390bfae092eda76876e4f63181542616bf7a7a98a572db9',
    external_id: '71da1f379c45429e9d7291b40f856ecf',
    exchange_gate_id: '1570',
    withdrawal_status: 'Completed',
    withdrawal_executed_at: '2022-01-11T12:48:37Z',
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: 'giv@exante.eu',
    description: '',
  },
  {
    id: 284339,
    updated_at: '2022-01-08T18:48:37Z',
    deposit_address: '0x21DBC2b64BBA23A6F02CC07A3798bE3849175c8b',
    currency: 'ETH',
    amount: '800.00000000',
    fee: '0.00000000',
    account_from: {
      id: 2,
      name: 'Deribit Gozo Main',
      exchange: 'DERIBIT',
    },
    account_to: {
      id: 10,
      name: 'iHodl Binance',
      exchange: 'BINANCE',
    },
    tx_id: '0xa8c4d4304ec7609433dc147c07ca967d4b135f6cc03ca20bbfebb6d8a77bfc86',
    external_id: '204457',
    exchange_gate_id: '1568',
    withdrawal_status: 'Completed',
    withdrawal_executed_at: '2022-01-08T18:47:30.005000Z',
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: 'ge@exante.eu',
    description: '',
  },
  {
    id: 284338,
    updated_at: '2021-12-18T20:32:49Z',
    deposit_address: '0x6277f6034086dbf25ac2683aa42688d2e53119e4',
    currency: 'ETH',
    amount: '0.41775600',
    fee: null,
    account_from: null,
    account_to: {
      id: 7,
      name: 'iHodl Bitstamp',
      exchange: 'BITSTAMP',
    },
    tx_id: '0x2805d367630c85b216f32b689da9ad8c45f826d2d67147e3042e0abe3e1933d0',
    external_id: null,
    exchange_gate_id: null,
    withdrawal_status: null,
    withdrawal_executed_at: null,
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: null,
    description: '',
  },
  {
    id: 284335,
    updated_at: '2021-12-14T19:10:46Z',
    deposit_address: '1AQYtrGtWeDuikNf3o19nHcHsFu6jesS2E',
    currency: 'BTC',
    amount: '1.00000000',
    fee: '0.00000000',
    account_from: {
      id: 2,
      name: 'Deribit Gozo Main',
      exchange: 'DERIBIT',
    },
    account_to: {
      id: 10,
      name: 'iHodl Binance',
      exchange: 'BINANCE',
    },
    tx_id: '7e903045db7e57953707bb3de50abd9a5c2df046dca8e0d08fe4076e0ec9915f',
    external_id: '200950',
    exchange_gate_id: '1561',
    withdrawal_status: 'Completed',
    withdrawal_executed_at: '2021-12-14T18:59:06.466000Z',
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: 'ge@exante.eu',
    description: '',
  },
  {
    id: 284336,
    updated_at: '2021-12-14T19:10:46Z',
    deposit_address: '1AQYtrGtWeDuikNf3o19nHcHsFu6jesS2E',
    currency: 'BTC',
    amount: '119.00000000',
    fee: '0.00003000',
    account_from: {
      id: 2,
      name: 'Deribit Gozo Main',
      exchange: 'DERIBIT',
    },
    account_to: {
      id: 10,
      name: 'iHodl Binance',
      exchange: 'BINANCE',
    },
    tx_id: '7e903045db7e57953707bb3de50abd9a5c2df046dca8e0d08fe4076e0ec9915f',
    external_id: '200961',
    exchange_gate_id: '1562',
    withdrawal_status: 'Completed',
    withdrawal_executed_at: '2021-12-14T18:59:06.466000Z',
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: null,
    description: '',
  },
  {
    id: 284337,
    updated_at: '2021-12-14T19:00:33Z',
    deposit_address: '0x21dbc2b64bba23a6f02cc07a3798be3849175c8b',
    currency: 'ETH',
    amount: '1600.00000000',
    fee: '0.00170000',
    account_from: {
      id: 2,
      name: 'Deribit Gozo Main',
      exchange: 'DERIBIT',
    },
    account_to: {
      id: 10,
      name: 'iHodl Binance',
      exchange: 'BINANCE',
    },
    tx_id: '0x58e54f2a14b6e3976d7099aa1f3ac7e5fd716cfd518acf06cb9125c72ac279d2',
    external_id: '200952',
    exchange_gate_id: '1563',
    withdrawal_status: 'Completed',
    withdrawal_executed_at: '2021-12-14T18:58:43.354000Z',
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: null,
    description: '',
  },
  {
    id: 284334,
    updated_at: '2021-12-14T12:00:13Z',
    deposit_address: '3EMtyqBP2EATrNYJMhc9MdARKJgjRGbKBh',
    currency: 'BTC',
    amount: '50.00000000',
    fee: '0.00000000',
    account_from: {
      id: 7,
      name: 'iHodl Bitstamp',
      exchange: 'BITSTAMP',
    },
    account_to: {
      id: 1,
      name: 'Bitfinex Gozo Exchange Main',
      exchange: 'BITFINEX',
    },
    tx_id: 'fe1839a707de1f866bea36cf15bd88d284722d42db86942f021dd9a21addf35b',
    external_id: '13157295',
    exchange_gate_id: '1559',
    withdrawal_status: 'Completed',
    withdrawal_executed_at: '2021-12-14T11:51:53Z',
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: 'ak@exante.eu',
    description: '',
  },
  {
    id: 284333,
    updated_at: '2021-12-10T09:47:24Z',
    deposit_address: '0x6277f6034086dbf25ac2683aa42688d2e53119e4',
    currency: 'USDC',
    amount: '28000.00000000',
    fee: null,
    account_from: null,
    account_to: {
      id: 7,
      name: 'iHodl Bitstamp',
      exchange: 'BITSTAMP',
    },
    tx_id: '0xd11fbc1c18cc3d112a49043da54a156996d9cfc6e176ad4e28f88d7e8c50423f',
    external_id: null,
    exchange_gate_id: null,
    withdrawal_status: null,
    withdrawal_executed_at: null,
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: null,
    description: '',
  },
  {
    id: 284332,
    updated_at: '2021-12-08T09:15:16Z',
    deposit_address: '0x6277f6034086dbf25ac2683aa42688d2e53119e4',
    currency: 'ETH',
    amount: '1.72621682',
    fee: null,
    account_from: null,
    account_to: {
      id: 7,
      name: 'iHodl Bitstamp',
      exchange: 'BITSTAMP',
    },
    tx_id: '0x638b79e54445d564d16e8dd3b5746953bf993292f13fbba799b658ee9bb4b32e',
    external_id: null,
    exchange_gate_id: null,
    withdrawal_status: null,
    withdrawal_executed_at: null,
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: null,
    description: '',
  },
  {
    id: 284331,
    updated_at: '2021-12-04T11:16:43Z',
    deposit_address: '0x511B0fE21847Adf96021C8A3BaEDafb3c9354f0C',
    currency: 'ETH',
    amount: '1000.00000000',
    fee: null,
    account_from: null,
    account_to: {
      id: 1,
      name: 'Bitfinex Gozo Exchange Main',
      exchange: 'BITFINEX',
    },
    tx_id: '0x8b698085ba7a873e1fc5a1d3e8ad811d7f4b7389cacdb31939e5d1cfe6632dbe',
    external_id: null,
    exchange_gate_id: null,
    withdrawal_status: null,
    withdrawal_executed_at: null,
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: null,
    description: '',
  },
  {
    id: 284330,
    updated_at: '2021-12-01T17:54:31Z',
    deposit_address: '35kVfHwEejHig8qbP8KVpVweebSSh2oEmU',
    currency: 'BTC',
    amount: '2.69980620',
    fee: null,
    account_from: null,
    account_to: {
      id: 7,
      name: 'iHodl Bitstamp',
      exchange: 'BITSTAMP',
    },
    tx_id: 'ae7df872e662ed28f8618a5d3b04321df5470d50cc142e0e8e5559a95cc48fc7',
    external_id: null,
    exchange_gate_id: null,
    withdrawal_status: null,
    withdrawal_executed_at: null,
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: null,
    description: '',
  },
  {
    id: 284329,
    updated_at: '2021-11-30T13:28:38Z',
    deposit_address: '0x0427933133113C016C398202352307539430c900',
    currency: 'USDT',
    amount: '600000.00000000',
    fee: '0.00000000',
    account_from: {
      id: 10,
      name: 'iHodl Binance',
      exchange: 'BINANCE',
    },
    account_to: {
      id: 13,
      name: 'iHodl new Safe Treasury',
      exchange: 'WALLETS',
    },
    tx_id: '0x31de99f9d25de7cbfaa8515d02d01cb65ddcad83ee09ef383338234afafd05b5',
    external_id: 'e74937bf86e84c00a680f13df5d5adaf',
    exchange_gate_id: '1554',
    withdrawal_status: 'Completed',
    withdrawal_executed_at: '2021-11-30T13:28:38Z',
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: 'ge@exante.eu',
    description: '',
  },
  {
    id: 284328,
    updated_at: '2021-11-30T10:10:57Z',
    deposit_address: '0x0427933133113C016C398202352307539430c900',
    currency: 'USDT',
    amount: '600000.00000000',
    fee: '0.00000000',
    account_from: {
      id: 10,
      name: 'iHodl Binance',
      exchange: 'BINANCE',
    },
    account_to: {
      id: 13,
      name: 'iHodl new Safe Treasury',
      exchange: 'WALLETS',
    },
    tx_id: '',
    external_id: '4e9836e3396146e5bd80da9c5e4e55c1',
    exchange_gate_id: '1553',
    withdrawal_status: 'Rejected',
    withdrawal_executed_at: null,
    deposit_status: null,
    status: StatusType.REJECTED,
    comment: 'Email confirmation timeout expired',
    created_by: 'giv@exante.eu',
    description: '',
  },
  {
    id: 284327,
    updated_at: '2021-11-30T09:57:59Z',
    deposit_address: '0x6F3F68525E5EdaD6F06f8b0EaE0DD7B9F695aF13',
    currency: 'USDT',
    amount: '14000000.00000000',
    fee: '25.01000000',
    account_from: {
      id: 1,
      name: 'Bitfinex Gozo Exchange Main',
      exchange: 'BITFINEX',
    },
    account_to: {
      id: 12,
      name: '0x6F3F iHodl Safe',
      exchange: 'WALLETS',
    },
    tx_id: '0x345947233093dc9d68c175d9ced964cac3c5ebad9ecb70e3823868859fea6b7b',
    external_id: '17304991',
    exchange_gate_id: '1552',
    withdrawal_status: 'Completed',
    withdrawal_executed_at: '2021-11-30T09:57:59Z',
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: null,
    description: '',
  },
  {
    id: 284326,
    updated_at: '2021-11-23T12:36:42Z',
    deposit_address: '0x21dbc2b64bba23a6f02cc07a3798be3849175c8b',
    currency: 'USDC',
    amount: '3000000.00000000',
    fee: null,
    account_from: null,
    account_to: {
      id: 10,
      name: 'iHodl Binance',
      exchange: 'BINANCE',
    },
    tx_id: '0x534c6f7fa2f4aa1ed21705d1829b7a00ccd04ab713cf852451da88d5ae648b70',
    external_id: null,
    exchange_gate_id: null,
    withdrawal_status: null,
    withdrawal_executed_at: null,
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: null,
    description: '',
  },
  {
    id: 284325,
    updated_at: '2021-11-18T07:54:58Z',
    deposit_address: '0x0427933133113C016C398202352307539430c900',
    currency: 'USDT',
    amount: '600000.00000000',
    fee: '0.00000000',
    account_from: {
      id: 10,
      name: 'iHodl Binance',
      exchange: 'BINANCE',
    },
    account_to: {
      id: 13,
      name: 'iHodl new Safe Treasury',
      exchange: 'WALLETS',
    },
    tx_id: '0xbd4c0eb126e868420820bbaefab5ecd451529f1bfba4ffb9400a0814f9f6dfed',
    external_id: 'd4415ca6f1c145f2a357788fec0b91df',
    exchange_gate_id: '1550',
    withdrawal_status: 'Completed',
    withdrawal_executed_at: '2021-11-18T07:54:58Z',
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: 'giv@exante.eu',
    description: '',
  },
  {
    id: 284324,
    updated_at: '2021-11-16T12:50:41Z',
    deposit_address: '0x21dbc2b64bba23a6f02cc07a3798be3849175c8b',
    currency: 'USDC',
    amount: '3000000.00000000',
    fee: null,
    account_from: null,
    account_to: {
      id: 10,
      name: 'iHodl Binance',
      exchange: 'BINANCE',
    },
    tx_id: '0x4f18f130889d183c40caba927f11aee53650083c0f1b5d1226794f60967b91ab',
    external_id: null,
    exchange_gate_id: null,
    withdrawal_status: null,
    withdrawal_executed_at: null,
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: null,
    description: '',
  },
  {
    id: 284323,
    updated_at: '2021-11-15T09:22:43Z',
    deposit_address: '0x6F3F68525E5EdaD6F06f8b0EaE0DD7B9F695aF13',
    currency: 'ETH',
    amount: '1500.00000000',
    fee: '0.00000000',
    account_from: {
      id: 1,
      name: 'Bitfinex Gozo Exchange Main',
      exchange: 'BITFINEX',
    },
    account_to: {
      id: 12,
      name: '0x6F3F iHodl Safe',
      exchange: 'WALLETS',
    },
    tx_id: null,
    external_id: '17186435',
    exchange_gate_id: '1548',
    withdrawal_status: 'Canceled',
    withdrawal_executed_at: null,
    deposit_status: null,
    status: StatusType.CANCELED,
    comment: '',
    created_by: 'ak@exante.eu',
    description: '',
  },
  {
    id: 284322,
    updated_at: '2021-11-15T09:20:32Z',
    deposit_address: '0x21dbc2b64bba23a6f02cc07a3798be3849175c8b',
    currency: 'USDC',
    amount: '3000000.00000000',
    fee: null,
    account_from: null,
    account_to: {
      id: 10,
      name: 'iHodl Binance',
      exchange: 'BINANCE',
    },
    tx_id: '0x1084eb4bd126981c6d11589c468bfe9d75623657be8099a166cdd0c16e1fe22a',
    external_id: null,
    exchange_gate_id: null,
    withdrawal_status: null,
    withdrawal_executed_at: null,
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: null,
    description: '',
  },
  {
    id: 284321,
    updated_at: '2021-11-11T12:06:36Z',
    deposit_address: '0x0427933133113C016C398202352307539430c900',
    currency: 'USDT',
    amount: '400000.00000000',
    fee: '0.00000000',
    account_from: {
      id: 10,
      name: 'iHodl Binance',
      exchange: 'BINANCE',
    },
    account_to: {
      id: 13,
      name: 'iHodl new Safe Treasury',
      exchange: 'WALLETS',
    },
    tx_id: '0xea190a11a111b1af65fc90a1d5b733f7ce06872dd977db23e8968deee9b43fe7',
    external_id: '206438931ee5482cb1b8425d264f2340',
    exchange_gate_id: '1545',
    withdrawal_status: 'Completed',
    withdrawal_executed_at: '2021-11-11T12:06:36Z',
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: 'ge@exante.eu',
    description: '',
  },
  {
    id: 284320,
    updated_at: '2021-11-10T17:13:23Z',
    deposit_address: '35kVfHwEejHig8qbP8KVpVweebSSh2oEmU',
    currency: 'BTC',
    amount: '0.10255136',
    fee: null,
    account_from: null,
    account_to: {
      id: 7,
      name: 'iHodl Bitstamp',
      exchange: 'BITSTAMP',
    },
    tx_id: '5c46626502288aee4ff9faade9bb3ace07877fafbc9fffa18207ed057c64cad7',
    external_id: null,
    exchange_gate_id: null,
    withdrawal_status: null,
    withdrawal_executed_at: null,
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: null,
    description: '',
  },
  {
    id: 284313,
    updated_at: '2021-10-26T19:03:04Z',
    deposit_address: '0x0427933133113C016C398202352307539430c900',
    currency: 'USDT',
    amount: '500000.00000000',
    fee: '0.00000000',
    account_from: {
      id: 10,
      name: 'iHodl Binance',
      exchange: 'BINANCE',
    },
    account_to: {
      id: 13,
      name: 'iHodl new Safe Treasury',
      exchange: 'WALLETS',
    },
    tx_id: '0x90bafc81c96de8c7d2ed0ba850a6a2c03b53a8b0745933550abe8f3cdbd1bda5',
    external_id: 'a28ecb485d33447189afbfbe1a20ebc6',
    exchange_gate_id: '1533',
    withdrawal_status: 'Completed',
    withdrawal_executed_at: '2021-10-26T19:03:04Z',
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: 'ge@exante.eu',
    description: '',
  },
  {
    id: 284315,
    updated_at: '2021-10-25T13:00:39Z',
    deposit_address: '0x21dbc2b64bba23a6f02cc07a3798be3849175c8b',
    currency: 'USDC',
    amount: '3000000.00000000',
    fee: null,
    account_from: null,
    account_to: {
      id: 10,
      name: 'iHodl Binance',
      exchange: 'BINANCE',
    },
    tx_id: '0xc44417aba1beb25d2362acfcc209e1e3cff39cd3f1944cac43a3c2bcd8f62a5b',
    external_id: null,
    exchange_gate_id: null,
    withdrawal_status: null,
    withdrawal_executed_at: null,
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: null,
    description: '',
  },
  {
    id: 284312,
    updated_at: '2021-10-22T15:05:11Z',
    deposit_address: '35kVfHwEejHig8qbP8KVpVweebSSh2oEmU',
    currency: 'BTC',
    amount: '0.01013565',
    fee: null,
    account_from: null,
    account_to: {
      id: 7,
      name: 'iHodl Bitstamp',
      exchange: 'BITSTAMP',
    },
    tx_id: '99e689861798df65cef877ed15c72a26cd33f393918b70036e4126f8a3aedcdc',
    external_id: null,
    exchange_gate_id: null,
    withdrawal_status: null,
    withdrawal_executed_at: null,
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: null,
    description: '',
  },
  {
    id: 284311,
    updated_at: '2021-10-22T13:25:52Z',
    deposit_address: '0x6F3F68525E5EdaD6F06f8b0EaE0DD7B9F695aF13',
    currency: 'ETH',
    amount: '500.00000000',
    fee: '0.00000000',
    account_from: {
      id: 1,
      name: 'Bitfinex Gozo Exchange Main',
      exchange: 'BITFINEX',
    },
    account_to: {
      id: 12,
      name: '0x6F3F iHodl Safe',
      exchange: 'WALLETS',
    },
    tx_id: '0xf29344225e62b8412c72cc7d53cf069cab0f007d49dc7b402a4e0b98e91a3de5',
    external_id: '17008864',
    exchange_gate_id: '1531',
    withdrawal_status: 'Completed',
    withdrawal_executed_at: '2021-10-22T13:25:52Z',
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: 'ak@exante.eu',
    description: '',
  },
  {
    id: 284310,
    updated_at: '2021-10-21T15:17:28Z',
    deposit_address: '0x511B0fE21847Adf96021C8A3BaEDafb3c9354f0C',
    currency: 'USDT',
    amount: '10884764.30851100',
    fee: null,
    account_from: null,
    account_to: {
      id: 1,
      name: 'Bitfinex Gozo Exchange Main',
      exchange: 'BITFINEX',
    },
    tx_id: '0x2de86ab38b9f93db0efc7e239f020064dfacf4612071838b7fa5468fdfc612e3',
    external_id: null,
    exchange_gate_id: null,
    withdrawal_status: null,
    withdrawal_executed_at: null,
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: null,
    description: '',
  },
  {
    id: 284308,
    updated_at: '2021-10-21T09:00:17.612000Z',
    deposit_address: '0x029dff2fd5035df0dbc54ce16e4b260852edc01d',
    currency: 'ETH',
    amount: '1000.00000000',
    fee: '0.00000000',
    account_from: {
      id: 1,
      name: 'Bitfinex Gozo Exchange Main',
      exchange: 'BITFINEX',
    },
    account_to: {
      id: 2,
      name: 'Deribit Gozo Main',
      exchange: 'DERIBIT',
    },
    tx_id: '0xf6c599670cd3e6bf94f81733337fcebf245a4842badf325f4f97f9302c1599a7',
    external_id: '16999180',
    exchange_gate_id: '1527',
    withdrawal_status: 'Completed',
    withdrawal_executed_at: '2021-10-21T08:01:35Z',
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: 'ak@exante.eu',
    description: '',
  },
  {
    id: 284309,
    updated_at: '2021-10-21T08:26:40Z',
    deposit_address: '0x21DBC2b64BBA23A6F02CC07A3798bE3849175c8b',
    currency: 'USDT',
    amount: '1000000.00000000',
    fee: '0.00000000',
    account_from: {
      id: 1,
      name: 'Bitfinex Gozo Exchange Main',
      exchange: 'BITFINEX',
    },
    account_to: {
      id: 10,
      name: 'iHodl Binance',
      exchange: 'BINANCE',
    },
    tx_id: null,
    external_id: '16999336',
    exchange_gate_id: '1528',
    withdrawal_status: 'Canceled',
    withdrawal_executed_at: null,
    deposit_status: null,
    status: StatusType.CANCELED,
    comment: '',
    created_by: 'ak@exante.eu',
    description: '',
  },
  {
    id: 284307,
    updated_at: '2021-10-20T17:27:13Z',
    deposit_address: '0x511B0fE21847Adf96021C8A3BaEDafb3c9354f0C',
    currency: 'USDT',
    amount: '10419517.54124500',
    fee: null,
    account_from: null,
    account_to: {
      id: 1,
      name: 'Bitfinex Gozo Exchange Main',
      exchange: 'BITFINEX',
    },
    tx_id: '0xe38e4021089575a22a31b0f24f8bed235c2dbb0c62bc327c213b61c8d55745f4',
    external_id: null,
    exchange_gate_id: null,
    withdrawal_status: null,
    withdrawal_executed_at: null,
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: null,
    description: '',
  },
  {
    id: 284316,
    updated_at: '2021-10-20T14:58:39Z',
    deposit_address: '0x21dbc2b64bba23a6f02cc07a3798be3849175c8b',
    currency: 'USDC',
    amount: '3000000.00000000',
    fee: null,
    account_from: null,
    account_to: {
      id: 10,
      name: 'iHodl Binance',
      exchange: 'BINANCE',
    },
    tx_id: '0x59844fdbe14a5941bce4c40f6302b1d5448b1b44be1b218350e2ec8c1a5b9a24',
    external_id: null,
    exchange_gate_id: null,
    withdrawal_status: null,
    withdrawal_executed_at: null,
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: null,
    description: '',
  },
  {
    id: 284317,
    updated_at: '2021-10-20T03:52:31Z',
    deposit_address: '0x21dbc2b64bba23a6f02cc07a3798be3849175c8b',
    currency: 'USDC',
    amount: '3000000.00000000',
    fee: null,
    account_from: null,
    account_to: {
      id: 10,
      name: 'iHodl Binance',
      exchange: 'BINANCE',
    },
    tx_id: '0x5b0fc12cdbcd9bf1324f8312b8e7a35b6eb454450a57192e8098ce9aafa5f4af',
    external_id: null,
    exchange_gate_id: null,
    withdrawal_status: null,
    withdrawal_executed_at: null,
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: null,
    description: '',
  },
  {
    id: 284306,
    updated_at: '2021-10-20T03:47:28Z',
    deposit_address: '0x0427933133113C016C398202352307539430c900',
    currency: 'USDT',
    amount: '700000.00000000',
    fee: '0.00000000',
    account_from: {
      id: 10,
      name: 'iHodl Binance',
      exchange: 'BINANCE',
    },
    account_to: {
      id: 13,
      name: 'iHodl new Safe Treasury',
      exchange: 'WALLETS',
    },
    tx_id: '0xaf2e0be3a61a40a50d0e27823b703d88d0b6ff3e02abce085c0b56a47e5cf621',
    external_id: 'e8af1e3ab5724da39e8af7d0d09b25d6',
    exchange_gate_id: '1525',
    withdrawal_status: 'Completed',
    withdrawal_executed_at: '2021-10-20T03:47:28Z',
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: 'ge@exante.eu',
    description: '',
  },
  {
    id: 284305,
    updated_at: '2021-10-18T11:18:38Z',
    deposit_address: '0x21dbc2b64bba23a6f02cc07a3798be3849175c8b',
    currency: 'USDT',
    amount: '2500000.00000000',
    fee: '0.00000000',
    account_from: {
      id: 1,
      name: 'Bitfinex Gozo Exchange Main',
      exchange: 'BITFINEX',
    },
    account_to: {
      id: 10,
      name: 'iHodl Binance',
      exchange: 'BINANCE',
    },
    tx_id: '0xb99c6a4528f69fe53f7c3ba819b768c2f76337662855191396661051d48bb02e',
    external_id: '16980020',
    exchange_gate_id: '1522',
    withdrawal_status: 'Completed',
    withdrawal_executed_at: '2021-10-18T10:55:45Z',
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: 'ge@exante.eu',
    description: '',
  },
  {
    id: 284304,
    updated_at: '2021-10-17T15:33:19.951000Z',
    deposit_address: '3Ez2WUDvKj32V7vCdcthREVhoAyw94R1Mw',
    currency: 'BTC',
    amount: '50.00000000',
    fee: '0.00000000',
    account_from: {
      id: 7,
      name: 'iHodl Bitstamp',
      exchange: 'BITSTAMP',
    },
    account_to: {
      id: 2,
      name: 'Deribit Gozo Main',
      exchange: 'DERIBIT',
    },
    tx_id: '9c09980768d91ce2b6a9cffda00515aa109d520246a31df569f56b200daeb08e',
    external_id: '12454588',
    exchange_gate_id: '1519',
    withdrawal_status: 'Completed',
    withdrawal_executed_at: '2021-10-17T15:17:38Z',
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: 'ak@exante.eu',
    description: '',
  },
  {
    id: 284303,
    updated_at: '2021-10-17T15:20:49.778000Z',
    deposit_address: '0x029dff2fd5035df0dbc54ce16e4b260852edc01d',
    currency: 'ETH',
    amount: '1000.00000000',
    fee: '0.00000000',
    account_from: {
      id: 7,
      name: 'iHodl Bitstamp',
      exchange: 'BITSTAMP',
    },
    account_to: {
      id: 2,
      name: 'Deribit Gozo Main',
      exchange: 'DERIBIT',
    },
    tx_id: '0xdad0f589d45aaa5191e2839a4f02bd867608f4cf96176b43a6bb246a65c70cb4',
    external_id: '12454581',
    exchange_gate_id: '1518',
    withdrawal_status: 'Completed',
    withdrawal_executed_at: '2021-10-17T15:16:53Z',
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: 'ak@exante.eu',
    description: '',
  },
  {
    id: 284302,
    updated_at: '2021-10-15T11:48:58Z',
    deposit_address: '0x511B0fE21847Adf96021C8A3BaEDafb3c9354f0C',
    currency: 'USDT',
    amount: '5000000.00000000',
    fee: null,
    account_from: null,
    account_to: {
      id: 1,
      name: 'Bitfinex Gozo Exchange Main',
      exchange: 'BITFINEX',
    },
    tx_id: '0xfa2ad3fccd890dfed6bdc7141c76ba4845d3fe359405e368acca84cfae7343c5',
    external_id: null,
    exchange_gate_id: null,
    withdrawal_status: null,
    withdrawal_executed_at: null,
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: null,
    description: '',
  },
  {
    id: 284301,
    updated_at: '2021-10-15T11:41:52Z',
    deposit_address: '0x6F3F68525E5EdaD6F06f8b0EaE0DD7B9F695aF13',
    currency: 'ETH',
    amount: '2000.00000000',
    fee: '0.00000000',
    account_from: {
      id: 1,
      name: 'Bitfinex Gozo Exchange Main',
      exchange: 'BITFINEX',
    },
    account_to: {
      id: 12,
      name: '0x6F3F iHodl Safe',
      exchange: 'WALLETS',
    },
    tx_id: '0xf8c35f111024b283c9f3c59c7a174ec5af7f5c1387aa36d0714006ac9d0a8951',
    external_id: '16962288',
    exchange_gate_id: '1516',
    withdrawal_status: 'Completed',
    withdrawal_executed_at: '2021-10-15T11:41:52Z',
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: 'ak@exante.eu',
    description: '',
  },
  {
    id: 284300,
    updated_at: '2021-10-12T15:42:27Z',
    deposit_address: '0x0427933133113C016C398202352307539430c900',
    currency: 'USDT',
    amount: '300000.00000000',
    fee: '0.00000000',
    account_from: {
      id: 10,
      name: 'iHodl Binance',
      exchange: 'BINANCE',
    },
    account_to: {
      id: 13,
      name: 'iHodl new Safe Treasury',
      exchange: 'WALLETS',
    },
    tx_id: '0xeaba3ce676758ada64a425d011dac5ece6176ab68c73b47d7a24f83c47d5e780',
    external_id: 'e970e1a398764e1abde4d00203dac657',
    exchange_gate_id: '1515',
    withdrawal_status: 'Completed',
    withdrawal_executed_at: '2021-10-12T15:42:27Z',
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: 'ge@exante.eu',
    description: '',
  },
  {
    id: 284299,
    updated_at: '2021-10-12T14:29:43Z',
    deposit_address: '0x511B0fE21847Adf96021C8A3BaEDafb3c9354f0C',
    currency: 'USDT',
    amount: '5997919.89251500',
    fee: null,
    account_from: null,
    account_to: {
      id: 1,
      name: 'Bitfinex Gozo Exchange Main',
      exchange: 'BITFINEX',
    },
    tx_id: '0x5419b4308c380a3cbb56f1e4d9e853f08f501e491431cda0ecf7eca53611472b',
    external_id: null,
    exchange_gate_id: null,
    withdrawal_status: null,
    withdrawal_executed_at: null,
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: null,
    description: '',
  },
  {
    id: 284318,
    updated_at: '2021-10-12T09:16:38Z',
    deposit_address: '0x21dbc2b64bba23a6f02cc07a3798be3849175c8b',
    currency: 'USDC',
    amount: '3000000.00000000',
    fee: null,
    account_from: null,
    account_to: {
      id: 10,
      name: 'iHodl Binance',
      exchange: 'BINANCE',
    },
    tx_id: '0xeb30819b845a473bbac3d6820ee1da94e037b4bbcfc860d61d397a01b93642bb',
    external_id: null,
    exchange_gate_id: null,
    withdrawal_status: null,
    withdrawal_executed_at: null,
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: null,
    description: '',
  },
  {
    id: 284298,
    updated_at: '2021-10-12T09:10:09Z',
    deposit_address: '0x0427933133113C016C398202352307539430c900',
    currency: 'USDT',
    amount: '200000.00000000',
    fee: '0.00000000',
    account_from: {
      id: 10,
      name: 'iHodl Binance',
      exchange: 'BINANCE',
    },
    account_to: {
      id: 13,
      name: 'iHodl new Safe Treasury',
      exchange: 'WALLETS',
    },
    tx_id: '0x1e0262ec5e52c2e1690f79b11fd2e6f0b0d61de49194f217062f735bc551ab46',
    external_id: '1cca2682ca98483c9b0e1ec0db6a88ab',
    exchange_gate_id: '1513',
    withdrawal_status: 'Completed',
    withdrawal_executed_at: '2021-10-12T09:10:09Z',
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: 'ge@exante.eu',
    description: '',
  },
  {
    id: 284297,
    updated_at: '2021-10-11T20:00:33.821000Z',
    deposit_address: '3Ez2WUDvKj32V7vCdcthREVhoAyw94R1Mw',
    currency: 'BTC',
    amount: '100.00000000',
    fee: '0.00000000',
    account_from: {
      id: 7,
      name: 'iHodl Bitstamp',
      exchange: 'BITSTAMP',
    },
    account_to: {
      id: 2,
      name: 'Deribit Gozo Main',
      exchange: 'DERIBIT',
    },
    tx_id: '8a9231a0ff3de505620f30e556aeff54a8868f8cd2ba3b1209a8be31a57f86f3',
    external_id: '12395929',
    exchange_gate_id: '1511',
    withdrawal_status: 'Completed',
    withdrawal_executed_at: '2021-10-11T19:47:49Z',
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: 'ge@exante.eu',
    description: '',
  },
  {
    id: 284296,
    updated_at: '2021-10-11T17:40:36Z',
    deposit_address: '0x21dbc2b64bba23a6f02cc07a3798be3849175c8b',
    currency: 'USDT',
    amount: '400000.00000000',
    fee: '0.00000000',
    account_from: {
      id: 1,
      name: 'Bitfinex Gozo Exchange Main',
      exchange: 'BITFINEX',
    },
    account_to: {
      id: 10,
      name: 'iHodl Binance',
      exchange: 'BINANCE',
    },
    tx_id: '0x2243ff947650c2fc6be35a907c5764e3bfdf1856b1abcdc413c7b95f6ea5ee46',
    external_id: '16939624',
    exchange_gate_id: '1509',
    withdrawal_status: 'Completed',
    withdrawal_executed_at: '2021-10-11T17:34:35Z',
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: 'ge@exante.eu',
    description: '',
  },
  {
    id: 284295,
    updated_at: '2021-10-08T10:23:41Z',
    deposit_address: '0x21DBC2b64BBA23A6F02CC07A3798bE3849175c8b',
    currency: 'USDT',
    amount: '500000.00000000',
    fee: '0.00000000',
    account_from: {
      id: 1,
      name: 'Bitfinex Gozo Exchange Main',
      exchange: 'BITFINEX',
    },
    account_to: {
      id: 10,
      name: 'iHodl Binance',
      exchange: 'BINANCE',
    },
    tx_id: null,
    external_id: '16919101',
    exchange_gate_id: '1508',
    withdrawal_status: 'Canceled',
    withdrawal_executed_at: null,
    deposit_status: null,
    status: StatusType.CANCELED,
    comment: '',
    created_by: 'ge@exante.eu',
    description: '',
  },
  {
    id: 284294,
    updated_at: '2021-10-07T14:22:20Z',
    deposit_address: '35kVfHwEejHig8qbP8KVpVweebSSh2oEmU',
    currency: 'BTC',
    amount: '0.75327761',
    fee: null,
    account_from: null,
    account_to: {
      id: 7,
      name: 'iHodl Bitstamp',
      exchange: 'BITSTAMP',
    },
    tx_id: '225a3a06eab6fc143506426fa009320970dc7d26705a19824c8f73e47d1bc897',
    external_id: null,
    exchange_gate_id: null,
    withdrawal_status: null,
    withdrawal_executed_at: null,
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: null,
    description: '',
  },
  {
    id: 284293,
    updated_at: '2021-10-05T09:49:50Z',
    deposit_address: '0x6277f6034086dbf25ac2683aa42688d2e53119e4',
    currency: 'ETH',
    amount: '5.89634961',
    fee: null,
    account_from: null,
    account_to: {
      id: 7,
      name: 'iHodl Bitstamp',
      exchange: 'BITSTAMP',
    },
    tx_id: '0x1fbe0e00e9169918deabcc9da64ab4dec8c8bfbb0bd07fae3181f55e2da54e62',
    external_id: null,
    exchange_gate_id: null,
    withdrawal_status: null,
    withdrawal_executed_at: null,
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: null,
    description: '',
  },
  {
    id: 283671,
    updated_at: '2021-10-02T10:36:43Z',
    deposit_address: '0x21DBC2b64BBA23A6F02CC07A3798bE3849175c8b',
    currency: 'USDT',
    amount: '100000.00000000',
    fee: '5.77150000',
    account_from: {
      id: 1,
      name: 'Bitfinex Gozo Exchange Main',
      exchange: 'BITFINEX',
    },
    account_to: {
      id: 10,
      name: 'iHodl Binance',
      exchange: 'BINANCE',
    },
    tx_id: null,
    external_id: '16879250',
    exchange_gate_id: '1505',
    withdrawal_status: 'Canceled',
    withdrawal_executed_at: null,
    deposit_status: null,
    status: StatusType.CANCELED,
    comment: '',
    created_by: null,
    description: '',
  },
  {
    id: 284319,
    updated_at: '2021-10-01T14:01:07Z',
    deposit_address: '0x6277f6034086dbf25ac2683aa42688d2e53119e4',
    currency: 'USDC',
    amount: '450000.00000000',
    fee: null,
    account_from: null,
    account_to: {
      id: 7,
      name: 'iHodl Bitstamp',
      exchange: 'BITSTAMP',
    },
    tx_id: '0x584e3aba72d6aed92e352971ba81e1aedf1bbc8ad77acd54f619b72e4d1c952d',
    external_id: null,
    exchange_gate_id: null,
    withdrawal_status: null,
    withdrawal_executed_at: null,
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: null,
    description: '',
  },
  {
    id: 283672,
    updated_at: '2021-09-26T20:06:47Z',
    deposit_address: '0x21DBC2b64BBA23A6F02CC07A3798bE3849175c8b',
    currency: 'USDT',
    amount: '100000.00000000',
    fee: '8.25870000',
    account_from: {
      id: 1,
      name: 'Bitfinex Gozo Exchange Main',
      exchange: 'BITFINEX',
    },
    account_to: {
      id: 10,
      name: 'iHodl Binance',
      exchange: 'BINANCE',
    },
    tx_id: null,
    external_id: '16846052',
    exchange_gate_id: '1504',
    withdrawal_status: 'Canceled',
    withdrawal_executed_at: null,
    deposit_status: null,
    status: StatusType.CANCELED,
    comment: '',
    created_by: null,
    description: '',
  },
  {
    id: 283673,
    updated_at: '2021-09-25T09:20:55Z',
    deposit_address: '0x21DBC2b64BBA23A6F02CC07A3798bE3849175c8b',
    currency: 'USDT',
    amount: '150000.00000000',
    fee: '4.58110000',
    account_from: {
      id: 1,
      name: 'Bitfinex Gozo Exchange Main',
      exchange: 'BITFINEX',
    },
    account_to: {
      id: 10,
      name: 'iHodl Binance',
      exchange: 'BINANCE',
    },
    tx_id: null,
    external_id: '16838234',
    exchange_gate_id: '1503',
    withdrawal_status: 'Canceled',
    withdrawal_executed_at: null,
    deposit_status: null,
    status: StatusType.CANCELED,
    comment: '',
    created_by: null,
    description: '',
  },
  {
    id: 283675,
    updated_at: '2021-09-24T16:03:41Z',
    deposit_address: '35kVfHwEejHig8qbP8KVpVweebSSh2oEmU',
    currency: 'BTC',
    amount: '80.00000000',
    fee: '0.00060000',
    account_from: {
      id: 2,
      name: 'Deribit Gozo Main',
      exchange: 'DERIBIT',
    },
    account_to: {
      id: 7,
      name: 'iHodl Bitstamp',
      exchange: 'BITSTAMP',
    },
    tx_id: 'e9784fc705fd6b531b454865ffddae4c04b45f6e15a8d7390da0338f45896f2d',
    external_id: '187728',
    exchange_gate_id: '1368',
    withdrawal_status: 'Completed',
    withdrawal_executed_at: '2021-09-24T15:22:02.388000Z',
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: null,
    description: '',
  },
  {
    id: 283674,
    updated_at: '2021-09-24T15:32:35Z',
    deposit_address: '0x6277f6034086dbf25ac2683aa42688d2e53119e4',
    currency: 'ETH',
    amount: '1300.00000000',
    fee: '0.00200000',
    account_from: {
      id: 2,
      name: 'Deribit Gozo Main',
      exchange: 'DERIBIT',
    },
    account_to: {
      id: 7,
      name: 'iHodl Bitstamp',
      exchange: 'BITSTAMP',
    },
    tx_id: '0x89d428cff717a6be1000a4f2bc8f1e70e08fc7c6694ebd52df32c4c6a7358b66',
    external_id: '187732',
    exchange_gate_id: '1369',
    withdrawal_status: 'Completed',
    withdrawal_executed_at: '2021-09-24T15:27:48.878000Z',
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: null,
    description: '',
  },
  {
    id: 283676,
    updated_at: '2021-09-24T13:13:39Z',
    deposit_address: '0x0427933133113C016C398202352307539430c900',
    currency: 'USDT',
    amount: '300000.00000000',
    fee: '25.00000000',
    account_from: {
      id: 10,
      name: 'iHodl Binance',
      exchange: 'BINANCE',
    },
    account_to: {
      id: 13,
      name: 'iHodl new Safe Treasury',
      exchange: 'WALLETS',
    },
    tx_id: '0x54a86b8a3582b78613974e03e94d67186593f8f7209b11724679a5185fd42c1a',
    external_id: '34447cbf58af4e08aea41f8d27d7424f',
    exchange_gate_id: '1367',
    withdrawal_status: 'Completed',
    withdrawal_executed_at: '2021-09-24T13:13:39Z',
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: null,
    description: '',
  },
  {
    id: 283677,
    updated_at: '2021-09-21T07:46:23Z',
    deposit_address: '0x0427933133113C016C398202352307539430c900',
    currency: 'EURS',
    amount: '1000.00000000',
    fee: '5.73910000',
    account_from: {
      id: 1,
      name: 'Bitfinex Gozo Exchange Main',
      exchange: 'BITFINEX',
    },
    account_to: {
      id: 13,
      name: 'iHodl new Safe Treasury',
      exchange: 'WALLETS',
    },
    tx_id: '0x9b159c2a409b6bb707f29f9de3cbec926e5d1141228f65b1b054e9cf71d56d63',
    external_id: '16812667',
    exchange_gate_id: '1365',
    withdrawal_status: 'Completed',
    withdrawal_executed_at: '2021-09-21T07:46:23Z',
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: null,
    description: '',
  },
  {
    id: 283678,
    updated_at: '2021-09-14T20:10:52Z',
    deposit_address: '0x511B0fE21847Adf96021C8A3BaEDafb3c9354f0C',
    currency: 'USDT',
    amount: '199975.00000000',
    fee: '25.00000000',
    account_from: {
      id: 10,
      name: 'iHodl Binance',
      exchange: 'BINANCE',
    },
    account_to: {
      id: 1,
      name: 'Bitfinex Gozo Exchange Main',
      exchange: 'BITFINEX',
    },
    tx_id: '0x1c96fd9ba473a862efd141a7afc4a849d734127997c3800aec03c71f60c796d3',
    external_id: '1f41709839124970a4129ea26b509bb6',
    exchange_gate_id: '1363',
    withdrawal_status: 'Completed',
    withdrawal_executed_at: '2021-09-14T20:08:32Z',
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: null,
    description: '',
  },
  {
    id: 283679,
    updated_at: '2021-09-14T16:33:47Z',
    deposit_address: '0x21DBC2b64BBA23A6F02CC07A3798bE3849175c8b',
    currency: 'USDT',
    amount: '1000.00000000',
    fee: '9.82630000',
    account_from: {
      id: 1,
      name: 'Bitfinex Gozo Exchange Main',
      exchange: 'BITFINEX',
    },
    account_to: {
      id: 10,
      name: 'iHodl Binance',
      exchange: 'BINANCE',
    },
    tx_id: null,
    external_id: '16772131',
    exchange_gate_id: '1362',
    withdrawal_status: 'Canceled',
    withdrawal_executed_at: null,
    deposit_status: null,
    status: StatusType.CANCELED,
    comment: '',
    created_by: null,
    description: '',
  },
  {
    id: 283680,
    updated_at: '2021-09-14T16:20:59Z',
    deposit_address: '0x0427933133113C016C398202352307539430c900',
    currency: 'USDT',
    amount: '200000.00000000',
    fee: '25.00000000',
    account_from: {
      id: 10,
      name: 'iHodl Binance',
      exchange: 'BINANCE',
    },
    account_to: {
      id: 13,
      name: 'iHodl new Safe Treasury',
      exchange: 'WALLETS',
    },
    tx_id: '0xb2e8f21b4a62fd2684a547faba0a42c70f611dc01bfbe97859733dc5418bfea3',
    external_id: 'a3ed3dcb8f45428b81b7b11179accd5a',
    exchange_gate_id: '1361',
    withdrawal_status: 'Completed',
    withdrawal_executed_at: '2021-09-14T16:20:59Z',
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: null,
    description: '',
  },
  {
    id: 283681,
    updated_at: '2021-09-14T15:24:22Z',
    deposit_address: '0x21DBC2b64BBA23A6F02CC07A3798bE3849175c8b',
    currency: 'USDT',
    amount: '5000.00000000',
    fee: '6.50260000',
    account_from: {
      id: 1,
      name: 'Bitfinex Gozo Exchange Main',
      exchange: 'BITFINEX',
    },
    account_to: {
      id: 10,
      name: 'iHodl Binance',
      exchange: 'BINANCE',
    },
    tx_id: null,
    external_id: '16771777',
    exchange_gate_id: '1360',
    withdrawal_status: 'Canceled',
    withdrawal_executed_at: null,
    deposit_status: null,
    status: StatusType.CANCELED,
    comment: '',
    created_by: null,
    description: '',
  },
  {
    id: 283682,
    updated_at: '2021-09-14T13:04:03Z',
    deposit_address: '0x21DBC2b64BBA23A6F02CC07A3798bE3849175c8b',
    currency: 'USDT',
    amount: '10000.00000000',
    fee: '6.50260000',
    account_from: {
      id: 1,
      name: 'Bitfinex Gozo Exchange Main',
      exchange: 'BITFINEX',
    },
    account_to: {
      id: 10,
      name: 'iHodl Binance',
      exchange: 'BINANCE',
    },
    tx_id: null,
    external_id: '16771013',
    exchange_gate_id: '1359',
    withdrawal_status: 'Canceled',
    withdrawal_executed_at: null,
    deposit_status: null,
    status: StatusType.CANCELED,
    comment: '',
    created_by: null,
    description: '',
  },
  {
    id: 283683,
    updated_at: '2021-09-14T12:20:36Z',
    deposit_address: '0x21DBC2b64BBA23A6F02CC07A3798bE3849175c8b',
    currency: 'USDT',
    amount: '20000.00000000',
    fee: '6.50260000',
    account_from: {
      id: 1,
      name: 'Bitfinex Gozo Exchange Main',
      exchange: 'BITFINEX',
    },
    account_to: {
      id: 10,
      name: 'iHodl Binance',
      exchange: 'BINANCE',
    },
    tx_id: null,
    external_id: '16770800',
    exchange_gate_id: '1358',
    withdrawal_status: 'Canceled',
    withdrawal_executed_at: null,
    deposit_status: null,
    status: StatusType.CANCELED,
    comment: '',
    created_by: null,
    description: '',
  },
  {
    id: 283684,
    updated_at: '2021-09-13T15:20:02Z',
    deposit_address: '0x0427933133113C016C398202352307539430c900',
    currency: 'USDT',
    amount: '1000.00000000',
    fee: '6.17110000',
    account_from: {
      id: 1,
      name: 'Bitfinex Gozo Exchange Main',
      exchange: 'BITFINEX',
    },
    account_to: {
      id: 13,
      name: 'iHodl new Safe Treasury',
      exchange: 'WALLETS',
    },
    tx_id: null,
    external_id: '16765460',
    exchange_gate_id: '1357',
    withdrawal_status: 'Canceled',
    withdrawal_executed_at: null,
    deposit_status: null,
    status: StatusType.CANCELED,
    comment: '',
    created_by: null,
    description: '',
  },
  {
    id: 283686,
    updated_at: '2021-09-13T14:16:53Z',
    deposit_address: '1AQYtrGtWeDuikNf3o19nHcHsFu6jesS2E',
    currency: 'BTC',
    amount: '0.10000000',
    fee: '0.00040000',
    account_from: {
      id: 1,
      name: 'Bitfinex Gozo Exchange Main',
      exchange: 'BITFINEX',
    },
    account_to: {
      id: 10,
      name: 'iHodl Binance',
      exchange: 'BINANCE',
    },
    tx_id: 'eb2de9ee9c1df7357ca7210dc9ef7e73194ca07e97f3174b9e9a4012cb0f0a7b',
    external_id: '16764521',
    exchange_gate_id: '1354',
    withdrawal_status: 'Completed',
    withdrawal_executed_at: '2021-09-13T13:18:34Z',
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: null,
    description: '',
  },
  {
    id: 283685,
    updated_at: '2021-09-13T14:07:57Z',
    deposit_address: '1AQYtrGtWeDuikNf3o19nHcHsFu6jesS2E',
    currency: 'BTC',
    amount: '0.10000000',
    fee: '0.00040000',
    account_from: {
      id: 1,
      name: 'Bitfinex Gozo Exchange Main',
      exchange: 'BITFINEX',
    },
    account_to: {
      id: 10,
      name: 'iHodl Binance',
      exchange: 'BINANCE',
    },
    tx_id: null,
    external_id: '16764920',
    exchange_gate_id: '1355',
    withdrawal_status: 'Canceled',
    withdrawal_executed_at: null,
    deposit_status: null,
    status: StatusType.CANCELED,
    comment: '',
    created_by: null,
    description: '',
  },
  {
    id: 284314,
    updated_at: '2021-09-13T12:42:34Z',
    deposit_address: '0x21dbc2b64bba23a6f02cc07a3798be3849175c8b',
    currency: 'USDC',
    amount: '1459045.79075000',
    fee: '20.00000000',
    account_from: {
      id: 7,
      name: 'iHodl Bitstamp',
      exchange: 'BITSTAMP',
    },
    account_to: {
      id: 10,
      name: 'iHodl Binance',
      exchange: 'BINANCE',
    },
    tx_id: '0x3a4307a8cf5eb6b87c6a08cb8b752f633ded6b188ea92ee58786876f595c2d6e',
    external_id: '12116647',
    exchange_gate_id: '1534',
    withdrawal_status: 'Completed',
    withdrawal_executed_at: '2021-09-13T12:27:44Z',
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: null,
    description: '',
  },
  {
    id: 283687,
    updated_at: '2021-09-13T11:58:40Z',
    deposit_address: '0x21dbc2b64bba23a6f02cc07a3798be3849175c8b',
    currency: 'USDT',
    amount: '100000.00000000',
    fee: '6.17110000',
    account_from: {
      id: 1,
      name: 'Bitfinex Gozo Exchange Main',
      exchange: 'BITFINEX',
    },
    account_to: {
      id: 10,
      name: 'iHodl Binance',
      exchange: 'BINANCE',
    },
    tx_id: '0x3729c47fc025023e9b67337c011a5c82e8687666f4d784480d22866f75e01ae0',
    external_id: '16764076',
    exchange_gate_id: '1352',
    withdrawal_status: 'Completed',
    withdrawal_executed_at: '2021-09-13T11:44:23Z',
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: null,
    description: '',
  },
  {
    id: 283690,
    updated_at: '2021-09-07T16:20:37Z',
    deposit_address: '0x21dbc2b64bba23a6f02cc07a3798be3849175c8b',
    currency: 'USDT',
    amount: '300000.00000000',
    fee: '13.29700000',
    account_from: {
      id: 1,
      name: 'Bitfinex Gozo Exchange Main',
      exchange: 'BITFINEX',
    },
    account_to: {
      id: 10,
      name: 'iHodl Binance',
      exchange: 'BINANCE',
    },
    tx_id: '0x2c31a956a99622d162b86f19027fd0ebf7a7ffc0da29840a3f0cfe01e63cd04a',
    external_id: '16725281',
    exchange_gate_id: '1350',
    withdrawal_status: 'Completed',
    withdrawal_executed_at: '2021-09-07T10:12:47Z',
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: null,
    description: '',
  },
  {
    id: 283688,
    updated_at: '2021-09-07T10:22:18Z',
    deposit_address: '0x0427933133113C016C398202352307539430c900',
    currency: 'ETH',
    amount: '50.00000000',
    fee: '0.00350000',
    account_from: {
      id: 7,
      name: 'iHodl Bitstamp',
      exchange: 'BITSTAMP',
    },
    account_to: {
      id: 13,
      name: 'iHodl new Safe Treasury',
      exchange: 'WALLETS',
    },
    tx_id: '0x90e869d92962f38cbc713f57d7f3b11c5706e3bc3b2ec5fe533dc1b1e951e98d',
    external_id: '12046286',
    exchange_gate_id: '1348',
    withdrawal_status: 'Completed',
    withdrawal_executed_at: '2021-09-07T10:22:18Z',
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: null,
    description: '',
  },
  {
    id: 283689,
    updated_at: '2021-09-07T10:19:15Z',
    deposit_address: '0x0427933133113C016C398202352307539430c900',
    currency: 'USDT',
    amount: '300000.00000000',
    fee: '13.29700000',
    account_from: {
      id: 1,
      name: 'Bitfinex Gozo Exchange Main',
      exchange: 'BITFINEX',
    },
    account_to: {
      id: 13,
      name: 'iHodl new Safe Treasury',
      exchange: 'WALLETS',
    },
    tx_id: '0x4e1cf7572e3411aaf91a9b698fbf4f88f89daf215257876297f598d3679467a3',
    external_id: '16725324',
    exchange_gate_id: '1349',
    withdrawal_status: 'Completed',
    withdrawal_executed_at: '2021-09-07T10:19:15Z',
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: null,
    description: '',
  },
  {
    id: 283692,
    updated_at: '2021-09-06T10:28:37Z',
    deposit_address: '0x21dbc2b64bba23a6f02cc07a3798be3849175c8b',
    currency: 'USDT',
    amount: '2000000.00000000',
    fee: '14.25800000',
    account_from: {
      id: 1,
      name: 'Bitfinex Gozo Exchange Main',
      exchange: 'BITFINEX',
    },
    account_to: {
      id: 10,
      name: 'iHodl Binance',
      exchange: 'BINANCE',
    },
    tx_id: '0x91ecbbb58acf15da2452221c76a21492666053a3b20d08fe03d965e7bd7cad80',
    external_id: '16717347',
    exchange_gate_id: '1344',
    withdrawal_status: 'Completed',
    withdrawal_executed_at: '2021-09-06T09:37:10Z',
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: null,
    description: '',
  },
  {
    id: 283691,
    updated_at: '2021-09-06T10:04:15.083000Z',
    deposit_address: '3Ez2WUDvKj32V7vCdcthREVhoAyw94R1Mw',
    currency: 'BTC',
    amount: '45.00000000',
    fee: '0.00050000',
    account_from: {
      id: 7,
      name: 'iHodl Bitstamp',
      exchange: 'BITSTAMP',
    },
    account_to: {
      id: 2,
      name: 'Deribit Gozo Main',
      exchange: 'DERIBIT',
    },
    tx_id: '1974812af47c0b0db0d343ed1840ea03cc33cba18f6772bd4aa67edcdcd544ee',
    external_id: '12034523',
    exchange_gate_id: '1345',
    withdrawal_status: 'Completed',
    withdrawal_executed_at: '2021-09-06T09:57:16Z',
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: null,
    description: '',
  },
  {
    id: 283693,
    updated_at: '2021-09-03T12:52:41Z',
    deposit_address: '0x21dbc2b64bba23a6f02cc07a3798be3849175c8b',
    currency: 'USDT',
    amount: '2000000.00000000',
    fee: '20.00100000',
    account_from: {
      id: 1,
      name: 'Bitfinex Gozo Exchange Main',
      exchange: 'BITFINEX',
    },
    account_to: {
      id: 10,
      name: 'iHodl Binance',
      exchange: 'BINANCE',
    },
    tx_id: null,
    external_id: '16701525',
    exchange_gate_id: '1343',
    withdrawal_status: 'Canceled',
    withdrawal_executed_at: null,
    deposit_status: null,
    status: StatusType.CANCELED,
    comment: '',
    created_by: null,
    description: '',
  },
  {
    id: 283694,
    updated_at: '2021-09-03T12:48:26Z',
    deposit_address: '0x21dbc2b64bba23a6f02cc07a3798be3849175c8b',
    currency: 'USDT',
    amount: '2000000.00000000',
    fee: '20.00100000',
    account_from: {
      id: 1,
      name: 'Bitfinex Gozo Exchange Main',
      exchange: 'BITFINEX',
    },
    account_to: {
      id: 10,
      name: 'iHodl Binance',
      exchange: 'BINANCE',
    },
    tx_id: null,
    external_id: '16701502',
    exchange_gate_id: '1342',
    withdrawal_status: 'Canceled',
    withdrawal_executed_at: null,
    deposit_status: null,
    status: StatusType.CANCELED,
    comment: '',
    created_by: null,
    description: '',
  },
  {
    id: 283695,
    updated_at: '2021-09-02T09:25:27Z',
    deposit_address: '0x0427933133113C016C398202352307539430c900',
    currency: 'USDT',
    amount: '10000.00000000',
    fee: '15.00000000',
    account_from: {
      id: 10,
      name: 'iHodl Binance',
      exchange: 'BINANCE',
    },
    account_to: {
      id: 13,
      name: 'iHodl new Safe Treasury',
      exchange: 'WALLETS',
    },
    tx_id: '0x1bbf2bb4e00ba89b4b6ea189af1cfb0b619599e08fe57789b90783cff7619354',
    external_id: '425aef07bd964cbd828dd660ffa5c803',
    exchange_gate_id: '1340',
    withdrawal_status: 'Completed',
    withdrawal_executed_at: '2021-09-02T09:25:27Z',
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: null,
    description: '',
  },
  {
    id: 283696,
    updated_at: '2021-09-02T08:49:57Z',
    deposit_address: '0x0427933133113C016C398202352307539430c900',
    currency: 'ETH',
    amount: '50.00000000',
    fee: '0.00350000',
    account_from: {
      id: 7,
      name: 'iHodl Bitstamp',
      exchange: 'BITSTAMP',
    },
    account_to: {
      id: 13,
      name: 'iHodl new Safe Treasury',
      exchange: 'WALLETS',
    },
    tx_id: '0xe598a73118bc477788f93efb9c0875df3f90b8cb991f3fc081088e652312373f',
    external_id: '11991494',
    exchange_gate_id: '1338',
    withdrawal_status: 'Completed',
    withdrawal_executed_at: '2021-09-02T08:49:57Z',
    deposit_status: 'Completed',
    status: StatusType.COMPLETED,
    comment: '',
    created_by: null,
    description: '',
  },
];
