import { action, makeObservable, observable, runInAction } from 'mobx';

import { requestGet } from 'shared/axios';
import { UserInfoType } from 'shared/types';

import { IUserStore } from '../models';

class UserStore implements IUserStore {
  constructor() {
    makeObservable(this);
  }

  @observable public user: Nullable<UserInfoType> = null;

  @action.bound public async fetchUserInfo() {
    try {
      const response = await requestGet('/profile/');
      if (response.status >= 200) {
        runInAction(() => {
          this.user = response.data;
        });
      }
    } catch (e) {
      console.log('get user info error:', e);
    }
  }

  @action.bound public async signIn() {

  }

  @action.bound public async signOut() {

  }
}

export const UserStoreInstance = new UserStore();
