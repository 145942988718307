import { useState, useEffect, useRef, FC, useMemo } from 'react';
import useOnClickOutside from 'use-onclickoutside';

import * as S from './styled';
import { useLocalStorage } from 'shared/hooks/useLocalStorage';
import { Checkbox } from '../FilterAbstract';
import Circle from '../Сircle';
import { useFilters } from 'shared/hooks/useFilters';
import InfiniteScroll from 'shared/modules/InfiniteScroll';
import { useUsersQuery } from 'features/Profile/view/hooks/useUsersQuery';
import { useToggleList } from 'shared/hooks/useToggleList';
import { useDebounce } from 'shared/hooks/useDebounce';

interface DropdownSelectProps {
  formik: any;
  setMaxsigners: any;
}

interface SearchBarProps {
  search: string;
  setSearch: any;
  refetchUsers: any;
}

export const SearchBar: FC<SearchBarProps> = ({
  search,
  setSearch,
  refetchUsers,
}) => {
  const [_, setFilters] = useFilters('users_search');
  const onChange = (e: any) => {
    setSearch(e.target.value);
  };

  useDebounce(
    () => {
      setFilters({ search });
      refetchUsers();
    },
    [search],
    600,
  );

  const onReset = () => {
    setSearch('');
  };

  return (
    <S.SearchForm>
      <S.IconSearch />
      <S.SearchInput
        placeholder="Search by name"
        value={search}
        onChange={onChange}
      />
      {search && <S.IconClear onClick={onReset} />}
    </S.SearchForm>
  );
};

export const DropdownSelect: FC<DropdownSelectProps> = ({
  formik,
  setMaxsigners,
}) => {
  const [_, setFilters] = useFilters('users_search');
  const [search, setSearch] = useState('');
  const [cache, setCache] = useLocalStorage('create-dropdown', false);
  const [opened, setIsOpen] = useState(cache);
  const ref = useRef(null);
  const {
    rows: users,
    fetchNextPage,
    hasNextPage,
    refetch: refetchUsers,
    isLoading,
  } = useUsersQuery();

  const [selectedEntities, selectEntity, unselectEntity] = useToggleList({
    findEntityById: (id: any) => users?.find(({ email }) => email === id),
    findSelectedByEntity: (selected, entity) => selected.email === entity.email,
  });

  useEffect(() => {
    formik.setFieldValue('participants', selectedEntities);
    setMaxsigners(selectedEntities.length);
  }, [selectedEntities]);

  useEffect(() => {
    localStorage.setItem('users_search', '{}');
    setFilters({});
    setCache(false);
    setIsOpen(false);
    refetchUsers();
  }, []);

  const entities = users?.map(({ email, first_name, last_name }: any) => ({
    value: email,
    label: `${first_name} ${last_name}`,
  }));

  const unselectedEntities = useMemo(
    () =>
      entities?.filter(
        ({ value: e }) =>
          selectedEntities?.findIndex(({ email: s }) => e === s) === -1,
      ),
    [entities, selectedEntities],
  );

  const onForceToggle = () => {
    setIsOpen(false);
    setCache(false);
  };

  const onClick = (e: any) => {
    e.stopPropagation();
    setIsOpen(!opened);
    setCache(!opened);
  };

  useOnClickOutside(ref, onForceToggle);

  return (
    <S.SelectWrap isOpen={opened} ref={ref}>
      <S.SelectItemDefault onClick={onClick}>
        {selectedEntities?.length ? (
          `${selectedEntities?.length} participants`
        ) : (
          <S.Placeholder>Select</S.Placeholder>
        )}
      </S.SelectItemDefault>
      <S.IconDropdown onClick={onClick} isOpen={opened} />
      {opened && (
        <>
          <S.SearchBar>
            <SearchBar
              search={search}
              refetchUsers={refetchUsers}
              setSearch={setSearch}
            />
          </S.SearchBar>
          <S.SelectDropdown>
            {selectedEntities?.map((entity) => {
              const { email, first_name, last_name } = entity;
              const onClick = () => {
                unselectEntity(entity);
              };

              return (
                <S.SelectItem key={email} onClick={onClick}>
                  <S.UserData>
                    <Circle
                      seed={`${email}_${first_name} ${last_name}`}
                      size={32}
                    />
                    <S.UserInfo>
                      <S.FullName>
                        {first_name} {last_name}
                      </S.FullName>
                      <S.Email>{email}</S.Email>
                    </S.UserInfo>
                  </S.UserData>
                  <S.Checkbox>
                    <Checkbox type="checkbox" checked={true} />
                    <span />
                  </S.Checkbox>
                </S.SelectItem>
              );
            })}
            {selectedEntities?.length && unselectedEntities?.length ? (
              <S.Divider />
            ) : null}
            <InfiniteScroll
              useWindow={false}
              pageStart={0}
              // @ts-ignore
              loadMore={fetchNextPage}
              hasMore={hasNextPage && !isLoading}
            >
              {unselectedEntities?.map(({ value, label }) => {
                const onClick = () => {
                  selectEntity(value);
                };

                return (
                  <S.SelectItem key={value} onClick={onClick}>
                    <S.UserData>
                      <Circle seed={`${value}_${label}`} size={32} />
                      <S.UserInfo>
                        <S.FullName>{label}</S.FullName>
                        <S.Email>{value}</S.Email>
                      </S.UserInfo>
                    </S.UserData>
                    <S.Checkbox>
                      <Checkbox type="checkbox" checked={false} />
                      <span />
                    </S.Checkbox>
                  </S.SelectItem>
                );
              })}
            </InfiniteScroll>
          </S.SelectDropdown>
        </>
      )}
    </S.SelectWrap>
  );
};

export default DropdownSelect;
