import { useMemo, useState } from 'react';
import type { ReactElement } from 'react';
import type { DecodedDataResponse } from '@safe-global/safe-gateway-typescript-sdk';
import { getDecodedData, Operation } from '@safe-global/safe-gateway-typescript-sdk';
import type { SafeTransaction } from '@safe-global/safe-core-sdk-types';
import { Box, Typography } from '@mui/material';

import SendFromBlock from './SendFromBlock';
import SendToBlock from './SendToBlock';
import SignOrExecuteForm from './SignOrExecuteForm';
import { generateDataRowValue } from './TxDataRow';
import useAsync from 'features/Multisig/view/hooks/useAsync';
import { _getChain, _getChainId } from 'features/Multisig/helpers';
import { useCurrentChain } from '../hooks/useChains';
import useTxSender from './hooks/useTxSender';
import { getInteractionTitle } from '../helpers/utils';
import type { SafeAppsTxParams } from '.';
import { trackSafeAppTxCount } from '../helpers/track-app-usage-count';
import { getTxOrigin } from './utils/transactions';

import { Loader } from 'shared/components';

import { Step } from 'features/SafeApp/hooks/useTxStepper';

type ReviewSafeAppsTxProps = {
  safeAppsTx: SafeAppsTxParams
  steps: Array<Step>
  onClose: any
};

export const isEmptyHexData = (encodedData: string) => encodedData && isNaN(parseInt(encodedData, 16));

const ReviewSafeAppsTx = ({
  steps,
  onClose,
  safeAppsTx: {
    txs,
    requestId,
    params,
    appId,
    app,
    initialData,
    initialStep,
  },
}: ReviewSafeAppsTxProps): ReactElement => {
  const { createMultiSendCallOnlyTx, dispatchSafeAppsTx } = useTxSender();
  const chainId = _getChainId();
  const chain = _getChain();
  const [submitError, setSubmitError] = useState<Error>();

  const isMultiSend = txs.length > 1;

  const [safeTx, safeTxError] = useAsync<SafeTransaction | undefined>(async () => {
    const tx = await createMultiSendCallOnlyTx(txs);

    if (params?.safeTxGas) {
      // FIXME: do it properly via the Core SDK
      // @ts-expect-error safeTxGas readonly
      tx.data.safeTxGas = params.safeTxGas;
    }

    return tx;
  }, [txs, createMultiSendCallOnlyTx]);

  const [decodedData] = useAsync<DecodedDataResponse | undefined>(async () => {
    if (!safeTx || isEmptyHexData(safeTx.data.data)) return;

    // @ts-ignore
    return getDecodedData(chainId, safeTx.data.data);
  }, [safeTx, chainId]);

  const handleSubmit = async () => {
    setSubmitError(undefined);
    if (!safeTx) return;
    trackSafeAppTxCount(Number(appId));

    try {
      await dispatchSafeAppsTx(safeTx, requestId);
    } catch (error) {
      setSubmitError(error as Error);
    }
  };

  const origin = useMemo(() => getTxOrigin(app), [app]);

  if (!safeTx) {
    return <Loader />;
  }

  return (
    <SignOrExecuteForm
      safeTx={safeTx}
      onSubmit={handleSubmit}
      error={safeTxError || submitError}
      origin={origin}
      onClose={onClose}
    >
      <>
        <SendFromBlock />

        <SendToBlock address={safeTx.data.to} title={getInteractionTitle(safeTx.data.value || '', chain)} />

        <Box pb={2}>
          <Typography mt={2} color="primary.light">
            Data (hex encoded)
          </Typography>
          {generateDataRowValue(safeTx.data.data, 'rawData')}
        </Box>
      </>
    </SignOrExecuteForm>
  );
};

export default ReviewSafeAppsTx;
