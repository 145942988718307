import { useFilters } from 'shared/hooks/useFilters';
import { useCallback, useState } from 'react';
import { debounce } from 'lodash';

import * as S from './styled';

type FilterSearchProps = FiltersProps;

export const FilterSearch = ({
  storageKey,
  onSubmit,
  disabled,
}: FilterSearchProps) => {
  const [getFilters, setFilters] = useFilters(storageKey);
  const { search } = getFilters();
  const [query, setQuery] = useState(search);

  // @ts-ignore
  const debouncesHandleInput = debounce(() => onSubmit(query), 500);

  const onChange = (event: any) => {
    const { value } = event.target;
    setQuery(value);
    setFilters({ search: value });

  // @ts-ignore
    onSubmit(value);
  };

  const onReset = () => {
    setQuery('');
    setFilters({ search: undefined });

    // @ts-ignore
    onSubmit('');
  };

  return (
    <S.SearchForm>
      <S.IconSearch />
      <S.SearchInput
        type="text"
        name="search"
        onChange={onChange}
        value={query}
        placeholder="Search"
        disabled={disabled}
      />
      {search && <S.IconClear onClick={onReset} />}
    </S.SearchForm>
  );
};
