import { FC, useContext, useMemo } from 'react';
import * as S from 'features/Transactions/view/styled';
import { Transaction } from 'services/Transactions/types';
import AppContext from 'shared/contexts/AppContext';
import {
  aboutToViolateMaxThreshold,
  aboutToViolateMinThreshold,
  getThreshold,
} from 'shared/helpers/transaction';
import { getExchangeLogoUrl } from 'shared/constants/exchanges';

interface ThresholdViolationInfoProps {
  tx: any;
}

export const ThresholdViolationInfo: FC<ThresholdViolationInfoProps> = ({
  tx,
}) => {
  const { currency, amount, account_from, account_to, wallet_from, wallet_to } =
    tx;

  const { getAccountById, isAccountsLoading } = useContext(AppContext);

  const fromAccount = getAccountById(Number(account_from?.id));
  const toAccount = getAccountById(Number(account_to?.id));
  const fromThreshold = getThreshold(fromAccount, currency);
  const toThreshold = getThreshold(toAccount, currency);
  const aboutToViolateFrom = aboutToViolateMinThreshold(fromThreshold, amount);
  const aboutToViolateTo = aboutToViolateMaxThreshold(toThreshold, amount);
  const fromLogoUrl = getExchangeLogoUrl(fromAccount?.exchange) || null;
  const toLogoUrl = getExchangeLogoUrl(toAccount?.exchange) || null;

  if (isAccountsLoading) return null;

  return (
    <>
      {aboutToViolateFrom && (
        <S.ThresholdViolation>
          This transaction will violate your
          <span>
            <S.ThresholdExchangeIcon
              style={{
                backgroundImage: `url(${fromLogoUrl})`,
              }}
            />
            {fromAccount?.name} {wallet_from?.type}
          </span>{' '}
          account’s minimum threshold ({fromThreshold?.min_amount} {currency})
        </S.ThresholdViolation>
      )}
      {aboutToViolateTo && (
        <S.ThresholdViolation>
          This transaction will violate your
          <span>
            <S.ThresholdExchangeIcon
              style={{
                backgroundImage: `url(${toLogoUrl})`,
              }}
            />
            {toAccount?.name} {wallet_to?.type}
          </span>{' '}
          account’s maximum threshold ({toThreshold?.max_amount} {currency})
        </S.ThresholdViolation>
      )}
    </>
  );
};
