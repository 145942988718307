import styled, { css } from 'styled-components/macro';
import { ReactComponent as SvgCopy } from 'shared/assets/icons/icon-copy-stroke.svg';

import Tippy from '@tippyjs/react';

export const ArrowWrap = styled.button<{
  opened?: boolean;
  width?: string;
  height?: string;
}>`
  background: none;
  border: none;
  cursor: pointer;
  width: ${(props) => props.width || '16px'};
  height: ${(props) => props.height || '16px'};
  transition: all 0.2s;
  color: var(--object-secondary);
  margin: 0 0 0 10px;
  cursor: pointer;

  ${(prop) =>
    prop.opened &&
    css`
      transform: rotate(180deg);
    `}
`;

export const IconStyle = css`
  stroke: currentColor;
  transition: 0.4s;

  &:hover {
    stroke: var(--object-primary);
  }
`;

const IconCopy = styled(SvgCopy)`
  ${IconStyle}
`;

function Copy({
  text,
  width,
  height,
  className,
}: {
  text: string;
  className?: string;
  width?: string;
  height?: string;
}) {
  return (
    <ArrowWrap
      onClick={(e) => e.stopPropagation()}
      className={className}
      width={width}
      height={height}
    >
      <Tippy
        onTrigger={() => {
          navigator.clipboard.writeText(text);
        }}
        trigger="click"
        placement="top"
        interactive={true}
        theme="transparent"
        content={<div>Copied!</div>}
      >
        <IconCopy />
      </Tippy>
    </ArrowWrap>
  );
}
export default Copy;
