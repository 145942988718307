import { motion } from 'framer-motion';
import { FC, useContext } from 'react';
import styled from 'styled-components';

import AppContext from '../../contexts/AppContext';
import { NoAccess } from 'shared/components/index';

import { PermissionType } from '../../types';

interface ProtectedAreaProps {
  every?: PermissionType[];
  any?: PermissionType[];
  displayMessage?: boolean;
  children: React.ReactNode;
}

const Wrap = styled(motion.div)`
  min-height: 100%;
  max-height: 100%;
  background: var(--base);
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ProtectedArea: FC<ProtectedAreaProps> = ({
  children,
  every,
  any,
  displayMessage = false,
}) => {
  const { user } = useContext(AppContext);
  const component = displayMessage ? (
    <Wrap
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      exit={{ opacity: 0, transition: { duration: 0.5 } }}
    >
      <NoAccess />
    </Wrap>
  ) : null;

  if (!user) return component;

  const { permissions } = user;

  if (
    every?.length &&
    !every.every((permission) => permissions.includes(permission))
  )
    return component;

  if (
    any?.length &&
    !any.some((permission) => permissions.includes(permission))
  )
    return component;

  return <>{children}</>;
};
