import type { ReactElement } from 'react';
import { useTxStepper, TxStepperProps } from 'features/SafeApp/hooks/useTxStepper';
import * as S from './styled';

const TxStepper = ({ steps, initialData, initialStep, onClose }: TxStepperProps): ReactElement => {
  const { onBack, onSubmit, setStep, activeStep, stepData, firstStep } = useTxStepper({
    steps,
    initialData,
    initialStep,
    onClose,
  });

  const activeLabel = steps[activeStep].label;

  const activeStepData = stepData[Math.max(0, activeStep)];

  return (
    <S.Container>
      <S.ModalHeader>{typeof activeLabel === 'string' ? activeLabel : activeLabel(activeStepData)}</S.ModalHeader>
      {steps.length > 1 && (
        <S.StepIndicator>
          Step {activeStep + 1} out of {steps.length}
        </S.StepIndicator>
      )}

      {steps[activeStep].render(activeStepData, onSubmit, onBack, setStep)}
    </S.Container>
  );
};

export default TxStepper;
