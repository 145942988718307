import Tippy from '@tippyjs/react';
import { QrModal } from 'features/Profile/view/components/QrModal';
import { useCallback, useContext, useMemo, useState } from 'react';
import AppContext from 'shared/contexts/AppContext';
import { CurrencyType } from 'shared/types';
import { ExchangeIconMin } from '../ExchangeAbstract';
import * as S from './styled';
import { toJS } from 'mobx';
import { getThreshold } from 'shared/helpers/transaction';
import { SettingsStoreInstance } from 'services';

export const AccRow = ({
  accWallet,
  chosenWallet,
  getCoinsInfoById,
  currency,
  couldAddTransaction,
  couldAddTransactionProposal,
  isCurrencyFiat,
  side,
  isFromWallet,
  chosenSecondWallet,
  account,
  exchange,
  onClick,
}: any) => {
  const {
    getCurrencyValue,
    openModal,
    setCurrentAccNode,
    getCurrencyPrecision,
  } = useContext(AppContext);
  const { appSettings } = SettingsStoreInstance;
  const coinInfo = getCoinsInfoById(currency, appSettings.currencies_icons);
  const balance = accWallet?.available;
  const isZeroBalance = Number(balance) === 0;

  const isActive = accWallet?.id === chosenWallet?.id;

  const isDisabled =
    !(couldAddTransaction || couldAddTransactionProposal) ||
    isCurrencyFiat(accWallet.currency) ||
    // (Boolean(chosenCurrency) &&
    //   chosenCurrency !== currency) ||
    (side === 'to' && isFromWallet) ||
    (side === 'from' && isZeroBalance && !account.is_multisig) ||
    (side === 'to' && accWallet?.id === chosenSecondWallet?.id) ||
    (side === 'to' && !chosenSecondWallet) ||
    (side === 'from' && account.exchange === 'WALLETS' && !account.is_multisig);
  const [domNode, setDomNode] = useState(null);
  const onRefChange = useCallback(
    (node: any) => {
      setDomNode(node);
      if (
        isFromWallet &&
        accWallet?.id === chosenWallet?.id &&
        side === 'from'
      ) {
        setCurrentAccNode(node);
      }
    },
    [isFromWallet, accWallet, chosenWallet, setCurrentAccNode],
  );

  const openQrModal = () => {
    openModal({
      title: 'Scan QR Code',
      component: () => (
        <QrModal
          encoded={`${accWallet.deposit_address},${accWallet.currency}`}
          decoded={`${accWallet.deposit_address},${accWallet.currency}`}
        />
      ),
    });
  };

  const selectAccount = () => {
    if (!isDisabled) {
      onClick({
        exchangeId: exchange.exchange,
        accountId: account.id,
        walletId: accWallet.id,
        currencyId: currency,
      });
      setTimeout(() => {
        // @ts-ignore
        domNode?.scrollIntoView({
          inline: 'center',
          block: 'center',
          behavior: 'smooth',
        });
      }, 0);
    }
  };

  const isViolated = useMemo(() => {
    const threshold = getThreshold(account, currency);
    if (!threshold) return false;
    return threshold.is_violated;
  }, [account]);

  return (
    <>
      <S.AccountRow
        isViolated={isViolated}
        ref={onRefChange}
        disabled={isDisabled}
        className={isActive ? 'active' : ''}
        onClick={selectAccount}
      >
        <ExchangeIconMin
          disabled={false}
          style={{
            backgroundImage: `url(${
              coinInfo?.logo_url ||
              (isActive
                ? '/i/coins/unknown-active.svg'
                : '/i/coins/unknown.svg')
            })`,
          }}
        />
        <S.CurrencyIso isViolated={isViolated} selected={isActive}>
          {currency}
          <Tippy
            theme="transparent"
            content={
              <>
                Total value of {currency} in your account exceeds the threshold
              </>
            }
          >
            <span>{isViolated && <S.LimitIcon />}</span>
          </Tippy>
        </S.CurrencyIso>
        <S.Currency selected={isActive}>
          <Tippy
            theme="transparent"
            content={
              <>
                <S.HintText>Total</S.HintText>
                <S.HintText>Available</S.HintText>
              </>
            }
          >
            <span>
              <S.AccWalletTotal>
                {Number(accWallet?.total) > 0
                  ? getCurrencyValue({
                      currency: currency as CurrencyType,
                      value: accWallet?.total,
                      minprecision: getCurrencyPrecision(
                        currency as CurrencyType,
                        'send-funds',
                      ),
                      location: 'send-funds',
                    })
                  : 0}
              </S.AccWalletTotal>
              <br />
              <S.AccWalletAvailable>
                {Number(accWallet?.available) > 0
                  ? getCurrencyValue({
                      currency: currency as CurrencyType,
                      value: accWallet?.available,
                      minprecision: getCurrencyPrecision(
                        currency as CurrencyType,
                        'send-funds',
                      ),
                      location: 'send-funds',
                    })
                  : 0}
              </S.AccWalletAvailable>
            </span>
          </Tippy>
        </S.Currency>
      </S.AccountRow>
      {isActive && accWallet.deposit_address && (
        <S.IconQr onClick={openQrModal} />
      )}
    </>
  );
};
