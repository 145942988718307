import { getExplorerInfo } from 'pages/MultisigPage/utilities';

import Copy from 'shared/components/Copy';

import * as T from '../../styled/transactions';

interface TxDetailProps {
  label: string;
  value: string;
  canBeCopied?: boolean;
  isHash?: boolean;
  explorable?: boolean;
}

export const TxDetail = ({
  label,
  value,
  canBeCopied = false,
  isHash = false,
  explorable = false,
}: TxDetailProps) => {
  const { url } = getExplorerInfo(value);

  return (
    <T.DropDataItem>
      <T.DropDataLabel>{label}:&nbsp;</T.DropDataLabel>
      <T.DropDataValue>
        <T.DropAddressText>
          {value === null
            ? 'n/a'
            : isHash
              ? `${value?.slice(0, 5)}...${value?.slice(-5)}`
              : value}
        </T.DropAddressText>
        {canBeCopied && (
          <Copy
            text={value || ''}
            width='14px'
            height='14px'
          />
        )}
        {explorable && (
          <T.Button>
            <a href={url} target="_blank">
              <T.IconExternal />
            </a>
          </T.Button>
        )}
      </T.DropDataValue>
    </T.DropDataItem>
  );
};
