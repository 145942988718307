import styled, { css } from 'styled-components/macro';

export const CurrencySelectWrap = styled.div`
  display: flex;
  width: 100%;
`;

export const CurrencyItem = styled.div<{ isLastActive: boolean }>`
  margin-right: 5px;
  padding: 7px;
  border-radius: 50px;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 10px;
  background-color: var(--border);
  color: var(--object-primary);
  user-select: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 25px;

  &.active {
    background-color: var(--accent-primary);
    color: white;
  }

`;

export const Delimiter = styled.div`
  width: 1px;
  height: 26px;
  background-color: var(--placeholder);
  margin: 0 10px 0 5px;
`;

export const CurrencyLogo = styled.div`
  width: 10px;
  height: 10px;
  margin: 0 5px 0 0;
  background-position: 50%;
  background-size: 10px auto;
`;

export const CurrencyDelimiter = styled.div`
  width: 1px;
  height: 24px;
  background-color: var(--object-secondary);
  margin: 0 10px 5px 10px;
`;

export const ResetFilter = styled.button<{ active: boolean }>`
  color: var(--accent-primary);
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
  padding: 0 10px 0 0;
  margin-right: 10px;
  border: none;
  white-space: nowrap;
  line-height: 24px;
  opacity: 0.5;

  ${(props) =>
    props.active &&
    css`
      opacity: 1;
    `};

  &:hover {
    color: var(--object-primary);
  }
`;

export const List = styled.div`
  display: flex;
  width: calc(100% - 30px);
  align-items: center;
`;

export const CurrencyCounter = styled.div`
  color: var(--accent-primary);
  font-weight: 700;
  font-size: 12px;
`;
