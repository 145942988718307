import { Whitelist } from 'features/Whitelist';
import { ProtectedArea } from 'shared/components';

export const WhitelistsPage = () => {
  return (
    <ProtectedArea displayMessage every={['accounts.view_account']}>
      <Whitelist />
    </ProtectedArea>
  );
};
