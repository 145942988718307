import { removeEmptyValues } from 'shared/helpers/format';

export const useFilters = (key: string): [() => any, (value: any) => void] => {
  const getFilters = () => {
    try {
      const savedFilters = localStorage.getItem(key);
      // TODO: check deep JSON parse
      return savedFilters ? JSON.parse(savedFilters) : {};
    } catch (error) {
      return {};
    }
  };

  const setFilters = (value: any) => {
    const currentFilters = getFilters();
    localStorage.setItem(
      key,
      JSON.stringify(removeEmptyValues({ ...currentFilters, ...value })),
    );
  };

  // TODO: find the best way to return current filters
  return [getFilters, setFilters];
};
