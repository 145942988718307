import Tippy from '@tippyjs/react';
import { useState } from 'react';
import styled from 'styled-components';
import * as S from './AccountStyled';
import Copy from 'shared/components/Copy';

interface AccountAliasesProps {
  address: string;
  aliases: string[] | null;
}

const TooltipMainAddressWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TooltipMainAddress = styled.div`
  margin-right: -5px;
`;

export const AccountAliases = ({ address, aliases }: AccountAliasesProps) => {
  if (!aliases?.length) return null;

  return (
    <Tippy
      theme="transparent"
      trigger="mouseenter focus click"
      placement="right"
      delay={[0, 800]}
      zIndex={9999999}
      interactive={true}
      content={
        <>
          {address && (
            <TooltipMainAddressWrapper>
              <TooltipMainAddress>{address}</TooltipMainAddress>
              <Copy width="10px" height="10px" text={address} />
            </TooltipMainAddressWrapper>
          )}
          {aliases?.map((alias, idx) => (
            <S.AccountSpoilerWrap key={idx}>
              <S.AccountSpoilerItem key={idx}>{alias}</S.AccountSpoilerItem>
            </S.AccountSpoilerWrap>
          ))}
        </>
      }
    >
      <S.AccountSpoiler>
        <S.SpoilerIcon />
      </S.AccountSpoiler>
    </Tippy>
  );
};
