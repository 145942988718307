import { useEffect, useState, useContext } from 'react';
import * as S from './styled';
import AppContext from 'shared/contexts/AppContext';

export const ThemeToggle = () => {
  const { theme, setTheme } = useContext(AppContext);

  function handleSetTheme(th: string) {
    if (th === theme) {
      return;
    }
    localStorage.setItem('theme', th);
    setTheme(th);
  }

  return (
    <S.ThemeWrap className="themetoggle">
      <S.Button onClick={() => handleSetTheme('light')} className="sun">
        <S.IconSun></S.IconSun>
      </S.Button>
      <S.Button onClick={() => handleSetTheme('dark')} className="moon">
        <S.IconMoon></S.IconMoon>
      </S.Button>
    </S.ThemeWrap>
  );
};
