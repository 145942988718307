import TransactionsQueue from './TransactionsQueue';
import TransactionsHistory from './TransactionsHistory';

import * as S from './styled/main';
import * as T from './styled/transactions';

function Transactions() {
  return (
    <T.Wrap
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      exit={{ opacity: 0, transition: { duration: 0.5 } }}
    >
      <S.ContentWrap>
        <TransactionsQueue />
        <TransactionsHistory />
      </S.ContentWrap>
    </T.Wrap>
  );
}

export default Transactions;
