// @ts-nocheck

import { Operation, TransactionDetails } from '@gnosis.pm/safe-react-gateway-sdk';
import { useState } from 'react';
import * as T from '../../styled/transactions';
import { formattedDate } from '../../../../../shared/helpers/date';
import { getByteLength } from '../../../helpers';
import { TxDetail } from './';

export const TransactionSecondaryDetails = ({ details }: {
  details: TransactionDetails;
}) => {
  const [showAdvanced, setShowAdvanced] = useState<boolean>(false);

  if (!details) return null;

  // If transaction is received transfer type
  const { executedAt, txHash } = details;
  const { type, direction } = details.txInfo;

  if (type === 'Transfer' && direction === 'INCOMING') {
    return (
      <T.DropDataWrap>
        <TxDetail
          label={'Transaction hash'}
          value={txHash}
          canBeCopied
          isHash
        />
        <TxDetail label={'Executed'} value={executedAt} />
      </T.DropDataWrap>
    );
  }

  // For any other type of transactions
  const { detailedExecutionInfo, txData } = details;
  const {
    submittedAt,
    confirmations,
    safeTxHash,
    baseGas,
    gasPrice,
    gasToken,
    safeTxGas,
  } = detailedExecutionInfo;
  const refundReceiver = detailedExecutionInfo.refundReceiver?.value;

  return (
    <T.DropDataWrap>
      {txHash && <TxDetail label={'Transaction hash'} value={txHash} canBeCopied explorable isHash />}
      <TxDetail label={'SafeTxHash'} value={safeTxHash} canBeCopied isHash />
      <TxDetail
        label={'Created'}
        value={formattedDate(submittedAt || 0, 'MMM DD YYYY, hh:mm A')}
      />
      {executedAt && <TxDetail
        label={'Executed'}
        value={formattedDate(executedAt || 0, 'MMM DD YYYY, hh:mm A')}
      />}
      <T.DropAdvanced onClick={() => setShowAdvanced(!showAdvanced)}>
        Advanced details
      </T.DropAdvanced>
      {showAdvanced && (
        <>
          {txData?.operation ? (
            <TxDetail
              label={'Operation'}
              value={`${txData.operation} (${Operation[
                txData.operation
                ]?.toLowerCase()})`}
            />
          ) : null}
          <TxDetail label={'safeTxGas'} value={safeTxGas} />
          <TxDetail label={'baseGas'} value={baseGas} />
          <TxDetail label={'gasPrice'} value={gasPrice} />
          <TxDetail label={'gasToken'} value={gasToken} explorable canBeCopied isHash />
          <TxDetail
            label={'refundReceiver'}
            explorable
            value={refundReceiver}
            canBeCopied
            isHash
          />
          {confirmations?.map(({ signature }, idx) => (
            <TxDetail
              label={`Signature ${idx + 1}`}
              key={`signature-${idx}`}
              value={`${getByteLength(signature)} bytes`}
              canBeCopied
            />
          ))}
          <TxDetail
            label={'Raw data'}
            canBeCopied
            value={`${getByteLength(txData?.hexData)} bytes`}
          />
        </>
      )}
    </T.DropDataWrap>
  );
};
