import { observer } from 'mobx-react';

import { ProtectedArea } from 'shared/components';
import { Multisig } from 'features/Multisig';

const MultisigPage = () => (
    <ProtectedArea displayMessage every={['accounts.view_account']}>
      <Multisig />
    </ProtectedArea>
  );

const C = observer(MultisigPage);

export { C as MultisigPage };
