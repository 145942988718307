import { useToggle } from 'shared/hooks/useToggle';
import * as S from './AccountStyled';
import { FiatMethodHeader } from './FiatMethodHeader';
import {
  AccountType,
  ColumnSide,
  CurrencyType,
  ExchangeAccountsType,
  WalletType,
} from 'shared/types';
import { useTransfer } from 'features/Transfer/context/TransferContext';
import { SubAccountItem } from './SubAccountItem';
import { SMALL_BALANCE_VALUE } from 'features/Transfer/constants';
import {
  useAccounts,
  useIsOpen,
} from 'features/Transfer/context/AccountContext';

import { v4 as uuidv4 } from 'uuid';
import { FiatWalletItem } from './FiatWalletItem';

interface AccountProps {
  accounts: any[];
  method: string;
}

export const FiatMethodItem = ({ accounts, method }: AccountProps) => {
  const [opened, toggle] = useIsOpen('to', 'method', method);

  return (
    <S.AccountWrap opened={opened}>
      <FiatMethodHeader
        toggle={toggle}
        opened={opened}
        method={method}
        balanceUpdatedAt={accounts[0].updated_at}
      />
      {opened ? (
        <S.AccountsRow key={method}>
          {accounts.map((account) => (
            <FiatWalletItem
              key={account.id}
              wallet={account}
            />
          ))}
        </S.AccountsRow>
      ) : null}
    </S.AccountWrap>
  );
};
