import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { format, parseISO } from 'date-fns';

import * as S from '../../styled';
import AppContext from 'shared/contexts/AppContext';
import { Position } from '../../../types';
import { StatusType } from 'shared/types';
import { formatAccount } from 'shared/helpers/format';
import { IconInfo } from '../Filters/styled';
import Tippy from '@tippyjs/react';
import { getExchangeInfoById } from 'shared/constants/exchanges';
import { PositionAccount } from './PositionAccount';

interface PositionExchangeProps {
  exchange: string;
  data: any;
  viewInfo: any;
}

export const PositionExchange = ({
  exchange,
  data,
  viewInfo,
}: PositionExchangeProps) => {
  const { appSettings, user, accounts } = useContext(AppContext);

  const exchangeInfo = getExchangeInfoById(exchange);

  // const {} = position;

  return (
    <S.PositionExchangeRow>
      <S.PositionExchangeName>
        <S.PositionExchangeLogo
          style={{ backgroundImage: `url(${exchangeInfo?.logo_url})` }}
        />
        {exchange}
      </S.PositionExchangeName>

      {Object.keys(data).sort((a, b) => a.localeCompare(b)).map((key) => (
        <PositionAccount
          viewInfo={viewInfo}
          key={key}
          name={key}
          exchange={exchange}
          positions={data[key]}
        />
      ))}
    </S.PositionExchangeRow>
  );
};
