import * as S from 'features/Whitelist/newView/styled';
import { useContext } from 'react';
import AppContext from 'shared/contexts/AppContext';
import { getDropdownFilters } from '../../constants';
import { FilterSearch, FilterDropdown } from '../Filters';
import { FilterViewOptions } from '../Filters/FilterViewOptions';

import { useFilters } from 'shared/hooks/useFilters';
import { useIsMobile } from 'shared/hooks/useIsMobile';
import queryString from 'query-string';
import { Filter, Filters } from '../../styled';

interface WhitelistsFiltersProps {
  onSubmit: () => void;
  disabled: boolean;
  isMobile: boolean;
  isAsideButtonChecked: string;
  setIsAsideButtonChecked: any;
  setIsMobile: any;
  tab: string;
}

const storageKey = 'whitelists_filters';

export const stringifyUrl = (url: string, filters: any) => {
  return queryString.stringifyUrl(
    {
      url,
      query: {
        ...filters,
      },
    },
    { arrayFormat: 'separator' },
  );
};

export const WhitelistsFilters = ({
  tab,
  onSubmit,
  disabled,
  isAsideButtonChecked,
  setIsAsideButtonChecked,
  setIsMobile,
  isMobile,
}: WhitelistsFiltersProps) => {
  const { exchanges, appSettings } = useContext(AppContext);
  const networks = [
    // @ts-ignore
    ...new Set(Object.values(appSettings.currencies_networks).flat()),
  ];
  const dropdownFilters = getDropdownFilters(exchanges, networks, appSettings);

  const [getFilters] = useFilters(storageKey);
  const filters = getFilters();

  const handleAsideSize = () => {
    setIsMobile(!isMobile);

    if (isAsideButtonChecked === 'unset') {
      setIsAsideButtonChecked(window.innerWidth < 1300 ? 'OPEN' : 'CLOSED');
    } else if (isAsideButtonChecked === 'CLOSED') {
      setIsAsideButtonChecked('OPEN');
    } else {
      setIsAsideButtonChecked('CLOSED');
    }
  };

  function countElements(obj: any) {
    let count = 0;
    const bothOptions = localStorage.getItem(
      'whitelist_view_options_both_selected',
    );

    if (bothOptions === 'true' && tab === 'whitelists') {
      count += 2;
    }

    for (const key in obj) {
      if (Array.isArray(obj[key])) {
        count += obj[key].length;
      } else {
        if (
          (key === 'is_whitelisted' || key === 'exchange') &&
          tab !== 'whitelists'
        ) {
          continue;
        } else {
          count++;
        }
      }
    }

    return count;
  }

  const filtersCount = countElements(filters);

  return (
    <Filters isMobile={isMobile} isAsideButtonChecked={isAsideButtonChecked}>
      {isMobile && (
        <S.FiltersData onClick={handleAsideSize} isMobile={isMobile}>
          <S.FiltersCounter>{filtersCount}</S.FiltersCounter>
          <S.FiltersIcon />
        </S.FiltersData>
      )}
      <Filter isMobile={isMobile}>
        <FilterSearch
          storageKey={storageKey}
          onSubmit={onSubmit}
          disabled={disabled}
        />
      </Filter>
      {dropdownFilters.map((filter) => {
        if (filter.field === 'is_whitelisted' && tab === 'whitelists') {
          return (
            <Filter isMobile={isMobile} key={`${storageKey}_${filter.field}`}>
              <FilterViewOptions
                onSubmit={onSubmit}
                disabled={disabled}
                storageKey={storageKey}
                {...filter}
              />
            </Filter>
          );
        } else if (
          (filter.field === 'is_whitelisted' || filter.field === 'exchange') &&
          tab !== 'whitelists'
        ) {
          return null;
        } else {
          return (
            <Filter isMobile={isMobile} key={`${storageKey}_${filter.field}`}>
              <FilterDropdown
                onSubmit={onSubmit}
                disabled={disabled}
                storageKey={storageKey}
                {...filter}
              />
            </Filter>
          );
        }
      })}
      <S.FilterDivider></S.FilterDivider>
      <S.AsideButton
        onClick={handleAsideSize}
        isMobile={isMobile}
        isAsideButtonChecked={isAsideButtonChecked}
      >
        <S.ArrowIconAside />
        {/* <S.Border /> */}
      </S.AsideButton>
    </Filters>
  );
};
