import Tippy from '@tippyjs/react';
import { addMinutes, format } from 'date-fns';
import moment from 'moment';
import styled from 'styled-components';

interface AccountIncomingLimitProps {
  used: number;
  limit: number;
}

const IncomingLimitBar = styled.div<{ used: boolean }>`
  width: 2px;
  height: 10px;
  border-radius: 1px;
  background-color: ${({ used }) =>
    used ? 'var(--accent-primary)' : 'var(--placeholder)'};
`;

export const AccountIncomingLimit = ({
  used,
  limit,
}: AccountIncomingLimitProps) => {
  const barAmount = 5;
  const amountPerBar = limit / barAmount;
  const usedBars = amountPerBar === 0 ? barAmount : Math.floor(used / amountPerBar) <= 5 ? Math.floor(used / amountPerBar) : 5;
  const unusedBars = barAmount - usedBars;

  const today = new Date();
  const minutesDifference = today.getTimezoneOffset();
  const currentDayOfWeek = today.getDay();
  const lastDayDate = new Date(
    today.getUTCFullYear(),
    today.getUTCMonth(),
    today.getUTCDate() + (7 - currentDayOfWeek),
    24 - minutesDifference / 60,
  );
  const firstDayDate = new Date(
    today.getUTCFullYear(),
    today.getUTCMonth(),
    today.getUTCDate() - currentDayOfWeek,
    24 - minutesDifference / 60,
  );
  const limitResetFormatted = format(lastDayDate, 'dd MMM, HH:mm');
  const limitStartFormatted = format(firstDayDate, 'dd MMM, HH:mm');
  const timeZone = -(minutesDifference / 60);

  return (
    <div style={{ margin: '0 4px' }}>
      <Tippy
        theme="transparent"
        content={
          <div style={{ display: 'flex' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                marginRight: '4px',
              }}
            >
              <div>Incoming limit: </div>
              <div>Used from {limitStartFormatted} (GMT {timeZone >= 0 ? '+' : '-'}{Math.abs(timeZone)}): </div>
              <div>Limit reset: </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <div>{limit} USD</div>
              <div>{used} USD</div>
              <div>{limitResetFormatted} (GMT {timeZone >= 0 ? '+' : '-'}{Math.abs(timeZone)})</div>
            </div>
          </div>
        }
      >
        <div style={{ display: 'flex', gap: '2px' }}>
          {Array.from({ length: usedBars }, (_, index) => (
            <IncomingLimitBar key={index} used={true} />
          ))}
          {Array.from({ length: unusedBars }, (_, index) => (
            <IncomingLimitBar key={index} used={false} />
          ))}
        </div>
      </Tippy>
    </div>
  );
};
