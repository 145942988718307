import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';
import AppContext from 'shared/contexts/AppContext';
import {
  AccountType,
  ColumnSide,
  ExchangeAccountsType,
  FiatAddressType,
  PermissionType,
} from 'shared/types';
import { useTransfer } from './TransferContext';
import { AccountsStoreInstance } from 'services';

interface AccountContextProps {
  couldEditHint: boolean;
  checkIsFromOpen: (
    type: string,
    id: string | number,
    walletType?: string,
  ) => boolean;
  collapseAllFrom: () => void;
  expandAllFrom: () => void;
  openTo: (type: string, id: string | number, walletType?: string) => void;
  checkIsToOpen: (
    type: string,
    id: string | number,
    walletType?: string,
  ) => boolean;
  collapseAllTo: () => void;
  expandAllTo: () => void;
  isAccountsReady: boolean;
  openFrom: (type: string, id: string | number, walletType?: string) => void;
}

const defaultContext: AccountContextProps = {
  checkIsFromOpen: () => false,
  collapseAllFrom: () => {},
  expandAllFrom: () => {},
  checkIsToOpen: () => false,
  collapseAllTo: () => {},
  expandAllTo: () => {},
  openFrom: () => {},
  openTo: () => {},
  couldEditHint: false,
  isAccountsReady: false,
};

export const AccountContext =
  createContext<AccountContextProps>(defaultContext);

export const useIsOpen = (
  side: ColumnSide,
  type: string,
  id: string | number,
  walletType?: string,
): [boolean, () => void] => {
  const { checkIsFromOpen, checkIsToOpen, openFrom, openTo } = useAccounts();
  const checkIsOpen = side === 'from' ? checkIsFromOpen : checkIsToOpen;
  const open = side === 'from' ? openFrom : openTo;
  const toggle = () => {
    open(type, id, walletType);
  };

  const isOpen = checkIsOpen(type, id, walletType);

  return [isOpen, toggle];
};

const useOpenState = (
  accounts: AccountType[],
  exchanges: ExchangeAccountsType[],
  fiatAccounts: FiatAddressType[],
): any[] => {
  const { exchanges: allExchanges, accounts: allAccounts } =
    useContext(AppContext);
  const [isAccountsReady, setIsAccountsReady] = useState(false);
  const [openAccounts, setOpenAccounts] = useState<number[]>([]);
  const [openExchanges, setOpenExchanges] = useState<string[]>([]);
  const [openMethods, setOpenMethods] = useState<string[]>([]);
  const [openFiatWallets, setOpenFiatWallets] = useState<string[]>([]);
  const [openSubAccounts, setOpenSubAccounts] = useState<number[]>([]);
  const [openWalletTypes, setOpenWalletTypes] = useState<string[]>([]);
  const [initiallyExpanded, setInitiallyExpanded] = useState(false);

  useEffect(() => {
    if (!initiallyExpanded && allExchanges.length) {
      initialExpandAll();
      setIsAccountsReady(true);
      setInitiallyExpanded(true);
    }
  }, [allExchanges]);

  function openMethod(name: string) {
    setOpenMethods((prevOpenMethods) => {
      const isOpen = prevOpenMethods.includes(name);

      if (isOpen) {
        return prevOpenMethods.filter((item) => item !== name);
      } else {
        return [...prevOpenMethods, name];
      }
    });
  }

  function openFiatWallet(name: string) {
    setOpenFiatWallets((prevFiatWallets) => {
      const isOpen = prevFiatWallets.includes(name);

      if (isOpen) {
        return prevFiatWallets.filter((item) => item !== name);
      } else {
        return [...prevFiatWallets, name];
      }
    });
  }

  function openExchange(name: string) {
    setOpenExchanges((prevOpenExchanges) => {
      const isOpen = prevOpenExchanges.includes(name);

      if (isOpen) {
        return prevOpenExchanges.filter((item) => item !== name);
      } else {
        return [...prevOpenExchanges, name];
      }
    });
  }

  function openAccount(id: number) {
    setOpenAccounts((prevOpenAccounts) => {
      const isOpen = prevOpenAccounts.includes(id);

      if (isOpen) {
        return prevOpenAccounts.filter((item) => item !== id);
      } else {
        return [...prevOpenAccounts, id];
      }
    });
  }

  function openSubAccount(id: number) {
    setOpenSubAccounts((prevOpenSubAccounts) => {
      const isOpen = prevOpenSubAccounts.includes(id);

      if (isOpen) {
        return prevOpenSubAccounts.filter((item) => item !== id);
      } else {
        return [...prevOpenSubAccounts, id];
      }
    });
  }

  function openWalletType(accountId: number, walletType: string) {
    const name = `${accountId}_${walletType}`;

    setOpenWalletTypes((prevOpenWalletTypes) => {
      const isOpen = prevOpenWalletTypes.includes(name);

      if (isOpen) {
        return prevOpenWalletTypes.filter((item) => item !== name);
      } else {
        return [...prevOpenWalletTypes, name];
      }
    });
  }

  function initialExpandAll() {
    setOpenExchanges(
      [...allExchanges.map((item: ExchangeAccountsType) => item.exchange), 'FIAT'],
    );
    setOpenAccounts(
      allAccounts
        .filter((item: AccountType) => item.master_id === null)
        .map((item: AccountType) => item.id),
    );
    setOpenMethods(
      fiatAccounts
        .map((item: FiatAddressType) => item.method)
        .filter((value, index, self) => self.indexOf(value) === index)
    );
    setOpenFiatWallets(
      fiatAccounts
        .map((item: FiatAddressType) => item.alias)
        .filter((value, index, self) => self.indexOf(value) === index),
    );
    setOpenSubAccounts(allAccounts.map((item: AccountType) => item.id));
    setOpenWalletTypes(
      allAccounts.flatMap((account) => {
        return account.wallets
          .map((wallet) => `${account.id}_${wallet.type}`)
          .filter((v: string, i: number, a: string[]) => a.indexOf(v) === i);
      }),
    );
  }

  function expandAll() {
    setOpenExchanges(
      [...exchanges.map((item: ExchangeAccountsType) => item.exchange), 'FIAT'],
    );
    setOpenAccounts(
      accounts
        .filter((item: AccountType) => item.master_id === null)
        .map((item: AccountType) => item.id),
    );
    setOpenSubAccounts(accounts.map((item: AccountType) => item.id));
    setOpenWalletTypes(
      accounts.flatMap((account) => {
        return account.wallets
          .map((wallet) => `${account.id}_${wallet.type}`)
          .filter((v: string, i: number, a: string[]) => a.indexOf(v) === i);
      }),
    );
  }

  function collapseAll() {
    setOpenExchanges([]);
    setOpenAccounts([]);
    setOpenSubAccounts([]);
    setOpenWalletTypes([]);
  }

  const open = (type: string, id: string | number, walletType?: string) => {
    switch (type) {
      case 'account': {
        openAccount(id as number);
        break;
      }

      case 'exchange': {
        openExchange(id as string);
        break;
      }

      case 'method': {
        openMethod(id as string);
        break;
      }

      case 'fiatAccount': {
        openFiatWallet(id as string);
        break;
      }

      case 'subAccount': {
        openSubAccount(id as number);
        break;
      }

      case 'walletType': {
        if (!walletType) return;
        openWalletType(id as number, walletType);
        break;
      }

      default: {
        return;
      }
    }
  };

  const checkIsOpen = (
    type: string,
    id: string | number,
    walletType?: string,
  ) => {
    if (!type) return false;
    switch (type) {
      case 'account': {
        return openAccounts.includes(id as number);
      }

      case 'exchange': {
        return openExchanges.includes(id as string);
      }

      case 'method': {
        return openMethods.includes(id as string);
      }

      case 'fiatAccount': {
        return openFiatWallets.includes(id as string);
      }

      case 'subAccount': {
        return openSubAccounts.includes(id as number);
      }

      case 'walletType': {
        return openWalletTypes.includes(`${id}_${walletType}` as string);
      }

      default: {
        return false;
      }
    }
  };

  return [checkIsOpen, collapseAll, expandAll, open, isAccountsReady];
};

export const AccountProvider: FC<PropsWithChildren> = ({ children }) => {
  const { user } = useContext(AppContext);
  const { fromAccounts, toAccounts, exchangesFrom, exchangesTo } =
    useTransfer();
  const { fiatAddresses } = AccountsStoreInstance;
  const [
    checkIsFromOpen,
    collapseAllFrom,
    expandAllFrom,
    openFrom,
    isFromReady,
  ] = useOpenState(fromAccounts, exchangesFrom, fiatAddresses);
  const [checkIsToOpen, collapseAllTo, expandAllTo, openTo, isToReady] =
    useOpenState(toAccounts, exchangesTo, fiatAddresses);

  const fullAccountPermissions: PermissionType[] = [
    'accounts.view_account',
    'accounts.change_account',
  ];

  const couldEditHint = fullAccountPermissions.every((item) =>
    user?.permissions.includes(item),
  );

  const isAccountsReady = isToReady && isFromReady;

  return (
    <AccountContext.Provider
      value={{
        couldEditHint,
        checkIsFromOpen,
        collapseAllFrom,
        expandAllFrom,
        openFrom,
        checkIsToOpen,
        collapseAllTo,
        expandAllTo,
        openTo,
        isAccountsReady,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};

export const useAccounts = () => useContext(AccountContext);
