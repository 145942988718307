import { FC, useState, useLayoutEffect, useRef, useEffect } from 'react';
import { format } from 'date-fns';
import { getCoinsInfoById } from 'shared/constants/coins';
import { getExchangeInfoById } from 'shared/constants/exchanges';

import { isUndefined } from 'shared/helpers/strings';

import { useTransfer } from 'features/Transfer/context/TransferContext';

import { formatAmountPrecise } from 'features/Multisig/helpers';

import Copy from 'shared/components/Copy';
import Tippy from '@tippyjs/react';

import * as C from 'shared/components/AbstractComponents';
import * as S from './styled';
import { isDepositAddressAvailable } from 'shared/helpers/transaction';
import { AccountsStoreInstance, SettingsStoreInstance } from 'services';
import { Whitelist } from 'features/Transfer/types';
import { capitalize } from 'shared/helpers/format';
import {
  AccountType,
  CurrencyType,
  ExchangeApiIdType,
  WalletType,
} from 'shared/types';
import { getCurrencyLogo } from 'shared/helpers/currencies';

interface AddressManagementProps {
  walletTo?: WalletType;
  accountTo?: AccountType;
  walletFrom?: WalletType;
  accountFrom?: AccountType;
  setManagement?: any;
  management?: boolean;
  transaction?: any;
  side?: string;
  step?: string;
  setTxNetwork?: any;
  setTxAddress?: any;
  amount?: string;
}

const dateFormat = 'dd/MM/yyyy hh:mm';

const formatDate = (value: any) => {
  if (isUndefined(value)) return null;
  return format(new Date(value), dateFormat) + ' UTC';
};

export const AddressManagement: FC<AddressManagementProps> = ({
  walletTo,
  accountTo,
  walletFrom,
  accountFrom,
  setManagement,
  management,
  transaction,
  side,
  step,
  amount,
  setTxAddress,
}) => {
  const { appSettings } = SettingsStoreInstance;
  const coinInfoTo = getCoinsInfoById(
    walletTo?.currency as CurrencyType,
    appSettings.currencies_icons,
  );
  const exchangeInfoTo = getExchangeInfoById(
    accountTo?.exchange as ExchangeApiIdType,
  );
  const coinInfoFrom = getCoinsInfoById(
    walletFrom?.currency as CurrencyType,
    appSettings.currencies_icons,
  );
  const exchangeInfoFrom = getExchangeInfoById(
    accountFrom?.exchange as ExchangeApiIdType,
  );
  const { whitelists } = AccountsStoreInstance;

  const {
    useGenerateAddressQuery,
    useCheckAddressQuery,
    useGetDepositAddressQuery,
    useUpdateAddressQuery,
    exchangesInfo,
    to,
    txAddress,
    network,
    setNetwork,
    isFiatWallet,
    chosenCurrency,
  } = useTransfer();

  const isTransactionStatusOk = transaction?.api_creation_status === 'ok';

  const isStandalone = accountTo?.exchange === 'WALLETS';
  const hasDepositAddress = isDepositAddressAvailable(
    accountTo?.exchange as ExchangeApiIdType,
    walletTo?.type as string,
    walletTo?.deposit_addresses as [],
  );

  const fromExchangeInfo = exchangesInfo.find(
    ({ exchange }: any) => exchange === accountFrom?.exchange,
  );
  const fromTransports = fromExchangeInfo?.transports;
  const fromNetworks = fromTransports?.[walletFrom?.currency as CurrencyType];

  const toExchangeInfo = exchangesInfo.find(
    ({ exchange }: any) => exchange === accountTo?.exchange,
  );
  const toTransports = toExchangeInfo?.transports;
  const toNetworks = toTransports?.[walletTo?.currency as CurrencyType];

  const sameNetworks = fromNetworks?.filter((nw: string) =>
    toNetworks?.includes(nw),
  );

  const networks = sameNetworks || [];

  useEffect(() => {
    if (networks?.length > 0) {
      setNetwork(networks[0]);
    }
  }, [networks]);

  const [isQrShown, setQrShown] = useState(false);

  useEffect(() => {
    removeCheck();
    removeGenerate();
    removeUpdate();
  }, []);

  const {
    data: depositAddressData,
    error: depositAddressDataError,
    isFetching: isFetchingDepositAddressData,
    refetch: getDepositAddressData,
  } = useGetDepositAddressQuery(
    walletTo?.id,
    walletTo?.currency,
    network,
    hasDepositAddress,
  );

  const isWhitelisted = whitelists?.find(
    ({ deposit_address, currency, network: nw }: Whitelist) =>
      deposit_address === depositAddressData?.deposit_address &&
      (currency === walletTo?.currency || currency === null) &&
      nw === network,
  );

  const {
    status: updateStatus,
    error: updateError,
    isFetching: isFetchingUpdate,
    refetch: updateAddress,
    isFetched: isFetchedUpdate,
    remove: removeUpdate,
  } = useUpdateAddressQuery(depositAddressData?.id);

  const {
    status: generateStatus,
    error: generateError,
    isFetching: isFetchingGenerate,
    isFetched: isFetchedGenerate,
    isSuccess: isGenerateSuccess,
    refetch: getAddress,
    remove: removeGenerate,
  } = useGenerateAddressQuery(depositAddressData?.id);

  const {
    data: checkData,
    status: checkStatus,
    error: checkError,
    isFetching: isFetchingCheck,
    isFetched: isFetchedCheck,
    refetch: checkAddress,
    remove: removeCheck,
  } = useCheckAddressQuery(depositAddressData?.id);

  useEffect(() => {
    if (
      to?.wallet?.id === walletTo?.id &&
      setTxAddress &&
      (generateStatus === 'success' || updateStatus === 'success')
    ) {
      getDepositAddressData();
    }
  }, [generateStatus, updateStatus]);

  useEffect(() => {
    if (
      to?.wallet?.id === walletTo?.id &&
      setTxAddress &&
      depositAddressData?.deposit_address
    ) {
      setTxAddress(depositAddressData?.deposit_address);
    }
  }, [depositAddressData, checkData]);

  useEffect(() => {
    removeUpdate();
    removeGenerate();
    getDepositAddressData();
  }, [isFetchingCheck]);

  useEffect(() => {
    getDepositAddressData();
  }, [isFetchedGenerate, isFetchedUpdate]);

  const accountName = accountTo?.name;

  const isTextTruncated = (element: HTMLDivElement) => {
    return element.scrollWidth > element.clientWidth;
  };

  const [isDynamicTooltipDisabled, setIsDynamicTooltipDisabled] =
    useState(false);
  const dynamicTextRef = useRef<HTMLDivElement | null>(null);

  const [isTooltipDisabled, setIsTooltipDisabled] = useState(false);
  const textRef = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    const observer = new ResizeObserver(() => {
      if (textRef.current) {
        setIsTooltipDisabled(!isTextTruncated(textRef.current));
      }
      if (dynamicTextRef.current) {
        setIsDynamicTooltipDisabled(!isTextTruncated(dynamicTextRef.current));
      }
    });

    if (textRef.current) {
      observer.observe(textRef.current);
    }

    if (dynamicTextRef.current) {
      observer.observe(dynamicTextRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [accountName]);

  const ManagementStatus = () => {
    if (
      isFetchingDepositAddressData ||
      isFetchingGenerate ||
      isFetchingCheck ||
      isFetchingUpdate
    ) {
      return null;
    }

    if (checkError || generateError || depositAddressDataError || updateError) {
      return (
        <C.Error>
          {
            (
              checkError ||
              generateError ||
              depositAddressDataError ||
              updateError
            )?.response?.data?.error_message
          }
        </C.Error>
      );
    }

    if (
      depositAddressData?.deposit_address &&
      isFetchedGenerate &&
      generateStatus === 'success'
    ) {
      return (
        <>
          <C.Success>Address successfully generated</C.Success>
          <C.Note>
            It must be added to the exchange whitelist in Multik Provisioning
            app via QR code
          </C.Note>
          <C.Check onClick={checkAddress}>Check address</C.Check>
        </>
      );
    }

    if (
      depositAddressData?.deposit_address &&
      isFetchedUpdate &&
      updateStatus === 'success'
    ) {
      return (
        <>
          <C.Success>Address successfully updated</C.Success>
          <C.Note>
            It must be added to the exchange whitelist in Multik Provisioning
            app via QR code
          </C.Note>
          <C.Check onClick={checkAddress}>Check address</C.Check>
        </>
      );
    }

    if (
      depositAddressData !== null &&
      checkData !== null &&
      checkData !== undefined &&
      checkData?.deposit_address_exchange !==
        depositAddressData?.deposit_address
    ) {
      return (
        <>
          <C.Note>{`You need to update the ${
            walletTo?.currency
          } wallet address from ${capitalize(
            String(accountTo?.exchange),
          )}`}</C.Note>
          <C.Check onClick={updateAddress}>Get address</C.Check>
        </>
      );
    }

    if (depositAddressData?.deposit_address) {
      return (
        <>
          {/* <C.Note>
            Last checked: {formatDate(depositAddressData?.updated_at)}
          </C.Note> */}
          {isFetchedCheck && (
            <C.Success>Address successfully received</C.Success>
          )}
          <C.Check onClick={checkAddress}>Check address</C.Check>
        </>
      );
    }

    if (depositAddressData !== null && !depositAddressData?.deposit_address) {
      return (
        <>
          <C.Note>{`You need to get the ${
            walletTo?.currency
          } wallet address from ${capitalize(
            String(accountTo?.exchange),
          )}`}</C.Note>
          <C.Check onClick={getAddress}>Get address</C.Check>
        </>
      );
    }

    return null;
  };

  const AddressTitle = () => {
    if (isFiatWallet) {
      return to?.wallet?.name + '\n' + to?.wallet?.address;
    }

    if (checkError || generateError || depositAddressDataError || updateError) {
      return null;
    }

    if (isFetchingGenerate) {
      return (
        <C.Loader>
          Getting address <C.LoaderIcon />
        </C.Loader>
      );
    }

    if (isFetchingUpdate) {
      return (
        <C.Loader>
          Updating address <C.LoaderIcon />
        </C.Loader>
      );
    }

    if (isFetchingDepositAddressData) {
      return (
        <C.Loader>
          Loading address <C.LoaderIcon />
        </C.Loader>
      );
    }

    if (isFetchingCheck) {
      return (
        <C.Loader>
          Checking address <C.LoaderIcon />
        </C.Loader>
      );
    }

    if (!isFetchingDepositAddressData && depositAddressData === null) {
      // prettier-ignore eslint-disable-next-line no-console
      return 'This wallet can\'t have a deposit address';
    }

    if (
      !isFetchingDepositAddressData &&
      depositAddressData?.deposit_address === null
    ) {
      return `Address not received from ${accountTo?.exchange}`;
    }

    return depositAddressData?.deposit_address;
  };

  const isCrossBinance =
    accountFrom?.exchange === 'BINANCE' &&
    accountFrom?.id === accountTo?.id &&
    walletFrom?.id !== walletTo?.id;

  const toIconBg = isFiatWallet
    ? `url(${getCurrencyLogo(
        chosenCurrency as CurrencyType,
        appSettings.currencies_icons,
      )})`
    : `url(${exchangeInfoTo?.logo_url})`;
  return (
    <S.Wrap management={walletFrom?.id === walletTo?.id}>
      {walletFrom && !management && (
        <C.Row>
          <C.Label>From</C.Label>
          <C.Data>
            <C.Chain>
              <C.ChainItem>
                <C.IconMin
                  style={{
                    backgroundImage: `url(${exchangeInfoFrom?.logo_url})`,
                  }}
                />
                {accountFrom?.exchange}
              </C.ChainItem>
              <C.ChainItemOverflow>
                <Tippy
                  trigger="mouseenter"
                  placement="top"
                  hideOnClick={true}
                  theme="transparent"
                  content={accountFrom?.name}
                  disabled={isTooltipDisabled}
                >
                  <C.ChainItemOverflowText
                    ref={textRef}
                    title={accountFrom?.name}
                  >
                    {accountFrom?.name}
                  </C.ChainItemOverflowText>
                </Tippy>
              </C.ChainItemOverflow>
              {walletFrom?.type && (
                <C.ChainItem>{walletFrom?.type}</C.ChainItem>
              )}
              <C.ChainItem>
                <C.IconMin
                  style={{
                    backgroundImage: `url(${
                      coinInfoFrom?.logo_url || '/i/coins/unknown.svg'
                    })`,
                  }}
                />
                {walletFrom?.currency}
              </C.ChainItem>
            </C.Chain>
          </C.Data>
        </C.Row>
      )}
      {(isTransactionStatusOk || step === 'otp') && (
        <C.Row>
          <C.Label>Amount</C.Label>
          <C.Data>
            <C.Amount>
              <C.AmountIcon
                style={{
                  backgroundImage: `url(${
                    coinInfoFrom?.logo_url || '/i/coins/unknown.svg'
                  })`,
                }}
              />
              <C.AmountValue>
                {formatAmountPrecise(transaction?.amount || amount, 8)}
              </C.AmountValue>
              {walletFrom?.currency}
            </C.Amount>
          </C.Data>
        </C.Row>
      )}
      <C.Row>
        <C.Label>{walletFrom && !management ? 'To' : 'Wallet'}</C.Label>
        <C.Data>
          <C.Chain style={{ flexWrap: 'wrap' }}>
            <C.ChainItem>
              <C.IconMin
                style={{
                  backgroundImage: toIconBg,
                }}
              />
              {accountTo?.exchange}
            </C.ChainItem>
            {/* <C.ChainItemOverflow>
              <Tippy
                trigger="mouseenter"
                placement="top"
                hideOnClick={true}
                theme="transparent"
                content={accountName}
                disabled={isDynamicTooltipDisabled}
              >
                <C.ChainItemOverflowText
                  ref={dynamicTextRef}
                  title={accountName}
                >
                  {accountName}
                </C.ChainItemOverflowText>
              </Tippy>
            </C.ChainItemOverflow> */}
            {to?.wallet?.alias && (
              <C.ChainItem>{to?.wallet?.alias}</C.ChainItem>
            )}
            <C.ChainItem>
              <C.IconMin
                style={{
                  backgroundImage: `url(${
                    coinInfoTo?.logo_url || '/i/coins/unknown.svg'
                  })`,
                }}
              />
              {walletTo?.currency}
            </C.ChainItem>
          </C.Chain>
        </C.Data>
      </C.Row>
      {isTransactionStatusOk && (
        <>
          <C.Row>
            <C.Label>Date</C.Label>
            <C.Data>
              <C.Info>{formatDate(transaction?.created_at)}</C.Info>
            </C.Data>
          </C.Row>
          <C.Row>
            <C.Label>Status</C.Label>
            <C.Data>
              <C.Info>{transaction?.status}</C.Info>
            </C.Data>
          </C.Row>
        </>
      )}
      {!isTransactionStatusOk &&
        Array.isArray(networks) &&
        networks?.length > 0 &&
        !isCrossBinance && (
          <C.Row>
            <C.Label>network</C.Label>
            <C.Data>
              <C.Tabs>
                {networks.map((item: any, index: number) => {
                  return (
                    <C.TabItem
                      key={index}
                      activeTab={item === network}
                      onClick={() => setNetwork(item)}
                    >
                      {item}
                    </C.TabItem>
                  );
                })}
              </C.Tabs>
            </C.Data>
          </C.Row>
        )}
      {isFiatWallet ? (
        !!to?.wallet?.address && !!depositAddressData?.deposit_address ? (
          <C.AdressWrap>
            <C.AdressHeader>
              <C.Label>Recipient</C.Label>
              <C.Whitelist>
                <C.Label>Verified</C.Label>
                <C.IconYes />
              </C.Whitelist>
            </C.AdressHeader>
            <C.AddressRow isLast={!management}>
              <C.Address title={to?.wallet?.address}>
                <AddressTitle />
              </C.Address>

              <C.AddressTools>
                {depositAddressData?.deposit_address && (
                  <C.Button>
                    <Copy
                      text={depositAddressData?.deposit_address}
                      width="14px"
                      height="14px"
                    />
                  </C.Button>
                )}
              </C.AddressTools>
            </C.AddressRow>
          </C.AdressWrap>
        ) : null
      ) : (
        !isTransactionStatusOk &&
        step !== 'otp' &&
        hasDepositAddress && (
          <C.AdressWrap>
            <C.AdressHeader>
              <C.Label>Address</C.Label>
              <C.Whitelist>
                <C.Label>Whitelist</C.Label>
                {isWhitelisted ? <C.IconYes /> : <C.IconNo />}
              </C.Whitelist>
            </C.AdressHeader>
            <C.AddressRow isLast={!management}>
              <C.Address title={depositAddressData?.deposit_address}>
                <AddressTitle />
              </C.Address>

              <C.AddressTools>
                {depositAddressData?.deposit_address && (
                  <C.Button>
                    <Copy
                      text={depositAddressData?.deposit_address}
                      width="14px"
                      height="14px"
                    />
                  </C.Button>
                )}
                {depositAddressData?.deposit_address && (
                  <C.Button>
                    <Tippy
                      theme="transparent"
                      placement="top"
                      onShow={() => setQrShown(true)}
                      onHide={() => setQrShown(false)}
                      trigger="mouseenter click"
                      content={
                        <C.QrCodeWrap>
                          <C.QrCodeVisual
                            value={depositAddressData?.deposit_address}
                            size={200}
                            fgColor="#000000"
                          />
                        </C.QrCodeWrap>
                      }
                    >
                      <C.IconQR isQrShown={isQrShown} />
                    </Tippy>
                  </C.Button>
                )}
                {depositAddressData?.deposit_address && !management && (
                  <C.Button onClick={() => setManagement(true)}>
                    <Tippy
                      theme="transparent"
                      placement="top"
                      trigger="mouseenter"
                      content={'Address management'}
                    >
                      <C.IconSettings />
                    </Tippy>
                  </C.Button>
                )}
              </C.AddressTools>
            </C.AddressRow>
            {management && !isStandalone && (
              <>
                <ManagementStatus />
              </>
            )}
          </C.AdressWrap>
        )
      )}
      {walletTo?.id === walletFrom?.id && (
        <C.ButtomLink
          target="_blank"
          href="https://exantech.atlassian.net/wiki/spaces/CH/pages/88277012/Adding+addresses+to+the+Whitelist+using+the+Multik+Provisioning+mobile+app"
        >
          How to add addresses?
        </C.ButtomLink>
      )}
    </S.Wrap>
  );
};
