import styled from 'styled-components/macro';

export const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  min-width: 1010px;
  max-width: 1200px;
  flex-wrap: nowrap;
  position: relative;
  flex-wrap: wrap;
`;
