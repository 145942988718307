import styled, { css } from 'styled-components/macro';
import { motion } from 'framer-motion';

import { ReactComponent as SvgArrow } from 'shared/assets/icons/icon-chevron-down.svg';
import { ReactComponent as SvgCopy } from 'shared/assets/icons/icon-copy-stroke.svg';
import { ReactComponent as SvgExternal } from 'shared/assets/icons/icon-external-link.svg';
import { ReactComponent as SvgQr } from 'shared/assets/icons/icon-qr-min.svg';
import { ReactComponent as SvgXMark } from 'shared/assets/icons/icon-x-mark.svg';
import { ReactComponent as SvgSearch } from 'shared/assets/icons/icon-search-multik.svg';

export const SearchForm = styled.div`
  position: relative;
  margin-bottom: 5px;
  
`;
export const IconClear = styled(SvgXMark)`
  position: absolute;
  right: 10px;
  top: 11px;
  cursor: pointer;
`;

export const SearchInput = styled.input`
  padding: 10px 40px 10px 40px;
  background: none;
  font-size: 12px;
  border-radius: 5px;
  line-height: 20px;
  width: 100%;
  border: 1px solid var(--border);
  outline: ${(props) =>
    props.value ? 'var(--accent-primary) auto 1px' : 'none'};

  &:focus-visible,
  &:focus {
    outline: var(--accent-primary) auto 1px;
  }

  color: var(--object-secondary);
`;

export const IconSearch = styled(SvgSearch)`
  stroke: var(--object-secondary);
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 10px;
  margin-top: -10px;
  pointer-events: none;
`;

export const SelectWrap = styled.div<{ isOpen: boolean }>`
  padding: 0;
  width: 100%;
  border: 1px solid var(--border);
  border-radius: 5px;
  margin: 0;
  position: relative;
  display: flex;
  align-items: center;
  user-select: none;

  ${({ isOpen }) => (isOpen ? 'border-bottom: none;' : '')}
  ${({ isOpen }) => (isOpen ? 'padding-bottom: 1px;' : '')}
  ${({ isOpen }) => (isOpen ? 'border-radius: 5px 5px 0px 0px;' : '')}
`;

export const Divider = styled.div`
  display: block;
  width: 100%;
  border: 0.5px solid var(--border);
  margin: 5px 10px;
`;

export const SelectDropdown = styled.div`
  position: absolute;
  left: -1px;
  right: -1px;
  top: calc(100% + 55px);
  overflow-x: hidden;
  background: var(--base);
  display: flex;
  flex-direction: column;
  z-index: 2;
  border: 1px solid var(--border);
  border-top: none;
  max-height: 200px;
  border-radius: 0px 0px 5px 5px;
  max-height: 530px;
  z-index: 99;
  padding: 5px 0px;
`;
export const SearchBar = styled.div`
  position: absolute;
  left: -1px;
  right: -1px;
  top: 100%;
  padding: 10px;
  padding-bottom: 5px;
  background: var(--base);
  border-left: 1px solid var(--border);
  border-right: 1px solid var(--border);
  height: 55px;
`;
export const FullName = styled.div`
  text-transform: capitalize;
  color: var(--object-primary);
  font-weight: 700;
`;
export const Checkbox = styled.div`
  display: block;
  width: 20px;
  height: 20px;
`;
export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  margin-left: 10px;
`;
export const UserData = styled.div`
  display: flex;
  align-items: center;
`;
export const Email = styled.div`
  color: var(--object-secondary);
`;
export const Title = styled.div`
  font-size: 12px;
  font-weight: 700;
`;
export const Addres = styled.div`
  width: 100%;
  font-size: 12px;
  color: var(--object-secondary);
`;
export const SelectItem = styled.div`
  padding: 5px 10px;
  width: 100%;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background-color: var(--highlight);
  }
`;
export const IconDropdown = styled(SvgArrow)<{ isOpen?: boolean }>`
  width: 20px;
  min-width: 20px;
  max-width: 20px;
  height: 20px;
  margin: 0 10px 0 0;
  stroke: var(--object-secondary);
  display: inline-block;
  cursor: pointer;
  transition: all 0.3s;
  ${({ isOpen }) => (isOpen && 'transform: rotate(180deg);')}
`;
export const SelectItemDefault = styled(SelectItem)`
  padding: 10px;
  height: 40px;
  padding-left: 20px;

  &:hover {
    background: none;
  }
`;
export const SelectItemWrap = styled.div`
  padding: 0 0 0 10px;
  display: flex;
  align-items: center;
  flex: 1;
  overflow: hidden;
  justify-content: space-between;
`;
export const SelectItemData = styled.div`
  padding: 0 10px 0 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;
export const Placeholder = styled.span`
  line-height: 1.5;
  opacity: 0.5;
`;
export const Tools = styled.div`
  display: flex;
`;
export const Button = styled.button`
  border: 0;
  background: none;
  margin: 0;
  color: var(--object-secondary);
  transition: all 0.2s;
  cursor: pointer;

  &:hover {
    color: var(--object-primary);
  }

  & + & {
    margin: 0 0 0 10px;
  }
`;
const IconCss = css`
  width: 20px;
  height: 20px;
  fill: currentColor;
`;
export const IconQr = styled(SvgQr)`
  ${IconCss}
`;
export const IconCopy = styled(SvgCopy)`
  ${IconCss}
  stroke: currentColor;
  fill: none;
`;
export const IconExternal = styled(SvgExternal)`
  ${IconCss}
  stroke: currentColor;
  fill: none;
`;
