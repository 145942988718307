import styled, { css } from 'styled-components/macro';

import { ReactComponent as SvgArrow } from 'shared/assets/icons/icon-dropdown-small.svg';

export const IconArrow = styled(SvgArrow)`
  width: 10px;
  height: 10px;
  margin: 0;
  // opacity: 0;
`;
export const IconArrowWrap = styled.div<{ rotateIcon: boolean }>`
  transform: rotate(0);
  transition: all 0.4s;
  transform-origin: center;
  margin: 0 5px 0 0;
  ${(props) =>
    props.rotateIcon &&
    css`
      transform: rotate(180deg);
    `};
`;

export const ExchangeIcon = styled.div<{ disabled: boolean }>`
  width: 20px;
  min-width: 20px;
  max-width: 20px;
  height: 20px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 20px;
  margin: 0 10px 0 0;

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0;
    `};
`;
export const ExchangeIconMin = styled(ExchangeIcon)`
  min-width: 10px;
  max-width: 10px;
  max-height: 10px;
  background-size: 10px;
  margin: 0 5px 0 0;
  // opacity: 0;
`;
