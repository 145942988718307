import { useCallback, useState } from 'react';

export const useToggle = (initialState = false): [boolean, (force?: boolean) => void] => {
  const [state, setState] = useState(initialState);

  const toggle = (force?: boolean) => {
    if (force !== undefined) {
      setState(force);
    } else {
      setState((state) => !state);
    }
  };

  return [state, toggle];
};
