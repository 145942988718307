import { FC } from 'react';
import * as S from '../styled';
import { CardSetValue } from './CardSetValue';

interface SettingCardProps {
  icon: any;
  title: string;
  value: any;
  readOnly?: boolean;
  errorMessage?: string;
  emptyValue?: any;
  dataId: string;
}

export const SettingCard: FC<SettingCardProps> = ({
  icon: Icon,
  title,
  value,
  errorMessage,
  readOnly = false,
  emptyValue,
  dataId,
}) => {
  return (
    <S.CardWrapper>
      <S.CardHeader>
        <S.CardHeaderText>
          <Icon />
          {title}
        </S.CardHeaderText>
        {!readOnly && <CardSetValue value={value} />}
      </S.CardHeader>
      {errorMessage ? (
        <S.ErrorText>{errorMessage}</S.ErrorText>
      ) : (
        <S.CardValue data-id={dataId}>{value || emptyValue}</S.CardValue>
      )}
    </S.CardWrapper>
  );
};
