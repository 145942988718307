import { ReactElement } from 'react';

import { CurrencyType } from 'shared/types';
import { ReactComponent as SvgCrypto } from 'shared/assets/icons/views/logo-crypto.svg';
import { ReactComponent as SvgEur } from 'shared/assets/icons/views/logo-eur.svg';
import { ReactComponent as SvgUsd } from 'shared/assets/icons/views/logo-usd.svg';
import { ReactComponent as SvgUsdK } from 'shared/assets/icons/views/logo-usdk.svg';

import { ReactComponent as SvgUsdM } from 'shared/assets/icons/views/logo-usdm.svg';
import { ViewType } from './types';

export const availableViews: {
  id: string;
  label: ViewType;
  currency: CurrencyType;
  currency_label: string;
  currency_icon: ReactElement;
  divisor: number;
  divisor_label: Nullable<string>;
}[] = [
  {
    id: 'crypto',
    label: 'CRYPTO',
    currency: 'USD',
    currency_label: '$',
    currency_icon: <SvgCrypto />,
    divisor: 1,
    divisor_label: null,
  },
  {
    id: 'eur',
    label: 'EUR',
    currency: 'EUR',
    currency_label: '€',
    currency_icon: <SvgEur />,
    divisor: 1,
    divisor_label: null,
  },
  {
    id: 'usd',
    label: 'USD',
    currency: 'USD',
    currency_label: '$',
    currency_icon: <SvgUsd />,
    divisor: 1,
    divisor_label: null,
  },
  {
    id: 'usd_k',
    label: 'USDk',
    currency: 'USD',
    currency_label: '$',
    currency_icon: <SvgUsdK />,
    divisor: 1000,
    divisor_label: 'k',
  },
  {
    id: 'usd_m',
    label: 'USDm',
    currency: 'USD',
    currency_label: '$',
    currency_icon: <SvgUsdM />,
    divisor: 1000000,
    divisor_label: 'm',
  },
];
