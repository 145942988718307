// @ts-nocheck

import { dateAgo, formattedDate } from 'shared/helpers/date';

import { useTransactionType } from '../../hooks/useTransactionType';
import { isTxQueued } from '../../../helpers';
import * as T from '../../styled/transactions';
import { getTxStatusColor, getTxStatusTitle } from '../../utilities';
import { TxInfo } from './';
import { LocalTransactionStatus } from '../../../constants';
import { Loader } from 'shared/components';
import styled from 'styled-components';

const IconImg = styled.img`
  width: 20px;
  height: 20px;
`;

export const TransactionMainInfo = ({ setOpen, isOpen, item }) => {
  const { transaction } = item;

  const { txStatus, executionInfo, timestamp } = transaction;
  const { icon, text } = useTransactionType(transaction);

  const confirmationsRequired = executionInfo?.confirmationsRequired;
  const confirmationsSubmitted = executionInfo?.confirmationsSubmitted;
  const isQueued = isTxQueued(txStatus);

  return (
    <T.MainInfo onClick={() => setOpen(!isOpen)} opened={isOpen}>
      <T.ColNumber>{executionInfo?.nonce}</T.ColNumber>
      <T.ColType>
        <IconImg src={icon} />
        <span>{text}</span>
      </T.ColType>
      <TxInfo tx={transaction} />
      <T.ColDate>
        {isQueued
          ? dateAgo(timestamp || 0)
          : formattedDate(timestamp || 0, 'HH:mm A')}
      </T.ColDate>
      <T.ColConfirm>
        {isQueued ? (
          <>
            <T.IconUsers />
            {confirmationsSubmitted} out of {confirmationsRequired}
          </>
        ) : null}
      </T.ColConfirm>
      <T.ColStatus color={getTxStatusColor(txStatus)}>
        {txStatus === LocalTransactionStatus.PENDING && <Loader small />} {getTxStatusTitle(txStatus)}
      </T.ColStatus>
      <T.ColToggleRow>
        <T.IconArrow />
      </T.ColToggleRow>
    </T.MainInfo>
  );
};
