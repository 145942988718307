import styled, { css } from 'styled-components/macro';
import { Field, Form } from 'formik';

import { ReactComponent as SvgClose } from 'shared/assets/icons/icon-close.svg';
import { ReactComponent as SvgSave } from 'shared/assets/icons/icon-save.svg';
import { ReactComponent as SvgCancel } from 'shared/assets/icons/icon-cancel.svg';
import { ReactComponent as SvgEdit } from 'shared/assets/icons/icon-edit.svg';
import { ReactComponent as SvgInfo } from 'shared/assets/icons/icon-info.svg';
import { ReactComponent as SvgExternal } from 'shared/assets/icons/icon-external-link.svg';

import { ExchangeIconMin, IconArrow } from '../../components/ExchangeAbstract';

const IconCss = css`
  width: 13px;
  height: 13px;
  fill: currentColor;
  cursor: pointer;
  margin-right: 8px;
`;
export const IconExternal = styled(SvgExternal)`
  ${IconCss}
  stroke: var(--object-secondary);
  opacity: 0.8;
  fill: none;
`;

export const AccountWrap = styled.section<{ opened: boolean }>`
  margin: 0 0 10px 0;
  padding: 0;

  &:hover {
    ${IconArrow},
    ${ExchangeIconMin} {
      opacity: 1;
    }
  }

  ${(props) =>
    props.opened &&
    css`
      ${IconArrow},
      ${ExchangeIconMin} {
        opacity: 1;
      }
    `};
`;

export const IconStyle = css`
  width: 10px;
  height: 10px;
`;

export const IconSave = styled(SvgSave)`
  ${IconStyle}
`;

export const IconCancel = styled(SvgCancel)`
  ${IconStyle}
  stroke: var(--object-secondary);
`;

export const IconEdit = styled(SvgEdit)`
  ${IconStyle}
`;

export const IconInfo = styled(SvgInfo)`
  ${IconStyle}
`;

export const SpoilerIcon = styled(IconInfo)`
  stroke: var(--object-secondary);
`;

export const AccountHeader = styled.header<{ isViolated?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 5px;
  margin: 0 0 10px 0;
  flex-wrap: wrap;
  position: relative;

  ${({ isViolated }) =>
    isViolated &&
    css`
      &:before {
        left: 0px;
        position: absolute;
        content: '';
        width: 2px;
        height: 100%;
        background-color: var(--error);
      }
    `}
`;
export const AccountHeaderTop = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  margin: 0 0 5px 0;
  cursor: pointer;
`;
export const AccountTags = styled.div`
  width: 100%;
  margin-top: -8px;
  margin-bottom: 5px;
  margin-left: -2.5px;
  padding-left: 30px;
`;

export const AccountTag = styled.div<{ color: string }>`
  text-transform: uppercase;
  font-size: 9px;
  font-weight: 700;
  font-family: var(--font-main);
  line-height: 11px;
  padding: 1.5px 4px;
  border: 1px solid ${({ color }) => color};
  color: ${({ color }) => color};
  border-radius: 5px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  margin: 0 2.5px;
`;
export const AccountTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

export const AccountName = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  min-width: 0;
  align-items: center;
`;

export const AccountNameLabel = styled.span`
  cursor: pointer;
  line-height: 14px;
  font-size: 12px;
  font-weight: 700;
  user-select: none;
  margin: 0 5px 0 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  min-width: 0;
  overflow: hidden;
`;
export const AccountHintLabel = styled.div`
  flex: 1;
  cursor: pointer;
  width: 100%;
  line-height: 12px;
  font-size: 10px;
  font-weight: 400;
  user-select: none;
  padding: 0 0 0 30px;
`;

export const AccountNameValue = styled.span`
  display: inline-block;
  word-break: break-word;
  margin-right: 5px;
`;

export const AccountHint = styled.span`
  color: var(--object-secondary);
  font-size: 10px;
  line-height: 12px;
  word-break: break-word;
`;

export const AccountSpoiler = styled.span`
  cursor: pointer;

  &[aria-expanded='true'] {
    ${SpoilerIcon} {
      stroke: var(--accent-primary);
    }
  }
`;
export const AccountSpoilerWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3px;
`;
export const AccountSpoilerItem = styled.span`
  max-width: 180px;
  word-break: break-all;
  margin-top: 2px;
`;

export const AccountBalanceStatus = styled.div`
  display: flex;
  align-items: center;
  margin: 0 10px 0 5px;
`;

export const AccountBalanceUpdated = styled.div<{ isOutdated?: boolean }>`
  margin: 0 0 0 5px;
  font-size: 12px;
  font-weight: 400;
  color: var(--object-secondary);
  white-space: nowrap;
`;

export const IconBalanceWarning = styled(SvgClose)`
  width: 10px;
  height: 10px;
  fill: var(--warning);
`;

export const AccountsRow = styled.div`
  /* display: flex; */
  width: 100%;
  margin: 0 0 15px 0;
  flex-wrap: wrap;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const TooltipWrap = styled.div``;

export const HintButton = styled.button`
  border: none;
  background: none;
  padding: 0;
  margin: 0 10px 0 0;
  min-width: 10px;
`;

export const HintEditButton = styled(HintButton)`
  margin: 0 0 0 10px;
  opacity: 0;
  transition: all 0.2s;
`;

export const HintTextWrap = styled.div<{ couldEdit?: boolean }>`
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${(props) =>
    props.couldEdit &&
    css`
      cursor: pointer;
    `};

  &:hover {
    ${HintEditButton} {
      opacity: 1;
    }
  }
`;

export const ActionIconsWrap = styled.div`
  width: 100%;
  margin: 5px 0 0 0;
  display: flex;
  align-items: center;
`;

export const HintText = styled.span`
  display: inline-block;
  font-size: 10px;
  line-height: 12px;
  vertical-align: middle;
  color: var(--object-secondary);
`;

export const HintTextArea = styled(Field)`
  border: none;
  resize: none;
  width: 100%;
  max-width: 100%;
  outline: none;
  font-size: 10px;
  font-weight: 400;
  color: var(--object-primary);
  line-height: 12px;
  background: none;
`;

export const StyledForm = styled(Form)``;

export const AddTooltipLabel = styled.div`
  color: var(--accent-primary);
  font-size: 10px;
  line-height: 12px;
  font-weight: 400;
`;

export const TooltipSavedText = styled.div`
  width: 100%;
  max-width: 140px;
  max-height: 22px;
  font-size: 10px;
  line-height: 11px;
  font-weight: normal;
  overflow: hidden;
  white-space: pre-line;
`;

export const NoHintPermission = styled.span`
  display: inline-block;
  font-size: 10px;
  line-height: 11px;
  color: var(--accent-primary);
`;
