import { makeObservable, observable } from 'mobx';

class WhitelistStore {
  constructor() {
    makeObservable(this);
  }

  @observable amount = 0;
}

export const WhitelistStoreInstance = new WhitelistStore();
