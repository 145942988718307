import { TransactionStatus } from '@gnosis.pm/safe-react-gateway-sdk';
import { BigNumber } from 'bignumber.js';
import { toJS } from 'mobx';

const data = {
  [TransactionStatus.AWAITING_CONFIRMATIONS]: {
    title: 'Awaiting confirmations',
    color: 'var(--warning)',
  },
  [TransactionStatus.AWAITING_EXECUTION]: {
    title: 'Awaiting execution',
    color: 'var(--warning)',
  },
  [TransactionStatus.CANCELLED]: {
    title: 'Cancelled',
    color: 'var(--error)',
  },
  [TransactionStatus.FAILED]: {
    title: 'Failed',
    color: 'var(--error)',
  },
  [TransactionStatus.SUCCESS]: {
    title: 'Success',
    color: 'var(--success)',
  },
  PENDING: {
    title: 'Pending',
    color: 'var(--warning)',
  },
  WILL_BE_REPLACED: {
    title: 'Will be replaced',
    color: 'var(--error)',
  },
};

export const getTxStatusTitle = (status: TransactionStatus) =>
  data[status].title;
export const getTxStatusColor = (status: TransactionStatus) =>
  data[status].color;

export const toTokenUnit = (
  amount: number | string,
  decimals: string | number,
): string =>
  new BigNumber(amount).times(`1e${decimals}`).toFixed(0, BigNumber.ROUND_DOWN);

const testNetEthMapping = {
  5: 'GOR',
};
export const getAvailableTokens = (
  currenciesList: string[],
  walletTokens: any[],
  chainId: number,
) => {
  return walletTokens?.filter(
    ({ tokenInfo: { symbol } }) =>
      currenciesList?.includes(symbol) ||
      // @ts-ignore
      (chainId && testNetEthMapping[chainId] === symbol),
  );
};
