import { ExchangeInfoType } from '../types';

export const EXCHANGES_INFO: ExchangeInfoType[] = [
  {
    api_id: 'WALLETS',
    title: 'Wallets',
    logo_url: 'i/exchanges/plain-wallets.svg',
  },
  {
    api_id: 'BINANCE',
    title: 'Binance',
    logo_url: 'i/exchanges/logo-binance.svg',
  },
  {
    api_id: 'BITFINEX',
    title: 'Bitfinex',
    logo_url: 'i/exchanges/logo-bitfinex.svg',
  },
  {
    api_id: 'BITSTAMP',
    title: 'Bitstamp',
    logo_url: 'i/exchanges/logo-bitstamp.svg',
  },
  {
    api_id: 'BYBIT',
    title: 'ByBit',
    logo_url: 'i/exchanges/logo-bybit.png',
  },
  {
    api_id: 'DERIBIT',
    title: 'Deribit',
    logo_url: 'i/exchanges/logo-deribit.svg',
  },
  {
    api_id: 'GOZO',
    title: 'Gozo',
    logo_url: 'i/exchanges/logo-gozo.svg',
  },
  {
    api_id: 'KRAKEN',
    title: 'Kraken',
    logo_url: 'i/exchanges/logo-kraken.svg',
  },
  {
    api_id: 'LMAX',
    title: 'Lmax',
    logo_url: 'i/exchanges/logo-lmax.svg',
  },
  {
    api_id: 'OKCOIN',
    title: 'OKCoin',
    logo_url: 'i/exchanges/logo-okcoin.svg',
  },
  {
    api_id: 'HITBTC',
    title: 'hitbtc',
    logo_url: 'i/exchanges/logo-hitbtc.svg',
  },
  {
    api_id: 'GLOBITEX',
    title: 'Globitex',
    logo_url: 'i/exchanges/logo-globitex.svg',
  },
  {
    api_id: 'DYDX',
    title: 'DYDX',
    logo_url: 'i/exchanges/logo-dydx.svg',
  },
  {
    api_id: 'DYDX_V3',
    title: 'DYDX_V3',
    logo_url: 'i/exchanges/logo-dydx.svg',
  },
  {
    api_id: 'DYDX_V4',
    title: 'DYDX_V4',
    logo_url: 'i/exchanges/logo-dydx.svg',
  },
  {
    api_id: 'FTX',
    title: 'FTX',
    logo_url: 'i/exchanges/logo-ftx.svg',
  },
  {
    api_id: 'OKX',
    title: 'OKX',
    logo_url: 'i/exchanges/logo-okx.svg',
  },
  {
    api_id: 'KUCOIN',
    title: 'Kucoin',
    logo_url: 'i/exchanges/logo-kucoin.svg',
  },
  {
    api_id: 'FIAT',
    title: 'Fiat accounts',
    logo_url: 'i/exchanges/logo-fiat-accounts.svg',
  },
];

export function getExchangeInfoById(id: string): ExchangeInfoType | undefined {
  return EXCHANGES_INFO.find(
    (item) => item.api_id?.toLowerCase() === id?.toLowerCase(),
  );
}

export const getExchangeLogoUrl = (id: Undefinedable<string>): Undefinedable<string> | null => {
  if (!id) return null;
  const info = getExchangeInfoById(id) as ExchangeInfoType;
  return info?.logo_url;
};
