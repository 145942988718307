import { memo } from 'react';

import * as S from 'features/Positions/view/styled';

export const PositionsHeader = memo(() => (
  <S.PositionsHeaderWrapper>
    <S.Header>
      <S.HeaderRow>
        <S.DateCol style={{ fontFamily: 'var(--font-main)' }}>Market pair</S.DateCol>
        <S.TxCol style={{ fontFamily: 'var(--font-main)' }}>Type</S.TxCol>
        <S.FromCol style={{ fontFamily: 'var(--font-main)' }}>Size</S.FromCol>
        <S.ToCol style={{ fontFamily: 'var(--font-main)' }}>Price entry</S.ToCol>
        <S.AmountCol style={{ fontFamily: 'var(--font-main)' }}>Opening time</S.AmountCol>
        <S.ConfirmationsCol style={{ fontFamily: 'var(--font-main)' }}>Accumulated funding</S.ConfirmationsCol>
        <S.StatusCol style={{ fontFamily: 'var(--font-main)' }}>Current value</S.StatusCol>
      </S.HeaderRow>
    </S.Header>
  </S.PositionsHeaderWrapper>
));
