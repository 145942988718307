import { observer } from 'mobx-react';

import { ProtectedArea } from 'shared/components';
import { Overview } from 'features/Overview';

const OverviewPage = () => (
    <ProtectedArea displayMessage every={['accounts.view_account']}>
      <Overview />
    </ProtectedArea>
  );

const C = observer(OverviewPage);

export { C as OverviewPage };
