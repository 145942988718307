import React from 'react';

import { ProtectedArea } from 'shared/components';
import { Transfer } from 'features/Transfer';

export const TransferPage = () => {
  return (
    <ProtectedArea displayMessage every={['accounts.view_account']}>
      <Transfer />
    </ProtectedArea>
  );
};
