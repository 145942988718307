import styled, { css } from 'styled-components/macro';
import { motion } from 'framer-motion';
import { Device } from 'shared/theme';
import { ReactComponent as SvgClose } from 'shared/assets/icons/icon-close.svg';
import { ReactComponent as WarningSvg } from 'shared/assets/icons/icon-warning.svg';
import { ReactComponent as SvgChevronUp } from 'shared/assets/icons/icon-chevron-up-multik.svg';
import { ReactComponent as SvgChevronDown } from 'shared/assets/icons/icon-chevron-down-multik.svg';
import { isOdd } from 'shared/helpers/number';

export const Wrap = styled(motion.div)`
  min-height: 100%;
  max-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const TableShadow = styled.div`
  position: relative;
  min-height: 100%;
  display: flex;
  flex: 1;

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 140px;
    width: 20px;
    background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.05)
    );
    z-index: 100;
    pointer-events: none;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 140px;
    width: 20px;
    background: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.05));
    z-index: 9;
    pointer-events: none;
  }
`;

export const TableWrap = styled.div`
  background: var(--base);
  position: relative;
  border: 1px solid var(--border);
  border-bottom: none;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  flex: 1;
  justify-content: space-between;
  border-radius: 5px 5px 0 0;
  overflow: auto;
`;

export const CoinFilter = styled.section`
  display: flex;
  padding: 13px 10px;
`;

export const Table = styled.div`
  position: relative;
`;

export const TableFooter = styled.div<{ firefox: boolean }>`
  position: sticky;
  min-width: fit-content;
  left: 0;
  bottom: 0;
  z-index: 99;

  ${(props) =>
    props.firefox &&
    css`
      bottom: -1px;
    `};

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: -20px;
    height: 20px;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.05)
    );
    z-index: 999;
    pointer-events: none;
  }
`;

export const Cell = styled.div`
  min-width: 130px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 10px 7.5px;
  position: relative;
  z-index: 1;
  background: var(--base);
  text-align: right;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 1px;
    background: var(--background);
  }
  &:nth-child(2) {
    &:before {
      display: none;
    }
  }
`;

export const RightFixedCell = css`
  position: sticky;
  right: 0;
  bottom: 0;
  top: 0;
  min-width: 140px;
  max-width: 140px;
  width: 140px;
  z-index: 4;
`;

export const DataCell = styled(Cell)<{ active: boolean; total: boolean }>`
  font-family: var(--font-monospaced);
  cursor: pointer;
  padding: 10px 7.5px;
  flex-wrap: wrap;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 2px solid rgba(255, 255, 255, 0);
    border-radius: 5px;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }

  ${(props) =>
    props.active &&
    css`
      &:after {
        opacity: 1;
        visibility: visible;
        border: 2px solid var(--accent-primary);
      }
    `};
  ${(props) =>
    props.total &&
    css`
      position: sticky;
      right: 0;
      bottom: 0;
      top: 0;
      max-width: 140px;
      min-width: 140px;
    `};
`;

export const CoinCell = styled(Cell)<{ active: boolean }>`
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 2px;
  }

  ${(props) =>
    props.active &&
    css`
      color: var(--accent-primary);
      font-weight: 700;
      &:after {
        background: var(--accent-primary);
      }
    `};
`;

export const CoinCellHeader = styled(CoinCell)`
  display: flex;
  align-items: center;
`;

export const LayoutWrap = styled.div`
  display: flex;
`;

export const RowWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CoinCellTotal = styled(CoinCell)`
  ${RightFixedCell}
  z-index: 1;
  align-items: center;
`;

export const TableCoinCellSubRowWrap = styled.div``;

export const ExchangeCell = styled.div<{ active: boolean }>`
  min-width: 140px;
  max-width: 140px;
  position: sticky;
  left: 0px;
  z-index: 2;
  background: var(--base);
  padding: 10px 7.5px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 2px;
  }

  ${(props) =>
    props.active &&
    css`
      color: var(--accent-primary);
      font-weight: 700;
      &:after {
        background: var(--accent-primary);
      }
    `};
`;

export const Row = styled.div<{ index: number; isExchange?: boolean }>`
  display: flex;
  flex-direction: row;
  background: ${({ index }) =>
    isOdd(index) ? 'var(--highlight)' : 'var(--base)'};
  z-index: 1;
  position: relative;
  justify-content: space-between;
  min-width: fit-content;
  height: ${({ isExchange }) => (isExchange ? '60px' : '40px')};

  ${Cell}, ${ExchangeCell} {
    background: ${({ index }) =>
      isOdd(index) ? 'var(--highlight)' : 'var(--base)'};
  }
`;

export const Header = styled(Row)`
  position: sticky;
  top: 0px;
  z-index: 5;
  background: var(--base) !important;

  ${CoinCell} {
    background: var(--base) !important;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
`;

export const FooterTitleCell = styled(ExchangeCell)`
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 700;
  display: flex;
  align-items: center;
`;

export const FooterTitleTotalCell = styled(ExchangeCell)`
  font-size: 10px;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  text-align: right;
  position: sticky;
  right: 0;
  bottom: 0;
  z-index: 100;
  height: 100px;
  padding-top: 60px;
  font-family: ${({ theme }) => theme.fonts.monospaced};
`;

export const CurrencySelectCell = styled(ExchangeCell)`
  background: var(--base) !important;
  overflow: visible;
  z-index: 99;
`;

export const CurrencySelectWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CurrencySelectItem = styled.div<{
  active: boolean;
  disabled: boolean;
}>`
  width: 20px;
  height: 20px;
  border-radius: 20px;
  cursor: pointer;
  background-color: var(--border);

  svg {
    width: 20px;
    height: 20px;
    fill: currentColor;
  }

  ${(props) =>
    props.active &&
    css`
      background: var(--accent-primary);
      color: var(--base);
    `};

  ${(props) =>
    props.disabled &&
    css`
      background: var(--border);
      color: var(--base);
      pointer-events: none;
    `};
`;

export const ExchangeTitle = styled.div<{ isExchange?: boolean }>`
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  padding-left: ${({ isExchange }) => (isExchange ? '0px' : '10px')};
`;

export const ExchangeName = styled.div`
  font-size: 10px;
  line-height: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 90px;
  // text-transform: uppercase;
`;

export const ChevronUp = styled(SvgChevronUp)`
  position: absolute;
  right: 10px;
`;

export const ChevronDown = styled(SvgChevronDown)`
  position: absolute;
  right: 10px;
`;

export const WarningLogo = styled(WarningSvg)`
  width: 10px;
  position: relative;
  top: -1px;
  left: 5px;
  outline: none;
  cursor: pointer;
`;

export const ExchangeLogo = styled.div<{ isExchange?: boolean }>`
  width: ${({ isExchange }) => (isExchange ? '20px' : '10px')};
  min-width: ${({ isExchange }) => (isExchange ? '20px' : '10px')};
  max-width: ${({ isExchange }) => (isExchange ? '20px' : '10px')};
  height: ${({ isExchange }) => (isExchange ? '20px' : '10px')};
  background-size: ${({ isExchange }) => (isExchange ? '20px' : '10px')};
  margin: 0 10px 0 0;
`;

export const ExchangeBalanceStatus = styled.div`
  display: flex;
  align-items: center;
  margin: 7px 0 0 0;
  padding: 0;
`;

export const ExchangeBalanceUpdated = styled.div<{ isOutdated?: boolean }>`
  margin: 0;
  font-size: 10px;
  font-weight: 400;
  color: var(--object-secondary);
  white-space: nowrap;
  line-height: 8px;
`;

export const IconBalanceWarning = styled(SvgClose)`
  width: 8px;
  height: 8px;
  fill: var(--warning);
  margin: 0 5px 0 0;
`;

export const CoinLogo = styled.div`
  width: 10px;
  min-width: 10px;
  max-width: 10px;
  height: 10px;
  background-size: 10px;
  margin: 0 5px 0 0;
`;

export const CoinLabel = styled.div`
  font-size: 10px;
  line-height: 13px;
  text-transform: uppercase;
`;

export const CoinTotalLabel = styled(CoinLabel)`
  font-weight: 700;
  margin: 0;
`;

export const TextCss = css`
  font-size: 11px;
  line-height: 12px;
`;

export const Total = styled.span<{ bold: boolean; error?: boolean }>`
  ${TextCss}
  color: var(--object-secondary);
  display: block;

  ${(props) =>
    props.bold &&
    css`
      font-weight: bold;
    `};

  ${(props) =>
    props.error &&
    css`
      color: var(--error);
    `};
`;

export const Available = styled.span<{ bold: boolean; error?: boolean }>`
  ${TextCss}
  color: var(--object-primary);
  display: block;

  ${(props) =>
    props.bold &&
    css`
      font-weight: bold;
    `};

  ${(props) =>
    props.error &&
    css`
      color: var(--error);
    `};
`;

export const Footer = styled(Row)`
  z-index: 3;
  background: var(--base) !important;
  z-index: 99;

  ${Cell}, ${ExchangeCell} {
    background: var(--base) !important;
  }
  ${Total}, ${Available} {
    font-family: var(--font-monospaced);
  }
`;

export const HintText = styled.p`
  font-size: 8px;
  line-height: 9px;
  text-align: right;
  color: var(--object-primary);
  margin: 0;
  padding: 0;

  &:first-child {
    color: var(--object-secondary);
  }
`;

export const ErrorHintText = styled.p`
  font-size: 8px;
  line-height: 9px;
  text-align: right;
  color: var(--error);
  margin: 0;
  padding: 0;
`;

export const CellAppearance = styled(motion.div)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--base);
  z-index: -1;
  pointer-events: none;
`;

export const CurrencyLabelWrap = styled.span`
  font-weight: bold;
  opacity: 0.9;
`;

export const TotalCellWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TotalCell = styled(DataCell)``;
