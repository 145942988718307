import * as S from './TransferStyled';

export const Columns = () => {
  return (
    <S.Sides>
      <S.Column side="from" />
      <S.Column side="to" />
    </S.Sides>
  );
};
