// @ts-nocheck
import semverSatisfies from 'semver/functions/satisfies';
import {
  getSafeSingletonDeployment,
  getSafeL2SingletonDeployment,
  getProxyFactoryDeployment,
  getFallbackHandlerDeployment,
} from '@gnosis.pm/safe-deployments';
import Web3 from 'web3';
import { AbiItem } from 'web3-utils';

import { ChainId } from 'src/config/chain.d';
import { GnosisSafe } from 'src/types/contracts/gnosis_safe.d';
import { ProxyFactory } from 'src/types/contracts/proxy_factory.d';
import { CompatibilityFallbackHandler } from 'shared/types/contracts/compatibility_fallback_handler';
import { MultiSend } from 'src/types/contracts/multi_send.d';
import { _getChain, _getChainId } from 'features/Multisig/helpers';
import { getWeb3 } from 'features/Multisig/mobx/logic/getWeb3';
import { ZERO_ADDRESS } from 'features/Multisig/mobx/logic/ethAddresses';
import {
  calculateGasOf,
  EMPTY_DATA,
} from 'features/Multisig/mobx/logic/ethTransactions';
import { LATEST_SAFE_VERSION } from 'features/Multisig/constants';

export const SENTINEL_ADDRESS = '0x0000000000000000000000000000000000000001';

let proxyFactoryMaster: ProxyFactory;
let safeMaster: GnosisSafe;
let fallbackHandler: CompatibilityFallbackHandler;
let multiSend: MultiSend;

const getSafeContractDeployment = async ({
  safeVersion,
}: {
  safeVersion: string;
}) => {
  // We check if version is prior to v1.0.0 as they are not supported but still we want to keep a minimum compatibility
  const useOldestContractVersion = semverSatisfies(safeVersion, '<1.0.0');
  // We have to check if network is L2
  const chainConfig = _getChain();
  // We had L1 contracts in three L2 networks, xDai, EWC and Volta so even if network is L2 we have to check that safe version is after v1.3.0
  const useL2ContractVersion =
    chainConfig.l2 && semverSatisfies(safeVersion, '>=1.3.0');
  const getDeployment = useL2ContractVersion
    ? getSafeL2SingletonDeployment
    : getSafeSingletonDeployment;

  return (
    getDeployment({
      version: safeVersion,
      network: chainConfig.chainId?.toString(),
    }) ||
    getDeployment({
      version: safeVersion,
    }) ||
    // In case we couldn't find a valid deployment and it's a version before 1.0.0 we return v1.0.0 to allow a minimum compatibility
    (useOldestContractVersion
      ? getDeployment({
          version: '1.0.0',
        })
      : undefined)
  );
};

/**
 * Creates a Contract instance of the GnosisSafe contract
 * @param {Web3} web3
 * @param {ChainId} chainId
 */
const getGnosisSafeContractInstance = async (
  web3: Web3,
  chainId: ChainId,
): GnosisSafe => {
  const safeSingletonDeployment = await getSafeContractDeployment({
    safeVersion: LATEST_SAFE_VERSION,
  });
  // @ts-ignore
  const contractAddress = safeSingletonDeployment?.networkAddresses[chainId];

  if (!contractAddress) {
    throw new Error(`GnosisSafe contract not found for chainId: ${chainId}`);
  }

  return new web3.eth.Contract(
    // @ts-ignore
    safeSingletonDeployment?.abi as AbiItem[],
    contractAddress,
  ) as unknown as GnosisSafe;
};

/**
 * Creates a Contract instance of the GnosisSafeProxyFactory contract
 * @param {Web3} web3
 * @param {ChainId} chainId
 */
const getProxyFactoryContractInstance = (
  web3: Web3,
  chainId: ChainId,
): ProxyFactory => {
  const proxyFactoryDeployment =
    getProxyFactoryDeployment({
      version: LATEST_SAFE_VERSION,
      network: chainId.toString(),
    }) ||
    getProxyFactoryDeployment({
      version: LATEST_SAFE_VERSION,
    });
  const contractAddress = proxyFactoryDeployment?.networkAddresses[chainId];

  if (!contractAddress) {
    throw new Error(
      `GnosisSafeProxyFactory contract not found for chainId: ${chainId}`,
    );
  }

  return new web3.eth.Contract(
    proxyFactoryDeployment?.abi as AbiItem[],
    contractAddress,
  ) as unknown as ProxyFactory;
};

/**
 * Creates a Contract instance of the FallbackHandler contract
 * @param {Web3} web3
 * @param {ChainId} chainId
 */
const getFallbackHandlerContractInstance = (
  web3: Web3,
  chainId: ChainId,
): CompatibilityFallbackHandler => {
  const fallbackHandlerDeployment =
    getFallbackHandlerDeployment({
      version: LATEST_SAFE_VERSION,
      network: chainId.toString(),
    }) ||
    getFallbackHandlerDeployment({
      version: LATEST_SAFE_VERSION,
    });
  const contractAddress = fallbackHandlerDeployment?.networkAddresses[chainId];

  if (!contractAddress) {
    throw new Error(
      `FallbackHandler contract not found for chainId: ${chainId}`,
    );
  }

  return new web3.eth.Contract(
    fallbackHandlerDeployment?.abi as AbiItem[],
    contractAddress,
  ) as unknown as CompatibilityFallbackHandler;
};

export const instantiateSafeContracts = async () => {
  const web3 = getWeb3();
  const { chainId } = _getChain();
  // Create ProxyFactory Master Copy
  proxyFactoryMaster = getProxyFactoryContractInstance(web3, chainId);

  // Create Safe Master copy
  safeMaster = await getGnosisSafeContractInstance(web3, chainId);

  // Create Fallback Handler
  fallbackHandler = getFallbackHandlerContractInstance(web3, chainId);
};

export const getSafeDeploymentTransaction = (
  safeAccounts: string[],
  numConfirmations: number,
  safeCreationSalt: number,
) => {
  const gnosisSafeData = safeMaster.methods
    .setup(
      safeAccounts,
      numConfirmations,
      ZERO_ADDRESS,
      EMPTY_DATA,
      fallbackHandler.options.address,
      ZERO_ADDRESS,
      0,
      ZERO_ADDRESS,
    )
    .encodeABI();
  return proxyFactoryMaster.methods.createProxyWithNonce(
    safeMaster.options.address,
    gnosisSafeData,
    safeCreationSalt,
  );
};

export const estimateGasForDeployingSafe = async (
  safeAccounts: string[],
  numConfirmations: number,
  userAccount: string,
  safeCreationSalt: number,
) => {
  const proxyFactoryData = getSafeDeploymentTransaction(
    safeAccounts,
    numConfirmations,
    safeCreationSalt,
  ).encodeABI();

  return calculateGasOf({
    data: proxyFactoryData,
    from: userAccount,
    to: proxyFactoryMaster.options.address,
  }).then((value) => value * 2);
};

export const getGnosisSafeInstanceAt = async (
  safeAddress: string,
  safeVersion: string,
): GnosisSafe => {
  const safeSingletonDeployment = await getSafeContractDeployment({ safeVersion });

  const web3 = getWeb3();
  return new web3.eth.Contract(
    // @ts-ignore
    safeSingletonDeployment?.abi as AbiItem[],
    safeAddress,
  ) as unknown as GnosisSafe;
};
