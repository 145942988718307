// @ts-nocheck
import { StatusProposalType, TransactionProposalType } from 'shared/types';

export const transactionProposal: TransactionProposalType = {
  id: 1,
  account_from: {
    id: 2,
    name: 'Account 2',
    exchange: 'BITSTAMP',
  },
  account_to: {
    id: 3,
    name: 'Account 3',
    exchange: 'DERIBIT',
  },
  currency: 'EURS',
  type: 'exchange',
  deposit_address: '0x86bbb445bE1ED7da8E3c4FA47A1C75837d6962B6',
  amount: '1.00000000',
  created_by: 'test@exan.tech',
  resolved_by: 'test@exan.tech',
  description: 'initiated proposal',
  status: StatusProposalType.INITIATED,
  updated_at: '2021-09-03T14:31:28.507705Z',
  sum_of_votes: 0,
  votes: [],
};
