export const rates = {
  USD: {
    BTC: 34615.635922745176,
    ETH: 1984.7921058933791,
    EURS: 1.19470778528969,
    USDT: 1.00025467580663,
    USD: 1.0,
    EUR: 1.19266985109517,
  },
  EUR: {
    BTC: 29023.65301760528,
    ETH: 1664.1588651468319,
    EURS: 1.0017087161150662,
    USDT: 0.8386685342034472,
    USD: 0.8384549999999993,
    EUR: 1.0,
  },
};
