// @ts-nocheck
import { StatusProposalType, TransactionProposalType } from 'shared/types';

export const proposals: TransactionProposalType[] = [
  {
    id: 1,
    account_from: {
      id: 2,
      name: 'Account 2',
      exchange: 'BITSTAMP',
    },
    account_to: {
      id: 3,
      name: 'Account 3',
      exchange: 'DERIBIT',
    },
    currency: 'EURS',
    type: 'exchange',
    deposit_address: '0x86bbb445bE1ED7da8E3c4FA47A1C75837d6962B6',
    amount: '1.00000000',
    created_by: 'test@exan.tech',
    resolved_by: 'test@exan.tech',
    description: 'approved proposal',
    status: StatusProposalType.APPROVED,
    updated_at: '2021-09-03T14:31:28.507705Z',
    sum_of_votes: 0,
    votes: [],
  },
  {
    id: 2,
    account_from: {
      id: 2,
      name: 'Account 2',
      exchange: 'BITSTAMP',
    },
    account_to: null,
    currency: 'ETH',
    type: 'exchange',
    deposit_address: 'initiated address',
    amount: '0.01000000',
    created_by: 'test@exan.tech',
    resolved_by: 'test@exan.tech',
    description: 'initiated proposal',
    status: StatusProposalType.INITIATED,
    updated_at: '2021-08-26T13:54:31.910230Z',
    sum_of_votes: 0,
    votes: [],
  },
  {
    id: 3,
    account_from: null,
    account_to: null,
    currency: 'BTC',
    type: 'exchange',
    deposit_address: '0x86bbb445bE1ED7da8E3c4FA47A1C75837d6962B6',
    amount: '2.34000000',
    created_by: 'test@exan.tech',
    resolved_by: 'test@exan.tech',
    description: 'rejected proposal',
    status: StatusProposalType.REJECTED,
    updated_at: '2021-08-26T13:51:07.127344Z',
    sum_of_votes: 0,
    votes: [],
  },
  {
    id: 4,
    account_from: null,
    account_to: null,
    currency: 'USDT',
    type: 'exchange',
    deposit_address: 'initiated address',
    amount: '10.00000000',
    created_by: 'test@exan.tech',
    resolved_by: 'test@exan.tech',
    description: 'initiated proposal',
    status: StatusProposalType.INITIATED,
    updated_at: '2021-08-26T13:40:31.910230Z',
    sum_of_votes: 0,
    votes: [],
  },
];
