// @ts-nocheck

import { useState, useContext, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Formik } from 'formik';

import Dropdown from 'shared/components/Dropdown';
import AppContext from 'shared/contexts/AppContext';
import { getCryptoValue } from 'shared/helpers/currencies';
import { getByteLength } from 'shared/helpers/getByteLength';

import * as Form from 'shared/components/Form';
import { SafeStoreInstance } from '../../mobx/SafeStore';
import { MultisigTransactionsStoreInstance } from '../../mobx/MultisigTransactionsStore';
import {
  calculateGasPrice,
  getFeesPerGas,
} from '../../mobx/logic/ethTransactions';

import * as M from '../styled/main';
import * as T from '../styled/transactions';
import { useWeb3React } from '@web3-react/core';
import { _getChain } from 'features/Multisig/helpers';

function ExecuteTransaction() {
  const [amount, setAmount] = useState(0.01);
  const [wasSubmitted, setWasSubmitted] = useState(false);
  const [recipient] = useState('');
  const { openModal } = useContext(AppContext);
  const [gas, setGas] = useState('');

  const {
    confirmTransaction,
    activeTransaction,
    setActiveTransaction,
    getTxParameters,
  } = MultisigTransactionsStoreInstance;

  useEffect(() => {
    const setGasPrice = async () => {
      const { ethGasLimit: gasLimit } = await getTxParameters({
        to: activeTransaction?.transaction?.txInfo?.recipient?.value,
        amount:
          activeTransaction?.transaction?.txInfo?.transferInfo?.value /
          1000000000000000000,
      });

      const gasPrice = await calculateGasPrice();
      const { maxPriorityFeePerGas: gasMaxPrioFee } = await getFeesPerGas();
      const totalPricePerGas =
        parseInt(gasPrice, 10) + parseInt(gasMaxPrioFee, 10);
      const estimatedGasCosts = parseInt(gasLimit, 10) * totalPricePerGas;

      setGas(estimatedGasCosts);
    };

    setGasPrice();
  }, []);

  const onCloseModal = () => {
    openModal(null);
    setActiveTransaction(null, null);
  };

  return (
    <Formik
      initialValues={{
        data: '',
        recipient,
        amount,
        execute: true,
      }}
      onSubmit={(values) => {
        console.log(values);
      }}
    >
      {({ errors, touched }) => (
        <Form.MainForm>
          <>
            <Form.Label>
              This action will execute this transaction..
            </Form.Label>
            <p />
            <p />
            <Form.Label>
              <b>
                Transaction Nonce:{' '}
                {
                  activeTransaction?.detailedExecutionInfo
                    ?.detailedExecutionInfo?.nonce
                }
              </b>
            </Form.Label>
            {gas !== '' && (
              <Form.Table>
                <Form.TableRow>
                  <Form.TableKey>Estimated fee price:</Form.TableKey>
                  <Form.TableValue>
                    {parseFloat(getCryptoValue(+gas)).toFixed(5)}{' '}
                    {_getChain().nativeCurrency.symbol}
                  </Form.TableValue>
                </Form.TableRow>
              </Form.Table>
            )}
            <Dropdown>
              <T.Table>
                <T.RowSmall>
                  <T.ColLabel>Nonce</T.ColLabel>
                  <T.ColValue>
                    {activeTransaction?.detailedExecutionInfo?.nonce}
                  </T.ColValue>
                </T.RowSmall>
                <T.RowSmall>
                  <T.ColLabel>Value</T.ColLabel>
                  <T.ColValue>
                    {getCryptoValue(activeTransaction?.txData?.value)}
                  </T.ColValue>
                </T.RowSmall>
                <T.RowSmall>
                  <T.ColLabel>To</T.ColLabel>
                  <T.ColValue>
                    {activeTransaction?.txData?.to.value}
                  </T.ColValue>
                </T.RowSmall>
                <T.RowSmall>
                  <T.ColLabel>safeTxHash</T.ColLabel>
                  <T.ColValue>
                    {activeTransaction?.detailedExecutionInfo?.safeTxHash}
                  </T.ColValue>
                </T.RowSmall>
                <T.RowSmall>
                  <T.ColLabel>Operation</T.ColLabel>
                  <T.ColValue>
                    {activeTransaction?.txData?.operation}
                  </T.ColValue>
                </T.RowSmall>
                <T.RowSmall>
                  <T.ColLabel>baseGas</T.ColLabel>
                  <T.ColValue>0</T.ColValue>
                </T.RowSmall>
                <T.RowSmall>
                  <T.ColLabel>gasPrice</T.ColLabel>
                  <T.ColValue>0</T.ColValue>
                </T.RowSmall>
                <T.RowSmall>
                  <T.ColLabel>gasToken</T.ColLabel>
                  <T.ColValue>0</T.ColValue>
                </T.RowSmall>
                <T.RowSmall>
                  <T.ColLabel>refundReceiver</T.ColLabel>
                  <T.ColValue>0</T.ColValue>
                </T.RowSmall>
                {activeTransaction?.detailedExecutionInfo.confirmations
                  ?.filter(({ signature }) => signature)
                  .map(({ signature }, i) => (
                    <T.RowSmall key={`signature-${i}`}>
                      <T.ColLabel>Signature {i + 1}</T.ColLabel>
                      <T.ColValue>
                        {signature ? getByteLength(signature) : 0} bytes
                      </T.ColValue>
                    </T.RowSmall>
                  ))}
                {activeTransaction?.detailedExecutionInfo?.txData
                  ?.hexData && (
                    <T.RowSmall>
                      <T.ColLabel>hexData</T.ColLabel>
                      <T.ColValue>
                        {
                          activeTransaction?.detailedExecutionInfo?.txData
                            ?.hexData
                        }
                      </T.ColValue>
                    </T.RowSmall>
                  )}
              </T.Table>
            </Dropdown>
          </>
          <Form.FormFooter>
            <Form.FooterText>
              You're about to execute a transaction and will have to
              confirm it with your currently connected wallet.
            </Form.FooterText>
            <Form.Buttons>
              <M.CancelButton
                type="button"
                onClick={onCloseModal}
              >
                Cancel
              </M.CancelButton>
              <M.SignButton
                type="button"
                disabled={wasSubmitted}
                onClick={() => {
                  try {
                    setWasSubmitted(true);
                    confirmTransaction({ execute: true }, () => {
                      openModal(null);
                    });
                  } catch (error) {
                    setWasSubmitted(false);
                  }
                }}
              >
                {wasSubmitted
                  ? `Sign on ${wallet.name}`
                  : 'Execute'}
              </M.SignButton>
            </Form.Buttons>
          </Form.FormFooter>
        </Form.MainForm>
      )}
    </Formik>
  );

}

const C = observer(ExecuteTransaction);

export { C as ExecuteTransaction };
