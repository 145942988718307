import React from 'react';
import styled, { css, keyframes } from 'styled-components/macro';
import blockies from 'blockies-ts';

const CircleWrap = styled.div<{ size?: number; seed?: string; image?: string }>`
  background-color: var(--highlight);
  background-position: 50%;
  background-size: ${(props) => (props.size ? props.size : '20')}px;
  width: ${(props) => (props.size ? props.size : '20')}px;
  height: ${(props) => (props.size ? props.size : '20')}px;
  max-width: ${(props) => (props.size ? props.size + 'px' : 'none')};
  min-width: ${(props) => (props.size ? props.size + 'px' : 'auto')};
  border-radius: ${(props) => (props.size ? props.size : '20')}px;
  background-repeat: no-repeat;
`;

function Circle(props: any) {
  const blockiesImage = blockies
    .create({ seed: props?.seed || 'cryptotreasury' })
    .toDataURL();
  return (
    <CircleWrap
      size={props?.size}
      style={{
        backgroundImage: `url(${props.image ? props.image : blockiesImage})`,
      }}
    />
  );
}
export default Circle;
