import { Helpdesk } from 'features/Helpdesk';
import { ProtectedArea } from 'shared/components';

export const HelpdeskPage = () => {
  return (
    <ProtectedArea displayMessage every={['accounts.view_account']}>
      <Helpdesk />
    </ProtectedArea>
  );
};
