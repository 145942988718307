import { makeObservable, observable } from 'mobx';

class HelpdeskStore {
  constructor() {
    makeObservable(this);
  }

  @observable amount = 0;
}

export const HelpdeskStoreInstance = new HelpdeskStore();
