import { useCallback, useEffect, useState } from 'react';
import { getTrackBackground, Range } from 'react-range';
import { IRenderTrackParams, IRenderThumbParams } from 'react-range/lib/types';
import { isUndefined } from 'shared/helpers/strings';
import { useFilters } from 'shared/hooks/useFilters';
import {
  FromToInputs,
  Input,
  Label,
  LabelWrap,
  FilterClearButton,
} from 'shared/components/FilterAbstract/FilterAbstract';
import { convertExpToDecimal } from 'shared/helpers/format';

interface FilterRangeProps extends FiltersProps {
  min: number;
  max: number;
}

interface RangeTrackProps extends IRenderTrackParams {
  min: number;
  max: number;
  values: number[];
}

const RangeTrack = ({
  props,
  children,
  min,
  max,
  values,
  disabled,
}: RangeTrackProps) => {
  const { onMouseDown, onTouchStart } = props;

  return (
    <div
      onMouseDown={onMouseDown}
      onTouchStart={onTouchStart}
      style={{
        ...props.style,
        display: 'flex',
        width: '100%',
        margin: '20px 0 0 0',
      }}
    >
      <div
        ref={props.ref}
        style={{
          height: '2px',
          width: '100%',
          borderRadius: '2px',
          background: getTrackBackground({
            colors: [
              'var(--base)',
              disabled ? 'var(--accent-primary)' : 'var(--accent-primary)',
              'var(--base)',
            ],
            min,
            max,
            values: [values[0] ? values[0] : min, values[1] ? values[1] : max],
          }),
          alignSelf: 'center',
        }}
      >
        {children}
      </div>
    </div>
  );
};

const RangeThumb = ({ props, isDragged, disabled }: any) => (
  <div
    {...props}
    style={{
      ...props.style,
      height: '8px',
      width: '8px',
      borderRadius: '8px',
      backgroundColor: disabled
        ? 'var(--accent-primary)'
        : isDragged
        ? 'var(--object-primary)'
        : 'var(--accent-primary)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  />
);

const getStep = (max: number) => {
  if (max < 1) {
    return max / 100;
  }

  if (max >= 1 && max <= 100) {
    return 0.1;
  }

  if (max > 100 && max <= 1000) {
    return 1;
  }

  return max / 1000;
};

export const FilterRange = ({
  storageKey,
  min: mn = 0,
  max: mx = 1,
  onSubmit,
  disabled,
}: FilterRangeProps) => {
  const jsonLastAmounts = localStorage.getItem('LAST_AMOUNT');
  const lastAmounts = jsonLastAmounts ? JSON.parse(jsonLastAmounts) : null;
  const [getFilters, setFilters] = useFilters(storageKey);
  const { amount_min, amount_max } = getFilters();
  const [min, max] = [Number(mn || lastAmounts?.rangeMin), Number(mx || lastAmounts?.rangeMax)];
  const [range, setRange] = useState<string[]>([amount_min, amount_max]);

  const setValues = useCallback((min: any, max: any) => {
    const minToSet = String(convertExpToDecimal(min));
    const maxToSet = String(convertExpToDecimal(max));

    setRange([minToSet, maxToSet]);
    setFilters({ amount_min: minToSet, amount_max: maxToSet });
  }, []);

  const onReset = useCallback(() => {
    setValues('', '');
    onSubmit();
  }, []);

  // useEffect(() => {
  //   if (isUndefined(mn) || isUndefined(mx)) {
  //     setTimeout(() => onReset(), 0);
  //   }
  // }, [mn, mx]);

  const onChangeMin = useCallback(
    (e: any) => {
      setValues(e.target.value, range[1]);
    },
    [range],
  );

  const onChangeMax = useCallback(
    (e: any) => {
      setValues(range[0], e.target.value);
    },
    [range],
  );

  useEffect(() => {
    localStorage.setItem(
      'LAST_AMOUNT',
      JSON.stringify({ rangeMin: mn, rangeMax: mx }),
    );
  }, [mn, mx]);

  return (
    <>
      <LabelWrap>
        <Label>Amount</Label>
        {(amount_max || amount_min) && (
          <FilterClearButton type="button" onClick={onReset}>
            Clear
          </FilterClearButton>
        )}
      </LabelWrap>
      <FromToInputs>
        <Input
          type="text"
          value={range[0]}
          onChange={onChangeMin}
          onBlur={onSubmit}
          disabled={disabled}
          placeholder={String(convertExpToDecimal(min)) || 'Min'}
        />
        <Input
          type="text"
          value={range[1]}
          onChange={onChangeMax}
          onBlur={onSubmit}
          disabled={disabled}
          placeholder={min ? String(convertExpToDecimal(max)) || 'Max' : 'Max'}
        />
      </FromToInputs>
      {/* @ts-ignore */}
      {/* <div style={{ marginBottom: '35px' }}>
        <Range
          step={getStep(rangeMax)}
          disabled={disabled}
          min={rangeMin}
          max={rangeMax}
          values={values}
          onChange={onRangeChange}
          onFinalChange={() => onSubmit()}
          renderTrack={({ props, children, isDragged, disabled }) => (
            <RangeTrack
              props={props}
              values={values}
              min={rangeMin}
              max={rangeMax}
              children={children}
              isDragged={isDragged}
              disabled={disabled}
            />
          )}
          renderThumb={({ props, isDragged, index }) => (
            <RangeThumb
              key={index}
              props={props}
              isDragged={isDragged}
              disabled={disabled}
            />
          )}
        />
      </div> */}
    </>
  );
};
