import { useState } from 'react';

interface UseToggleListProps {
  findEntityById: (id: any) => any;
  findSelectedByEntity: (selected: any, entity: any) => any;
}

export const useToggleList = ({
  findEntityById,
  findSelectedByEntity,
}: UseToggleListProps): [any[], (id: any) => any, (id: any) => any] => {
  const [selected, setSelected] = useState<any[]>([]);

  const selectEntity = (id: any) => {
    const entity = findEntityById(id);
    const index = selected.findIndex((s) => findSelectedByEntity(s, entity));
    if (index !== -1) return;
    setSelected((value) => [...value, entity]);
  };

  const unselectEntity = (entity: any) => {
    const index = selected.findIndex((s) => findSelectedByEntity(s, entity));
    if (index === -1) return;
    setSelected((value) => {
      const temp = [...value];
      temp.splice(index, 1);
      return temp;
    });
  };

  return [selected, selectEntity, unselectEntity];
};
