import { useLayoutEffect, useRef, useState } from 'react';
import { CurrenciesStoreInstance } from 'services';
import * as S from '../styled';
import { OverviewStoreInstance } from 'features/Overview/mobx/OverviewStore';
import { TableCoinCell } from './TableCoinCell';
import { CurrencyType } from 'shared/types';
import { isUndefined } from 'shared/helpers/strings';
import Decimal from 'decimal.js-light';
import { getExchangeInfoById } from 'shared/constants/exchanges';

import Tippy from '@tippyjs/react';

export const OverviewAccountRow = ({
  data,
  availableCurrencies,
  initialCurrencies,
  chosenCurrency,
  cell,
  setCell,
  isCryptoTab,
}: any) => {
  const { view, viewInfo, chosenRate } = OverviewStoreInstance;
  const { rates, getCurrencyPrecision, getCurrencyValue } =
    CurrenciesStoreInstance;
  const exchangeInfo = getExchangeInfoById(data.exchange);

  const walletName = data.name;
  const values: any = {};

  const textRef = useRef<HTMLDivElement | null>(null);

  const isTextTruncated = (element: HTMLDivElement) => {
    return element.scrollWidth > element.clientWidth;
  };

  const [isTooltipDisabled, setIsTooltipDisabled] = useState(false);

  useLayoutEffect(() => {
    const observer = new ResizeObserver(() => {
      if (textRef.current) {
        setIsTooltipDisabled(!isTextTruncated(textRef.current));
      }
    });

    if (textRef.current) {
      observer.observe(textRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [walletName]);

  const total = data.wallets.reduce((acc: any, { total, currency }: any) => {
    const multiplier =
      rates[chosenRate] && !isUndefined(rates[chosenRate][currency])
        ? rates[chosenRate][currency]
        : 0;
    return new Decimal(acc).plus(
      new Decimal(total).mul(multiplier).div(viewInfo.divisor),
    );
  }, 0);
  const available = data.wallets.reduce(
    (acc: any, { available, currency }: any) => {
      const multiplier =
        rates[chosenRate] && !isUndefined(rates[chosenRate][currency])
          ? rates[chosenRate][currency]
          : 0;
      return new Decimal(acc).plus(
        new Decimal(available).mul(multiplier).div(viewInfo.divisor),
      );
    },
    0,
  );
  data.wallets.forEach(({ currency, total, available }: any) => {
    const currentValue = values[currency as string];
    if (currentValue) {
      values[currency as string] = {
        total: new Decimal(currentValue.total).plus(new Decimal(total)),
        available: new Decimal(currentValue.available).plus(
          new Decimal(available),
        ),
      };
    } else {
      values[currency as string] = {
        total: new Decimal(total),
        available: new Decimal(available),
      };
    }
  });

  values.TOTAL = {
    total,
    available,
  };

  const sortedCurrencies = [
    //@ts-ignore
    ...new Set([...initialCurrencies, ...chosenCurrency]),
  ];

  return (
    <S.Row key={data.name} index={data.index}>
      <S.LayoutWrap>
        <S.ExchangeCell active={false}>
          <S.ExchangeTitle>
            <S.ExchangeLogo
              style={{
                backgroundImage: `url(${exchangeInfo?.logo_url})`,
              }}
            />
            <Tippy
              trigger="mouseenter"
              placement="top"
              hideOnClick={true}
              theme="transparent"
              content={walletName}
              disabled={isTooltipDisabled}
            >
              <S.ExchangeName ref={textRef}>{walletName}</S.ExchangeName>
            </Tippy>
          </S.ExchangeTitle>
        </S.ExchangeCell>
        {sortedCurrencies
          .filter((currency) => chosenCurrency.includes(currency))
          .map((availableCurrency: string) => {
            const wallet = data.wallets.find(({ currency }: any) => availableCurrency === currency);
            const walletId = wallet?.id;
            return (
              <TableCoinCell
                key={availableCurrency}
                availableCurrency={availableCurrency as CurrencyType}
                data={{
                  walletId,
                  exchange: data.exchange,
                  info: {
                    balance_updated_at: null,
                    is_balance_outdated: false,
                  },
                  values,
                }}
                cell={cell}
                chosenRate={chosenRate}
                exchangeName={`${data.exchange}-${data.name}`}
                getCurrencyPrecision={getCurrencyPrecision}
                getCurrencyValue={getCurrencyValue}
                isCryptoTab={isCryptoTab}
                rates={rates}
                setCell={setCell}
                view={view}
                viewInfo={viewInfo}
              />
            );
          })}
      </S.LayoutWrap>
      <TableCoinCell
        availableCurrency={'TOTAL'}
        data={{
          exchange: data.exchange,
          info: {
            balance_updated_at: null,
            is_balance_outdated: false,
          },
          values,
        }}
        cell={cell}
        chosenRate={chosenRate}
        exchangeName={`${data.exchange}-${data.name}`}
        getCurrencyPrecision={getCurrencyPrecision}
        getCurrencyValue={getCurrencyValue}
        isCryptoTab={isCryptoTab}
        rates={rates}
        setCell={setCell}
        view={view}
        viewInfo={viewInfo}
      />
    </S.Row>
  );
};
