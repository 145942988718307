import Tippy from '@tippyjs/react';
import { QrModal } from 'features/Profile/view/components/QrModal';
import * as S from 'features/Whitelist/newView/styled';
import {
  ColAccount,
  ColCoin,
  ColDeposit,
  ColExchange,
  DepositAddress,
  IconQr,
  TippyText,
} from 'features/Whitelist/view/styled';
import { useContext } from 'react';
import { getCoinsInfoById } from 'shared/constants/coins';
import { getExchangeInfoById } from 'shared/constants/exchanges';
import AppContext from 'shared/contexts/AppContext';
import { useFilters } from 'shared/hooks/useFilters';
import { useToggle } from 'shared/hooks/useToggle';

interface WhitelistRowProps {
  deposit_address: string;
  network: string;
  currencies: string[] | null | undefined;
  wallet_account: string;
  wallet_type: string;
  wallet_account_exchange: string;
  is_whitelisted: boolean;
  alias: string;
}

export const WhitelistRow = ({ tx }: { tx: WhitelistRowProps }) => {
  const {
    deposit_address,
    network,
    wallet_type,
    currencies,
    wallet_account,
    wallet_account_exchange,
    is_whitelisted,
    alias,
  } = tx;
  const [isOpen, toggleDetails] = useToggle(false);
  const [getFilters] = useFilters('whitelists_filters');
  const { coin: filtercurrencies } = getFilters();
  const id = `${deposit_address}_${wallet_account}`;
  const isUnbound = !wallet_account;

  const { openModal, appSettings } = useContext(AppContext);

  const openQrModal = () => {
    openModal({
      title: 'Scan QR Code',
      component: () => (
        <QrModal
          encoded={`${deposit_address}`}
          decoded={`${deposit_address}`}
        />
      ),
    });
  };

  const getcurrencies = () => {
    if (is_whitelisted && !currencies?.length) {
      return (
        <Tippy
          trigger="mouseenter"
          placement="top"
          theme="transparent"
          content={
            <TippyText>
              {filtercurrencies?.length ? filtercurrencies?.join(', ') : 'All'}{' '}
              token
              {filtercurrencies?.length > 1 || filtercurrencies?.length === 0
                ? 's '
                : ' '}
              whitelisted
            </TippyText>
          }
        >
          <S.ApprovedIcon style={{ marginTop: '6px' }}></S.ApprovedIcon>
        </Tippy>
      );
    } else if (!is_whitelisted) {
      return (
        <Tippy
          trigger="mouseenter"
          placement="top"
          theme="transparent"
          content={<TippyText>Not in whitelist</TippyText>}
        >
          <S.RejectedIcon style={{ marginTop: '6px' }}></S.RejectedIcon>
        </Tippy>
      );
    } else if (currencies?.length) {
      if (currencies?.length <= 3) {
        return (
          <>
            {currencies.map((currency) => {
              const info = getCoinsInfoById(
                currency,
                appSettings.currencies_icons,
              );
              const logo_url = info?.logo_url;
              return (
                <S.CurrencyItem>
                  {logo_url && (
                    <S.CurrencyLogo
                      style={{
                        backgroundImage: `url(${logo_url})`,
                      }}
                    />
                  )}
                  {currency}
                </S.CurrencyItem>
              );
            })}
          </>
        );
      } else {
        const excess = currencies.length - 3;

        return (
          <div style={{ userSelect: 'none' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {[currencies[0], currencies[1], currencies[2]].map((currency) => {
                const info = getCoinsInfoById(
                  currency,
                  appSettings.currencies_icons,
                );
                const logo_url = info?.logo_url;
                return (
                  <S.CurrencyItem>
                    {logo_url && (
                      <S.CurrencyLogo
                        style={{
                          backgroundImage: `url(${logo_url})`,
                        }}
                      />
                    )}
                    {currency}
                  </S.CurrencyItem>
                );
              })}

              {!isOpen && (
                <div style={{ display: 'flex', paddingTop: '3px' }}>
                  <S.CurrencyCounter>+{excess}</S.CurrencyCounter>
                </div>
              )}

              <S.ArrowIcon
                style={{ marginTop: '4px' }}
                /* @ts-ignore */
                isopen={isOpen}
              ></S.ArrowIcon>
            </div>

            {isOpen && (
              // <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, min-content)', rowGap: '6px', marginTop: 6 }}>

              <div style={{ width: '80%' }}>
                {/* @ts-ignore */}
                {currencies.slice(3, currencies.length).map((currency) => {
                  const info = getCoinsInfoById(
                    currency,
                    appSettings.currencies_icons,
                  );
                  const logo_url = info?.logo_url;
                  return (
                    <S.CurrencyItem>
                      {logo_url && (
                        <S.CurrencyLogo
                          style={{
                            backgroundImage: `url(${logo_url})`,
                          }}
                        />
                      )}
                      {currency}
                    </S.CurrencyItem>
                  );
                })}
              </div>
            )}
          </div>
        );
      }
    } else {
      return null;
    }
  };

  const exchangeInfo = getExchangeInfoById(wallet_account_exchange);
  const exchangeLogo = exchangeInfo?.logo_url;
  const displayWalletName =
    wallet_account?.length > 20
      ? `${wallet_account?.slice(0, 17)}...`
      : wallet_account;
  const displayAlias = alias?.length > 20 ? `${alias?.slice(0, 17)}...` : alias;

  return (
    <S.Row key={id} data-id={id} isOpen={isOpen}>
      <S.TransactionIntro onClick={() => toggleDetails()}>
        <ColDeposit>
          <>
            <IconQr onClick={openQrModal} />
            <Tippy
              trigger="click"
              placement="top"
              hideOnClick={true}
              theme="transparent"
              content={<TippyText>{deposit_address}</TippyText>}
            >
              <DepositAddress title={deposit_address}>
                {deposit_address}
              </DepositAddress>
            </Tippy>
          </>
        </ColDeposit>
        <ColCoin>{getcurrencies()}</ColCoin>
        <ColExchange>{network}</ColExchange>
        {!isUnbound && (
          <ColAccount
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              // padding: '0',
              height: '40px',
              // paddingTop: isOpen ? '7px' : '0px',
              marginTop: '-2px',
            }}
          >
            {wallet_account.length > 20 ? (
              <Tippy
                placement="top"
                theme="transparent"
                content={<TippyText>{wallet_account}</TippyText>}
              >
                <div>{displayWalletName}</div>
              </Tippy>
            ) : (
              <div>{displayWalletName}</div>
            )}

            <div style={{ color: 'var(--object-secondary)' }}>
              {wallet_type}
            </div>
          </ColAccount>
        )}
        {!isUnbound && (
          <ColExchange>
            <S.ToExchangeIcon
              style={{ backgroundImage: `url(${exchangeLogo})` }}
            />
            {wallet_account_exchange}
          </ColExchange>
        )}
        {isUnbound && (
          <ColAccount>
            {alias.length > 20 ? (
              <Tippy
                placement="top"
                theme="transparent"
                content={<TippyText>{alias}</TippyText>}
              >
                <div>{displayAlias}</div>
              </Tippy>
            ) : (
              <div>{displayAlias}</div>
            )}
          </ColAccount>
        )}
      </S.TransactionIntro>
    </S.Row>
  );
};
