import { ReactElement } from 'react';
import { Box, Typography } from '@mui/material';
import useSafeAddress from 'features/SafeApp/hooks/useSafeAddress';
import PrefixedEthHashInfo from '../EthHashInfo';

import * as S from './styled';

const SendFromBlock = (): ReactElement => {
  const address = useSafeAddress();

  return (
    <S.Container>
      <S.Text>
        Sending from
      </S.Text>

      <S.TextData>
        <PrefixedEthHashInfo address={address} shortAddress={false} hasExplorer showCopyButton />
      </S.TextData>

      <S.Hr>
        <S.IconTransfer />
      </S.Hr>
    </S.Container>
  );
};

export default SendFromBlock;
