import * as S from 'features/Transactions/view/styled';
import * as LoaderStyle from 'shared/components/Loader/styled';
import { useContext } from 'react';
import { FC, useEffect, useState } from 'react';
import InfiniteScroll from 'shared/modules/InfiniteScroll';
import AppContext from 'shared/contexts/AppContext';
import { useWindowSize } from 'shared/hooks/useWindowSize';
import styled from 'styled-components';
import { Loader as InlineLoader } from 'shared/components/Loader';

interface ListProps {
  row: any;
  data?: any[];
  hasNextPage: any;
  fetchNextPage: any;
  isInitialLoading: boolean;
  infiniteScroll?: boolean;
  embedded?: boolean;
  isLoading: boolean;
  isFetching: boolean;
  paddingBottom?: boolean;
}

const InlineLoaderWrapper = styled.div`
  width: 100%;
  height: 48.5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
interface TransactionsLoaderProps {
  isInitialLoading: boolean;
  isLoading: boolean;
}

export const Loader = ({
  isInitialLoading,
  isLoading,
}: TransactionsLoaderProps) => {
  if (isInitialLoading)
    return (
      <LoaderStyle.InitialLoaderWrap small={false} position="relative">
        <LoaderStyle.Logo
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.5 } }}
          exit={{ opacity: 0, transition: { duration: 0.5 } }}
        />
      </LoaderStyle.InitialLoaderWrap>
    );
  if (isLoading) return <InlineLoader position="fixed" />;

  return null;
};

export const List: FC<ListProps> = ({
  row: Row,
  data = [],
  hasNextPage,
  fetchNextPage,
  isLoading,
  embedded = false,
  isInitialLoading,
  isFetching,
  infiniteScroll = true,
  paddingBottom = true,
}) => {
  const { ref } = useContext(AppContext);
  const { width } = useWindowSize();

  return (
    <S.ListWrapper style={{ paddingBottom: paddingBottom ? '48.5px' : '0px' }}>
      {isInitialLoading || isLoading ? (
        <Loader isInitialLoading={isInitialLoading} isLoading={isLoading} />
      ) : (
        <S.Table
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.5 } }}
          exit={{ opacity: 0, transition: { duration: 0.5 } }}
        >
          {infiniteScroll ? (
            <InfiniteScroll
              pageStart={0}
              loadMore={fetchNextPage}
              hasMore={hasNextPage}
              useWindow={width && width < 1800 ? false : true}
              getScrollParent={() => ref}
            >
              <S.TransactionsList style={{ paddingTop: embedded ? '54px' : 0 }}>
                {data.map((tx) => (
                  <Row key={tx.id} tx={tx} />
                ))}
              </S.TransactionsList>
              {isFetching && (
                <InlineLoaderWrapper>
                  <InlineLoader small={true} />
                </InlineLoaderWrapper>
              )}
            </InfiniteScroll>
          ) : (
            <>
              <S.TransactionsList style={{ paddingTop: embedded ? '54px' : 0 }}>
                {data.map((tx) => (
                  <Row key={tx.id} tx={tx} />
                ))}
              </S.TransactionsList>
              {hasNextPage && (
                <S.LoadMoreButton onClick={fetchNextPage}>
                  {!isFetching ? 'Load more' : <InlineLoader small={true} />}
                </S.LoadMoreButton>
              )}
            </>
          )}
        </S.Table>
      )}
    </S.ListWrapper>
  );
};
