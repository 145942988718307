import styled, { css } from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
  line-height: 1.4;
  margin-bottom: 5px;
`;

export const Avatar = styled.div`
  flex-shrink: 0;
`;

export const NameRow = styled.div`
 overflow: hidden;
`;

export const AddressRow = styled.div`
 overflow: hidden;
 display: flex;
`;

export const MobileAddress = styled.span`
  display: inline-block;
`;

export const DesktopAddress = styled.span`
  display: none;
`;
