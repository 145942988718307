import { TypedDataUtils } from 'eth-sig-util';
import { TransactionReceipt } from 'web3-core';
import { TxArgs } from '../../models';
import { generateTypedDataFrom, getEip712MessageTypes } from './EIP712Signer';

export const generateSafeTxHash = (safeAddress: string, safeVersion: string, txArgs: TxArgs): string => {
  const typedData = generateTypedDataFrom({ safeAddress, safeVersion, ...txArgs });

  const messageTypes = getEip712MessageTypes(safeVersion);

  // @ts-ignore
  return `0x${TypedDataUtils.sign<typeof messageTypes>(typedData).toString('hex')}`;
};

export const didTxRevert = (receipt: TransactionReceipt): boolean => {
  return receipt?.status === false;
};
