import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
  toJS,
} from 'mobx';
import { formatISO, min, parseISO } from 'date-fns';
import Decimal from 'decimal.js-light';

import { availableViews as _availableViews } from '../constants';
import { ViewType } from '../types';
import {
  AccountsStoreInstance,
  CurrenciesStoreInstance,
  SettingsStoreInstance,
} from 'services';
import { isUndefined } from 'shared/helpers/strings';

class OverviewStore {
  constructor() {
    makeObservable(this);
  }
  @observable public view: ViewType = 'CRYPTO';
  @observable public availableViews = _availableViews;

  @computed public get chosenCurrency() {
    const localStorageValue = JSON.parse(
      localStorage.getItem('overview_filters') ?? '[]',
    );

    if (localStorageValue?.length) {
      return localStorageValue;
    } else {
      localStorage.setItem(
        'overview_filters',
        JSON.stringify([
          ...SettingsStoreInstance.appSettings.currencies_primary,
        ]),
      );
      return [...SettingsStoreInstance.appSettings.currencies_primary];
    }
  }

  @computed public get viewInfo() {
    return (
      this.availableViews.find((item) => item.label === this.view) ??
      this.availableViews[0]
    );
  }

  @computed public get chosenRate() {
    return this.viewInfo.currency;
  }

  @computed public get balances(): { [key: string]: any } {
    const { exchanges } = AccountsStoreInstance;
    const { rates } = CurrenciesStoreInstance;

    if (!rates) return {};

    return exchanges.reduce((acc, exchange) => {
      const exchangeName = exchange.exchange;
      // @ts-expect-error
      acc[exchangeName] = acc[exchangeName] || {
        values: {
          TOTAL: { available: '0.0', total: '0.0' },
        },
        info: {
          balance_updated_at: null,
          is_balance_outdated: false,
        },
      };

      if (!exchange) {
        return acc;
      }

      let isNoDataAvailable = false;

      exchange.accounts.forEach((account) => {
        // @ts-expect-error
        const info = acc[exchangeName]['info'];

        if (account.is_balance_outdated) {
          info.is_balance_outdated = true;
        }

        if (!isNoDataAvailable) {
          if (account.balance_updated_at) {
            if (info.balance_updated_at) {
              info.balance_updated_at = formatISO(
                min([
                  parseISO(info.balance_updated_at),
                  parseISO(account.balance_updated_at),
                ]),
              );
            } else {
              info.balance_updated_at = account.balance_updated_at;
            }
          } else {
            info.balance_updated_at = null;

            isNoDataAvailable = true;
          }
        }

        // calculate total and available balances for each exchange
        account.wallets.forEach((wallet) => {
          if (wallet.currency) {
            runInAction(() => {
              wallet.available = wallet.available ?? '0';
              wallet.total = wallet.total ?? '0';
            });
            // @ts-expect-error
            const currency = acc[exchangeName]['values'][wallet.currency] ?? {
              available: '0',
              total: '0',
            };

            currency.available = new Decimal(currency.available)
              .plus(wallet.available)
              .toFixed();
            currency.total = new Decimal(currency.total)
              .plus(wallet.total)
              .toFixed();

            // @ts-expect-error
            acc[exchangeName]['values'][wallet.currency] = currency;

            // @ts-expect-error
            const total = acc[exchangeName]['values']['TOTAL'] || {
              available: 0,
              total: 0,
            };

            const multiplier =
              rates[this.chosenRate] &&
              !isUndefined(rates[this.chosenRate][wallet.currency])
                ? rates[this.chosenRate][wallet.currency]
                : 0;

            total.available = new Decimal(wallet.available)
              .mul(multiplier)
              .div(this.viewInfo.divisor)
              .plus(total.available)
              .toFixed();
            total.total = new Decimal(wallet.total)
              .mul(multiplier)
              .div(this.viewInfo.divisor)
              .plus(total.total)
              .toFixed();

            // @ts-expect-error
            acc[exchangeName]['values']['TOTAL'] = total;
          }
        });
      });

      return acc;
    }, {});
  }

  @action.bound public setView = (newView?: ViewType) => {
    if (!newView) return;
    this.view = newView;
  };
}

export const OverviewStoreInstance = new OverviewStore();
