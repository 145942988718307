// @ts-nocheck

import { TransactionSummary } from '@gnosis.pm/safe-react-gateway-sdk';
import {
  TransactionInfoCreation,
  TransactionInfoCustom,
  TransactionInfoSettings,
  TransactionInfoTransfer,
} from '../Transactions';

export const TransactionPrimaryDetails = ({ tx }: {
  tx: TransactionSummary;
}) => {
  const { type } = tx.txInfo;

  switch (type) {
    case 'Transfer':
      return <TransactionInfoTransfer txInfo={tx.txInfo} />;
    case 'SettingsChange':
      return <TransactionInfoSettings txInfo={tx.txInfo} />;
    case 'Custom':
      return <TransactionInfoCustom txInfo={tx.txInfo} />;
    case 'Creation':
      return <TransactionInfoCreation txInfo={tx.txInfo} />;
  }
};
