import { formatISO } from 'date-fns';
import { BigNumberish } from 'ethers';
import { formatUnits } from 'ethers/lib/utils';
import moment from 'moment';
import { isUndefined } from './strings';

export function formatDate(date: number) {
  const f = formatISO(date);
  return moment(f).format('MMM DD YYYY, hh:mm A');
}

export function dateAgo(date: number) {
  const f = formatISO(date);
  return moment(f, 'YYYYMMDD').fromNow();
}

export const capitalize = (text: string) => {
  return text.charAt(0)?.toUpperCase() + text.slice(1)?.toLowerCase();
};

export function formatAccount(account?: string, middleCut = false) {
  if (!account) return account;
  if (middleCut) {
    return account.length > 20
      ? `${account?.slice(0, 5)}...${account?.slice(-5)}`
      : account;
  } else {
    return account.length > 20 ? `${account.substring(0, 20)}...` : account;
  }
}

export function convertExpToDecimal(n: number) {
  if (!n) return '';

  const sign = +n < 0 ? '-' : '',
    toStr = n.toString();
  if (!/e/i.test(toStr)) {
    return n;
  }
  const [lead, decimal, pow] = n
    .toString()
    .replace(/^-/, '')
    .replace(/^([0-9]+)(e.*)/, '$1.$2')
    .split(/e|\./);
  return +pow < 0
    ? sign +
        '0.' +
        '0'.repeat(Math.max(Math.abs(Number(pow)) - 1 || 0, 0)) +
        lead +
        decimal
    : sign +
        lead +
        (+pow >= decimal.length
          ? decimal + '0'.repeat(Math.max(+pow - decimal.length || 0, 0))
          : decimal.slice(0, +pow) + '.' + decimal.slice(+pow));
}

export const removeEmptyValues = (object: Record<string, unknown>) =>
  Object.fromEntries(
    Object.entries(object).filter(([_, value]) => !isUndefined(value)),
  );

export const _removeTrailingZeros = (value: string): string => {
  // Match `.000` or `.01000`
  return value.replace(/\.0+$/, '').replace(/(\..*?)0+$/, '$1');
};

const GWEI = 'gwei';

export const safeFormatUnits = (
  value: BigNumberish,
  decimals: number | string = GWEI,
): string => {
  try {
    const formattedAmount = formatUnits(value, decimals);

    // ethers' `formatFixed` doesn't remove trailing 0s and using `parseFloat` can return exponentials
    return _removeTrailingZeros(formattedAmount);
  } catch (err) {
    console.error('Error formatting units', err);
    return '';
  }
};

export const roundToFixed = (number: number, digits = 0) => {
    const multiplier = Math.pow(10, digits);
    return Math.round(number * multiplier) / multiplier;
  };

export const formatNumberWithoutExponent = (numStr: string, digits = 0) => {
    const [integerPart, decimalPart] = numStr.split('.');

    if (decimalPart) {
      const roundedDecimal = roundToFixed(parseFloat('0.' + decimalPart), digits);
      const roundedInteger = integerPart ? parseInt(integerPart) + Math.floor(roundedDecimal) : Math.floor(roundedDecimal);
      const roundedDecimalStr = (roundedDecimal - Math.floor(roundedDecimal)).toFixed(digits).slice(2);
      const noTrailingZerosDecimalStr = roundedDecimalStr.replace(/0+$/, '');

      if (noTrailingZerosDecimalStr === '') {
        return roundedInteger.toString();
      }
      return roundedInteger.toString() + '.' + noTrailingZerosDecimalStr;
    }
    return integerPart;
  };
