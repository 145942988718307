import { format, parseISO } from 'date-fns';
import { DetailItem } from '../shared/DetailItem';
import Copy from 'shared/components/Copy';
import styled from 'styled-components';

import * as S from 'features/Transactions/view/styled';
import { StatusBar } from '../shared/StatusBar';
import { Transaction } from 'services/Transactions/types';

const CopyWrapper = styled.div``;

const TxIdValue = ({ value }: any) => (
  <S.TxIdValue>
    {value}
    <CopyWrapper>
      <Copy width="14px" height="14px" text={value || ''} />
    </CopyWrapper>
  </S.TxIdValue>
);

interface TransactionDetailsProps {
  tx: Transaction;
  voteDown: any;
  voteUp: any;
  executeTx: any;
  voteUpAndSign: any;
  voteError: string;
}

export const TransactionDetails = ({
  tx,
  voteDown,
  voteUpAndSign,
  voteUp,
  executeTx,
  voteError,
}: TransactionDetailsProps) => {
  const {
    comment,
    deposit_address,
    deposit_status,
    description,
    external_id,
    created_by,
    resolved_by,
    tx_id,
    network,
    network_info,
    is_fiat,
  } = tx;

  return (
    <S.TransactionDetail>
      <S.TransactionDetailWrap>
        <S.Left>
          <S.SideWrap>
            <DetailItem title="TX ID:" value={tx_id} wrapper={TxIdValue} />
            {!tx_id && (
              <DetailItem
                title="TX ID (internal):"
                value={external_id}
                wrapper={TxIdValue}
              />
            )}
            <DetailItem title="Created by:" value={created_by} />
            <DetailItem title="Resolved by:" value={resolved_by} />
            {is_fiat && <DetailItem
              title="Method:"
              value={network_info}
              wrapper={S.DepositStatusValue}
            />}
            {is_fiat && <DetailItem
              title="Address:"
              value={deposit_address}
              wrapper={S.DepositStatusValue}
            />}
            <DetailItem
              title="Deposit status:"
              value={deposit_status}
              wrapper={S.DepositStatusValue}
            />
            <DetailItem
              title="Comment:"
              value={comment}
              wrapper={S.DepositStatusValue}
            />
            <DetailItem
              title="Description:"
              value={description}
              wrapper={S.DepositStatusValue}
            />
            <DetailItem
              title="Network:"
              value={network}
              wrapper={S.DepositStatusValue}
            />
          </S.SideWrap>
        </S.Left>

        <S.Right>
          <S.SideWrap>
            <StatusBar
              tx={tx}
              voteError={voteError}
              voteDown={voteDown}
              voteUpAndSign={voteUpAndSign}
              voteUp={voteUp}
              executeTx={executeTx}
            />
          </S.SideWrap>
        </S.Right>
      </S.TransactionDetailWrap>
    </S.TransactionDetail>
  );
};
