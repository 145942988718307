import { useEffect, useState } from 'react';

import { TransactionsHeader } from './TransactionsHeader';
import { TransactionRow } from './TransactionRow';
import { TransactionsFilters } from './TransactionsFilters';
import { List } from '../shared/List';
import { NotFound } from '../shared/NotFound';
import { findMinMaxDates } from 'shared/helpers/date';
import { TransactionsQueue } from './TransactionsQueue';
import { ListWrapper } from '../../styled';

import _ from 'lodash';

import * as S from '../../styled';
import { useTransactions } from '../hooks/useTransactions';

export const Transactions = () => {
  const {
    filters_data,
    rows,
    isLoading,
    isInitialLoading,
    hasNextPage,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
    refetch,
  } = useTransactions('history');
  const queueQuery = useTransactions('queue');

  const { min: minDate, max: maxDate } = findMinMaxDates(rows || []);
  const onFetchNextPage = () => {
    if (!isFetching) {
      fetchNextPage();
    }
  };
  const onSubmit = () => {
    refetch();
    queueQuery.refetch();
  };

  const minAmount = _.min([
    filters_data?.min_tx_amount,
    queueQuery?.filters_data?.min_tx_amount,
  ]);
  const maxAmount = _.max([
    filters_data?.max_tx_amount,
    queueQuery?.filters_data?.max_tx_amount,
  ]);

  const [isAsideButtonChecked, setIsAsideButtonChecked] = useState('unset');

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1300);

  useEffect(() => {
    function handleResize() {
      if (isAsideButtonChecked === 'unset') {
        setIsMobile(window.innerWidth < 1300);
      }
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isAsideButtonChecked]);

  return (
    <>
      <ListWrapper
        style={{ paddingBottom: rows?.length ? '48.5px' : '0px' }}
        isMobile={isMobile}
        isAsideButtonChecked={isAsideButtonChecked}
      >
        <TransactionsHeader />
        <TransactionsQueue query={queueQuery} />
        <S.HeaderTitle style={{ marginTop: '-48.5px' }}>History</S.HeaderTitle>
        <List
          isInitialLoading={isInitialLoading}
          isLoading={isLoading}
          isFetching={isFetchingNextPage}
          hasNextPage={hasNextPage}
          fetchNextPage={onFetchNextPage}
          row={TransactionRow}
          data={rows}
          paddingBottom={false}
        />
        <NotFound paddingTop={false} active={!isLoading && !rows?.length}>
          No transactions found
        </NotFound>
      </ListWrapper>
      {/* TODO: add otp modal */}
      <TransactionsFilters
        disabled={isLoading}
        onSubmit={onSubmit}
        minDate={minDate}
        maxDate={maxDate}
        minAmount={minAmount}
        maxAmount={maxAmount}
        isMobile={isMobile}
        isAsideButtonChecked={isAsideButtonChecked}
        setIsAsideButtonChecked={setIsAsideButtonChecked}
        setIsMobile={setIsMobile}
      />
    </>
  );
};
