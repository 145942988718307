import * as S from './AccountStyled';
interface AccountNameLabelProps {
  name: string;
}

export const AccountNameLabel = ({ name }: AccountNameLabelProps) => {
  return (
    <S.AccountNameLabel title={name}>{name || '<no name>'}</S.AccountNameLabel>
  );
};
