import { shortenText } from 'features/SafeApp/helpers/formatters';
import { Box, Link } from '@mui/material';
import type { ReactElement } from 'react';
import { useState } from 'react';
import * as S from './styled';

interface Props {
  hexData: string
  title?: string
  limit?: number
}

export const HexEncodedData = ({ hexData, title, limit = 20 }: Props): ReactElement => {
  const [showTxData, setShowTxData] = useState(false);
  const showExpandBtn = hexData.length > limit;

  const toggleExpanded = () => {
    setShowTxData((val) => !val);
  };

  return (
    <S.EncodedData data-testid="tx-hexData">
      {title && (
        <span>
          <b>{title}: </b>
        </span>
      )}
      {showExpandBtn ? (
        <>
          {showTxData ? hexData : shortenText(hexData, 25)}{' '}
          <Link component="button" onClick={toggleExpanded} type="button">
            Show {showTxData ? 'less' : 'more'}
          </Link>
        </>
      ) : (
        <span>{hexData}</span>
      )}
    </S.EncodedData>
  );
};
