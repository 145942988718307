import { useContext, useEffect, useMemo, useState } from 'react';

import { WhitelistsHeader } from './WhitelistHeader';
import { WhitelistsFilters } from './WhitelistsFilter';
import { findMinMaxDates } from 'shared/helpers/date';
import { ListWrapper } from '../../styled';

import _ from 'lodash';

import * as S from '../../styled';
import { WhitelistTabs } from './WhitelistTabs';
import { WhitelistRow } from './WhitelistRow';
import { List } from '../shared/List';
import { NotFound } from '../shared/NotFound';
import { useWhitelists } from '../hooks/useWhitelists';
import AppContext from 'shared/contexts/AppContext';

export const Whitelists = () => {
  const [tab, setTab] = useState<WhitelistTab>('whitelists');

  const [isAsideButtonChecked, setIsAsideButtonChecked] = useState('unset');

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1300);

  useEffect(() => {
    function handleResize() {
      if (isAsideButtonChecked === 'unset') {
        setIsMobile(window.innerWidth < 1300);
      }
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isAsideButtonChecked]);

  const { data, refetch, isLoading, isInitialLoading } = useWhitelists(tab);

  const onSubmit = () => {
    refetch();
  };

  const sortedData = useMemo(() => {
    return data?.sort((a: any, b: any) => {
      if (a.is_whitelisted && !a.currencies?.length) {
        return -1;
      } else if (b.is_whitelisted && !b.currencies?.length) {
        return 1;
      } else if (a.is_whitelisted && a.currencies?.length) {
        return -1;
      } else if (b.is_whitelisted && b.currencies?.length) {
        return 1;
      } else {
        return 0;
      }
    });
  }, [data]);

  return (
    <>
      <ListWrapper
        isMobile={isMobile}
        isAsideButtonChecked={isAsideButtonChecked}
      >
        <WhitelistTabs tab={tab} setTab={setTab} onSubmit={onSubmit} />
        <WhitelistsHeader tab={tab} />
        <List
          isInitialLoading={isInitialLoading}
          isLoading={isLoading}
          isFetching={false}
          row={WhitelistRow}
          data={sortedData}
        />
        <NotFound active={!isLoading && sortedData?.length === 0}>
          No whitelists found
        </NotFound>
      </ListWrapper>
      {/* TODO: add otp modal */}
      <WhitelistsFilters
        tab={tab}
        disabled={false}
        onSubmit={onSubmit}
        isMobile={isMobile}
        isAsideButtonChecked={isAsideButtonChecked}
        setIsAsideButtonChecked={setIsAsideButtonChecked}
        setIsMobile={setIsMobile}
      />
    </>
  );
};
