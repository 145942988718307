import { getExchangeInfoById } from 'shared/constants/exchanges';
import { ExchangeIconMin } from '../../components/ExchangeAbstract';
import { TransactionExchangeType } from 'services/Transactions/types';

interface AccountExchangeIconProps {
  exchange: TransactionExchangeType;
}

export const AccountExchangeIcon = ({ exchange }: AccountExchangeIconProps) => {
  if (!exchange) return null;

  const info = getExchangeInfoById(exchange);

  return (
    <ExchangeIconMin
      style={{
        backgroundImage: `url(${info?.logo_url})`,
      }}
      disabled={false}
    />
  );
};
