import styled from 'styled-components';

export const GridRow = styled.div`
  display: grid;
  grid-template-columns: 35% auto;
  gap: var(--space-1);
  justify-content: flex-start;
  max-width: 900px;
`;

export const Title = styled.div`
  font-weight: 700;
  word-break: break-all;
`;

export const ValueWrapper = styled.div`
  min-width: 50%;
  flex-shrink: 0;
`;

export const RawData = styled.div`
  display: flex;
  align-items: center;
`;
