import { useQuery } from '@tanstack/react-query';
import { requestGet } from 'shared/axios';

export const useMpcGroupsQuery = ({ limit, offset }: any) => {
  return useQuery({
    queryKey: ['multisig-groups', limit, offset],
    queryFn: async () => {
      const response = await requestGet('/mpc/groups/', {
        params: { limit, offset },
      });
      return response.data;
    },
  });
};
