import { FC, ReactNode } from 'react';
import * as S from '../../styled';

interface DetailItemProps {
  title: string;
  value: any;
  wrapper?: FC<{ children: ReactNode, value?: any }>;
}

export const DetailItem = ({
  title,
  value,
  wrapper: Wrapper = S.TransactionDetailValue,
}: DetailItemProps) => {
  if (!value) return null;

  return (
    <S.TransactionDetailItem>
      <S.TransactionDetailLabel>{title}</S.TransactionDetailLabel>
      <Wrapper value={value}>{value}</Wrapper>
    </S.TransactionDetailItem>
  );
};
