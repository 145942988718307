import React from 'react';
import { createRoot } from 'react-dom/client';

import 'tailwindcss/dist/base.min.css';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light-border.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'whatwg-fetch';

import { App } from './App';
import 'style.css';
// import * as serviceWorker from './serviceWorker';

// eslint-disable-next-line
import * as types from 'styled-components/cssprop';
import { interceptors } from 'shared/axios'; // used for css prop and ts
import { BrowserRouter as Router } from 'react-router-dom';

if (process.env.REACT_APP_MOCK === 'true') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { worker } = require('./mocks/browser');
  worker.start();
}

interceptors();

const rootElement = document.getElementById('root');

if (!rootElement) throw new Error('Failed to find the root element');

const root = createRoot(rootElement);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
