import { FC } from 'react';

import * as S from './styled';

interface IProps {
  text?: string;
  width?: string;
  height?: string;
}

export const Placeholder: FC<IProps> = ({ text, width, height }) => {
  return (
    <S.PlaceholderWrap width={width} height={height}>
      <S.BorderWrap>
        <S.Text>{text}</S.Text>
      </S.BorderWrap>
    </S.PlaceholderWrap>
  );
};
