import {
  ChainInfo,
  TransactionStatus,
} from '@gnosis.pm/safe-react-gateway-sdk';
import { _getChain } from 'features/Multisig/helpers';

const data = {
  [TransactionStatus.AWAITING_CONFIRMATIONS]: {
    title: 'Awaiting confirmations',
    color: 'var(--warning)',
  },
  [TransactionStatus.AWAITING_EXECUTION]: {
    title: 'Awaiting execution',
    color: 'var(--warning)',
  },
  [TransactionStatus.CANCELLED]: {
    title: 'Cancelled',
    color: 'var(--error)',
  },
  [TransactionStatus.FAILED]: {
    title: 'Failed',
    color: 'var(--error)',
  },
  [TransactionStatus.SUCCESS]: {
    title: 'Success',
    color: 'var(--success)',
  },
  PENDING: {
    title: 'Pending',
    color: 'var(--warning)',
  },
  WILL_BE_REPLACED: {
    title: 'Will be replaced',
    color: 'var(--error)',
  },
};

export const getTxStatusTitle = (status: TransactionStatus) =>
  data[status].title;

export const getTxStatusColor = (status: TransactionStatus) =>
  data[status].color;

const getExplorerUriTemplate = (): ChainInfo['blockExplorerUriTemplate'] => {
  return _getChain().blockExplorerUriTemplate;
};

export const getExplorerUrl = (): string => {
  const { address } = getExplorerUriTemplate();
  return new URL(address).origin;
};

export const getHashedExplorerUrl = (hash: string): string => {
  const isTx = hash.length > 42;
  const param = isTx ? 'txHash' : 'address';
  const uri = getExplorerUriTemplate()[param];
  return evalTemplate(uri, { [param]: hash });
};

// Matches return type of ExplorerInfo from SRC
export const getExplorerInfo = (hash: string): { url: string; alt: string } => {
  const url = getHashedExplorerUrl(hash);

  const { hostname } = new URL(url);
  const alt = `View on ${hostname}`; // Not returned by CGW
  return { url, alt };
};

// Template syntax returned from CGW is {{this}}
export const evalTemplate = (
  uri: string,
  data: string | Record<string, string>,
): string => {
  const TEMPLATE_REGEX = /\{\{([^}]+)\}\}/g;
  // @ts-ignore
  return uri.replace(TEMPLATE_REGEX, (_: string, key: string) => data[key]);
};
