import { useNavigate } from 'react-router-dom';
import * as S from '../Modal/styled';
import { FC, useContext } from 'react';
import AppContext from 'shared/contexts/AppContext';

export const UnsetOtp: FC = () => {
  const { openModal, setIsHideSidebar } = useContext(AppContext);
  const navigate = useNavigate();
  const onClick = () => {
    navigate('/profile');
    openModal(null);
    setIsHideSidebar(false);
  };
  return (
    <>
      <S.ModalText>
        You do not have two-factor authentication installed, you can set it up
        in your profile.
      </S.ModalText>
      <S.ModalDiv />
      <S.ConfirmButton type="button" onClick={onClick}>
        Go to profile <S.IconArrow />
      </S.ConfirmButton>
    </>
  );
};
