import { useEffect, useState } from 'react';
import useOnboard, { ConnectedWallet, getConnectedWallet } from './useOnboard';
import { WalletState } from '@web3-onboard/core';

const useWallet = (): ConnectedWallet | null => {
  const onboard = useOnboard();
  const onboardWallets = onboard?.state.get().wallets || [];
  const [wallet, setWallet] = useState<ConnectedWallet | null>(getConnectedWallet(onboardWallets));

  useEffect(() => {
    if (!onboard) return;

    const walletSubscription = onboard.state.select('wallets').subscribe((wallets: WalletState[]) => {
      const newWallet = getConnectedWallet(wallets);
      setWallet(newWallet);
    });

    return () => {
      walletSubscription.unsubscribe();
    };
  }, [onboard]);

  return wallet;
};

export default useWallet;
