import { TextareaAutosize } from '@mui/material';
import { TransferContext } from 'features/Transfer/context/TransferContext';
import { Formik } from 'formik';
import { useContext, useMemo, useState } from 'react';
import { requestPatch } from 'shared/axios';
import { API_URL } from 'shared/constants/app';
import AppContext from 'shared/contexts/AppContext';
import { AccountType, PermissionType } from 'shared/types';
import * as S from './AccountStyled';

interface AccountHintsProps {
  account: AccountType;
}

const fullAccountPermissions: PermissionType[] = [
  'accounts.view_account',
  'accounts.change_account',
];

export const AccountHints = ({ account }: AccountHintsProps) => {
  const [editable, setEditable] = useState(false);
  const [tooltipError, setTooltipError] = useState<string>('');
  const { user, refetchAccounts, appToken } = useContext(AppContext);

  const couldEditHint = useMemo(
    () =>
      fullAccountPermissions.every((item) => user?.permissions.includes(item)),
    [user],
  );
  const TEST_HEADERS = {
    headers: {
      authorization: `Token ${appToken}`,
    },
  };

  const submitHint = async (accountId: number, hint: string) => {
    try {
      const rawData = await fetch(`${API_URL}/accounts/${accountId}/`, {
        ...TEST_HEADERS,
        method: 'PATCH',
        headers: {
          ...TEST_HEADERS.headers,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          hint,
        }),
      });

      if (rawData.status >= 200 && rawData.status < 300) {
        account.hint = hint;
        await refetchAccounts();
        setEditable(false);
      } else {
        setEditable(true);
        setTooltipError('Can\'t save a hint');
        setTimeout(() => setTooltipError(''), 1000);
      }
    } catch (err) {
      setEditable(true);
      setTooltipError('Can\'t save a hint');
      setTimeout(() => setTooltipError(''), 1000);
    }
  };
  return (
    <>
      {couldEditHint || account.hint ? (
        <S.AccountHintLabel>
          {couldEditHint && editable ? (
            <Formik
              initialValues={{ hint: account.hint }}
              onSubmit={async (values, { setSubmitting }) => {
                setSubmitting(true);
                await submitHint(account.id, values.hint);
                setSubmitting(false);
              }}
            >
              {({
                isSubmitting,
                resetForm,
                setFieldValue,
                submitForm,
                values,
              }) => (
                <S.StyledForm>
                  <S.HintTextWrap>
                    <S.HintTextArea
                      name="hint"
                      autoComplete="off"
                      maxRows={2}
                      autoFocus={true}
                      placeholder="Type hint text here"
                      maxLength={68}
                      value={values.hint}
                      disabled={isSubmitting}
                      onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                        setFieldValue('hint', e.target.value)
                      }
                      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                        if (isSubmitting) {
                          return;
                        }

                        if (e.key === 'Enter') {
                          e.preventDefault();
                          submitForm();
                        }

                        if (e.key === 'Escape') {
                          e.preventDefault();
                          resetForm();
                          setEditable(false);
                        }
                      }}
                      component={TextareaAutosize}
                    />
                  </S.HintTextWrap>
                  <S.ActionIconsWrap>
                    <S.HintButton
                      type="button"
                      disabled={isSubmitting}
                      onClick={() => submitForm()}
                    >
                      <S.IconSave title="Save hint" />
                    </S.HintButton>
                    <S.HintButton
                      type="button"
                      onClick={() => {
                        resetForm();
                        setEditable(false);
                      }}
                    >
                      <S.IconCancel title="Cancel" />
                    </S.HintButton>
                    <S.HintText>
                      {isSubmitting ? 'Saving…' : tooltipError}
                    </S.HintText>
                  </S.ActionIconsWrap>
                </S.StyledForm>
              )}
            </Formik>
          ) : couldEditHint ? (
            <>
              {account.hint ? (
                <S.HintTextWrap
                  onClick={() => setEditable(true)}
                  couldEdit={true}
                >
                  <S.AccountHint>{account.hint}</S.AccountHint>
                  <S.HintEditButton>
                    <S.IconEdit />
                  </S.HintEditButton>
                </S.HintTextWrap>
              ) : (
                <S.AddTooltipLabel onClick={() => setEditable(true)}>
                  + Add hint
                </S.AddTooltipLabel>
              )}
            </>
          ) : (
            <>
              <S.HintTextWrap>
                <S.TooltipSavedText>
                  {account.hint || (
                    <S.NoHintPermission>
                      You can't modify this hint
                    </S.NoHintPermission>
                  )}
                </S.TooltipSavedText>
              </S.HintTextWrap>
            </>
          )}
        </S.AccountHintLabel>
      ) : null}
    </>
  );
};
