import { FC } from 'react';

import * as S from './styled';

interface IProps {
  position?: string;
  background?: string;
  small?: boolean;
}

export const Loader: FC<IProps> = ({ position, background, small }) => {
  return (
    <S.LoaderWrap small={small} position={position} background={background}>
      <S.Logo
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 0.5 } }}
        exit={{ opacity: 0, transition: { duration: 0.5 } }}
      />
    </S.LoaderWrap>
  );
};
