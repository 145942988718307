import { GoogleLogin } from '@react-oauth/google';
import { FC, useContext } from 'react';
import { AuthStoreInstance } from 'services';
import AppContext from 'shared/contexts/AppContext';
import { LoginOtp } from '../OtpModal/LoginOtp';

import * as S from './styled';

export const Login: FC = () => {
  const { openModal } = useContext(AppContext);
  const { onSignInSuccess, fetchAppTokenWithOtp } = AuthStoreInstance;

  const openLoginModal = (googleToken: string | undefined) => {
    openModal({
      title: 'Security Verification',
      component: () => <LoginOtp googleToken={googleToken} fetchToken={fetchAppTokenWithOtp} />,
    });
  };

  return (
    <S.LoginWrap
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      exit={{ opacity: 0, transition: { duration: 0.5 } }}
    >
      <S.LogoWrap
        initial={{ opacity: 0, scale: 0 }}
        animate={{ opacity: 1, scale: 1, transition: { duration: 0.5 } }}
        exit={{ opacity: 0, scale: 0, transition: { duration: 0.5 } }}
      >
        <div
          style={{
            opacity: 0,
            position: 'absolute',
            top: '80px',
            zIndex: 99999,
          }}
        >
          <GoogleLogin
            onSuccess={(response) => onSignInSuccess(response, openLoginModal)}
          />
        </div>
        <S.IconLogo />
        <S.LoginButton href="#">
          <S.IconGoogle />
          <span>Sign in with Google</span>
        </S.LoginButton>
      </S.LogoWrap>
    </S.LoginWrap>
  );
};
