import { ChainInfo } from '@safe-global/safe-gateway-typescript-sdk';
import { _getChainId } from 'features/Multisig/helpers';
import { computed } from 'mobx';

export default class ChainsStore {
  chains: ChainInfo[] = [];
  error?: string;
  loading = false;
  chainId = _getChainId();

  @computed get currentChain() {
    return this.chains.find(item => item.chainId === this.chainId);
  }

  @computed get findChainById() {
    return (chainId: string) => {
      return this.chains.find(item => item.chainId === chainId);
    };
  }
}
