import { useLocalStore } from 'mobx-react';
import { ChainInfo } from '@safe-global/safe-gateway-typescript-sdk';
import { _getChainId } from 'features/Multisig/helpers';
import ChainsStore from 'features/Multisig/mobx/ChainsStore';

export const useChains = (): { configs: ChainInfo[]; error?: string; loading?: boolean } => {
  const store = useLocalStore(() => new ChainsStore());

  return {
    configs: store.chains,
    error: store.error,
    loading: store.loading,
  };
};

export const useCurrentChain = (): ChainInfo | undefined => {
  const store = useLocalStore(() => new ChainsStore());
  const chainId = _getChainId();
  return store.findChainById(chainId);
};
