import { useState, useEffect } from 'react';
import { CurrencyType } from 'shared/types';
import { getCoinsInfoById } from 'shared/constants/coins';

import * as S from './styled';
import { SettingsStoreInstance } from 'services';

interface CurrencySearchProps {
  currencies: CurrencyType[];
  chosenCurrency: CurrencyType[];
  handleChangeCurrency: (currency: CurrencyType) => void;
  setChosenInSearch: React.Dispatch<React.SetStateAction<CurrencyType[]>>;
  chosenInSearch: CurrencyType[];
}

function CurrencySearch({
  currencies,
  chosenCurrency,
  handleChangeCurrency,
  setChosenInSearch,
  chosenInSearch,
}: CurrencySearchProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState('');
  const { appSettings } = SettingsStoreInstance;

  const filteredCurrencies = currencies.filter((availableCurrency) =>
    availableCurrency?.toLowerCase().includes(query?.toLowerCase()),
  );

  useEffect(() => {
    const newChosenInSearch = chosenCurrency.filter((currency) =>
      filteredCurrencies.includes(currency),
    );
    // Only update state if it has changed
    if (
      newChosenInSearch.length !== chosenInSearch.length ||
      newChosenInSearch.some((value, index) => value !== chosenInSearch[index])
    ) {
      setChosenInSearch(newChosenInSearch);
    }
  }, [chosenCurrency, setChosenInSearch, filteredCurrencies, chosenInSearch]);

  const renderCurrencyItem = (
    availableCurrency: CurrencyType,
    isActive: boolean,
  ) => (
    <S.CurrencyItem
      key={availableCurrency}
      onClick={() => {
        handleChangeCurrency(availableCurrency);
        if (!chosenCurrency.includes(availableCurrency)) {
          setChosenInSearch((oldChosenInSearch) => [
            ...oldChosenInSearch,
            availableCurrency,
          ]); // обновляем состояние chosenInSearch при выборе новой валюты
        } else {
          // Если валюта уже выбрана и снова кликается, она должна быть удалена из выбранных валют
          setChosenInSearch((oldChosenInSearch) =>
            oldChosenInSearch.filter(
              (currency) => currency !== availableCurrency,
            ),
          );
        }
      }}
      className={isActive ? 'active' : ''}
    >
      {getCoinsInfoById(availableCurrency, appSettings.currencies_icons)
        ?.logo_url && (
        <S.CurrencyLogo
          style={{
            backgroundImage: `url(${
              getCoinsInfoById(availableCurrency, appSettings.currencies_icons)
                ?.logo_url
            })`,
          }}
        />
      )}
      {availableCurrency}
    </S.CurrencyItem>
  );

  const chosenCurrencies = filteredCurrencies
    .filter((availableCurrency) => chosenCurrency.includes(availableCurrency))
    .map((availableCurrency) => renderCurrencyItem(availableCurrency, true));

  const nonChosenCurrencies = filteredCurrencies
    .filter((availableCurrency) => !chosenCurrency.includes(availableCurrency))
    .sort((a, b) => (a > b ? 1 : -1))
    .map((availableCurrency) => renderCurrencyItem(availableCurrency, false));

  const isShowDelimiter =
    chosenCurrencies.length > 0 && nonChosenCurrencies.length > 0;

  return (
    <S.CurrencySearchWrap>
      <S.HandlerButton onClick={() => setIsOpen(!isOpen)}>
        <S.ArrowIcon isopen={isOpen ? 'true' : undefined} />
      </S.HandlerButton>
      <S.SearchLogicWrap isOpen={isOpen}>
        <S.SearchForm>
          <S.IconSearch />
          <S.SearchInput
            type="text"
            name="search"
            onChange={(e) => setQuery(e.target.value)}
            value={query}
            placeholder="Search by name"
          />
          {query && <S.IconClear onClick={() => setQuery('')} />}
        </S.SearchForm>
        <S.CurrenciesList>
          <S.ChosenCurrency>{chosenCurrencies}</S.ChosenCurrency>
          {isShowDelimiter && <S.Delimiter />}
          <S.NonChosenCurrency>{nonChosenCurrencies}</S.NonChosenCurrency>
        </S.CurrenciesList>
      </S.SearchLogicWrap>
    </S.CurrencySearchWrap>
  );
}

export { CurrencySearch };
