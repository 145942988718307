import { Navigate, Route } from 'react-router-dom';
import { AuthStoreInstance, UserStoreInstance } from 'services';

// @ts-ignore
export const ProtectedRoute = ({ component: Component, ...rest }): Route => {
  const { user } = UserStoreInstance;
  const { appToken } = AuthStoreInstance;

  return (
    user && appToken ? <Component /> : <Navigate to="/" />
  );
};
