import { useToggle } from 'shared/hooks/useToggle';
import * as S from './AccountStyled';
import { AccountHeader } from './AccountHeader';
import {
  AccountType,
  ColumnSide,
  CurrencyType,
  ExchangeAccountsType,
  WalletType,
} from 'shared/types';
import { useTransfer } from 'features/Transfer/context/TransferContext';
import { SubAccountItem } from './SubAccountItem';
import { SMALL_BALANCE_VALUE } from 'features/Transfer/constants';
import {
  useAccounts,
  useIsOpen,
} from 'features/Transfer/context/AccountContext';

import { v4 as uuidv4 } from 'uuid';

interface AccountProps {
  account: AccountType;
  side: ColumnSide;
  exchange: ExchangeAccountsType;
}

export const Account = ({ account, side, exchange }: AccountProps) => {
  const { filters, fromAccountsIds, toAccountsIds, chosenCurrency, from, to } =
    useTransfer();
  const [opened, toggle] = useIsOpen(side, 'account', account?.id);
  const ids = side === 'from' ? fromAccountsIds : toAccountsIds;
  const chosenWallet = side === 'from' ? from?.wallet : to?.wallet;

  const subAccounts = exchange?.accounts
    ?.filter((acc: AccountType) =>
      filters.isHideSmallBalances && side !== 'to'
        ? ids?.includes(acc.id)
        : true,
    )
    .filter((acc) => acc.master_id === account.id);

  const hasSubAccounts = subAccounts.length > 0;
  const filteredCurrencies = account.wallets
    .filter((wallet: WalletType) =>
      filters.isHideSmallBalances &&
      side !== 'to' &&
      chosenWallet?.id !== wallet.id
        ? Number(wallet.available) >= SMALL_BALANCE_VALUE
        : true,
    )
    .map((wallet: WalletType) => wallet.currency as string);
  const isShowMain =
    filteredCurrencies.filter((currency) =>
      filters.currencies.includes(currency as CurrencyType),
    ).length > 0;

  return (
    <S.AccountWrap opened={opened}>
      <AccountHeader
        toggle={toggle}
        opened={opened}
        account={account}
        subAccounts={subAccounts}
      />
      {opened ? (
        <S.AccountsRow key={account.id}>
          {isShowMain ? (
            <SubAccountItem
              account={{ ...account, name: 'MAIN' }}
              side={side}
              hasSubAccounts={hasSubAccounts}
            />
          ) : null}
          {hasSubAccounts
            ? subAccounts.map((subAccount) => (
                <SubAccountItem
                  account={subAccount}
                  side={side}
                  hasSubAccounts={hasSubAccounts}
                />
              ))
            : null}
        </S.AccountsRow>
      ) : null}
    </S.AccountWrap>
  );
};
