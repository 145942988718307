// @ts-nocheck

import { _getChain, getTxAmount } from '../../../helpers';
import * as T from '../../styled/transactions';
import TokenPlaceholder from '../../../../Multisig/view/assets/token-placeholder.svg';

export const TxInfo = ({ tx }) => {
  const { txInfo } = tx;
  const { type } = txInfo;

  switch (type) {
    case 'Transfer': {
      const { nativeCurrency } = _getChain();
      const logoUri = txInfo.transferInfo.logoUri || nativeCurrency.logoUri;
      const sign = txInfo.direction === 'OUTGOING' ? '-' : '+';
      const amount = getTxAmount(txInfo);

      const handleImageError = (e) => {
        e.target.onerror = null;
        e.target.src = TokenPlaceholder;
      };

      return <T.ColValue>
        <T.CoinIcon src={logoUri} onError={handleImageError} />
        &nbsp;{sign}{amount}
      </T.ColValue>;
    }

    case 'Custom': {
      return <T.ColValue>{txInfo.methodName}</T.ColValue>;
    }

    default:
      return <T.ColValue></T.ColValue>;
  }
};
