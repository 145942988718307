import styled, { css } from 'styled-components';

import { ReactComponent as SvgDropdown } from 'shared/assets/icons/icon-dropdown.svg';
import { ReactComponent as SvgSearch } from 'shared/assets/icons/icon-search.svg';
import { ReactComponent as SvgXMark } from 'shared/assets/icons/icon-x-mark.svg';
import { ReactComponent as SvgInfo } from 'shared/assets/icons/icon-info-multik.svg';

export const Filters = styled.aside<{
  isMobile?: boolean;
  isAsideButtonChecked: string;
}>`
  width: 280px;
  grid-area: 1 / 3 / 6 / 4;
  align-self: start;
  top: 0px;
  padding: 0 0 0 30px;
  position: relative;

  ${(prop) =>
    prop.isAsideButtonChecked === 'OPEN' &&
    css`
      position: sticky;
      right: 0;
      width: 280px;
      background: var(--background);
      height: 100%;
      border-left: 1px solid var(--border);
    `}

  ${(prop) =>
    prop.isMobile &&
    css`
      height: 100%;
      width: 60px;
      border-right: 1px solid var(--border);
      border-left: 1px solid var(--border);
      padding: 0;
      background: var(--background);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      overflow: hidden;
      position: sticky;
      right: 0;
      padding: 40px 0;

      @media (min-width: 1800px) {
        position: sticky;
      }

      @media (max-width: 1110px) {
        position: fixed;
      }
    `}
`;

export const Filter = styled.div<{ isMobile?: boolean }>`
  width: 100%;
  margin: 0 0 20px 0;

  &:first-child {
    margin-top: 30px;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  ${(prop) =>
    prop.isMobile &&
    css`
      display: none;
      overflow: hidden;
    `}
`;

export const SearchForm = styled.div`
  position: relative;
  margin-bottom: 30px;
`;

export const CheckboxLabel = styled.label`
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`;

export const StatusLabel = styled.div`
  margin-bottom: 10px;
`;

export const FilterViewOptions = styled.div`
  margin-bottom: 30px;
`;

export const SearchInput = styled.input`
  padding: 10px 40px 10px 40px;
  background: none;
  font-size: 12px;
  border-radius: 5px;
  line-height: 20px;
  width: 100%;
  border: 1px solid var(--border);

  &:focus-visible,
  &:focus {
    outline: var(--accent-primary) solid 1px;
  }

  /* Autocomplete styles */
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active,
  &:-webkit-autofill::first-line {
    background: none !important;
    color: var(--object-primary);
  }

  color: var(--object-primary);
`;

export const IconSearch = styled(SvgSearch)`
  stroke: var(--placeholder);
  width: 25px;
  height: 25px;
  position: absolute;
  top: 45%;
  left: 8px;
  margin-top: -10px;
  pointer-events: none;
`;

export const IconClear = styled(SvgXMark)`
  position: absolute;
  right: 10px;
  top: 11px;
  cursor: pointer;
`;
// TODO: move styles
export const FilterWrap = styled.div<{ opened: boolean }>`
  background: rgba(255, 255, 255, 0);
  border-radius: 5px;
  position: relative;

  .form {
    display: none;
  }

  .filter_input {
    margin-bottom: 10px;
  }

  ${(prop) =>
    prop.opened &&
    css`
      border: var(--border);

      .dropdown {
        transform: rotate(180deg);
      }

      .form {
        display: block;
      }
    `}
`;

export const IconInfo = styled(SvgInfo)`
  width: 10px;
  height: 10px;
  outline: none;
`;

export const ChoosenItem = styled.div``;

export const FilterHr = styled.div`
  width: 100%;
  height: 1px;
  margin-bottom: 10px;
  background: var(--border);
`;

export const FilterDropdownToggle = styled.button`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 5px;
  right: 5px;
  border: none;
  background: none;
  transition: all 0.4s;

  &:focus {
    outline: none;
  }
`;

export const FilterDropdownIcon = styled(SvgDropdown)`
  width: 20px;
  stroke: var(--object-secondary);
`;

export const ExchangeItem = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

export const Cap = styled.span<{
  status?: string;
  tab?: string;
}>`
  display: block;

  ${(prop) =>
    prop.status === 'AWAITING CONFIRMATIONS' &&
    css`
      color: var(--warning);
    `}

  ${(prop) =>
    prop.status === 'AWAITING APPROVAL' &&
    css`
      color: var(--warning);
    `}
  ${(prop) =>
    prop.status === 'INITIATED' &&
    css`
      color: var(--warning);
    `}
  ${(prop) =>
    prop.status === 'UNCONFIRMED' &&
    css`
      color: var(--warning);
    `}
    
      ${(prop) =>
    prop.status === 'PROCESSING' &&
    css`
      color: var(--accent-primary);
    `}
    
      ${(prop) =>
    prop.status === 'CANCELED' &&
    css`
      color: var(--object-secondary);
    `}
    
      ${(prop) =>
    prop.status === 'CLOSED' &&
    css`
      color: var(--object-secondary);
    `}
    
      ${(prop) =>
    prop.status === 'REJECTED' &&
    css`
      color: var(--error);
    `}
    
      ${(prop) =>
    prop.status === 'APPROVED' &&
    css`
      color: var(--success);
    `}
    
      ${(prop) =>
    prop.status === 'COMPLETED' &&
    css`
      color: var(--success);
    `}
    
      ${(prop) =>
    prop.status === 'СONFIRMED' &&
    css`
      color: var(--accent-primary);
    `}
      ${(prop) =>
    prop.status === 'FAILED' &&
    css`
      color: var(--error);
    `}
`;
