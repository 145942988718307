import styled, { css } from 'styled-components/macro';

import { ReactComponent as SvgArrow } from 'shared/assets/icons/icon-chevron-down.svg';
import { ReactComponent as SvgLink } from 'shared/assets/icons/icon-link.svg';
import { ReactComponent as SvgGrid } from 'shared/assets/icons/icon-grid.svg';
import { ReactComponent as SvgCopy } from 'shared/assets/icons/icon-copy-stroke.svg';
import { ReactComponent as SvgExternal } from 'shared/assets/icons/icon-external-link.svg';
import { ReactComponent as SvgPlus } from 'shared/assets/icons/icon-plus-circle.svg';
import { ReactComponent as SvgMetaMusk } from 'shared/assets/icons/icon-metamusk.svg';
import { ReactComponent as SvgBookmark } from 'shared/assets/icons/icon-bookmark.svg';

export const Tools = styled.div`
  display: none;
  flex-direction: column;
`;
export const Header = styled.header`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: stretch;
  justify-content: space-between;
  background: var(--base);
  border: 1px solid var(--border);
  border-radius: 0px 0px 5px 5px;
  z-index: 3;
`;
export const MainSafe = styled.div`
  display: flex;
  padding: 0;
  position: relative;
`;
export const MainSafeItem = styled.div<{ current?: boolean }>`
  display: flex;
  padding: 15px 20px;
  position: relative;
  align-items: center;
  transition: background-color 0.4s;

  &.buttonwrap {
    &:hover {
      background-color: var(--base);
    }
  }

  &:hover ${Tools} {
    display: flex;
  }

  &:hover {
    background-color: var(--background);
  }

  ${(prop) =>
    prop.current &&
    css`
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 15px;
        bottom: 15px;
        width: 2px;
        background: var(--accent-primary);
      }
    `}
`;
export const MainSafeCurrentItem = styled(MainSafeItem)`
  z-index: 2;
  cursor: pointer;
  box-sizing: border-box;
  width: 20vw;
  max-width: 220px;
  min-width: 180px;
  border-right: 1px solid var(--border);
  &:before {
    display: none;
  }
  border-bottom: ${({ current }) =>
    current ? '2px solid var(--accent-primary)' : '2px solid transparent'};
`;
export const MainSafeDropdownIcon = styled.div`
  display: flex;
  padding: 15px 20px;
  z-index: 2;
  cursor: pointer;
  transition: background-color 0.4s;
  width: 40px;
  padding: 0;
  align-items: center;
  border-right: 1px solid var(--border);
  &:hover {
    background-color: var(--background);
  }

  ${Tools} {
    display: flex;
    justify-content: space-between;
  }
`;
export const WalletDropdown = styled.div`
  width: 300px;
  position: absolute;
  top: 0;
  right: -1px;
  background: var(--base);
  border: 1px solid var(--border);
  border-top: none;
  border-radius: 0px 0px 5px 5px;
  padding: 70px 0 0 0;
  z-index: 1;

  ${MainSafeItem} {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 1px;
      background: var(--border);
      pointer-events: none;
    }
  }
`;
export const AddressTools = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50px;
`;
export const Dropdown = styled.div`
  width: calc(20vw + 40px);
  max-width: 260px;
  min-width: 220px;
  position: absolute;
  top: 0;
  right: 0;
  background: var(--base);
  border: 1px solid var(--border);
  border-radius: 0px 0px 5px 5px;
  padding: 70px 0 0 0;
  z-index: 1;

  ${MainSafeItem} {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 1px;
      background: var(--border);
      pointer-events: none;
    }
  }
`;
export const MainSafeWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  max-width: calc(100% - 50px);
  padding-left: 10px;
`;
export const MainSafeName = styled.div`
  font-size: 10px;
  line-height: 12px;
  width: 100%;
`;
export const MainSafeAddress = styled.div`
  font-size: 10px;
  line-height: 13px;
  width: 100%;
  color: var(--object-secondary);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
export const MainSafeBalance = styled.div`
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
  text-transform: uppercase;
`;
export const Button = styled.button`
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  background-color: var(--accent-primary);
  color: var(--dark-bg);
  padding: 13px 40px;
  font-weight: 700;
  width: 100%;
  border-radius: 20px;
  cursor: pointer;
  position: relative;
  transition: 0.4s;

  &:hover {
    background: var(--object-primary);
    color: var(--base);
  }
  ${(prop) =>
    prop.disabled &&
    css`
      background: var(--placeholder);
      color: var(--dark-bg);
      pointer-events: none;
    `}
`;
export const LoadMoreButton = styled(Button)`
  width: 200px;
  padding: 5px 15px;
  font-size: 10px;
`;
export const MainSafeButton = styled(Button)``;
export const DisconnectButton = styled(Button)`
  background-color: var(--error);
  &:hover {
    background: var(--warning);
    color: var(--dark-bg);
  }
`;
export const Wallet = styled.div`
  display: flex;
  padding: 15px 20px;
  align-items: center;
  position: relative;
  width: 220px;

  ${Dropdown} {
    background-color: var(--highlight);
  }
  @media (min-width: 1100px) {
    width: 300px;
  }
`;
export const WalletWrap = styled.div`
  position: relative;
  z-index: 3;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  max-width: 100%;
  min-width: 0;
`;
export const WalletInfoWrap = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  overflow: hidden;
`;
export const WalletAvatar = styled.div`
  width: 40px;
  min-width: 40px;
  height: 40px;
  margin: 0 10px 0 0;
  border-radius: 20px;
  background-color: var(--background);
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
`;
export const WalletName = styled.div`
  font-size: 10px;
  line-height: 12px;
  width: 100%;
  max-width: 280px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 1160px) {
    max-width: 100px;
  }
`;
export const WalletConnect = styled.div`
  font-size: 12px;
  font-weight: bold;
  line-height: 14px;
  width: 100%;
  color: var(--accent-primary);
`;
export const WalletDropdownWrap = styled.div`
  padding: 0 20px;
`;
export const WalletBody = styled.div`
  padding: 20px 0;
  border-top: 1px solid var(--border);
`;
export const WalletFooter = styled.footer`
  padding: 20px 0;
  border-top: 1px solid var(--border);
`;
export const IconMetaMusk = styled(SvgMetaMusk)`
  width: 40px;
  height: 40px;
  margin: 0 10px 0 0;
`;
export const WalletConneted = styled.div`
  display: flex;
  align-items: center;
  min-width: 0;
`;
export const WalletConnetedLogo = styled.div`
  margin: 0 0 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const WalletConnetedTools = styled.div`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  background-color: var(--background);
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const WalletConnetedAddress = styled.div`
  font-size: 12px;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;
export const WalletConnetedRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 3px 0;
  margin: 2px 0 0 0;
`;
export const WalletConnetedRowLabel = styled.span`
  font-size: 10px;
  flex: 0 0 50%;
  font-weight: 100;
  color: var(--object-secondary);
`;
export const WalletConnetedRowValue = styled.span`
  font-size: 10px;
  flex: 0 0 50%;
  font-weight: 700;
  text-align: right;
`;
export const WalletAddressWrap = styled.div`
  overflow: hidden;
  display: flex;
  width: 100%;
  margin: 5px 0 0 0;
`;
export const WalletAddressLogo = styled.div`
  margin: 0 5px 0 0;
`;
export const WalletAddress = styled.div`
  overflow: hidden;
  flex: 1;
  font-size: 12px;
  font-weight: 700;
  line-height: 10px;
  text-overflow: ellipsis;
  padding: 0 0 0 5px;
`;
export const ArrowWrap = styled.button<{ opened?: boolean }>`
  background: none;
  border: none;
  cursor: pointer;
  width: 20px;
  height: 20px;
  transition: all 0.2s;
  color: var(--object-secondary);
  margin: 0 0 0 10px;
  cursor: pointer;

  ${(prop) =>
    prop.opened &&
    css`
      transform: rotate(180deg);
    `}
`;
export const IconStyle = css`
  width: 20px;
  height: 20px;
  stroke: currentColor;
  transition: 0.4s;

  &:hover {
    stroke: var(--object-primary);
  }
`;
export const IconBookmark = styled(SvgBookmark)`
  ${IconStyle}
  width: 16px;
  height: 16px;
`;
export const IconArrow = styled(SvgArrow)`
  ${IconStyle}
`;
export const IconGrid = styled(SvgGrid)`
  ${IconStyle}
`;
export const IconCopy = styled(SvgCopy)`
  ${IconStyle}
  width: 16px;
  height: 16px;
`;
export const IconExternal = styled(SvgExternal)`
  ${IconStyle}
`;
export const IconLink = styled(SvgLink)`
  width: 20px;
  height: 20px;
  stroke: var(--object-secondary);
`;
export const IconPlus = styled(SvgPlus)`
  width: 20px;
  height: 20px;
  stroke: currentColor;
  position: absolute;
  top: 50%;
  left: 10px;
  margin-top: -10px;
`;
export const TransactionButtonWrap = styled.div`
  padding: 15px;
  white-space: nowrap;

  @media (max-width: 1290px) {
    button {
      padding: 13px 15px;
      font-size: 10px;
    }
  }

  @media (max-width: 1430px) {
    button {
      padding: 13px 30px;
      font-size: 12px;
    }
  }
`;
