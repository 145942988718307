import type { SafeAppData } from '@safe-global/safe-gateway-typescript-sdk';

import * as S from '../../styled';

const SafeAppsModalLabel = ({ app }: { app?: SafeAppData }) => {
  if (!app) {
    return <S.ModalLabel>Safe Apps Transaction</S.ModalLabel>;
  }

  return (
    <S.ModalLabel>{app.name}</S.ModalLabel>
  );
};

export default SafeAppsModalLabel;
