import { ReactElement, ReactNode, SyntheticEvent, useEffect, useState } from 'react';
import { Button, DialogContent, Typography } from '@mui/material';
import type { SafeTransaction } from '@safe-global/safe-core-sdk-types';

import useTxSender from '../hooks/useTxSender';
import useGasLimit from '../hooks/useGasLimit';
import useSafeInfo from 'features/SafeApp/hooks/useSafeInfo';
import ErrorMessage from '../ErrorMessage';
import { shouldUseEthSignMethod } from 'features/SafeApp/constants/wallets';
import ExecuteCheckbox from '../ExecuteCheckbox';
import { logError, Errors } from 'shared/helpers/exceptions';
import { ConnectedWallet } from 'features/SafeApp/helpers/onboard';
import { useCurrentChain } from 'features/SafeApp/hooks/useChains';
import { getTxOptions } from '../utils/transactions';
import { Web3Provider } from '@ethersproject/providers';
import useIsWrongChain from 'features/SafeApp/hooks/useIsWrongChain';
import useIsSafeOwner from 'features/SafeApp/hooks/useIsSafeOwner';
import { sameString } from '@safe-global/safe-core-sdk/dist/src/utils';
import useIsValidExecution from 'features/SafeApp/hooks/useIsValidExecution';
import { useAdvancedParams } from '../AdvancedParams/useAdvancedParams';
import { SafeStoreInstance } from 'features/Multisig';

import { _getChainId } from 'features/Multisig/helpers';
import { getWeb3ReactContext } from '@web3-react/core';
import { checksumAddress } from 'features/Multisig/helpers';

import { useTxStepper, TxStepperProps } from 'features/SafeApp/hooks/useTxStepper';

import { AdvancedParameters } from '../AdvancedParams';
import AdvancedParams from '../AdvancedParams';

import { Step } from 'features/SafeApp/hooks/useTxStepper';

import * as S from '../../styled';

type SignOrExecuteProps = {
  safeTx?: SafeTransaction
  txId?: string
  onSubmit: (txId?: string) => void
  children?: ReactNode
  error?: Error
  isExecutable?: boolean
  isRejection?: boolean
  onlyExecute?: boolean
  disableSubmit?: boolean
  origin?: string
  onClose: () => void
};

const SignOrExecuteForm = ({
  safeTx,
  txId,
  onlyExecute,
  onSubmit,
  onClose,
  children,
  isExecutable = false,
  isRejection = false,
  disableSubmit = false,
  origin,
  ...props
}: SignOrExecuteProps): ReactElement => {
  //
  // Hooks & variables
  //
  const [shouldExecute, setShouldExecute] = useState<boolean>(true);
  const [isSubmittable, setIsSubmittable] = useState<boolean>(true);
  const [tx, setTx] = useState<SafeTransaction | undefined>(safeTx);
  const [submitError, setSubmitError] = useState<Error | undefined>();
  const { wallet, userAccount } = SafeStoreInstance;
  const { safe, safeAddress } = useSafeInfo();
  const isWrongChain = useIsWrongChain();
  const isOwner = useIsSafeOwner();
  const web3 = getWeb3ReactContext();

  const provider = web3.Provider;

  const currentChain = useCurrentChain();

  const { createTx, dispatchTxProposal, dispatchOnChainSigning, dispatchTxSigning, dispatchTxExecution } = useTxSender();

  // Check that the transaction is executable
  const isNewExecutableTx = !txId && safe.threshold === 1;
  const isCorrectNonce = tx?.data.nonce === safe.nonce;
  const canExecute = isCorrectNonce && (isExecutable || isNewExecutableTx);

  // If checkbox is checked and the transaction is executable, execute it, otherwise sign it
  const willExecute = shouldExecute && canExecute;

  // Synchronize the tx with the safeTx
  useEffect(() => setTx(safeTx), [safeTx]);

  // Estimate gas limit
  const { gasLimit, gasLimitError, gasLimitLoading } = useGasLimit(willExecute ? tx : undefined);

  // Check if transaction will fail
  const { executionValidationError, isValidExecutionLoading } = useIsValidExecution(
    willExecute ? tx : undefined,
    gasLimit,
  );

  const [advancedParams, setAdvancedParams] = useAdvancedParams({
    nonce: tx?.data.nonce,
    gasLimit,
    safeTxGas: tx?.data.safeTxGas,
  });

  // Estimating gas
  const isEstimating = willExecute && gasLimitLoading;
  // Nonce cannot be edited if the tx is already proposed, or signed, or it's a rejection
  const nonceReadonly = !!txId || !!tx?.signatures.size || isRejection;

  // Assert that wallet, tx and provider are defined
  const assertDependencies = (): [ConnectedWallet, SafeTransaction, Web3Provider] => {
    if (!wallet) throw new Error('Wallet not connected');
    if (!tx) throw new Error('Transaction not ready');
    if (!provider) throw new Error('Provider not ready');
    // @ts-ignore
    return [wallet, tx, provider];
  };

  // Propose transaction if no txId
  const proposeTx = async (newTx: SafeTransaction): Promise<string> => {
    const proposedTx = await dispatchTxProposal({
      chainId: safe.chainId,
      safeAddress,
      sender: checksumAddress(userAccount!),
      safeTx: newTx,
      txId,
      origin,
    });

    /**
     * We need to handle this case because of the way useTxSender is designed,
     * but it should never happen here because this function is explicitly called
     * through a user interaction
     */
    if (!proposedTx) {
      throw new Error('Could not propose transaction');
    }

    return proposedTx.txId;
  };

  // Sign transaction
  const onSign = async (): Promise<string | undefined> => {
    const [wallet, createdTx, provider] = assertDependencies();

    /* // Smart contract wallets must sign via an on-chain tx
    if (await isSmartContractWallet(wallet)) {
      // If the first signature is a smart contract wallet, we have to propose w/o signatures
      // Otherwise the backend won't pick up the tx
      // The signature will be added once the on-chain signature is indexed
      const id = txId || (await proposeTx(createdTx));
      await dispatchOnChainSigning(createdTx, provider, id);
      return id;
    } */

    // Otherwise, sign off-chain
    const shouldEthSign = shouldUseEthSignMethod(wallet);
    const signedTx = await dispatchTxSigning(createdTx, shouldEthSign, txId);

    /**
     * We need to handle this case because of the way useTxSender is designed,
     * but it should never happen here because this function is explicitly called
     * through a user interaction
     */
    if (!signedTx) {
      throw new Error('Could not sign transaction');
    }

    return await proposeTx(signedTx);
  };

  // Execute transaction
  const onExecute = async (): Promise<string | undefined> => {
    const [, createdTx, provider] = assertDependencies();

    // If no txId was provided, it's an immediate execution of a new tx
    const txOptions = getTxOptions(advancedParams, currentChain);

    await dispatchTxExecution(createdTx, provider, txOptions, txId);

    return txId;
  };

  // On modal submit
  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    setIsSubmittable(false);
    setSubmitError(undefined);

    let id: string | undefined;
    try {
      id = await (willExecute ? onExecute() : onSign());
    } catch (err) {
      logError(Errors._804, (err as Error).message);
      setIsSubmittable(true);
      setSubmitError(err as Error);
      return;
    }

    onSubmit(id);
  };

  // On advanced params submit (nonce, gas limit, price, etc)
  const onAdvancedSubmit = async (data: AdvancedParameters) => {
    // If nonce was edited, create a new tx with that nonce
    if (tx && (data.nonce !== tx.data.nonce || data.safeTxGas !== tx.data.safeTxGas)) {
      try {
        setTx(await createTx({ ...tx.data, safeTxGas: data.safeTxGas }, data.nonce));
      } catch (err) {
        logError(Errors._103, (err as Error).message);
        return;
      }
    }

    setAdvancedParams(data);
  };

  const isExecutionLoop = wallet ? sameString(wallet.provider.selectedAddress, safeAddress) : false; // Can't execute own transaction
  const cannotPropose = !isOwner && !onlyExecute; // Can't sign or create a tx if not an owner
  const submitDisabled =
    !isSubmittable ||
    isEstimating ||
    !tx ||
    disableSubmit ||
    isWrongChain ||
    cannotPropose ||
    isExecutionLoop ||
    isValidExecutionLoading;

  const error = props.error || (willExecute ? gasLimitError || executionValidationError : undefined);

  return (
    <form onSubmit={handleSubmit}>
      <S.Dialog>
        {children}
        {canExecute && <ExecuteCheckbox checked={shouldExecute} onChange={setShouldExecute} disabled={onlyExecute} />}

        <AdvancedParams
          params={advancedParams}
          recommendedGasLimit={gasLimit}
          recommendedNonce={safeTx?.data.nonce}
          willExecute={willExecute}
          nonceReadonly={nonceReadonly}
          onFormSubmit={onAdvancedSubmit}
          gasLimitError={gasLimitError}
        />

        {/* Error messages */}
        {isWrongChain ? (
          <ErrorMessage>Your wallet is connected to the wrong chain.</ErrorMessage>
        ) : cannotPropose ? (
          <ErrorMessage>
            You are currently not an owner of this Safe and won&apos;t be able to submit this transaction.
          </ErrorMessage>
        ) : isExecutionLoop ? (
          <ErrorMessage>
            Cannot execute a transaction from the Safe itself, please connect a different account.
          </ErrorMessage>
        ) : error ? (
          <ErrorMessage error={error}>
            This transaction will most likely fail.{' '}
            {isNewExecutableTx
              ? 'To save gas costs, avoid creating the transaction.'
              : 'To save gas costs, reject this transaction.'}
          </ErrorMessage>
        ) : submitError ? (
          <ErrorMessage error={submitError}>Error submitting the transaction. Please try again.</ErrorMessage>
        ) : null}

        <S.Bottom>
          <S.BottomText>
            You&apos;re about to {txId ? '' : 'create and '}
            {willExecute ? 'execute' : 'sign'} a transaction and will need to confirm it with your currently connected
            wallet.
          </S.BottomText>

          <S.Buttons>
            <S.CancelButton color="inherit" onClick={() => onClose()}>
              Cancel
            </S.CancelButton>
            <S.SignButton type="submit" disabled={submitDisabled}>
              {isEstimating ? 'Estimating...' : 'Submit'}
            </S.SignButton>
          </S.Buttons>

        </S.Bottom>

      </S.Dialog>
    </form>
  );
};

export default SignOrExecuteForm;
