import { ProviderLabel } from '@web3-onboard/injected-wallets';
import { EthersError } from './ethers-utils';
import { ErrorCode } from '@ethersproject/logger';

const isWCRejection = (err: Error): boolean => {
  return /rejected/.test(err?.message);
};

const isEthersRejection = (err: EthersError): boolean => {
  return err.code === ErrorCode.ACTION_REJECTED;
};

export const isWalletRejection = (err: EthersError): boolean => {
  return isEthersRejection(err) || isWCRejection(err);
};

export const WalletNames = {
  METAMASK: ProviderLabel.MetaMask,
  WALLET_CONNECT: 'WalletConnect',
};

/* Check if the wallet is unlocked. */
export const isWalletUnlocked = async (walletName: string): Promise<boolean> => {
  if (typeof window === 'undefined') return false;

  // Only MetaMask exposes a method to check if the wallet is unlocked
  if (walletName === WalletNames.METAMASK) {
    return window.ethereum?.isMetaMask || false;
  }

  // Wallet connect creates a localStorage entry when connected and removes it when disconnected
  if (walletName === WalletNames.WALLET_CONNECT) {
    return window.localStorage.getItem('walletconnect') !== null;
  }

  return false;
};
