import Tippy from '@tippyjs/react';
import { CurrencyType, Thresholds } from 'shared/types';
import { LimitIcon } from '../../components/SubAccount/styled';

import { generateThresholdExceededMessage } from 'features/Transfer/functions';

interface AccountViolationsProps {
  violatedCurrencies: CurrencyType[];
  thresholds: Thresholds;
}

export const AccountViolations = ({
  violatedCurrencies,
  thresholds,
}: AccountViolationsProps) => {
  const data = generateThresholdExceededMessage(thresholds);

  return (
    <>
      {violatedCurrencies && violatedCurrencies.length ? (
        <Tippy theme="transparent" content={<>{data}</>}>
          <LimitIcon />
        </Tippy>
      ) : null}
    </>
  );
};
