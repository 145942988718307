import { format, isToday, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import * as S from './AccountStyled';

const balanceTimeFormat = 'HH:mm';
const balanceDayFormat = 'dd.MM.yyyy';

interface AccountBalanceStatus {
  hasSubAccounts: boolean;
  balanceUpdatedAt: string;
  isBalanceOutdated: boolean;
}

export const AccountBalanceStatus = ({
  hasSubAccounts,
  balanceUpdatedAt,
  isBalanceOutdated,
}: AccountBalanceStatus) => {
  const isTodayDate = isToday(new Date(String(balanceUpdatedAt)));
  const currentBalanceFormat = !isTodayDate
    ? balanceDayFormat
    : balanceTimeFormat;
  const formattedBalanceDate = balanceUpdatedAt
    ? format(
        utcToZonedTime(parseISO(balanceUpdatedAt), 'UTC'),
        currentBalanceFormat,
      )
    : '';

  return (
    <>
      {!hasSubAccounts ? (
        <S.AccountBalanceStatus>
          {isBalanceOutdated || !balanceUpdatedAt ? (
            <S.IconBalanceWarning />
          ) : null}
          <S.AccountBalanceUpdated
            isOutdated={isBalanceOutdated || !balanceUpdatedAt}
          >
            {balanceUpdatedAt ? (
              <span>
                {formattedBalanceDate} {isTodayDate && 'UTC'}
              </span>
            ) : (
              <span>Never updated</span>
            )}
          </S.AccountBalanceUpdated>
        </S.AccountBalanceStatus>
      ) : null}
    </>
  );
};
