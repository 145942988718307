import styled from 'styled-components/macro';

const Wrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  min-height: 90vh;
`;

function NoAccess() {
  return <Wrap>You don't have access to this page</Wrap>;
}

export { NoAccess };
