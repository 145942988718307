import type { ReactElement } from 'react';
import { useController, useFormContext, useWatch } from 'react-hook-form';
import { IconButton, InputAdornment, MenuItem, Tooltip } from '@mui/material';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import useSafeInfo from 'features/SafeApp/hooks/useSafeInfo';
import NumberField from '../NumberField';

type NonceFormProps = {
  name: string
  nonce: number
  recommendedNonce?: number
  readonly?: boolean
};

const NonceForm = ({ name, nonce, recommendedNonce, readonly }: NonceFormProps): ReactElement => {
  const { safe } = useSafeInfo();
  const safeNonce = safe.nonce || 0;

  // Initialise form field
  const { setValue, control } = useFormContext() || {};
  const {
    field: { ref, onBlur, onChange, value },
    fieldState,
  } = useController({
    name,
    control,
    defaultValue: nonce,
    rules: {
      required: true,
      validate: (val: number) => {
        if (!Number.isInteger(val)) {
          return 'Nonce must be an integer';
        } else if (val < safeNonce) {
          return `Nonce can't be lower than ${safeNonce}`;
        }
      },
    },
  });

  // Warn about a higher nonce
  const editableNonce = useWatch({ name, control, exact: true });
  const nonceWarning =
    recommendedNonce != null && editableNonce > recommendedNonce ? `Recommended nonce is ${recommendedNonce}` : '';
  const label = fieldState.error?.message || nonceWarning || 'Safe transaction nonce';

  const onResetNonce = () => {
    if (recommendedNonce != null) {
      setValue(name, recommendedNonce, { shouldValidate: true });
    }
  };

  return (
    <NumberField
      name={name}
      onBlur={onBlur}
      inputRef={ref}
      error={!!fieldState.error}
      label={label}
      value={value}
      onChange={(valueInput) => {
        onChange(valueInput.target.value ? Number(valueInput.target.value) : '');
      }}
      InputProps={{
        endAdornment: !readonly &&
          recommendedNonce !== undefined && (
            <InputAdornment position="end">
              <Tooltip title="Reset to recommended nonce">
                <IconButton onClick={onResetNonce} size="small" color="primary">
                  <RotateLeftIcon />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
        readOnly: readonly,
      }}
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
};

export default NonceForm;
