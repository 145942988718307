import { ReactElement } from 'react';

import { Typography } from '@mui/material';

import PrefixedEthHashInfo from '../EthHashInfo';
import { HexEncodedData } from '../HexEncodedData';
import Copy from 'shared/components/Copy';
import { hexDataLength } from 'ethers/lib/utils';

import * as S from './styled';

type TxDataRowProps = {
  title: string
  children?: React.ReactNode
};

const TxDataRow: React.FC<{ title: string, children?: React.ReactNode }> = ({ title, children }) => {
  if (children == undefined) return null;
  return (
    <S.GridRow>
      <S.Title>{title}</S.Title>
      <S.ValueWrapper>{children}</S.ValueWrapper>
    </S.GridRow>
  );
};

const generateDataRowValue = (
  value?: string,
  type?: 'hash' | 'rawData' | 'address' | 'bytes',
  hasExplorer?: boolean,
): ReactElement | null => {
  if (value == undefined) return null;
  switch (type) {
    case 'hash':
    case 'address':
      return <PrefixedEthHashInfo address={value} hasExplorer={hasExplorer} showAvatar={false} showCopyButton />;
    case 'rawData':
      return (
        <S.RawData>
          <div>{value ? hexDataLength(value) : 0} bytes</div>
          <Copy text={value} />
        </S.RawData>
      );
    case 'bytes':
      return <HexEncodedData limit={60} hexData={value} />;
    default:
      return <Typography sx={{ wordBreak: 'break-all' }}>{value}</Typography>;
  }
};

export { TxDataRow, generateDataRowValue };
