// @ts-nocheck
import * as T from './styled/transactions';

import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { TransactionRow } from './components/Transactions';
import { MultisigTransactionsStoreInstance } from '../mobx/MultisigTransactionsStore';
import { Loader, Placeholder } from 'shared/components';
import { Logo } from 'shared/components/Loader/styled';
import { LoadMoreButton } from './styled/header';

import { SafeStoreInstance } from '../mobx/SafeStore';

const getLabel = ({ type, label, nonce }) => {
  switch (type) {
    case 'LABEL':
      return label === 'Next'
        ? 'Next transaction'
        : label === 'Queued'
        ? 'Queued transactions'
        : label;
    case 'CONFLICT_HEADER':
      return `These transactions conflict as they use the same nonce (${nonce}). Executing one will automatically replace the other(s)`;
  }
};

const LabelRow = ({ item }) => <T.Next>{getLabel(item)}</T.Next>;

const TransactionsQueue = observer(() => {
  const {
    getTransactionQueue,
    transactionQueue,
    nextQueue,
    fetchMoreTransactionQueue,
    fetchingMoreQueue,
    resetPagination,
    isLoadingQueue,
    errors,
    initiallyLoadedQueue,
  } = MultisigTransactionsStoreInstance;

  const { selectedSafe, isSelectedSafeLoading } = SafeStoreInstance;

  useEffect(() => {
    resetPagination();
    getTransactionQueue(true);

    const interval = setInterval(() => getTransactionQueue(), 10000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <T.Wrap
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      exit={{ opacity: 0, transition: { duration: 0.5 } }}
    >
      {!isLoadingQueue && transactionQueue?.length ? (
        <T.Table>
          {transactionQueue?.map((item, i) =>
            item.type === 'TRANSACTION' ? (
              <T.Row key={item.transaction.id}>
                <TransactionRow
                  error={errors[item.transaction.id]}
                  item={item}
                />
              </T.Row>
            ) : (
              <T.Row key={i}>
                <LabelRow item={item} />
              </T.Row>
            ),
          )}
          <div
            style={{
              marginTop: '16px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {nextQueue && !fetchingMoreQueue && (
              <LoadMoreButton onClick={fetchMoreTransactionQueue}>
                Load more
              </LoadMoreButton>
            )}
            {fetchingMoreQueue && (
              <div>
                <Logo
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1, transition: { duration: 0.5 } }}
                  exit={{ opacity: 0, transition: { duration: 0.5 } }}
                />
              </div>
            )}
          </div>
        </T.Table>
      ) : isSelectedSafeLoading || isLoadingQueue ? (
        <Loader />
      ) : initiallyLoadedQueue ? (
        <Placeholder height="140px" text="No queue transactions found" />
      ) : null}
    </T.Wrap>
  );
});

export default TransactionsQueue;
