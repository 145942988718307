import { useQuery } from '@tanstack/react-query';
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useFilters } from 'shared/hooks/useFilters';
import { requestGet } from 'shared/axios';
import _ from 'lodash';
import { Position } from 'features/Positions/types';
const nest = (
  seq: any[],
  keys: Array<string | ((obj: any) => string)>,
): any => {
  if (!keys.length) {
    return seq;
  }
  const [first, ...rest] = keys;
  return _.mapValues(_.groupBy(seq, first), (value) => nest(value, rest));
};

export const stringifyUrl = (url: string, filters: any) => {
  return queryString.stringifyUrl(
    {
      url,
      query: {
        ...filters,
      },
    },
    { arrayFormat: 'separator' },
  );
};
const parseFilters = ({ ...rest }: any) => {
  return {
    ...rest,
  };
};

const getPositions = async (filters: any) => {
  return await requestGet(
    stringifyUrl('/positions/', { ...filters, status: 'OPEN' }),
  );
};

export const usePositions = (search: string) => {
  const [getFilters] = useFilters('positions_filters');
  const location = useLocation();
  const navigate = useNavigate();
  const { data, ...rest } = useQuery(
    ['positions'],
    async () => {
      const filters = getFilters();
      const parsedFilters = parseFilters(filters);

      navigate({
        pathname: location.pathname,
        search: queryString.stringify(
          { ...filters },
          {
            arrayFormat: 'separator',
          },
        ),
      });

      const { data } = await getPositions(parsedFilters);
      return data;
    },
    {
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      refetchInterval: 15000,
    },
  );

  const grouped = nest(
    data?.results
      ?.map((item: Position) => ({ ...item, ...item.account }))
      .filter((item: Position) =>
        item.pair?.toLowerCase().includes(search?.toLowerCase()),
      )
      .sort((a: Position, b: Position) => a.pair.localeCompare(b.pair)),
    ['exchange', 'name'],
  );
  // TODO: grouping

  return {
    ...rest,
    rows: [],
    data: grouped,
  };
};
