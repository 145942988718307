import { useFilters } from 'shared/hooks/useFilters';
import {
  Label,
  LabelWrap,
  FilterContent,
  Checkbox,
} from 'shared/components/FilterAbstract/FilterAbstract';
import { useState, useMemo, useCallback } from 'react';

import * as S from './styled';
import _ from 'lodash';
import Tippy from '@tippyjs/react';

interface FilterViewOptionsProps extends FiltersProps {
  entities: any[];
  label: string;
  field: string;
  entity: string;
  fieldIdFn: FieldGetter;
  fieldNameFn: FieldGetter;
}

type FieldGetter = (item: any) => any;

interface ViewOptionItemProps {
  entity: any;
  field: string;
  onChange: any;
  info: string;
  checked: boolean;
  fieldIdFn: FieldGetter;
  fieldNameFn: FieldGetter;
}

const ViewOptionItem = ({
  entity,
  field,
  onChange,
  info,
  checked,
  fieldIdFn,
  fieldNameFn,
}: ViewOptionItemProps) => {
  const id = useMemo(() => fieldIdFn(entity), [entity, fieldIdFn]);
  const name = useMemo(() => fieldNameFn(entity), [entity, fieldNameFn]);

  return (
    <S.CheckboxLabel key={id}>
      <Checkbox
        checked={checked}
        onChange={onChange}
        type="checkbox"
        name={field}
        title={name}
        value={id}
      />

      <span>{name}</span>
      <Tippy theme="transparent" placement="auto" content={info}>
        <S.IconInfo />
      </Tippy>
    </S.CheckboxLabel>
  );
};

export const parseValues = (
  statuses: any[] = [],
  action: boolean,
  value: any,
) => {
  const clone = _.cloneDeep(statuses);

  if (action) {
    clone.push(value);
  } else {
    const index = clone.findIndex((status) => status === value);
    if (index !== -1) {
      clone.splice(index, 1);
    }
  }

  return _.uniq(clone);
};

export const FilterViewOptions = ({
  entities,
  label,
  field,
  storageKey,
  fieldIdFn,
  fieldNameFn,
  onSubmit,
}: FilterViewOptionsProps) => {
  const [getFilters, setFilters] = useFilters(storageKey);
  const [isInternal, setIsInternal] = useState(getFilters()[field]);
  const [values, setValues] = useState(() => {
    if (isInternal === 'false') {
      return ['blockchain_transactions'];
    } else if (isInternal === undefined) {
      if (localStorage.getItem('view_options_both_selected') === 'true') {
        return ['blockchain_transactions', 'offchain_transactions'];
      } else {
        return [];
      }
    } else {
      return ['offchain_transactions'];
    }
  });

  const onChange = useCallback(
    (e: any) => {
      const newValues = parseValues(values, e.target.checked, e.target.value);
      const blockchainTx = newValues?.includes('blockchain_transactions');
      const offchainTx = newValues?.includes('offchain_transactions');

      if ((blockchainTx && offchainTx) || (!blockchainTx && !offchainTx)) {
        if (blockchainTx && offchainTx) {
          localStorage.setItem('view_options_both_selected', 'true');
        } else {
          localStorage.setItem('view_options_both_selected', 'false');
        }
        setFilters({
          [field]: undefined,
        });
        setIsInternal(undefined);
      } else if (blockchainTx && !offchainTx) {
        setFilters({
          [field]: 'false',
        });
        setIsInternal('false');
      } else if (!blockchainTx && offchainTx) {
        setFilters({
          [field]: true,
        });
        setIsInternal(true);
      }

      setValues(newValues);
      onSubmit();
    },
    [isInternal, field],
  );

  return (
    <S.FilterViewOptions>
      <LabelWrap>
        <Label>{label}</Label>
      </LabelWrap>

      <S.FilterWrap opened={true}>
        <FilterContent className="filter_content">
          {entities?.length > 0 && (
            <S.ChoosenItem>
              {entities.map((entity: any) => (
                <ViewOptionItem
                  info={entity.info}
                  key={fieldIdFn(entity)}
                  onChange={onChange}
                  checked={values?.includes(String(fieldIdFn(entity)))}
                  entity={entity}
                  field={field}
                  fieldIdFn={fieldIdFn}
                  fieldNameFn={fieldNameFn}
                />
              ))}
            </S.ChoosenItem>
          )}
        </FilterContent>
      </S.FilterWrap>
    </S.FilterViewOptions>
  );
};
