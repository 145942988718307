import { useQuery } from '@tanstack/react-query';
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useFilters } from 'shared/hooks/useFilters';
import { requestGet } from 'shared/axios';
import { capitalize } from 'shared/helpers/format';

export const stringifyUrl = (url: string, filters: any) => {
  return queryString.stringifyUrl(
    {
      url,
      query: {
        ...filters,
      },
    },
    { arrayFormat: 'separator' },
  );
};

const getWhitelists = async (filters: any, tab: WhitelistTab) => {
  return await requestGet(stringifyUrl('/whitelist/grouped/', filters));
};

type WhitelistTab = 'whitelists' | 'unbound';

export const useWhitelists = (tab: WhitelistTab) => {
  const [getFilters] = useFilters('whitelists_filters');
  const location = useLocation();
  const navigate = useNavigate();
  const query = useQuery(
    [`whitelists_${tab}`],
    async () => {
      const filters = getFilters();
      const is_unbound = tab === 'unbound';

      navigate({
        pathname: location.pathname,
        search: queryString.stringify(
          {
            ...filters,
            is_unbound,
            is_whitelisted:
              tab === 'whitelists' ? filters.is_whitelisted : undefined,
            exchange: tab === 'whitelists' ? filters.exchange : undefined,
          },
          {
            arrayFormat: 'separator',
          },
        ),
      });

      const { data } = await getWhitelists(
        {
          ...filters,
          is_unbound: capitalize(String(is_unbound)),
          is_whitelisted:
            tab === 'whitelists' ? filters.is_whitelisted : undefined,
          exchange: tab === 'whitelists' ? filters.exchange : undefined,
        },
        tab,
      );
      return data;
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchInterval: 60000,
    },
  );

  return query;
};
