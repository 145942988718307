// @ts-nocheck
import {
  TransactionDetails,
  TransactionStatus,
  TransactionSummary,
} from '@gnosis.pm/safe-react-gateway-sdk';
import { sameString } from 'features/Multisig/helpers';
import { TransactionListItemDetailed } from 'features/Multisig/mobx/models';

type TransactionButtonsState = {
  isTheFirstExecution: boolean;
  amIOwner: boolean;
  canIConfirm: boolean;
  canIExecute: boolean;
  shouldBeConfirmed: boolean;
  shouldBeExecuted: boolean;
  toBeCanceled: boolean;
  canICancel: boolean;
};

interface IProps {
  tx: TransactionSummary;
  details: TransactionDetails;
  transactionQueue: TransactionListItemDetailed[];
  userAccount: string;
}

export const useTransactionButtonsState = ({
  tx,
  details,
  userAccount,
  transactionQueue,
  lastNonce,
}: IProps): TransactionButtonsState => {
  const confirmationsRequired = tx.executionInfo?.confirmationsRequired;
  const confirmationsSubmitted = tx.executionInfo?.confirmationsSubmitted;
  const thresholdReached = confirmationsSubmitted >= confirmationsRequired;
  const isTheFirstExecution = tx?.executionInfo?.nonce === lastNonce;
  const amIOwner = details?.detailedExecutionInfo?.signers.some(
    ({ value }) => sameString(userAccount, value),
  );

  return {
    isTheFirstExecution,
    amIOwner,
    canIConfirm: tx?.executionInfo?.missingSigners?.some(
      ({ value }) => sameString(userAccount, value),
    ),
    canIExecute:
      !tx?.executionInfo?.missingSigners?.length &&
      isTheFirstExecution &&
      thresholdReached,
    shouldBeConfirmed:
      tx?.txStatus === 'AWAITING_CONFIRMATIONS' ||
      tx?.txStatus === 'WILL_BE_REPLACED',
    shouldBeExecuted: tx?.txStatus === 'AWAITING_EXECUTION',

    isCancellation: tx?.txInfo?.isCancellation,
    isBeingCancelled: transactionQueue.some((item) => item?.transaction?.executionInfo?.nonce === tx?.executionInfo?.nonce && item?.transaction?.txInfo?.isCancellation),

    canBeCancelled: [TransactionStatus.AWAITING_CONFIRMATIONS, TransactionStatus.AWAITING_EXECUTION].includes(tx?.txStatus),
    canICancel:
      tx?.txInfo?.isCancellation !== true &&
      amIOwner,
  };
};
