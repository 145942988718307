import { useEffect, useState } from 'react';
import useWallet from '../hooks/useWallet';
import { SafeStoreInstance } from 'features/Multisig/mobx/SafeStore';
import { initSafeSDK, setSafeSDK } from './safeCoreSDK';
import useOnboard from 'features/SafeApp/hooks/useOnboard';
import { toJS } from 'mobx';

export const useInitSafeCoreSDK = (): Error | null => {
  const { selectedChain, selectedSafe, wallet } = SafeStoreInstance;
  const onboard = useOnboard();

  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    // @ts-ignore
    if (!selectedSafe?.address || !selectedChain || !wallet) {
      return;
    }

    if (selectedSafe) {
      initSafeSDK(wallet.provider, wallet.provider.chainId, selectedSafe.address.value, selectedSafe.version)
      .then(setSafeSDK)
      .catch((e) => {
        // If we don't reset the SDK, a previous Safe could remain in the store
        setSafeSDK(undefined);
        setError(e);

        onboard?.disconnectWallet({ label: wallet.label });
      });
    }
  }, [selectedChain, wallet, selectedSafe]);

  return error;
};
