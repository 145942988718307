import styled from 'styled-components/macro';
import { motion } from 'framer-motion';

import { ReactComponent as SvgClose } from 'shared/assets/icons/icon-x.svg';

export const Main = styled(motion.div)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Wrap = styled.div`
  padding: 0;
  width: 560px;
  border-radius: 5px;
  background: var(--base);
  position: relative;
  z-index: 2;
`;
export const BackDrop = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(35, 59, 88, 0.6);
  z-index: 1;
  backdrop-filter: blur(2px);
`;
export const Header = styled.header`
  display: flex;
  padding: 20px;
  justify-content: space-between;
`;
export const SubHeader = styled.h4`
  font-size: 14px;
  font-weight: 700;
  margin: 0;
`;
export const Title = styled.h3`
  margin: 0;
  font-size: 18px;
  line-height: 21px;
  font-weight: 700;
`;
export const HeaderTextRight = styled.span`
  color: var(--object-secondary)
  font-size: 18px;
  line-height: 21px;
`;
export const Body = styled.div`
  font-size: 12px;
  line-height: 16px;
  padding: 0 20px 20px;
`;
export const Footer = styled.div`
  display: flex;
  position: relative;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 20px;
    right: 20px;
    height: 1px;
    background-color: var(--border);
    pointer-events: none;
  }
`;
export const IconClose = styled(SvgClose)`
  width: 40px;
  height: 40px;
  stroke: var(--base);
`;
export const CloseButton = styled.button`
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
  z-index: 999;
  opacity: 0.5;
  transition: all 0.2s;

  &:hover {
    opacity: 1;
  }
`;
