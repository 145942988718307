import Web3 from 'web3';
import { provider as Provider } from 'web3-core';
import Web3Adapter from '@gnosis.pm/safe-web3-lib';

import { ZERO_ADDRESS } from './ethAddresses';
import { getRpcServiceUrl, _getChainId } from 'features/Multisig/helpers';

// With some wallets from web3connect you have to use their provider instance only for signing
// And our own one to fetch data
// export const web3HttpProviderOptions = {
//   timeout: 10_000,
// };

// const web3ReadOnly: Web3[] = [];
// export const getWeb3ReadOnly = (chainId = _getChainId()): Web3 => {
//   if (!web3ReadOnly[chainId]) {
//     web3ReadOnly[chainId] = new Web3(
//       process.env.NODE_ENV !== 'test'
//         ? new Web3.providers.HttpProvider(
//             getRpcServiceUrl(),
//             web3HttpProviderOptions,
//           )
//         : 'ws://localhost:8545',
//     );
//   }

//   return web3ReadOnly[chainId];
// };

let web3: Web3;
export const getWeb3ReadOnly = (): Web3 => web3;
export const getWeb3 = (): Web3 => web3;
export const setWeb3 = (provider: Provider): Web3 => {
  web3 = new Web3(provider);
  return web3;
};
export const setWeb3ReadOnly = (): void => {
  web3 = getWeb3ReadOnly();
};
// export const resetWeb3 = (): void => {
//   web3 = web3ReadOnly[_getChainId()];
// };

export const getSDKWeb3ReadOnly = (): Web3Adapter => {
  return new Web3Adapter({
    web3: getWeb3ReadOnly(),
    signerAddress: ZERO_ADDRESS,
  });
};
