import { useIsOpen } from 'features/Transfer/context/AccountContext';
import { getExchangeInfoById } from 'shared/constants/exchanges';
import { AccountType, ColumnSide, ExchangeAccountsType, FiatAddressType } from 'shared/types';
import * as S from './ColumnStyled';
import Tippy from '@tippyjs/react';
import { ExchangeIcon } from '../components/ExchangeAbstract';
import { useTransfer } from 'features/Transfer/context/TransferContext';
import { Account } from './Account/AccountItem';
import { AccountsStoreInstance } from 'services';
import { FiatMethodItem } from './Account/FiatMethodItem';

export const FiatExchangeItem = ({ fiatAddresses }: { fiatAddresses: any }) => {
  const exchangeInfo = getExchangeInfoById('FIAT');
  const [opened, toggle] = useIsOpen('to', 'exchange', 'FIAT');
  const methods = Object.keys(fiatAddresses);

  return (
    <S.Row key={exchangeInfo?.api_id}>
      <S.ExchangeTop onClick={toggle}>
        {exchangeInfo?.logo_url ? (
          <ExchangeIcon
            style={{
              backgroundImage: `url(${exchangeInfo.logo_url})`,
              width: '14px',
              height: '14px',
              backgroundSize: '14px',
              marginTop: '2px',
            }}
            disabled={false}
          />
        ) : null}
        <S.ExchangeName>{exchangeInfo?.title}</S.ExchangeName>
      </S.ExchangeTop>
      {opened && Array.isArray(methods) ? (
        <div tw="w-full">
          {methods
            .sort((a, b) => (a < b ? -1 : 1))
            .map((method: any, idx: number) => (
              <FiatMethodItem
                key={method}
                method={method}
                accounts={fiatAddresses[method]}
              />
            ))}
        </div>
      ) : null}
    </S.Row>
  );
};
