import { toJS } from 'mobx';
import { OverviewExchangeRow } from './OverviewExchangeRow';
import { AccountsStoreInstance } from 'services';
import { useMemo, useState } from 'react';
import { OverviewAccountRow } from './OverviewAccountRow';
import { AccountType } from 'shared/types';

const mapList = (
  accounts: AccountType[],
  balances: any,
  openedExchanges: string[],
) => {
  const initialList = Object.keys(balances).map((exchange, index) => ({
    exchange,
    ...balances[exchange],
    isExchange: true,
    index,
  }));

  openedExchanges.forEach((openedExchange) => {
    const index = initialList.findIndex(
      ({ exchange }) => exchange === openedExchange,
    );
    const exchangeData = initialList[index];
    const exchangeAccounts = accounts.filter(
      ({ exchange }) => exchange === openedExchange,
    );
    initialList.splice(
      index + 1,
      0,
      ...exchangeAccounts.map((acc) => ({ ...acc, index: exchangeData.index })),
    );
  });

  return initialList;
};

export const OverviewRows = ({
  balances,
  availableCurrencies,
  cell,
  chosenCurrency,
  setCell,
  isCryptoTab,
  initialCurrencies,
}: any) => {
  const { accounts } = AccountsStoreInstance;
  const [openedExchanges, setOpenedExchanges] = useState<string[]>([]);

  const list = useMemo(
    () => mapList(accounts, balances, openedExchanges),
    [accounts, balances, openedExchanges],
  );

  const toggleAccounts = (exchange: string) => {
    const index = openedExchanges.findIndex((item) => item === exchange);
    if (index !== -1) {
      setOpenedExchanges((currentValue) => {
        const modifiedArray = [...currentValue];
        modifiedArray.splice(index, 1);
        return modifiedArray;
      });
    } else {
      setOpenedExchanges((currentValue) => [...currentValue, exchange]);
    }
  };

  return (
    <>
      {list?.map((data: any) => {
        if (data.isExchange) {
          return (
            <OverviewExchangeRow
              key={data.exchange}
              isOpen={openedExchanges.includes(data.exchange)}
              chosenCurrency={chosenCurrency}
              toggleAccounts={toggleAccounts}
              initialCurrencies={initialCurrencies}
              data={data}
              availableCurrencies={availableCurrencies}
              cell={cell}
              setCell={setCell}
              isCryptoTab={isCryptoTab}
            />
          );
        } else {
          return (
            <OverviewAccountRow
              key={data.id}
              chosenCurrency={chosenCurrency}
              initialCurrencies={initialCurrencies}
              data={data}
              availableCurrencies={availableCurrencies}
              cell={cell}
              setCell={setCell}
              isCryptoTab={isCryptoTab}
            />
          );
        }
      })}
    </>
  );
};
