import { useMemo } from 'react';
import { SafeInfo } from '@gnosis.pm/safe-react-gateway-sdk';
import { MultisigTransactionsStoreInstance } from 'features/Multisig/mobx/MultisigTransactionsStore';
import { SafeStoreInstance } from 'features/Multisig';
import { defaultSafeInfo } from '../constants/safeInfo';

const useSafeInfo = (): {
  safe: SafeInfo
  safeAddress: string
  safeLoaded: boolean
  safeError?: string
} => {
  const { selectedSafe: safeData, safeCreationError: safeErrorFromStore } = SafeStoreInstance;

  return useMemo(
    () => ({
      safe: safeData || defaultSafeInfo,
      safeAddress: safeData?.address.value || '',
      safeLoaded: !!safeData,
      safeError: safeErrorFromStore,
    }),
    [safeData, safeErrorFromStore],
  );
};

export default useSafeInfo;
