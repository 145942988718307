import { useContext, useEffect } from 'react';
import { observer } from 'mobx-react';

import AppContext from 'shared/contexts/AppContext';

import MultisigHeader from './MultisigHeader';
import Assets from './Assets';
import Transactions from './Transactions';
import Apps from './Apps';
import { SafeStoreInstance } from '../mobx/SafeStore';

import * as S from './styled/main';

const Multisig = () => {
  const { multisigTab, scrollToTop } = useContext(AppContext);
  const { getSafes, getChains, getCoins } = SafeStoreInstance;

  useEffect(() => {
    scrollToTop();
    const loadData = async () => {
      await getChains();
      await getSafes();
      await getCoins();
    };

    loadData();
  }, []);

  return (
    <S.Wrap
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      exit={{ opacity: 0, transition: { duration: 0.5 } }}
    >
      <MultisigHeader />
      {(() => {
        switch (multisigTab) {
          case 'assets':
            return <Assets />;
          case 'transactions':
            return <Transactions />;
          case 'apps':
            return <Apps />;
          // case 'settings':
          //   return <Settings />;
          default:
            return null;
        }
      })()}
    </S.Wrap>
  );
};

const C = observer(Multisig);
export { C as Multisig };
