import { useContext, useEffect, useState } from 'react';
import AppContext from 'shared/contexts/AppContext';

export const useIsMobile = (width = 1300): [boolean, string, () => void, (params: any) => void] => {
  const { setAsideMobileType } = useContext(AppContext);
  const [isAsideButtonChecked, setIsAsideButtonChecked] = useState('unset');
  const [isMobile, setIsMobile] = useState(window.innerWidth < width);

  useEffect(() => {
    function handleResize() {
      if (isAsideButtonChecked === 'unset') {
        setIsMobile(window.innerWidth < width);
        setAsideMobileType(window.innerWidth < width);
      }
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isAsideButtonChecked]);

  const handleAsideSize = () => {
    setIsMobile(!isMobile);

    if (isAsideButtonChecked === 'unset') {
      setIsAsideButtonChecked(window.innerWidth < width ? 'OPEN' : 'CLOSED');
    } else if (isAsideButtonChecked === 'CLOSED') {
      setIsAsideButtonChecked('OPEN');
    } else {
      setIsAsideButtonChecked('CLOSED');
    }
  };

  return [isMobile, isAsideButtonChecked, handleAsideSize, setIsMobile];
};
