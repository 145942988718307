import Tippy from '@tippyjs/react';
import { Tag, tagColor } from 'shared/types';
import * as S from './AccountStyled';

interface AccountTagsProps {
  tags: Tag[];
}

export const AccountTags = ({ tags }: AccountTagsProps) => {
  const firstTags = tags?.slice(0, 4);
  const otherTags = tags?.slice(4, tags.length);
  return (
    <S.AccountTags>
      {firstTags?.map(({ type, value }) => (
        <S.AccountTag color={tagColor[type] || 'var(--object-primary)'}>
          {value}
        </S.AccountTag>
      ))}
      {otherTags?.length ? (
        <Tippy
          trigger="mouseenter"
          placement="bottom"
          hideOnClick={true}
          theme="transparent"
          content={
            <>
              {otherTags.map(({ type, value }) => (
                <S.AccountTag color={tagColor[type] || 'var(--object-primary)'}>
                  {value}
                </S.AccountTag>
              ))}
            </>
          }
        >
          <S.AccountTag color={'var(--object-secondary)'}>
            {otherTags.length} more
          </S.AccountTag>
        </Tippy>
      ) : null}
    </S.AccountTags>
  );
};
