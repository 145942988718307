import * as S from 'features/Positions/view/styled';
import { CurrenciesStoreInstance } from 'services';
import { ReactComponent as SvgEur } from 'shared/assets/icons/views/logo-eur.svg';
import { ReactComponent as SvgUsd } from 'shared/assets/icons/views/logo-usd.svg';
import { ReactComponent as SvgUsdK } from 'shared/assets/icons/views/logo-usdk.svg';
import { ReactComponent as SvgUsdM } from 'shared/assets/icons/views/logo-usdm.svg';

export const VIEWS = [
  {
    id: 'usd',
    label: 'USD',
    currency: 'USD',
    currency_label: '$',
    currency_icon: <SvgUsd />,
    divisor: 1,
    divisor_label: null,
  },
  {
    id: 'eur',
    label: 'EUR',
    currency: 'EUR',
    currency_label: '€',
    currency_icon: <SvgEur />,
    divisor: 1,
    divisor_label: null,
  },
  {
    id: 'usd_k',
    label: 'USDk',
    currency: 'USD',
    currency_label: '$',
    currency_icon: <SvgUsdK />,
    divisor: 1000,
    divisor_label: 'k',
  },
  {
    id: 'usd_m',
    label: 'USDm',
    currency: 'USD',
    currency_label: '$',
    currency_icon: <SvgUsdM />,
    divisor: 1000000,
    divisor_label: 'm',
  },
];

interface FilterViewProps {
  setViewInfo: (viewInfo: any) => void;
  viewInfo: any;
}

export const FilterView = ({ viewInfo, setViewInfo }: FilterViewProps) => {
  const { rates } = CurrenciesStoreInstance;
  return (
    <>
      <S.FilterViewLabel>Current value view</S.FilterViewLabel>
      <S.CurrencySelectCell active={false}>
        <S.CurrencySelectWrap>
          {VIEWS.map((item) => {
            return (
              <S.CurrencySelectItem
                key={item.id}
                disabled={!rates?.[item.currency]}
                active={item.label?.toLowerCase() === viewInfo?.label?.toLowerCase()}
                onClick={() => setViewInfo(item)}
              >
                {item.currency_icon}
              </S.CurrencySelectItem>
            );
          })}
        </S.CurrencySelectWrap>
      </S.CurrencySelectCell>
    </>
  );
};
