import { useMutation, useQuery } from '@tanstack/react-query';
import { FC, MouseEventHandler, useContext, useRef, useState } from 'react';
import { UserStoreInstance } from 'services';
import { API_URL } from 'shared/constants/app';
import AppContext from 'shared/contexts/AppContext';
import { CardSetValue } from './CardSetValue';
import { QrModal, QrModalProps } from './QrModal';
import queryString from 'query-string';

import * as S from '../styled';
import { CodeInput, digitsNumber } from 'shared/components/OtpModal/shared';
import { parseError } from 'shared/helpers/errors';
import { IconSend } from 'features/Transfer/view/newComponents/TransferStyled';
import {
  ModalDiv,
  ModalError,
  ModalText,
} from 'shared/components/Modal/styled';
import { useNavigate } from 'react-router';
import { requestPost } from 'shared/axios';

const getQrCode = async () => {
  const response = await fetch(`${API_URL}/hotp/init/`, {
    method: 'POST',
    headers: {
      authorization: `Token ${localStorage.getItem('appToken')}`,
    },
  });

  return await response.json();
};

const resetOtp = async () => {
  const response = await fetch(`${API_URL}/hotp/reset/`, {
    method: 'POST',
    headers: {
      authorization: `Token ${localStorage.getItem('appToken')}`,
    },
  });

  return response;
};

const activateQrCode = async (code: string) => {
  const response = await requestPost(
    '/hotp/activate/',
    { code },
    {
      headers: {
        authorization: `Token ${localStorage.getItem('appToken')}`,
      },
    },
  );

  return await response.data;
};

const SuccessModal = () => {
  const { openModal, setIsHideSidebar } = useContext(AppContext);
  const navigate = useNavigate();
  const onClick = () => {
    navigate('/profile');
    openModal(null);
    setIsHideSidebar(false);
  };

  return (
    <div style={{ width: '440px' }}>
      <ModalText>
        <b>Please note</b>: the HOTP code in your 2FA app is manually updated.
        If you update the code more than 10 times, but do not enter these codes
        into Multik, you will need to reset the HOTP code through the
        administrator and set a new one.
      </ModalText>
      <ModalDiv />
      <S.GoToProfileButton type="button" onClick={onClick}>
        Go to profile <S.IconSend />
      </S.GoToProfileButton>
    </div>
  );
};

interface OtpCardProps {
  has_hotp: boolean;
}

const GetQrModal = ({ encoded, decoded, comment }: QrModalProps) => {
  const ref = useRef(null);
  const [otp, setOtp] = useState('');
  const [isSubmit, setSubmit] = useState(false);
  const { openModal, getUserInfo, appSettings } = useContext(AppContext);
  const [otpErrorText, setOtpErrorText] = useState<string | null>(null);

  const onOtpReset = () => {
    setOtp('');
    setOtpErrorText(null);
    // @ts-ignore
    ref?.current?.__clearvalues__();
  };

  const onOtpChange = (vals: any) => {
    setOtp(vals);
    setSubmit(false);
    setOtpErrorText(null);
  };

  const onSubmit = async () => {
    try {
      setSubmit(true);
      await activateQrCode(otp);
      await getUserInfo();

      openModal({
        title: 'Authentication code successfully added',
        titleType: 'success',
        component: () => <SuccessModal />,
      });
    } catch (error) {
      setOtpErrorText(parseError(error, appSettings));
    } finally {
      setSubmit(false);
    }
  };

  return (
    <S.QrCodeWrap style={{ width: '440px' }}>
      <S.QrCodeVisual value={encoded} size={250} fgColor="#000000" />
      {comment && <S.QrCodeHint>{comment}</S.QrCodeHint>}
      {decoded && <S.QrCodeText>{decoded}</S.QrCodeText>}
      {comment && (
        <S.QrCodeHint style={{ marginBottom: '32px' }}>
          Please confirm the new authentication code
        </S.QrCodeHint>
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          padding: '0 20px',
          width: '100%',
        }}
      >
        <div style={{ width: '280px' }}>
          <CodeInput
            ref={ref}
            value={otp}
            autoFocus={true}
            responsive={false}
            required={true}
            title="Multik Authenticator code"
            onChange={onOtpChange}
            onReset={onOtpReset}
            errorText={otpErrorText}
          />{' '}
          {otpErrorText && (
            <ModalError responsive={false} data-id="otp-error">
              {otpErrorText}
            </ModalError>
          )}
        </div>
        <S.SubmitButton
          style={{ marginTop: 22 }}
          disabled={isSubmit || otp.length < digitsNumber}
          onClick={onSubmit}
          type="submit"
        >
          Submit
          <S.IconSend />
        </S.SubmitButton>
      </div>
    </S.QrCodeWrap>
  );
};

export const OtpCard: FC<OtpCardProps> = ({ has_hotp }) => {
  const { fetchUserInfo } = UserStoreInstance;
  const [internalError, setInternalError] = useState(false);
  const [error, setError] = useState(false);
  const [resetted, setReset] = useState(false);
  const { openModal } = useContext(AppContext);
  const { refetch } = useQuery({
    queryKey: ['hotp-code'],
    queryFn: getQrCode,
    enabled: false,
    onSuccess: (data) => {
      if (!data.error_code) {
        const extracted = queryString.extract(data.secret);
        const parts = queryString.parse(extracted);
        openModal({
          title: 'Get QR Code',
          component: () => (
            <GetQrModal
              encoded={data.secret}
              decoded={String(parts.secret)}
              comment={
                <>
                  Scan QR code with Google Authenticator <br />
                  or type in the text code.
                </>
              }
            />
          ),
        });
        fetchUserInfo();
      } else {
        setError(true);
      }
    },
  });
  const { mutateAsync: reset } = useMutation({
    mutationKey: ['reset-hotp-code'],
    mutationFn: resetOtp,
    onSuccess: async (data) => {
      if (data.ok && data.status === 204) {
        setReset(true);
      } else {
        const d = await data.json();
        if (d?.error_code === 'service_unavailable') {
          setInternalError(true);
        } else if (d?.error_message) {
          setError(true);
        }
      }
    },
  });

  const onClick: MouseEventHandler<HTMLElement> = (e) => {
    setError(false);
    refetch();
  };

  const onClickReset = () => {
    setError(false);
    reset();
  };

  const Value = () => {
    if (internalError) {
      return (
        <S.ErrorText>
          We are currently experiencing internal issues, and we are unable to
          reset your OTP code at this time. Please try again later or contact us
          at <a href="mailto:support@multik.io">support@multik.io</a>
        </S.ErrorText>
      );
    }
    if (error) {
      return (
        <S.ErrorText>
          To reset your OTP code please contact us at{' '}
          <a href="mailto:support@multik.io">support@multik.io</a>
        </S.ErrorText>
      );
    }

    if (resetted) {
      return (
        <S.ErrorText>
          Your reset request has been sent to an administrator
        </S.ErrorText>
      );
    }

    if (has_hotp) {
      return <S.OtpButton onClick={onClickReset}>reset qr code</S.OtpButton>;
    } else {
      return <S.OtpButton onClick={onClick}>get qr code</S.OtpButton>;
    }
  };

  return (
    <S.CardWrapper>
      <S.CardHeader>
        <S.CardHeaderText>
          <S.IconLock />
          OTP: Google Authenticator
        </S.CardHeaderText>
        <CardSetValue value={has_hotp} />
      </S.CardHeader>
      <S.CardValue>
        <Value />
      </S.CardValue>
    </S.CardWrapper>
  );
};
