import styled from 'styled-components/macro';

export const Wrap = styled.div<{ management: boolean }>`
  width: ${({ management }) => (management ? '480px' : '100%')};
  // max-width: 480px;

  @media (min-width: 1600px) {
    width: ${({ management }) => (management ? 'calc(480px * 1.25)' : '100%')};
  }

  @media (min-width: 2160px) {
    width: ${({ management }) => (management ? 'calc(480px * 1.5)' : '100%')};
  }
`;
