import { Operation } from '@gnosis.pm/safe-react-gateway-sdk';
import { FEATURES } from '@gnosis.pm/safe-react-gateway-sdk';
import { Record, RecordOf } from 'immutable';
import { TransactionStatus } from '@gnosis.pm/safe-react-gateway-sdk/dist/types/transactions';

export type SafeOwner = string;

export type ModulePair = [
  // previous module
  string,
  // module
  string,
];
export type BalanceRecord = {
  tokenAddress?: string;
  tokenBalance: string;
  fiatBalance?: string;
};

export enum SafeAppsTag {
  NFT = 'nft',
  TX_BUILDER = 'transaction-builder',
  DASHBOARD_FEATURED = 'dashboard-widgets',
  SAFE_CLAIMING_APP = 'safe-claiming-app',
  WALLET_CONNECT = 'wallet-connect',
}

export const INFURA_TOKEN = process.env.REACT_APP_INFURA_TOKEN || '';

export type SpendingLimit = {
  delegate: string;
  token: string;
  amount: string;
  spent: string;
  resetTimeMin: string;
  lastResetMin: string;
  nonce: string;
};

export type SafeRecordProps = {
  address: string;
  chainId?: string;
  threshold: number;
  ethBalance: string;
  totalFiatBalance: string;
  owners: SafeOwner[];
  modules?: ModulePair[] | null;
  spendingLimits?: SpendingLimit[] | null;
  balances: BalanceRecord[];
  nonce: number;
  recurringUser?: boolean;
  currentVersion: string;
  needsUpdate: boolean;
  featuresEnabled: FEATURES[];
  loadedViaUrl: boolean;
  guard: string;
  collectiblesTag: string;
  txQueuedTag: string;
  txHistoryTag: string;
};

/**
 * Create a safe record defaulting to these values
 */
const makeSafe = Record<SafeRecordProps>({
  address: '',
  chainId: undefined,
  threshold: 0,
  ethBalance: '0',
  totalFiatBalance: '0',
  owners: [],
  modules: [],
  spendingLimits: [],
  balances: [],
  nonce: 0,
  recurringUser: undefined,
  currentVersion: '',
  needsUpdate: false,
  featuresEnabled: [],
  loadedViaUrl: true,
  guard: '',
  collectiblesTag: '0',
  txQueuedTag: '0',
  txHistoryTag: '0',
});

export type SafeRecord = RecordOf<SafeRecordProps>;

export default makeSafe;

export const FIELD_CREATE_CUSTOM_SAFE_NAME = 'customSafeName';
export const FIELD_CREATE_SUGGESTED_SAFE_NAME = 'suggestedSafeName';
export const FIELD_SAFE_OWNERS_LIST = 'owners';
export const FIELD_SAFE_OWNER_ENS_LIST = 'safeOwnerENSList';
export const FIELD_NEW_SAFE_THRESHOLD = 'requieres';
export const FIELD_MAX_OWNER_NUMBER = 'maxOwnerNumber';
export const FIELD_NEW_SAFE_PROXY_SALT = 'safeCreationSalt';
export const FIELD_NEW_SAFE_GAS_LIMIT = 'gasLimit';
export const FIELD_NEW_SAFE_GAS_PRICE = 'gasPrice';
export const FIELD_NEW_SAFE_GAS_MAX_PRIO_FEE = 'gasMaxPrioFee';
export const FIELD_NEW_SAFE_CREATION_TX_HASH = 'safeCreationTxHash';

export const SAFE_VERSION_FOR_OFF_CHAIN_SIGNATURES = '>=1.0.0';

export type OwnerFieldItem = {
  nameFieldName: string;
  addressFieldName: string;
};

export type CreateSafeFormValues = {
  [FIELD_CREATE_SUGGESTED_SAFE_NAME]: string;
  [FIELD_CREATE_CUSTOM_SAFE_NAME]?: string;
  [FIELD_NEW_SAFE_THRESHOLD]: number;
  [FIELD_SAFE_OWNERS_LIST]: Array<OwnerFieldItem>;
  // @ts-ignore
  [FIELD_SAFE_OWNER_ENS_LIST]: Record<string, string>;
  [FIELD_MAX_OWNER_NUMBER]: number;
  [FIELD_NEW_SAFE_PROXY_SALT]: number;
  [FIELD_NEW_SAFE_GAS_LIMIT]: number;
  [FIELD_NEW_SAFE_GAS_PRICE]: string;
  [FIELD_NEW_SAFE_GAS_MAX_PRIO_FEE]: number;
  [FIELD_NEW_SAFE_CREATION_TX_HASH]?: string;
};

export const LATEST_SAFE_VERSION =
  process.env.REACT_APP_LATEST_SAFE_VERSION || '1.3.0';

export const SAFE_PENDING_CREATION_STORAGE_KEY =
  'NEW_SAFE_PENDING_CREATION_STORAGE_KEY';

export enum PendingActionType {
  CONFIRM = 'confirm',
  REJECT = 'reject',
}
export type PendingActionValues = PendingActionType[keyof PendingActionType];

export type TxArgs = {
  baseGas: string;
  data: string;
  gasPrice: string;
  gasToken: string;
  nonce: number;
  operation: Operation;
  refundReceiver: string;
  safeInstance: any;
  safeTxGas: string;
  sender: string;
  sigs: string;
  to: string;
  valueInWei: string;
};

export const LocalTransactionStatus = { // Record
  AWAITING_CONFIRMATIONS: 'AWAITING_CONFIRMATIONS',
  AWAITING_EXECUTION: 'AWAITING_EXECUTION',
  CANCELLED: 'CANCELLED',
  FAILED: 'FAILED',
  SUCCESS: 'SUCCESS',
  PENDING: 'PENDING',
  WILL_BE_REPLACED: 'WILL_BE_REPLACED',
};
