import styled from 'styled-components';
import { Accordion, AccordionSummary } from '@mui/material';

export const StyledAccordion = styled(Accordion)`
    border-radius: 6px !important;
    border: 1px solid #42a5f56b;
    overflow: hidden !important;
    margin: 0 !important;

    & > ::before {
        content: none !imporant;
    }

    & > .Mui-expanded {
        margin: 0 !imporant;
        background: #42a5f56b;
        height: 50px;
    }

    & > .MuiAccordionSummary-root {
        min-height: 50px !important;
        height: 50px;

        &:hover {
            background: #42a5f56b;
        }
    }
`;

export const EditButton = styled.div`
    color: #1976d2;
    font-size: medium;
    text-decoration: underline;
    text-decoration-color: rgba(25, 118, 210, 0.4);
    background-color: transparent;
    cursor: pointer;
    margin-top: 16px;
`;
