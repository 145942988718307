// @ts-nocheck
import { TransactionSummary } from '@gnosis.pm/safe-react-gateway-sdk';

import SettingsIcon from '../assets/settings.svg';
import CustomIcon from '../assets/custom.svg';
import IncomingIcon from '../assets/incoming.svg';
import OutgoingIcon from '../assets/outgoing.svg';
import CrossRedIcon from '../assets/circle-cross-red.svg';

export const useTransactionType = (tx: TransactionSummary) => {
  const { type } = tx.txInfo;

  switch (type) {
    case 'Creation': {
      return {
        icon: SettingsIcon,
        text: 'Safe created',
      };
    }
    case 'Transfer': {
      const isSendTx = tx.txInfo.direction === 'OUTGOING';

      return {
        icon: isSendTx ? OutgoingIcon : IncomingIcon,
        text: isSendTx ? 'Sent' : 'Received',
      };
    }
    case 'SettingsChange': {
      const isDeleteGuard = tx.txInfo.settingsInfo?.type === 'DELETE_GUARD';

      return {
        icon: SettingsIcon,
        text: isDeleteGuard ? 'deleteGuard' : tx.txInfo.dataDecoded.method,
      };
    }
    case 'Custom': {
      if (!tx.executionInfo) {
        return {
          icon: SettingsIcon,
          text: 'Module',
        };
      }

      if (tx.txInfo.isCancellation) {
        return {
          icon: CrossRedIcon,
          text: 'On-chain rejection',
        };
      }

      if (tx.safeAppInfo) {
        return {
          icon: tx.safeAppInfo.logoUri,
          text: tx.safeAppInfo.name,
        };
      }

      return {
        icon: CustomIcon,
        text: 'Contract interaction',
      };
    }
    default: {
      return {
        icon: '',
        text: '',
      };
    }
  }
};
