import useAsync from 'features/Multisig/view/hooks/useAsync';
import useWallet from 'features/SafeApp/hooks/useWallet';
import { getUserNonce } from 'features/SafeApp/helpers/web3';

const useUserNonce = (): number | undefined => {
  const wallet = useWallet();

  const [userNonce] = useAsync<number>(() => {
    if (!wallet) return;
    return getUserNonce(wallet.address);
  }, [wallet]);

  return userNonce;
};

export default useUserNonce;
