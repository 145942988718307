import { FC, useContext, useEffect, useMemo, useRef, useState } from 'react';

import * as S from '../styled';

import AppContext from 'shared/contexts/AppContext';

import { CreateGroupModal } from './CreateGroupModal';
import { useMpcGroupsQuery } from '../hooks/useMpcGroupsQuery';
import { MultisigGroupsCardProps } from '../types';
import { QrModal } from './QrModal';
import { Input, ProtectedArea } from 'shared/components';
import { Loader as InlineLoader } from 'shared/components/Loader';
import styled from 'styled-components';
import useOnClickOutside from 'use-onclickoutside';
const InlineLoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface SelectProps {
  label: string;
  values: any[];
  onChange: any;
}

const Select = ({ label, values, onChange }: SelectProps) => {
  const selectRef = useRef(null);
  useOnClickOutside(selectRef, () => handleClose());
  const [currentValue, setCurrentValue] = useState('');
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleValueChange = (value: any) => {
    setCurrentValue(value);
  };
  const handleChange = (value: any) => {
    handleValueChange(value);
    if (onChange) onChange(value);
    handleClose();
  };
  return (
    <S.SelectContainer ref={selectRef}>
      <S.SelectLabelButton onClick={handleOpen}>
        {currentValue !== '' ? currentValue : label}
      </S.SelectLabelButton>
      <S.DropdownStyle isVisible={open}>
        {values.map((value, index) => (
          <S.DropdownItem
            onClick={() => handleChange(value)}
            active={value === currentValue}
            key={index}
          >
            {value}
          </S.DropdownItem>
        ))}
      </S.DropdownStyle>
      <S.SelectDropdownIcon />
    </S.SelectContainer>
  );
};

const GroupRowItem = ({ item }: any) => {
  const { openModal } = useContext(AppContext);

  const joinedNumber = useMemo(
    () => item.users.filter(({ is_joined }: any) => is_joined).length,
    [item],
  );

  const onOpenQrModal = () => {
    openModal({
      title: 'Join group',
      component: () => (
        <QrModal
          encoded={JSON.stringify({
            join_token: item.join_token,
            group_id: item.group_id,
          })}
          comment={
            <>
              Scan QR code with <br />
              <a>Multik Multisignature</a>
            </>
          }
        />
      ),
    });
  };

  return (
    <S.GroupsRow>
      <S.GroupsCol>{item.name}</S.GroupsCol>
      <S.GroupsCol>
        <S.IconEdit />
        {item.schema.t}
      </S.GroupsCol>
      <S.GroupsCol>
        <S.IconUsers />
        <S.JoinedNumber complete={joinedNumber === item.schema.n}>
          Joined {joinedNumber} / {item.schema.n}
        </S.JoinedNumber>
      </S.GroupsCol>
      {!item.is_joined ? (
        <S.JoinButton onClick={onOpenQrModal}>
          Join
          <S.IconAdd />
        </S.JoinButton>
      ) : (
        <S.JoinedButton>
          Joined
          <S.IconAdd />
        </S.JoinedButton>
      )}
    </S.GroupsRow>
  );
};

export const MultisigGroupsCard: FC<MultisigGroupsCardProps> = ({ title }) => {
  const { openModal, user } = useContext(AppContext);

  const selectRef = useRef(null);

  const [limit, setLimit] = useState(5);
  const [count, setCount] = useState(0);
  const [pages, setPages] = useState(Math.ceil(count / limit));
  const [page, setPage] = useState(1);

  const { data, isLoading, isFetching } = useMpcGroupsQuery({
    limit,
    offset: (page - 1) * limit,
  });
  const groups = data?.results;

  useEffect(() => {
    setCount(data?.count);
  }, [data?.count]);

  useEffect(() => {
    if (!count || !limit) return;
    setPages(Math.ceil(count / limit));
  }, [limit, count]);

  const onChangeLimit = (v: any) => {
    setLimit(v);
    setPage(1);
  };

  const onPrevPage = () => {
    if (page <= 1) return;
    setPage(page - 1);
  };

  const onNextPage = () => {
    if (page >= pages) return;
    setPage(page + 1);
  };

  const onSetPage = (page: any) => {
    setPage(page);
  };

  const onSetInputPage = (e: any) => {
    const page = parseInt(e.target.value);
    if (isNaN(page)) return;
    if (page <= 0) {
      setPage(1);
    } else if (page > pages) {
      setPage(pages);
    } else {
      setPage(page);
    }
  };

  const onOpenCreateGroupModal = () => {
    openModal({
      title: 'Create multisig group',
      component: () => <CreateGroupModal />,
      outsideClickClose: false,
    });
  };

  const formArray = (l: number) =>
    Array.from(Array(l).keys()).map((p) => p + 1);

  const formArrayFrom = (l: number, from: number) =>
    Array.from(Array(l).keys()).map((p) => p + from);

  const getPagesList = () => {
    const arr = formArray(pages);

    if (arr.length <= 7) return arr;

    const modified = [];

    if (page <= 4) {
      modified.push(...formArray(5));
    } else if (page >= pages - 4) {
      modified.push(1, '...');
    } else {
      modified.push(1, '...', ...formArrayFrom(3, page - 1));
    }

    if (page <= 4) {
      modified.push('...', pages);
    } else if (page >= pages - 4) {
      if (page >= pages - 3) {
        modified.push(...formArrayFrom(5, pages - 4));
      } else {
        modified.push(...formArrayFrom(3, page - 1), '...', pages);
      }
    } else {
      modified.push('...', pages);
    }

    return modified;
  };

  const pagesList = getPagesList();

  return (
    <S.MultisigGroupsCardWrapper
      hasGroups={groups?.length > 0}
      limit={groups?.length}
    >
      <S.CardHeader>
        <S.CardHeaderText>
          <S.IconUsers />
          {title}
        </S.CardHeaderText>
        <S.CardSetWrapper>
          {groups?.length ? (
            <ProtectedArea every={['mpc.add_mpcgroup']}>
              <S.SlimButton onClick={onOpenCreateGroupModal}>
                <span>Create group</span>
                <S.IconAdd />
              </S.SlimButton>
            </ProtectedArea>
          ) : null}
        </S.CardSetWrapper>
      </S.CardHeader>
      {groups?.length || isLoading || isFetching ? (
        <S.GroupsBody>
          <S.GroupsList limit={groups?.length}>
            <S.GroupsHeader>
              <S.GroupsCol>group name</S.GroupsCol>
              <S.GroupsCol>signers</S.GroupsCol>
              <S.GroupsCol>participants</S.GroupsCol>
            </S.GroupsHeader>
            {isLoading || isFetching ? (
              <InlineLoaderWrapper>
                <InlineLoader small={true} />
              </InlineLoaderWrapper>
            ) : (
              groups?.map((item: any) => (
                <GroupRowItem key={item.group_id} item={item} />
              ))
            )}
          </S.GroupsList>
          <S.SelectWrap>
            <Select
              label={String(limit)}
              values={[5, 10, 15]}
              onChange={onChangeLimit}
            />
            &nbsp;&nbsp;&nbsp;&nbsp;Lines per page
          </S.SelectWrap>
          <S.PagesButtons pages={pages}>
            <S.PrevButton inactive={page <= 1} onClick={onPrevPage} />
            <S.PagesList>
              {pagesList?.map((p) => {
                const onChangePage = () => {
                  if (p === '...') return;
                  onSetPage(p);
                };
                return (
                  <S.PageButton selected={page === p} onClick={onChangePage}>
                    {p}
                  </S.PageButton>
                );
              })}
            </S.PagesList>
            <S.NextButton inactive={page >= pages} onClick={onNextPage} />
          </S.PagesButtons>
          <S.GoToPage disabled={pages === 1}>
            Go to page
            <S.StyledInput
              placeholder="#"
              onChange={onSetInputPage}
              type="number"
              value={page}
              disabled={pages === 1}
            />
          </S.GoToPage>
        </S.GroupsBody>
      ) : user?.permissions.includes('mpc.add_mpcgroup') ? (
        <>
          <S.GroupsBody>Create a group to invite participants</S.GroupsBody>
          <S.CardFooter>
            <S.Button onClick={onOpenCreateGroupModal}>Create group</S.Button>
          </S.CardFooter>
        </>
      ) : (
        <>
          <S.GroupsBody>You are not yet invited to any group</S.GroupsBody>
        </>
      )}
    </S.MultisigGroupsCardWrapper>
  );
};
