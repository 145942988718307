import styled from 'styled-components/macro';
import { motion } from 'framer-motion';
import { ReactComponent as SvgGoogle } from '../../assets/icons/icon-google.svg';

export const LoginWrap = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: flex;
  background: var(--object-primary);
  align-items: center;
  justify-content: center;
`;

export const NotifyView = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  font-weight: bold;
  font-size: 15px;
  color: var(--object-primary);
`;

export const Text = styled.div`
  font-size: 12px;
  color: var(--object-secondary);
`;

export const CloseButton = styled.img`
  height: fit-content;
`;
