import { useEffect, useMemo } from 'react';
import { SafeAppAccessPolicyTypes } from '@gnosis.pm/safe-react-gateway-sdk';

import { Errors, logError } from 'shared/helpers/exceptions';
import { fetchSafeAppFromManifest } from '../manifest';
import useAsync from 'features/Multisig/view/hooks/useAsync';

import { SafeAppDataWithPermissions } from '../constants/types';

type UseSafeAppFromManifestReturnType = {
  safeApp?: SafeAppDataWithPermissions
  isLoading: boolean
};

export const getEmptySafeApp = (url = ''): SafeAppDataWithPermissions => {
  return {
    id: Math.random(),
    url,
    name: 'unknown',
    iconUrl: '/images/apps/apps-icon.svg',
    description: '',
    chainIds: [],
    accessControl: {
      type: SafeAppAccessPolicyTypes.NoRestrictions,
    },
    tags: [],
    safeAppsPermissions: [],
  };
};

const useSafeAppFromManifest = (appUrl: string, chainId: string): UseSafeAppFromManifestReturnType => {
  const [data, error, isLoading] = useAsync<SafeAppDataWithPermissions>(() => {
    if (appUrl && chainId) return fetchSafeAppFromManifest(appUrl, chainId);
  }, [appUrl, chainId]);

  const emptyApp = useMemo(() => getEmptySafeApp(appUrl), [appUrl]);

  useEffect(() => {
    if (!error) return;
    logError(Errors._903, `${appUrl}, ${(error as Error).message}`);
  }, [appUrl, error]);

  return { safeApp: data || emptyApp, isLoading };
};

export { useSafeAppFromManifest };
