import { _getChainId } from 'features/Multisig/helpers';

import useWallet from './useWallet';

const useIsWrongChain = (): boolean => {
  const chainId = _getChainId();
  const wallet = useWallet();
  return !wallet || !chainId ? false : wallet.chainId !== chainId;
};

export default useIsWrongChain;
