import { useEffect, useMemo } from 'react';
import { SafeAppsResponse } from '@gnosis.pm/safe-react-gateway-sdk';
import { getSafeApps } from '@gnosis.pm/safe-react-gateway-sdk';
import {
  _getChainId,
} from '../../helpers';
import { Errors, logError } from 'shared/helpers/exceptions';
import { AsyncResult } from './useAsync';
import useAsync from './useAsync';
import { SafeAppsTag } from '../../constants';

// To avoid multiple simultaneous requests (e.g. the Dashboard and the SAFE header widget),
// cache the request promise for 100ms
const cache: Record<string, Promise<SafeAppsResponse> | undefined> = {};
const cachedGetSafeApps = (chainId: string): ReturnType<typeof getSafeApps> | undefined => {
  if (!cache[chainId]) {
    cache[chainId] = getSafeApps(chainId, { client_url: window.location.origin });

    // Clear the cache the promise resolves with a small delay
    cache[chainId]?.finally(() => {
      setTimeout(() => (cache[chainId] = undefined), 100);
    });
  }

  return cache[chainId];
};

const useRemoteSafeApps = (tag?: SafeAppsTag): AsyncResult<SafeAppsResponse> => {
  const chainId = _getChainId();

  const [remoteApps, error, loading] = useAsync(async () => {
    if (!chainId) return;
    return cachedGetSafeApps(chainId);
  }, [chainId]);

  useEffect(() => {
    if (error) {
      logError(Errors._902, error.message);
    }
  }, [error]);

  const apps = useMemo(() => {
    if (!remoteApps || !tag) return remoteApps;
    return remoteApps.filter((app) => app.tags.includes(tag));
  }, [remoteApps, tag]);

  return [apps, error, loading];
};

export { useRemoteSafeApps };
