import { EthAdapterTransaction } from '@gnosis.pm/safe-core-sdk-types';
import {
  GasPriceOracle,
  Operation,
  postSafeGasEstimation,
} from '@gnosis.pm/safe-react-gateway-sdk';
import axios from 'axios';
import { BigNumber } from 'bignumber.js';
import { FeeHistoryResult } from 'web3-eth';
import { hexToNumber } from 'web3-utils';
import {
  getSDKWeb3ReadOnly,
  getWeb3ReadOnly,
} from 'features/Multisig/mobx/logic/getWeb3';
import {
  _getChainId,
  checksumAddress,
  getFixedGasPrice,
  getGasPriceOracles,
} from 'features/Multisig/helpers';
import {
  SafeTransactionEstimation,
  SafeTransactionEstimationRequest,
} from '@gnosis.pm/safe-react-gateway-sdk/dist/types/transactions';
import { GnosisSafe } from 'shared/types/contracts/gnosis_safe';
import { Transaction } from 'ethers';
import { notify } from 'shared/components/Notification/notify';

import { LocalTransactionStatus } from '../../constants';

export const EMPTY_DATA = '0x';

export const DEFAULT_MAX_GAS_FEE = 3.5e9; // 3.5 GWEI
export const DEFAULT_MAX_PRIO_FEE = 2.5e9; // 2.5 GWEI

const fetchGasPrice = async (
  gasPriceOracle: GasPriceOracle,
): Promise<string> => {
  const { uri, gasParameter, gweiFactor } = gasPriceOracle;
  const { data: response } = await axios.get(uri);
  const data = response.data || response.result || response; // Sometimes the data comes with a data parameter

  const gasPrice = new BigNumber(data[gasParameter]).multipliedBy(gweiFactor);
  if (gasPrice.isNaN()) {
    throw new Error('Fetched gas price is NaN');
  }
  return gasPrice.toString();
};

export const setMaxPrioFeePerGas = (
  maxPriorityFeePerGas: number,
  maxFeePerGas: number,
): number => {
  return maxPriorityFeePerGas > maxFeePerGas
    ? maxFeePerGas
    : maxPriorityFeePerGas;
};

export const getFeesPerGas = async (): Promise<{
  maxFeePerGas: number;
  maxPriorityFeePerGas: number;
}> => {
  let blocks: FeeHistoryResult | undefined;
  let maxPriorityFeePerGas: string | number | undefined;
  let baseFeePerGas: string | number | undefined;

  const web3 = getWeb3ReadOnly();

  try {
    // Lastest block, 50th reward percentile
    blocks = await web3.eth.getFeeHistory(1, 'latest', [50]);

    // hexToNumber can throw if not parsing a valid hex string
    baseFeePerGas = hexToNumber(blocks.baseFeePerGas[0]);
    maxPriorityFeePerGas = hexToNumber(blocks.reward[0][0]);
  } catch (err) {
    console.log(err);
  }

  if (
    !blocks ||
    !maxPriorityFeePerGas ||
    isNaN(Number(maxPriorityFeePerGas)) ||
    !baseFeePerGas ||
    isNaN(Number(baseFeePerGas))
  ) {
    return {
      maxFeePerGas: DEFAULT_MAX_GAS_FEE,
      maxPriorityFeePerGas: DEFAULT_MAX_PRIO_FEE,
    };
  }

  return {
    maxFeePerGas: Number(baseFeePerGas) + Number(maxPriorityFeePerGas),
    maxPriorityFeePerGas: Number(maxPriorityFeePerGas),
  };
};

export const calculateGasPrice = async (): Promise<string> => {
  const gasPriceOracles = getGasPriceOracles();

  for (const gasPriceOracle of gasPriceOracles) {
    try {
      const fetchedGasPrice = await fetchGasPrice(gasPriceOracle);
      return fetchedGasPrice;
    } catch (err) {
      // Keep iterating price oracles
    }
  }

  // A fallback to fixed gas price from the chain config
  const fixedGasPrice = getFixedGasPrice();
  if (fixedGasPrice) {
    return fixedGasPrice.weiValue;
  }

  // A fallback based on the median of a few last blocks
  const web3ReadOnly = getWeb3ReadOnly();
  return await web3ReadOnly.eth.getGasPrice();
};

export const calculateGasOf = async (
  txConfig: EthAdapterTransaction,
): Promise<number> => {
  try {
    const ethAdapter = getSDKWeb3ReadOnly();

    return await ethAdapter.estimateGas(txConfig);
  } catch (err) {
    notify({
      type: 'error',
      header: 'Transaction failed',
      text: 'Error while gas estimating',
    });
    throw console.log('error while gas estimating', err);
  }
};

type FetchSafeTxGasEstimationProps = {
  safeAddress: string;
} & SafeTransactionEstimationRequest;

export const fetchSafeTxGasEstimation = async ({
  safeAddress,
  ...body
}: FetchSafeTxGasEstimationProps): Promise<SafeTransactionEstimation> => {
  return postSafeGasEstimation(
    _getChainId(),
    checksumAddress(safeAddress),
    body,
  );
};

export const getRecommendedNonce = async (
  safeAddress: string,
): Promise<number> => {
  const { recommendedNonce } = await fetchSafeTxGasEstimation({
    safeAddress,
    value: '0',
    operation: Operation.CALL,
    // Workaround: use a cancellation transaction to fetch only the recommendedNonce
    to: safeAddress,
    data: '0x',
  });
  return recommendedNonce;
};

export const canExecuteCreatedTx = async (
  safeInstance: GnosisSafe,
  nonce: string,
  lastTx: Transaction | null,
): Promise<boolean> => {
  const safeNonce = (await safeInstance.methods.nonce().call()).toString();
  const thresholdAsString = await safeInstance.methods.getThreshold().call();
  const threshold = Number(thresholdAsString);

  // Needs to collect owners signatures
  if (threshold > 1) {
    return false;
  }

  // Allow first tx.
  if (Number(nonce) === 0) {
    return true;
  }

  // Allow if nonce === safeNonce and threshold === 1
  if (nonce === safeNonce) {
    return true;
  }

  return false;
};
