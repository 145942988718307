import styled, { css } from 'styled-components';

import { ReactComponent as SvgDropdown } from 'shared/assets/icons/icon-dropdown-arrow.svg';

export const CollapseButton = styled.button<{ rotateIcon?: boolean }>`
  padding: 0;
  border: 0;
  background: none;
  transform: rotate(180deg);
  transition: all 0.2s;
  
  svg {
    width: 15px;
    height: 10px; 
  }

  ${(props) =>
    props.rotateIcon &&
    css`
      transform: rotate(0);
    `};
`;
export const Dropdown = styled(SvgDropdown)`
  width: 20px;
  height: 20px;
  stroke: var(--placeholder);
`;

export const CardWrapper = styled.div<{ isActive?: boolean }>`
  max-width: 275px;
  cursor: pointer;
  box-sizing: border-box;
  margin-bottom: 20px;
  padding: 20px;
  background: var(--highlight);
  border: 1px solid var(--border);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 62px;
  transition: max-height 0.25s ease-out;
  overflow: hidden;

  ${(props) =>
    !props.isActive &&
    css`
      max-height: 160px;
      transition: max-height 0.15s ease-in;
    `};
`;

export const CardHeader = styled.header`
  display: flex;
  justify-content: space-between;
`;
export const CardHeaderText = styled.div`
  display: flex;
  align-items: center;
  color: var(--object-secondary);
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
  font-family: Lato;
`;
export const CardFooter = styled.footer`
  display: flex;
  justify-content: space-between;
  margin: 10px 0 0 0;
`;
export const CardValue = styled.div<{ isActive?: boolean }>`
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  font-family: Lato;
  color: var(--object-primary);
  margin-top: 20px;

  max-height: 0;
  transition: max-height 0.25s ease-out;
  overflow: hidden;

  ${(props) =>
    !props.isActive &&
    css`
      max-height: 78px;
      transition: max-height 0.15s ease-in;
    `};
`;
