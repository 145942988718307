import { useEffect, useState } from 'react';
import Tippy from '@tippyjs/react';

import tw from 'twin.macro';

import { getExchangeInfoById } from 'shared/constants/exchanges';
import {
  CurrencyType,
  WalletType,
  AccountType,
  ExchangeAccountsType,
} from 'shared/types';
import { Loader, Placeholder } from 'shared/components';

import { AccountsStoreInstance } from 'services';

import { ExchangeIcon } from '../ExchangeAbstract';
import { Account } from '../Account';
import { SMALL_BALANCE_VALUE } from '../../../constants';
import { TransferFiltersType } from '../../../types';

import * as S from './styled';
import { toJS } from 'mobx';

type TransferColumnProps = {
  isFromWallet: boolean;
  accounts: AccountType[];
  availableCurrencies: CurrencyType[];
  chosenCurrency: string;
  chosenWallet: WalletType | null;
  chosenSecondWallet: WalletType | null;
  couldAddTransaction: boolean;
  couldAddTransactionProposal: boolean;
  exchanges: any;
  filters: TransferFiltersType;
  isAccountsReady: boolean;
  paddingBottom: string;
  onClick: any;
  onReset: any;
  side: 'from' | 'to';
  sourceColumn: null | 'from' | 'to';
  isAccountsLoading: boolean | undefined;
  currentAccountCount: number;
};

const TransferColumn = ({
  accounts,
  availableCurrencies,
  chosenCurrency,
  chosenWallet,
  chosenSecondWallet,
  couldAddTransaction,
  couldAddTransactionProposal,
  exchanges,
  filters,
  isFromWallet,
  isAccountsReady,
  paddingBottom,
  side = 'from',
  onClick,
  onReset,
  sourceColumn,
  isAccountsLoading,
  currentAccountCount,
}: TransferColumnProps) => {
  const [openAccounts, setOpenAccounts] = useState<number[]>([]);
  const [openExchanges, setOpenExchanges] = useState<string[]>([]);
  const [openSubAccounts, setOpenSubAccounts] = useState<number[]>([]);
  const [openWalletTypes, setOpenWalletTypes] = useState<string[]>([]);
  const [initiallyExpanded, setInitiallyExpanded] = useState(false);

  const { exchangesStatuses } = AccountsStoreInstance;

  useEffect(() => {
    if (!initiallyExpanded && exchanges.length) {
      expandAll();
      setInitiallyExpanded(true);
    }
  }, [exchanges]);

  function expandAll() {
    setOpenExchanges(
      exchanges.map((item: ExchangeAccountsType) => item.exchange),
    );
    setOpenAccounts(
      accounts
        .filter((item: AccountType) => item.master_id === null)
        .map((item: AccountType) => item.id),
    );
    setOpenSubAccounts(accounts.map((item: AccountType) => item.id));
    setOpenWalletTypes(
      accounts.flatMap((account) => {
        return account.wallets
          .map((wallet) => `${account.id}_${wallet.type}`)
          .filter((v: string, i: number, a: string[]) => a.indexOf(v) === i);
      }),
    );
  }

  function collapseAll() {
    setOpenExchanges([]);
    setOpenAccounts([]);
    setOpenSubAccounts([]);
    setOpenWalletTypes([]);
  }

  function handleOpenExchanges(name: string) {
    setOpenExchanges((prevOpenExchanges) => {
      const isOpen = prevOpenExchanges.includes(name);

      if (isOpen) {
        return prevOpenExchanges.filter((item) => item !== name);
      } else {
        return [...prevOpenExchanges, name];
      }
    });
  }

  function handleOpenAccounts(id: number) {
    setOpenAccounts((prevOpenAccounts) => {
      const isOpen = prevOpenAccounts.includes(id);

      if (isOpen) {
        return prevOpenAccounts.filter((item) => item !== id);
      } else {
        return [...prevOpenAccounts, id];
      }
    });
  }

  function handleOpenSubAccounts(id: number) {
    setOpenSubAccounts((prevOpenSubAccounts) => {
      const isOpen = prevOpenSubAccounts.includes(id);

      if (isOpen) {
        return prevOpenSubAccounts.filter((item) => item !== id);
      } else {
        return [...prevOpenSubAccounts, id];
      }
    });
  }

  function handleOpenWalletTypes(accountId: number, walletType: string) {
    const name = `${accountId}_${walletType}`;

    setOpenWalletTypes((prevOpenWalletTypes) => {
      const isOpen = prevOpenWalletTypes.includes(name);

      if (isOpen) {
        return prevOpenWalletTypes.filter((item) => item !== name);
      } else {
        return [...prevOpenWalletTypes, name];
      }
    });
  }

  const filteredAccounts = accounts.filter((account: AccountType) => {
    const subAccounts = accounts.filter(
      (item: AccountType) => item.master_id === account.id,
    );

    if (side === 'from' || side === 'to') {
      const accountCurrencies: string[] = account.wallets
        .map((wallet: WalletType) => wallet.currency)
        .filter((currency) => filters.currencies.includes(currency));

      const subAccountCurrencies: string[] = subAccounts.flatMap(
        (subAccount: AccountType) =>
          subAccount.wallets
            .map((wallet: WalletType) => wallet.currency)
            .filter((currency) => filters.currencies.includes(currency)),
      );

      const isAllowed =
        accountCurrencies.length > 0 || subAccountCurrencies.length > 0;

      if (!isAllowed) {
        return false;
      }
    }

    if (side === 'to' && chosenCurrency) {
      const accountCurrencies: string[] = account.wallets.map(
        (wallet: WalletType) => wallet.currency,
      );

      const subAccountCurrencies: string[] = subAccounts.flatMap(
        (subAccount: AccountType) =>
          subAccount.wallets.map((wallet: WalletType) => wallet.currency),
      );

      const isAllowed =
        accountCurrencies.includes(chosenCurrency) ||
        subAccountCurrencies.includes(chosenCurrency);

      if (!isAllowed) {
        return false;
      }
    }

    if (filters.isHideSmallBalances) {
      const accountBalances: string[] = account.wallets
        .filter((wallet: WalletType) =>
          chosenCurrency && side === 'to'
            ? wallet.currency === chosenCurrency
            : true,
        )
        .filter((wallet) => filters.currencies.includes(wallet.currency))
        .filter(({ available, id }) =>
          side === 'from' && chosenWallet?.id !== id
            ? Number(available) >= SMALL_BALANCE_VALUE
            : true,
        )
        .map((wallet: WalletType) => wallet.available);

      const subAccountBalances: string[] = subAccounts
        .flatMap((subAccount: AccountType) =>
          subAccount.wallets
            .filter((wallet: WalletType) =>
              chosenCurrency && side === 'to'
                ? wallet.currency === chosenCurrency
                : true,
            )
            .filter((wallet) => filters.currencies.includes(wallet.currency)),
        )
        .filter(({ available, id }) =>
          side === 'from' && chosenWallet?.id !== id
            ? Number(available) >= SMALL_BALANCE_VALUE
            : true,
        )
        .map((wallet: WalletType) => wallet.available);

      const isAllowed =
        accountBalances.length > 0 || subAccountBalances.length > 0;

      if (!isAllowed) {
        return false;
      }
    }

    /* if (
      account.wallets.find((wallet) => wallet.id === chosenSecondWallet?.id)
    ) {
      return false;
    } */

    return true;
  });

  const filteredAccountIds = filteredAccounts.map(
    (item: AccountType) => item.id,
  );
  const filteredExchangeIds = Array.from(
    new Set(filteredAccounts.map((item: AccountType) => item.exchange)),
  );

  const isShouldFilterByCurrency = side === 'to' || side === 'from';

  const isEmpty = filteredAccounts.length === 0 || currentAccountCount === 0;

  return (
    <>
      {exchanges.length > 0 && isAccountsReady ? (
        <S.TransferList>
          <S.TransferListWrap paddingBottom={paddingBottom}>
            {!isEmpty &&
              exchanges
                .filter((item: ExchangeAccountsType) =>
                  isShouldFilterByCurrency ||
                  (filters.isHideSmallBalances && side !== 'to')
                    ? filteredExchangeIds.includes(item.exchange)
                    : true,
                )
                .map((exchange: ExchangeAccountsType) => {
                  const exchangeInfo = getExchangeInfoById(exchange.exchange);
                  const isOpenExchange = openExchanges.includes(
                    exchange.exchange,
                  );

                  const statusData = exchangesStatuses.find(
                    (status) => status.exchange === exchangeInfo?.api_id,
                  );
                  const exchangeStatus = statusData?.ok;

                  return (
                    <S.Row key={exchange.exchange}>
                      <S.ExchangeTop
                        onClick={() => handleOpenExchanges(exchange.exchange)}
                      >
                        {exchangeInfo?.logo_url ? (
                          <ExchangeIcon
                            style={{
                              backgroundImage: `url(${exchangeInfo.logo_url})`,
                            }}
                            disabled={false}
                          />
                        ) : null}
                        <S.ExchangeName>{exchange.exchange}</S.ExchangeName>
                        {!exchangeStatus && statusData && (
                          <Tippy
                            theme="transparent"
                            placement="top"
                            content="Some functionality could be unavailable now"
                          >
                            <S.WarningLogo />
                          </Tippy>
                        )}
                      </S.ExchangeTop>
                      {isOpenExchange && Array.isArray(exchange.accounts) ? (
                        <div tw="w-full">
                          {exchange.accounts
                            .filter((acc: AccountType) =>
                              isShouldFilterByCurrency ||
                              (filters.isHideSmallBalances && side !== 'to')
                                ? filteredAccountIds.includes(acc.id)
                                : true,
                            )
                            .filter(
                              (acc: AccountType) => acc.master_id === null,
                            )
                            .map((exchangeAccount: any, idx: number) => (
                              <Account
                                isFromWallet={isFromWallet}
                                key={idx}
                                account={exchangeAccount}
                                accounts={accounts}
                                availableCurrencies={availableCurrencies}
                                chosenCurrency={chosenCurrency}
                                chosenSecondWallet={chosenSecondWallet}
                                chosenWallet={chosenWallet}
                                couldAddTransaction={couldAddTransaction}
                                couldAddTransactionProposal={
                                  couldAddTransactionProposal
                                }
                                exchange={exchange}
                                filteredAccountIds={filteredAccountIds}
                                filters={filters}
                                handleOpenAccounts={handleOpenAccounts}
                                handleOpenSubAccounts={handleOpenSubAccounts}
                                handleOpenWalletTypes={handleOpenWalletTypes}
                                isShouldFilterByCurrency={
                                  isShouldFilterByCurrency
                                }
                                onClick={onClick}
                                openAccounts={openAccounts}
                                openSubAccounts={openSubAccounts}
                                openWalletTypes={openWalletTypes}
                                side={side}
                              />
                            ))}
                        </div>
                      ) : null}
                    </S.Row>
                  );
                })}
            {isEmpty ? (
              <S.NotAvalable>
                <p>
                  <span>NOT AVAILABLE</span>
                </p>
                <p>
                  There is no account with such currency to transfer. Try to
                  pick another one.
                </p>
              </S.NotAvalable>
            ) : null}
          </S.TransferListWrap>
        </S.TransferList>
      ) : !isAccountsLoading && !exchanges.length ? (
        <Placeholder text="No accounts found" height="140px" />
      ) : (
        <Loader />
      )}
    </>
  );
};

export default TransferColumn;
