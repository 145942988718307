// @ts-nocheck

import { useState, useContext } from 'react';
import { observer } from 'mobx-react';
import { Formik } from 'formik';
import AppContext from 'shared/contexts/AppContext';

import * as Form from 'shared/components/Form';
import { SafeStoreInstance } from '../../mobx/SafeStore';
import { MultisigTransactionsStoreInstance } from '../../mobx/MultisigTransactionsStore';

import * as M from '../styled/main';

function RejectTransaction() {
  const [wasSubmitted, setWasSubmitted] = useState(false);
  const [amount, setAmount] = useState(0.01);
  const [recipient] = useState('');
  const { openModal } = useContext(AppContext);
  const { wallet } = SafeStoreInstance;

  const onCloseModal = () => {
    openModal(null);
  };

  const { rejectTransaction, activeTransaction } =
    MultisigTransactionsStoreInstance;

  return (
    <Formik
      initialValues={{
        data: '',
        recipient,
        amount,
        execute: true,
      }}
      onSubmit={(values) => {
        console.log(values);
      }}
    >
      {({ errors, touched }) => (
        <Form.MainForm>
          <>
            <Form.Label>
              This action will reject this transaction. A separate
              transaction will be performed to submit the rejection.
            </Form.Label>
            <p />
            <p />
            <Form.Label>
              <b>
                Transaction Nonce:{' '}
                {
                  activeTransaction?.detailedExecutionInfo
                    ?.detailedExecutionInfo?.nonce
                }
              </b>
            </Form.Label>
          </>
          <Form.FormFooter>
            <Form.FooterText>
              You're about to create a rejection transaction and will have
              to confirm it with your currently connected wallet.
            </Form.FooterText>
            <Form.Buttons>
              <M.CancelButton
                type="button"
                onClick={onCloseModal}
              >
                Cancel
              </M.CancelButton>
              <M.SignButton
                disabled={wasSubmitted}
                type="button"
                onClick={() => {
                  try {
                    setWasSubmitted(true);
                    rejectTransaction(() => {
                      openModal(null);
                    });
                  } catch (error) {
                    setWasSubmitted(false);
                  }
                }}
              >
                {wasSubmitted
                  ? `Sign on ${wallet.name}`
                  : 'Reject transaction'}
              </M.SignButton>
            </Form.Buttons>
          </Form.FormFooter>
        </Form.MainForm>
      )}
    </Formik>
  );
}

const C = observer(RejectTransaction);

export { C as RejectTransaction };
