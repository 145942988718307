import styled from 'styled-components/macro';

import { ReactComponent as SvgArrow } from 'shared/assets/icons/icon-direction-down.svg';
import { ReactComponent as SvgTransfer } from 'shared/assets/icons/icon-transfer.svg';

export const Container = styled.div`
  border-bottom: 2px solid var(--color-border-light);
  position: relative;
  margin-bottom: 5px;
`;

export const Avatar = styled(SvgArrow)`
  position: absolute;
  bottom: -10px;
  left: 50%;
  margin-left: -12px;
  color: var(--color-border-light);
  background-color: var(--color-background-paper);
  width: 35px;
  height: 35px;
`;

export const Text = styled.span`
  font-weight: 400;
  font-size: 16px;
  color: var(--object-secondary);
`;

export const TextData = styled.span`
  font-size: 14px;
`;

export const Hr = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 1px;
    background: var(--border);
  }
`;
export const IconTransfer = styled(SvgTransfer)`
  width: 40px;
  height: 40px;
  padding: 10px 0;
  stroke: var(--border);
  background-color: var(--base);
  z-index: 2;
  transform: rotate(90deg);
`;
