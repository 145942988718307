import styled, { css } from 'styled-components/macro';
import { ReactComponent as SvgSun } from '../../assets/icons/icon-sun.svg';
import { ReactComponent as SvgMoon } from '../../assets/icons/icon-moon.svg';

export const ThemeWrap = styled.div`
  width: 70px;
  border: 1px solid var(--border);
  border-radius: 20px;
  position: absolute;
  bottom: 10px;
  left: 20px;
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
  background-color: var(--base);
`;
const IconCss = css`
  width: 20px;
  height: 20px;
  stroke: currentColor;
`;
export const Button = styled.button`
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
`;
export const IconSun = styled(SvgSun)`
  ${IconCss}
`;
export const IconMoon = styled(SvgMoon)`
  ${IconCss}
`;
