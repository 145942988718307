import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { requestGet } from 'shared/axios';
import { GetUsersResponse } from '../types';
import { getNextPageParam, stringifyUrl } from 'shared/helpers/query';
import { useFilters } from 'shared/hooks/useFilters';
import { useEffect } from 'react';

const limit = 30;

export const useUsersQuery = () => {
  const [getFilters] = useFilters('users_search');

  const { data, ...rest } = useInfiniteQuery(
    ['users'],
    async ({ pageParam = 0 }) => {
      const filters = getFilters();
      const { data } = await requestGet(
        stringifyUrl('/users/', filters, pageParam, limit),
      );

      return data;
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      getNextPageParam: ({ next }) => getNextPageParam({ next, limit }),
    },
  );

  return {
    ...rest,
    rows: data?.pages.map(({ results }) => results).flat(),
    filters_data: data?.pages[data?.pages?.length - 1]?.filters_data,
    data,
  };
};
