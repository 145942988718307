import { action, makeObservable, observable, runInAction } from 'mobx';

import { requestGet } from 'shared/axios';
import {
  AccountType,
  ExchangeAccountsType,
  ExchangeStatusType,
  FiatAddressType,
  WalletType,
} from 'shared/types';

import { IAccountsStore } from '../models';
import groupBy from 'lodash.groupby';
import { Whitelist } from 'features/Transfer/types';
import { isValidChecksumAddress } from 'ethereumjs-util';

class AccountsStore implements IAccountsStore {
  constructor() {
    makeObservable(this);
  }

  @observable public accounts: AccountType[] = [];
  @observable public fiatAddresses: FiatAddressType[] = [];
  @observable public accountsMap: Map<number, AccountType> = new Map();
  @observable public walletsMap: Map<number, any> = new Map();
  @observable public fiatAddressesMap: Map<number, FiatAddressType> = new Map();
  @observable public unsupportedBalances: any = {};
  @observable public whitelists: any[] = [];
  @observable public exchanges: ExchangeAccountsType[] = [];
  @observable public exchangesStatuses: ExchangeStatusType[] = [];
  @observable public multisigAccounts?: string[] = undefined;
  @observable public isAccountsLoading?: boolean = false;

  @action.bound public getAccountById(id: number): Nullable<AccountType> {
    return this.accounts?.find((item) => item.id === id) ?? null;
  }

  @action.bound public getFiatWalletById(accountId: number, walletId: number) {
    return this.fiatAddresses?.find(
      (item) => item.wallet === walletId && item.id === accountId,
    );
  }

  @action.bound public async fetchAccounts(refetch = false) {
    try {
      if (!refetch) {
        this.isAccountsLoading = true;
      }
      const [accounts, whitelists, wallets, depositAddresses, fiatAddresses] =
        await Promise.all([
          requestGet('/accounts/'),
          requestGet('/whitelist/'),
          requestGet('/wallets/'),
          requestGet('/deposit-addresses/'),
          requestGet('/fiat-addresses/'),
        ]);

      if (accounts.status >= 200 && whitelists.status >= 200) {
        runInAction(() => {
          const groupedWallets = groupBy(
            Array.isArray(wallets.data) ? wallets.data : [],
            'account',
          );
          const groupedDepositAddresses = groupBy(
            Array.isArray(depositAddresses.data) ? depositAddresses.data : [],
            'wallet',
          );
          this.whitelists = whitelists.data;
          this.fiatAddresses = Array.isArray(fiatAddresses.data)
            ? fiatAddresses.data
            : [];
          this.accounts = Array.isArray(accounts.data)
            ? accounts.data.map((account) => {
                const wallets = groupedWallets[account.id] ?? [];
                return {
                  ...account,
                  wallets: wallets.map((wallet: WalletType) => {
                    if (wallet?.available === null || wallet?.total === null) {
                      this.unsupportedBalances[account.exchange] = {
                        ...this.unsupportedBalances[account.exchange],
                        [wallet.currency]: true,
                      };
                    }

                    const isWhitelisted = whitelists.data.find(
                      ({ deposit_address, currency }: Whitelist) =>
                        deposit_address === wallet.deposit_address &&
                        (currency === wallet.currency || currency === null),
                    );
                    const deposit_addresses =
                      groupedDepositAddresses[wallet.id] ?? [];

                    this.walletsMap.set(wallet.id, {
                      ...wallet,
                      deposit_addresses,
                      isWhitelisted,
                    });

                    return {
                      ...wallet,
                      deposit_addresses,
                      isWhitelisted,
                    };
                  }),
                };
              })
            : [];
          const groupedAccounts = groupBy(this.accounts, 'exchange');
          this.exchanges = Object.entries(groupedAccounts).map((exchange) => ({
            exchange: exchange[0],
            accounts: exchange[1],
          })) as ExchangeAccountsType[];
          this.multisigAccounts = this.accounts
            .filter(({ is_multisig }) => is_multisig)
            .map(
              ({ wallets }) =>
                // @ts-ignore
                wallets[0]?.deposit_addresses?.[0]?.deposit_address,
            )
            .filter((address) => isValidChecksumAddress(address));

          this.accounts.forEach(({ id, ...rest }) => {
            this.accountsMap.set(id, { id, ...rest });
          });
          this.fiatAddressesMap = new Map(
            this.fiatAddresses.map((fiatAddress) => [
              fiatAddress.id,
              fiatAddress,
            ]),
          );
        });
      }
    } catch (e) {
      console.log('get accounts error:', e);
    } finally {
      this.isAccountsLoading = false;
    }
  }

  @action.bound public async fetchExchangesStatuses() {
    try {
      const response = await requestGet('/exchanges/statuses/');
      if (response.status >= 200) {
        runInAction(() => {
          this.exchangesStatuses = Array.isArray(response.data)
            ? response.data
            : [];
        });
      }
    } catch (e) {
      console.log('get exchanges statuses error:', e);
    }
  }
}

export const AccountsStoreInstance = new AccountsStore();
