import { format } from 'date-fns';
import { useCallback, useMemo, useState } from 'react';
import { isUndefined } from 'shared/helpers/strings';
import { useFilters } from 'shared/hooks/useFilters';
import {
  FilterClearButton,
  FromToInputs,
  Label,
  LabelWrap,
} from 'shared/components/FilterAbstract/FilterAbstract';
import DatePicker from 'react-datepicker';

interface FilterDatesProps extends FiltersProps {
  min: Nullable<Date>;
  max: Nullable<Date>;
}

const dateFormat = 'yyyy-MM-dd';

const formatDate = (value: any) => {
  if (isUndefined(value)) return null;
  return format(value, dateFormat);
};

export const FilterDates = ({
  storageKey,
  max,
  min,
  onSubmit,
  disabled,
}: FilterDatesProps) => {
  const [getFilters, setFilters] = useFilters(storageKey);
  const { updated_at_after, updated_at_before } = getFilters();
  const [startDate, setStartDate] = useState<Date | null>(
    !isUndefined(updated_at_after) ? new Date(updated_at_after) : null,
  );
  const [endDate, setEndDate] = useState<Date | null>(
    !isUndefined(updated_at_before) ? new Date(updated_at_before) : null,
  );

  const onChangeStart = useCallback((start: Date | null) => {
    setStartDate(start);
    setFilters({
      updated_at_after: formatDate(start),
    });
  }, []);

  const onChangeEnd = useCallback((end: Date | null) => {
    setEndDate(end);
    setFilters({
      updated_at_before: formatDate(end),
    });
  }, []);

  const onReset = useCallback(() => {
    onChangeStart(null);
    onChangeEnd(null);
    onSubmit();
  }, []);

  const minPlaceholderText = useMemo(
    () => (!min ? 'Start' : format(min, dateFormat)),
    [min],
  );
  const maxPlaceholderText = useMemo(
    () => (!max ? 'End' : format(max, dateFormat)),
    [max],
  );

  return (
    <>
      <LabelWrap>
        <Label>Date</Label>
        {(updated_at_before || updated_at_after) && (
          <FilterClearButton type="button" onClick={onReset}>
            Clear
          </FilterClearButton>
        )}
      </LabelWrap>
      <FromToInputs>
        <DatePicker
          name="updated_at_after"
          selected={startDate}
          onCalendarClose={onSubmit}
          onChange={onChangeStart}
          popperPlacement="bottom-end"
          placeholderText={minPlaceholderText}
          disabled={disabled}
          dateFormat={dateFormat}
          maxDate={endDate}
        />
        <DatePicker
          name="updated_at_before"
          selected={endDate}
          onCalendarClose={onSubmit}
          onChange={onChangeEnd}
          popperPlacement="bottom-end"
          placeholderText={maxPlaceholderText}
          disabled={disabled}
          dateFormat={dateFormat}
          minDate={startDate}
        />
      </FromToInputs>
    </>
  );
};
