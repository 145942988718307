import {
  ChainInfo,
  getBalances,
  getChainsConfig,
  getTransactionQueue,
  SafeBalanceResponse,
  getTransactionHistory,
  getOwnedSafes,
  getSafeInfo,
} from '@gnosis.pm/safe-react-gateway-sdk';
import detectEthereumProvider from '@metamask/detect-provider';
import { action, makeObservable, observable, toJS, runInAction } from 'mobx';
import {
  _setChain,
  formatAmount,
  fromTokenUnit,
  _getChain,
  isHardwareWallet,
} from 'features/Multisig/helpers';
import {
  calculateGasPrice,
  getFeesPerGas,
  setMaxPrioFeePerGas,
} from 'features/Multisig/mobx/logic/ethTransactions';
import { isMaxFeeParam } from 'features/Multisig/mobx/logic/gas';
import { setWeb3 } from 'features/Multisig/mobx/logic/getWeb3';
import {
  estimateGasForDeployingSafe,
  instantiateSafeContracts,
} from 'features/Multisig/mobx/logic/safeContracts';
import { MultisigTransactionsStoreInstance } from './MultisigTransactionsStore';
import { CTSafeInfo, ISafeStore } from '../models';
import Web3 from 'web3';
import Onboard from 'bnc-onboard';
import { AccountsStoreInstance, SettingsStoreInstance } from 'services';
import { API } from 'bnc-onboard/dist/src/interfaces';
import { INFURA_TOKEN } from '../constants';

const isSupportedWallet = (name: string) =>
  ['MetaMask', 'Ledger'].includes(name);
class SafeStore implements ISafeStore {
  @observable userAccount?: string | undefined | null = undefined;

  @observable safeCreationError: string | undefined = undefined;
  @observable isCoinsLoading = false;

  @observable chains?: ChainInfo[];
  @observable coins?: SafeBalanceResponse;
  @observable safeCreationTransaction: string | undefined = undefined;
  @observable safes?: CTSafeInfo[];
  @observable wallet?: any = undefined;

  @observable selectedChain?: ChainInfo = _getChain();
  @observable selectedSafe?: CTSafeInfo;
  @observable onboard: Nullable<API> = null;
  @observable isSelectedSafeLoading = false;

  @observable web3: Nullable<Web3> = null;

  get isHardwareWallet() {
    return isHardwareWallet(this.wallet);
  }

  @action.bound public async selectChain(chain: ChainInfo) {
    if (!this.onboard || this.selectedChain?.chainId !== chain.chainId) {
      this.onboard = Onboard({
        networkId: Number(chain.chainId),
        subscriptions: {
          wallet: (wallet) => {
            this.wallet = wallet;
            setWeb3(wallet.provider);
            localStorage.setItem('lastUsedProvider', String(wallet?.name));
            instantiateSafeContracts();
          },
          address: (address) => {
            this.userAccount = address;
          },
        },
        walletSelect: {
          wallets: [
            {
              walletName: 'ledger',
              rpcUrl: `${chain.rpcUri.value}${INFURA_TOKEN}`,
            },
            { walletName: 'metamask' },
          ],
        },
        walletCheck: [
          { checkName: 'derivationPath' },
          { checkName: 'connect' },
          { checkName: 'accounts' },
          { checkName: 'network' },
        ],
      });

      const lastUsedProvider = localStorage.getItem('lastUsedProvider');
      const isProviderEnabled =
        lastUsedProvider && isSupportedWallet(lastUsedProvider);

      if (isProviderEnabled) {
        try {
          await this.onboard?.walletSelect(String(lastUsedProvider));
        } catch (error) {
          localStorage.removeItem('lastUsedProvider');
        }
      }
    }
    this.selectedChain = chain;
    _setChain(chain);
  }

  @action.bound public async selectSafe(safe: CTSafeInfo) {
    try {
      if (safe?.address?.value !== this.selectedSafe?.address?.value) {
        MultisigTransactionsStoreInstance.resetTransactionsHistory();
        MultisigTransactionsStoreInstance.resetTransactionsQueue();
        MultisigTransactionsStoreInstance.resetPagination();
      }

      this.selectedSafe = safe;
      this.addRecentlyUsedSafe(safe?.address?.value);

      this.isSelectedSafeLoading = true;

      await Promise.all([
        this.getCoins(),
        MultisigTransactionsStoreInstance.getTransactionHistory(),
        MultisigTransactionsStoreInstance.getTransactionQueue(),
      ]);
    } catch (error) {
      console.error('Error while selecting safe:', error);
    } finally {
      this.isSelectedSafeLoading = false;
    }
  }

  get recentlyUsedSafes(): string[] {
    const json = localStorage.getItem('LRU_SAFES');
    return json && json !== '' ? JSON.parse(json) : [];
  }

  private sortSafes() {
    this.safes?.sort((a, b) => {
      const aIndex = this.recentlyUsedSafes.findIndex(
        (address) => address === a.address.value,
      );
      const bIndex = this.recentlyUsedSafes.findIndex(
        (address) => address === b.address.value,
      );

      if (aIndex === -1 && bIndex === -1) {
        return 0;
      }

      if (aIndex !== -1 && (bIndex === -1 || aIndex < bIndex)) {
        return -1;
      } else if (bIndex !== -1 && (aIndex === -1 || bIndex < aIndex)) {
        return 1;
      }

      return 0;
    });
  }

  @action.bound public addRecentlyUsedSafe(address: string) {
    const currentRecentlyUsedSafes = this.recentlyUsedSafes;
    const index = currentRecentlyUsedSafes.findIndex(
      (value) => value === address,
    );
    if (index !== -1) {
      currentRecentlyUsedSafes.splice(index, 1);
    }

    currentRecentlyUsedSafes.unshift(address);

    localStorage.setItem('LRU_SAFES', JSON.stringify(currentRecentlyUsedSafes));
    this.sortSafes();
  }

  constructor() {
    makeObservable(this);
  }

  @action.bound public async updateSafesBalances() {
    // @ts-ignore
    await Promise.all(this.safes?.map((safe) => this.updateSafeBalance(safe)));
  }

  @action.bound public async updateSafeBalance(safe: CTSafeInfo) {
    const { fiatTotal } = await getBalances(
      String(this.selectedChain?.chainId),
      safe.address.value,
      'USD',
      { exclude_spam: true, trusted: false },
    );

    safe.balance = fiatTotal;
  }

  @action.bound public async setUserAccount(
    account: string | undefined | null,
  ) {
    this.userAccount = account;
  }

  @action.bound public async getChains() {
    try {
      const { results } = await getChainsConfig();
      this.chains = results;
      const chain = results?.find(
        (ch) =>
          ch?.chainId ===
          String(SettingsStoreInstance.appSettings.gnosis_chain_id),
      );
      // @ts-ignore
      this.selectChain(chain);
    } catch (error) {
      //
    }
  }

  @action.bound public async getCoins() {
    try {
      this.isCoinsLoading = true;
      if (!this.selectedChain) return;
      if (!this.selectedSafe) return;

      this.coins = await getBalances(
        this.selectedChain?.chainId,
        this.selectedSafe?.address.value,
        'USD',
        { exclude_spam: true, trusted: false },
      );
    } catch (error) {
      //
    } finally {
      this.isCoinsLoading = false;
    }
  }

  private async getSafeInfo(safeAddress: string): Promise<CTSafeInfo> {
    const [info, balance] = await Promise.all([
      getSafeInfo(String(this.selectedChain?.chainId), safeAddress),
      getBalances(String(this.selectedChain?.chainId), safeAddress, 'USD', {
        exclude_spam: true,
        trusted: false,
      }),
    ]);

    return { ...info, balance: balance.fiatTotal };
  }

  @action.bound public async getSafes() {
    try {
      if (!this.selectedChain) return;

      if (!AccountsStoreInstance.multisigAccounts) {
        await AccountsStoreInstance.fetchAccounts();
      }

      const data = await Promise.allSettled(
        // @ts-ignore
        AccountsStoreInstance.multisigAccounts?.map((safeAddress) =>
          this.getSafeInfo(safeAddress),
        ),
      );

      const fulfilledData = data
        .filter(({ status }) => status === 'fulfilled')
        // @ts-ignore
        .map(({ value }) => value);

      fulfilledData.sort((a, b) =>
        Number(a.balance) > Number(b.balance)
          ? -1
          : Number(a.balance) < Number(b.balance)
          ? 1
          : 0,
      );

      runInAction(() => {
        this.safes = fulfilledData;
        this.sortSafes();
        const recentlyUsedSafe = this.safes.findIndex(
          ({ address }) => address.value === this.recentlyUsedSafes[0],
        );

        if (
          !this.selectedSafe ||
          this.safes?.findIndex(
            ({ address }) => address.value === this.selectedSafe?.address.value,
          ) === -1 ||
          recentlyUsedSafe !== -1
        ) {
          if (recentlyUsedSafe !== -1) {
            this.selectSafe(this.safes[recentlyUsedSafe]);
          } else {
            this.selectSafe(this.safes[0]);
          }
        }
      });
    } catch (error) {
      //
    }
  }

  @action.bound getHeaderSafes(width: number) {
    const firstSafes = this.safes?.slice(
      0,
      width > 1410 + 10 ? 3 : width > 1024 + 10 ? 2 : 1,
    );

    // @ts-ignore
    if (
      this.selectedSafe &&
      firstSafes?.findIndex(
        ({ address }) => address.value === this.selectedSafe?.address.value,
      ) === -1
    ) {
      firstSafes?.pop();
      // @ts-ignore
      firstSafes?.push(this.selectedSafe);
      return firstSafes;
    }

    return firstSafes;
  }

  @action.bound getDropdownSafes(width: number) {
    const firstSafes = this.safes?.slice(
      0,
      width > 1410 + 10 ? 3 : width > 1024 + 10 ? 2 : 1,
    );

    const restSafes = this.safes?.slice(
      width > 1410 + 10 ? 3 : width > 1024 + 10 ? 2 : 1,
      this.safes?.length,
    );

    // @ts-ignore
    if (
      restSafes?.findIndex(
        ({ address }) => address.value === this.selectedSafe?.address.value,
      ) !== -1
    ) {
      // @ts-ignore
      restSafes?.splice(
        restSafes.findIndex(
          ({ address }) => address.value === this.selectedSafe?.address.value,
        ),
        1,
      );
      // @ts-ignore
      restSafes?.unshift(firstSafes[firstSafes?.length - 1]);
      return restSafes;
    }

    return restSafes;
  }
}

export const SafeStoreInstance = new SafeStore();
