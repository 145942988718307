import { useFilters } from 'shared/hooks/useFilters';
import {
  Label,
  LabelWrap,
  FilterContent,
  Checkbox,
} from 'shared/components/FilterAbstract/FilterAbstract';
import { useState, useMemo, useCallback } from 'react';

import * as S from './styled';
import _ from 'lodash';
import Tippy from '@tippyjs/react';

interface FilterViewOptionsProps extends FiltersProps {
  entities: any[];
  label: string;
  field: string;
  entity: string;
  fieldIdFn: FieldGetter;
  fieldNameFn: FieldGetter;
}

type FieldGetter = (item: any) => any;

interface ViewOptionItemProps {
  entity: any;
  field: string;
  onChange: any;
  info: string;
  checked: boolean;
  fieldIdFn: FieldGetter;
  fieldNameFn: FieldGetter;
}

const ViewOptionItem = ({
  entity,
  field,
  onChange,
  info,
  checked,
  fieldIdFn,
  fieldNameFn,
}: ViewOptionItemProps) => {
  const id = useMemo(() => fieldIdFn(entity), [entity, fieldIdFn]);
  const name = useMemo(() => fieldNameFn(entity), [entity, fieldNameFn]);

  return (
    <S.CheckboxLabel key={id}>
      <Checkbox
        checked={checked}
        onChange={onChange}
        type="checkbox"
        name={field}
        title={name}
        value={id}
      />

      <span>{name}</span>
      {info && <Tippy theme="transparent" placement="auto" content={info}>
        <S.IconInfo />
      </Tippy>}
    </S.CheckboxLabel>
  );
};

export const parseValues = (
  statuses: any[] = [],
  action: boolean,
  value: any,
) => {
  const clone = _.cloneDeep(statuses);

  if (action) {
    clone.push(value);
  } else {
    const index = clone.findIndex((status) => status === value);
    if (index !== -1) {
      clone.splice(index, 1);
    }
  }

  return _.uniq(clone);
};

export const FilterViewOptions = ({
  entities,
  label,
  field,
  storageKey,
  fieldIdFn,
  fieldNameFn,
  onSubmit,
}: FilterViewOptionsProps) => {
  const [getFilters, setFilters] = useFilters(storageKey);
  const [isFIlterWhitelisted, setIsWhitelisted] = useState(getFilters()[field]);
  const [values, setValues] = useState(() => {
    if (isFIlterWhitelisted === true) {
      return ['whitelisted'];
    } else if (isFIlterWhitelisted === undefined) {
      if (localStorage.getItem('whitelist_view_options_both_selected') === 'true') {
        return ['whitelisted', 'non_whitelisted'];
      } else {
        return [];
      }
    } else {
      return ['non_whitelisted'];
    }
  });

  const onChange = useCallback(
    (e: any) => {
      const newValues = parseValues(values, e.target.checked, e.target.value);
      const isWhitelisted = newValues?.includes('whitelisted');
      const isNonWhitelisted = newValues?.includes('non_whitelisted');

      if (isWhitelisted && isNonWhitelisted) {
        localStorage.setItem('whitelist_view_options_both_selected', 'true');
      } else {
        localStorage.setItem('whitelist_view_options_both_selected', 'false');
      }

      if ((isWhitelisted && isNonWhitelisted) || (!isWhitelisted && !isNonWhitelisted)) {
        setFilters({
          [field]: undefined,
        });
        setIsWhitelisted(undefined);
      } else if (isWhitelisted && !isNonWhitelisted) {
        setFilters({
          [field]: true,
        });
        setIsWhitelisted(true);
      } else if (!isWhitelisted && isNonWhitelisted) {
        setFilters({
          [field]: 'false',
        });
        setIsWhitelisted('false');
      }

      setValues(newValues);
      onSubmit();
    },
    [isFIlterWhitelisted, field],
  );

  return (
    <S.FilterViewOptions>
      <LabelWrap>
        <Label>{label}</Label>
      </LabelWrap>

      <S.FilterWrap opened={true}>
        <FilterContent className="filter_content">
          {entities?.length > 0 && (
            <S.ChoosenItem>
              {entities.map((entity: any) => (
                <ViewOptionItem
                  info={entity.info}
                  key={fieldIdFn(entity)}
                  onChange={onChange}
                  checked={values?.includes(String(fieldIdFn(entity)))}
                  entity={entity}
                  field={field}
                  fieldIdFn={fieldIdFn}
                  fieldNameFn={fieldNameFn}
                />
              ))}
            </S.ChoosenItem>
          )}
        </FilterContent>
      </S.FilterWrap>
    </S.FilterViewOptions>
  );
};
