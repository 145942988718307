import { useState, useEffect } from 'react';

export const useLocalStorage = (key: string, defaultValue: any) => {
  const [isSaved, setIsSaved] = useState(true);
  const [value, setValue] = useState(() => {
    return getStorageValue(key, defaultValue);
  });

  function getStorageValue(key: string, defaultValue: any) {
    try {
      const saved = localStorage.getItem(key);

      if (saved !== null) {
        const initial = JSON.parse(saved);

        return initial || defaultValue;
      } else {
        setIsSaved(false);
        return defaultValue;
      }
    } catch (e) {
      setIsSaved(false);
      return defaultValue;
    }
  }

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue, isSaved];
};
