// @ts-nocheck
import { useContext, useEffect, useState } from 'react';
import * as T from './styled/transactions';

import { MultisigTransactionsStoreInstance } from 'features/Multisig/mobx/MultisigTransactionsStore';
import { TransactionListItem } from '@gnosis.pm/safe-react-gateway-sdk';
import { observer } from 'mobx-react-lite';
import { formattedDate } from 'shared/helpers/date';
import { TransactionRow } from './components/Transactions';
import InfiniteScroll from 'react-infinite-scroller';
import { Loader, Placeholder } from 'shared/components';
import { useWindowSize } from 'shared/hooks/useWindowSize';
import AppContext from 'shared/contexts/AppContext';

import { SafeStoreInstance } from '../mobx/SafeStore';

const TransactionsHistory = observer(() => {
  const {
    getTransactionHistory,
    transactionHistory,
    fetchMoreTransactionHistory,
    nextHistory,
    isLoadingHistory,
    initiallyLoadedHistory,
  } = MultisigTransactionsStoreInstance;

  const {
    selectedSafe,
    isSelectedSafeLoading,
  } = SafeStoreInstance;

  const { ref } = useContext(AppContext);
  const { width } = useWindowSize();
  const [scrollableTarget, setScrollableTarget] = useState(
    width && width < 1800 ? ref : undefined,
  );

  useEffect(() => {
    setScrollableTarget(width && width < 1800 ? ref : undefined);
  }, [width]);

  useEffect(() => {
    getTransactionHistory(true);

    const interval = setInterval(() => getTransactionHistory(), 10000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <T.Wrap
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      exit={{ opacity: 0, transition: { duration: 0.5 } }}
    >
      {!isLoadingHistory && transactionHistory.length ? (
        <T.TableHistory>
          <InfiniteScroll
            loader={<Loader />}
            pageStart={0}
            loadMore={fetchMoreTransactionHistory}
            hasMore={Boolean(nextHistory)}
            useWindow={scrollableTarget ? false : true}
            getScrollParent={scrollableTarget ? () => ref : undefined}
          >
            {transactionHistory?.map((item: TransactionListItem, i) =>
              item.type === 'DATE_LABEL' ? (
                <T.Next key={i}>
                  {formattedDate(item?.timestamp || 0, 'MMM DD YYYY')}
                </T.Next>
              ) : (
                <TransactionRow key={item.transaction.id} item={item} />
              ),
            )}
          </InfiniteScroll>
        </T.TableHistory>
      ) : (isSelectedSafeLoading || isLoadingHistory) ? (
        <Loader />
      ) : initiallyLoadedHistory ? (
        <Placeholder height="140px" text="No history transactions found" />
      ) : null}
    </T.Wrap>
  );
});

export default TransactionsHistory;
