import { useState } from 'react';

import { ReactComponent as WarningSvg } from 'shared/assets/icons/icon-warning.svg';

import { Link, Typography, SvgIcon } from '@mui/material';

import * as S from './styled';

const ErrorMessage: React.FC<{ children: React.ReactNode, error?: Error & { reason?: string } }> = ({ children, error }) => {
  const [showDetails, setShowDetails] = useState<boolean>(false);

  const onDetailsToggle = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setShowDetails((prev) => !prev);
  };

  return (
    <S.Container>
      <S.Message>
        <SvgIcon component={WarningSvg} inheritViewBox fontSize="small" />

        <div>
          <Typography variant="body2" component="span">
            {children}

            {error && (
              <Link component="button" onClick={onDetailsToggle} display="block">
                Details
              </Link>
            )}
          </Typography>

          {error && showDetails && (
            <S.Typography variant="body2">
              {error.reason || error.message.slice(0, 300)}
            </S.Typography>
          )}
        </div>
      </S.Message>
    </S.Container>
  );
};

export default ErrorMessage;
