// @ts-nocheck

import { useEffect, useState } from 'react';
import Circle from 'shared/components/Сircle';
import * as T from '../styled/transactions';

import {
  SettingsInfoType,
  TransactionDetails,
  TransactionInfo,
  TransferDirection,
} from '@gnosis.pm/safe-react-gateway-sdk';

import { getTxAmount } from '../../helpers';
import Copy from 'shared/components/Copy';
import { MultisigTransactionsStoreInstance } from '../../mobx/MultisigTransactionsStore';

import { TransactionDropdownInfo, TransactionMainInfo } from './Transaction';

interface TxAddressProps {
  address: string;
}

export const TxAddress = ({ address }: TxAddressProps) => (
  <T.DropAddressWrap>
    <Circle seed={address} />
    <T.DropAddress>
      <T.DropAddressText>{address}</T.DropAddressText>
      <Copy
        text={address}
        width="14px"
        height="14px"
      />
      <T.Button>
        <T.IconExternal />
      </T.Button>
    </T.DropAddress>
  </T.DropAddressWrap>
);

export const TransactionInfoSettings = ({
  txInfo,
}: {
  txInfo: TransactionInfo;
}) => {
  const { settingsInfo } = txInfo;
  const { type } = settingsInfo;

  switch (type) {
  case SettingsInfoType.SET_FALLBACK_HANDLER: {
    const { handler } = settingsInfo;
    return (
      <>
        <T.DropSend>Set fallback handler: </T.DropSend>
        <TxAddress address={handler.value} />
      </>
    );
  }

  case SettingsInfoType.ADD_OWNER:
  case SettingsInfoType.REMOVE_OWNER: {
    const { owner } = settingsInfo;
    return (
      <>
        <T.DropSend>
          {type === SettingsInfoType.ADD_OWNER ? 'Add' : 'Remove'} owner:{' '}
        </T.DropSend>
        <TxAddress address={owner.value} />
      </>
    );
  }

  case SettingsInfoType.SWAP_OWNER: {
    const { oldOwner, newOwner } = settingsInfo;
    return (
      <>
        <T.DropSend>Swap owner: </T.DropSend>
        <T.DropSend>Old owner: </T.DropSend>
        <TxAddress address={oldOwner.value} />
        <T.DropSend>New owner: </T.DropSend>
        <TxAddress address={newOwner.value} />
      </>
    );
  }

  case SettingsInfoType.CHANGE_THRESHOLD: {
    const { threshold } = settingsInfo;
    return (
      <>
        <T.DropSend>Change threshold to: </T.DropSend>
        {threshold}
      </>
    );
  }

  case SettingsInfoType.CHANGE_IMPLEMENTATION: {
    const { implementation } = settingsInfo;
    return (
      <>
        <T.DropSend>Change implementation: </T.DropSend>
        <TxAddress address={implementation.value} />
      </>
    );
  }

  case SettingsInfoType.ENABLE_MODULE:
  case SettingsInfoType.DISABLE_MODULE: {
    const { module } = settingsInfo;
    return (
      <>
        <T.DropSend>
          {type === SettingsInfoType.ENABLE_MODULE ? 'Enable' : 'Disable'}{' '}
            module:{' '}
        </T.DropSend>
        <TxAddress address={module.value} />
      </>
    );
  }

  case SettingsInfoType.SET_GUARD: {
    const { guard } = settingsInfo;
    return (
      <>
        <T.DropSend>
          {type === SettingsInfoType.ENABLE_MODULE ? 'Enable' : 'Disable'}{' '}
            module:{' '}
        </T.DropSend>
        <TxAddress address={guard.value} />
      </>
    );
  }

  case SettingsInfoType.DELETE_GUARD:
    return <T.DropSend>Delete guard</T.DropSend>;
  }
};

export const TransactionInfoTransfer = ({
  txInfo,
}: {
  txInfo: TransactionInfo;
}) => {
  const { direction, recipient, sender } = txInfo;

  switch (direction) {
  case TransferDirection.INCOMING: {
    return (
      <>
        <T.DropSend>{`Received ${getTxAmount(txInfo)} from: `}</T.DropSend>
        <TxAddress address={sender.value} />
      </>
    );
  }

  case TransferDirection.OUTGOING: {
    return (
      <>
        <T.DropSend>{`Sent ${getTxAmount(txInfo)} to: `}</T.DropSend>
        <TxAddress address={recipient.value} />
      </>
    );
  }

  case TransferDirection.UNKNOWN:
    return null;
  }
};

export const TransactionInfoCreation = ({
  txInfo,
}: {
  txInfo: TransactionInfo;
}) => {
  const { creator, factory, implementation } = txInfo;

  return (
    <>
      <T.DropSend>Creator: </T.DropSend>
      <TxAddress address={creator.value} />
      <T.DropSend>Factory: </T.DropSend>
      <TxAddress address={factory.value} />
      <T.DropSend>Mastercopy: </T.DropSend>
      <TxAddress address={implementation.value} />
    </>
  );
};

export const TransactionInfoCustom = ({
  txInfo,
}: {
  txInfo: TransactionInfo;
}) => {
  const { to } = txInfo;

  return (
    <>
      <T.DropSend>Interact with: </T.DropSend>
      <TxAddress address={to.value} />
    </>
  );
};

export const TransactionRow = ({ item, error }) => {
  const [isOpen, setOpen] = useState(false);
  const { getTransactionDetails } = MultisigTransactionsStoreInstance;
  const [details, setDetails] = useState<TransactionDetails | undefined>(
    undefined,
  );

  useEffect(() => {
    const getDetails = async () => {
      if (!details && isOpen && item.transaction.txInfo.type !== 'Creation') {
        const d = await getTransactionDetails(item?.transaction?.id);
        setDetails(d);
      }
    };

    getDetails();
  }, [isOpen]);

  return (
    <T.Row>
      <T.RowWrap>
        <TransactionMainInfo setOpen={setOpen} isOpen={isOpen} item={item} />
        <TransactionDropdownInfo
          error={error}
          isOpen={isOpen}
          item={item}
          details={details}
        />
      </T.RowWrap>
    </T.Row>
  );
};
