// @ts-nocheck

import { useState } from 'react';

import Circle from 'shared/components/Сircle';

import * as T from '../../styled/transactions';
import { toJS } from 'mobx';
import Copy from 'shared/components/Copy';

export const TransactionConfirmations = ({ tx, details }) => {
  const [openChain, setOpenChain] = useState(false);
  if (!details) return null;
  const { txStatus, executionInfo } = tx;

  const { detailedExecutionInfo } = details;
  const confirmations = detailedExecutionInfo?.confirmations || [];
  const confirmationsRequired = executionInfo?.confirmationsRequired;
  const confirmationsSubmitted = executionInfo?.confirmationsSubmitted;

  // If transaction doesn't require confirmations, don't show them
  if (!confirmationsRequired) return null;

  const isConfirmed = confirmationsSubmitted === confirmationsRequired;

  return (
    <>
      <T.ChainCreated>
        <T.IconChainCreated />
        <T.ChainTitle>Created</T.ChainTitle>
      </T.ChainCreated>
      <T.ChainConfirm success={isConfirmed}>
        {isConfirmed ? <T.IconChainInCheck /> : <T.IconChainInProcess />}
        <T.ChainTitle>Confirmations</T.ChainTitle>
        <T.ChainConfirmTitle>
          ({confirmationsSubmitted} of {confirmationsRequired})
        </T.ChainConfirmTitle>
      </T.ChainConfirm>
      {isConfirmed ? null : openChain ? (
        <T.ChainConfirmationsWrap>
          {confirmations?.map(
            ({ signer }, i) => {
              return (
                <T.ChainConfirmations key={i}>
                  <T.ChainConfirmationsDot />
                  <Circle seed={signer.value} />
                  <T.DropAddressText title={signer.value}>
                    {signer.value?.slice(0, 5)}...
                    {signer.value?.slice(-5)}
                  </T.DropAddressText>
                  <Copy
                    text={signer.value || ''}
                    width="14px"
                    height="14px"
                  />
                  <T.Button>
                    <T.IconExternal />
                  </T.Button>
                </T.ChainConfirmations>
              );
            },
          )}

          <T.ChainConfirmations>
            <T.ChainConfirmationsDot />
            <T.ChainConfirmationsTitle onClick={() => setOpenChain(false)}>
              Hide all
            </T.ChainConfirmationsTitle>
          </T.ChainConfirmations>
        </T.ChainConfirmationsWrap>
      ) : (
        <T.ChainConfirmationsWrap>
          <T.ChainConfirmations>
            <T.ChainConfirmationsDot />
            <T.ChainConfirmationsTitle onClick={() => setOpenChain(true)}>
              Show all
            </T.ChainConfirmationsTitle>
          </T.ChainConfirmations>
        </T.ChainConfirmationsWrap>
      )}

      <T.ChainExecution success={txStatus === 'SUCCESS'}>
        {txStatus === 'SUCCESS' ? (
          <T.IconChainInCheck />
        ) : (
          <T.IconChainInProcess />
        )}
        <T.ChainTitle>Execution</T.ChainTitle>
        <T.ChainText>Can be executed once the threshold is reached</T.ChainText>
      </T.ChainExecution>
    </>
  );
};
