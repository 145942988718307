import { useEffect, useCallback, useState } from 'react';
import { debounce } from 'lodash';

import { useTransfer } from 'features/Transfer/context/TransferContext';
import * as S from '../styled';
import { TransferExchangeFilterType } from 'shared/types';

import { DEBOUNCE_DELAY } from 'features/Transfer/constants';

const ACCOUNT_TYPES: TransferExchangeFilterType[] = [
  {
    exchange_type: null,
    name: 'ALL',
  },
  {
    exchange_type: 'WALLET',
    name: 'WALLETS',
  },

  {
    exchange_type: 'EXCHANGE',
    name: 'EXCHANGES',
  },
];

export const TransferHeader = () => {
  const {
    selectAccountType,
    fromTypes,
    toTypes,
    useGetWalletsFrom,
    useGetWalletsTo,
    filterSearchValue,
    handleFilterSearch,
    from,
  } = useTransfer();

  const { refetch: getWalletsFrom } = useGetWalletsFrom();
  const { refetch: getWalletsTo } = useGetWalletsTo(from?.wallet?.currency);

  const debouncedGetWalletsFrom = useCallback(
    debounce(() => getWalletsFrom(), DEBOUNCE_DELAY),
    [getWalletsFrom],
  );

  const debouncedGetWalletsTo = useCallback(
    debounce(() => getWalletsTo(), DEBOUNCE_DELAY),
    [getWalletsTo],
  );

  useEffect(() => {
    debouncedGetWalletsFrom();
  }, [fromTypes, filterSearchValue.from]);

  useEffect(() => {
    debouncedGetWalletsTo();
  }, [toTypes, filterSearchValue.to]);

  const handleSearch = (event: any, side: string) => {
    const { value } = event.target;

    if (side === 'from') {
      handleFilterSearch('from', value);
    } else {
      handleFilterSearch('to', value);
    }
  };

  const handleReset = (side: string) => {
    if (side === 'from') {
      if (filterSearchValue.from !== '') handleFilterSearch('from', '');
    } else {
      if (filterSearchValue.to !== '') handleFilterSearch('to', '');
    }
  };

  return (
    <S.Header>
      <S.HeaderTitleWrap>
        <S.HeaderTitle>
          <span>FROM</span>

          <S.SearchForm style={{ maxWidth: 'calc(100% - 110px)' }}>
            <S.IconSearch />
            <S.SearchInput
              type="text"
              name="search"
              onChange={(e) => handleSearch(e, 'from')}
              value={filterSearchValue.from}
              placeholder="Search"
            />
            <S.IconClear onClick={() => handleReset('from')} />
          </S.SearchForm>
        </S.HeaderTitle>
        <S.FilterWrap>
          {ACCOUNT_TYPES.map((type) => (
            <S.AccountFilter
              key={type.name}
              active={type.name === fromTypes.name}
              onClick={() => {
                selectAccountType('from', type);
              }}
            >
              {type.name}
            </S.AccountFilter>
          ))}
        </S.FilterWrap>
      </S.HeaderTitleWrap>
      <S.HeaderTitleWrap>
        <S.HeaderTitle>
          <span>TO</span>
          <S.SearchForm style={{ maxWidth: 'calc(100% - 67px)' }}>
            <S.IconSearch />
            <S.SearchInput
              type="text"
              name="search"
              onChange={(e) => handleSearch(e, 'to')}
              value={filterSearchValue.to}
              placeholder="Search"
            />
            <S.IconClear onClick={() => handleReset('to')} />
          </S.SearchForm>
        </S.HeaderTitle>
        <S.FilterWrap>
          {ACCOUNT_TYPES.map((type) => (
            <S.AccountFilter
              key={type.name}
              active={type.name === toTypes.name}
              onClick={() => {
                selectAccountType('to', type);
              }}
            >
              {type.name}
            </S.AccountFilter>
          ))}
        </S.FilterWrap>
      </S.HeaderTitleWrap>
    </S.Header>
  );
};
