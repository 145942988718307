import { AccountType, ColumnSide, WalletType } from 'shared/types';
import { useTransfer } from 'features/Transfer/context/TransferContext';
import {
  ExchangeIconMin,
  IconArrowWrap,
} from '../../components/ExchangeAbstract';
import { IconArrow } from '../../styled';
import { getExchangeInfoById } from 'shared/constants/exchanges';
import { format, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { useIsOpen } from 'features/Transfer/context/AccountContext';

import * as S from './SubAccountStyled';
import { WalletTypeItem } from './WalletTypeItem';

interface SubAccountItemProps {
  account: AccountType;
  side: ColumnSide;
  hasSubAccounts: boolean;
}

const balanceDateFormat = 'HH:mm';

export const SubAccountItem = ({
  account,
  side,
  hasSubAccounts,
}: SubAccountItemProps) => {
  const { filters } = useTransfer();
  const [opened, toggleSubAccount] = useIsOpen(side, 'subAccount', account?.id);
  const exchangeInfo = getExchangeInfoById(account?.exchange);
  const isOnlyMain = !hasSubAccounts && account.name === 'MAIN';

  const accountTypes = account.wallets
    .filter((item: WalletType) => filters.currencies.includes(item.currency))
    .map((item: WalletType) => item.type)
    // @ts-expect-error
    .filter((v: string, i: number, a: string[]) => a.indexOf(v) === i);

  const formattedBalanceDate = account.balance_updated_at
    ? format(
        utcToZonedTime(parseISO(account.balance_updated_at), 'UTC'),
        balanceDateFormat,
      )
    : '';

  return (
    <S.AccountWrap opened={opened}>
      {!isOnlyMain ? (
        <S.AccountHeader onClick={toggleSubAccount}>
          <IconArrowWrap rotateIcon={opened}>
            <IconArrow />
          </IconArrowWrap>
          {account?.exchange ? (
            <ExchangeIconMin
              style={{
                backgroundImage: `url(${exchangeInfo?.logo_url})`,
              }}
              disabled={false}
            />
          ) : null}

          <S.AccountTitle>
            <S.AccountName>{account.name}</S.AccountName>
            <S.AccountBalanceStatus>
              {account.is_balance_outdated || !account.balance_updated_at ? (
                <S.IconBalanceWarning />
              ) : null}
              <S.AccountBalanceUpdated
                isOutdated={
                  account.is_balance_outdated || !account.balance_updated_at
                }
              >
                {account.balance_updated_at ? (
                  <span>{formattedBalanceDate} UTC</span>
                ) : (
                  <span>Never updated</span>
                )}
              </S.AccountBalanceUpdated>
            </S.AccountBalanceStatus>
          </S.AccountTitle>
        </S.AccountHeader>
      ) : null}
      <S.Wallets hasSubAccounts={hasSubAccounts}>
        {opened
          ? accountTypes
              // @ts-expect-error
              .map((subAccountType: string) => (
                <WalletTypeItem
                  key={`${account.id}_${subAccountType}`}
                  subAccountType={subAccountType}
                  account={account}
                  side={side}
                  exchangeInfo={exchangeInfo}
                />
              ))
          : null}
      </S.Wallets>
    </S.AccountWrap>
  );
};
