import styled, { css } from 'styled-components/macro';
import { motion } from 'framer-motion';

import { ReactComponent as SvgArrow } from 'shared/assets/icons/icon-chevron-down.svg';
import { ReactComponent as SvgArrowUpRight } from 'shared/assets/icons/icon-arrow-up-right.svg';
import { ReactComponent as SvgUsers } from 'shared/assets/icons/icon-users.svg';
import { ReactComponent as SvgCopy } from 'shared/assets/icons/icon-copy-stroke.svg';
import { ReactComponent as SvgExternal } from 'shared/assets/icons/icon-external-link.svg';
import { ReactComponent as SvgCreated } from 'shared/assets/icons/icon-plus-circle.svg';
import { ReactComponent as SvgCircleEmpty } from 'shared/assets/icons/icon-circle-empty.svg';
import { ReactComponent as SvgCircleCheck } from 'shared/assets/icons/icon-whitelist-yes.svg';

export const Wrap = styled(motion.div)`
  width: 100%;
  min-width: 1010px;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 0 0 0;
`;
export const Table = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: column;
`;
export const TableHistory = styled(Table)`
  padding-bottom: 100px;
`;
export const RowWrap = styled.section`
  width: 100%;
  border-radius: 5px;
  background: var(--base);
`;
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: stretch;
  justify-content: space-between;
  position: relative;
  z-index: 1;

  &:nth-child(even) {
    ${RowWrap} {
      background-color: var(--highlight);
    }
  }
`;
export const RowSmall = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
`;
export const CoinIcon = styled.img`
  width: 20px;
  height: 20px;
`;
export const Col = styled.div`
  width: 20%;
  min-width: 180px;
  font-size: 10px;
  line-height: 20px;
  font-size: 10px;
  display: flex;
  align-items: center;
  padding: 3px;
  font-family: var(--font-monospaced);
  pointer-events: none;
  user-select: none;
`;
export const ColNumber = styled(Col)`
  width: 40px;
  max-width: 40px;
  min-width: 40px;
`;
export const ColLabel = styled(Col)`
  width: 40px;
`;
export const ColType = styled(Col)`
  span {
    margin: 0 0 0 10px;
  }

  img {
    width: 20px;
    height: 20px;
  }
`;
export const ColValue = styled(Col)`
  span {
    margin: 0 0 0 10px;
  }
`;
export const ColDate = styled(Col)``;
export const ColConfirm = styled(Col)``;
export const ColStatus = styled(Col)<{ status?: string }>`
  font-weight: 700;
  justify-content: flex-end;
  margin-right: 10px;
  color: ${({ color }) => color};
`;
export const ColToggleRow = styled(Col)`
  width: 20px;
  min-width: 20px;
  max-width: 20px;
  padding: 0;
`;

export const IconSend = styled(SvgArrowUpRight)`
  width: 20px;
  min-width: 20px;
  height: 20px;
  margin: 0 10px 0 0;
  stroke: var(--error);
`;
export const IconUsers = styled(SvgUsers)`
  width: 20px;
  min-width: 20px;
  height: 20px;
  margin: 0 10px 0 0;
  stroke: var(--object-secondary);
`;
export const IconArrow = styled(SvgArrow)`
  width: 20px;
  height: 20px;
  stroke: currentColor;
  transition: 0.4s;
  stroke: var(--object-secondary);
  transition: all 0.2s;
`;

export const MainInfo = styled.div<{ opened?: boolean }>`
  padding: 7px 20px;
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  cursor: pointer;

  ${(prop) =>
    prop.opened &&
    css`
      ${IconArrow} {
        transform: rotate(180deg);
      }
    `}
`;
export const DropdownInfo = styled.section`
  padding: 0x;
  border-top: 1px solid var(--border);
  font-size: 10px;
  display: flex;
`;
export const DropSide = styled.div`
  width: 50%;
  min-width: 50%;
  max-width: 50%;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
`;
export const DropLeft = styled.div`
  padding: 20px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 50%;
`;
export const DropRight = styled(DropSide)`
  flex-direction: column;
  border-left: 1px solid var(--border);
  align-items: flex-start;
`;
export const DropSend = styled.div`
  text-transform: uppercase;
  font-weight: 700;
  width: 100%;
  padding: 5px 0;
`;
export const DropAddressWrap = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 0;
  max-width: 100%;
`;
export const DropAddress = styled.div`
  display: flex;
  max-width: 100%;
  min-width: 0;
  align-items: center;
`;
export const Button = styled.button`
  padding: 0;
  border: 0;
  background: none;
  width: 14px;
  height: 14px;
  margin: 0 0 0 10px;
  color: var(--object-secondary);

  &:hover {
    color: var(--object-primary);
  }
`;
export const Buttons = styled.div``;
export const ActionButton = styled.button`
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  background-color: var(--accent-primary);
  color: var(--dark-bg);
  padding: 13px 40px;
  font-weight: 700;
  border-radius: 20px;
  margin: 0 5px;
  cursor: pointer;
  position: relative;
  transition: 0.4s;
  display: inline-block;

  &:hover {
    background: var(--object-primary);
  }
  &[type='reset'] {
    background: var(--error);
  }
  ${(prop) =>
    prop.disabled &&
    css`
      background: var(--placeholder);
      color: var(--dark-bg);
      pointer-events: none;
    `}
`;
const IconStyle = css`
  width: 14px;
  min-width: 14px;
  max-width: 14px;
  height: 14px;
  stroke: currentColor;
  transition: 0.4s;
`;
export const IconCopy = styled(SvgCopy)`
  ${IconStyle}
`;
export const IconExternal = styled(SvgExternal)`
  ${IconStyle}
`;

export const DropDataWrap = styled.div`
  width: 100%;
  padding: 5px 0;
`;
export const DropDataItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 100%;
`;
export const DropDataLabel = styled.div`
  color: var(--object-secondary);
  font-weight: 100;
  line-height: 20px;
  font-size: 10px;
  width: 120px;
  min-width: 120px;
  max-width: 120px;
`;
export const DropDataValue = styled.div`
  flex: 1;
  line-height: 20px;
  font-size: 10px;
  font-weight: 700;
  display: flex;
  align-items: center;
  max-width: 100%;
  min-width: 0;
`;
export const DropAddressText = styled.div`
  cursor: default;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  min-width: 0;
  padding-left: 10px;
  color: var(--object-primary);
`;

export const DropAdvanced = styled.div`
  color: var(--accent-primary);
  font-weight: 700;
  width: 100%;
  padding: 5px 0;
  cursor: pointer;
`;
export const Next = styled.div`
  padding: 20px 20px 10px 20px;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
`;
export const Digits = styled.span`
  font-family: var(--font-monospaced);
`;

export const Chain = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 0 24px 0;
  position: relative;
  flex-wrap: wrap;
  color: var(--object-secondary);
  font-family: var(--font-main);

  &:after {
    content: '';
    position: absolute;
    top: 27px;
    left: 9px;
    width: 2px;
    height: 10px;
    border-radius: 2px;
    background-color: var(--object-secondary);
  }
`;
export const ChainTitle = styled.span`
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
`;
export const ChainConfirmTitle = styled.span`
  font-size: 12px;
  line-height: 14px;
  color: var(--object-secondary);
  margin-left: 5px;
`;
export const ChainConfirmationsTitle = styled.span`
  font-size: 12px;
  line-height: 14px;
  color: var(--object-secondary);
  font-weight: 400;
  cursor: pointer;
`;
export const ChainCreated = styled(Chain)`
  color: var(--success);
`;
export const ChainConfirm = styled(Chain)<{ success?: boolean }>`
  color: var(--warning);

  ${(prop) =>
    prop.success &&
    css`
      color: var(--success);
    `}
`;
export const ChainConfirmations = styled(Chain)``;
export const ChainConfirmationsWrap = styled.div`
  width: 100%;
`;
export const ChainConfirmationsDot = styled.div`
  width: 20px;
  height: 20px;
  min-width: 20px;
  position: relative;
  pointer-events: none;
  margin: 0 10px 0 0;

  &:after {
    content: '';
    position: absolute;
    top: 8px;
    left: 8px;
    width: 4px;
    height: 4px;
    border-radius: 4px;
    background-color: var(--object-secondary);
  }
`;
export const ChainExecution = styled(Chain)<{ success?: boolean }>`
  &:after {
    display: none;
  }

  ${(prop) =>
    prop.success &&
    css`
      color: var(--success);
    `}
`;
const ChainIconStyle = css`
  width: 20px;
  min-width: 20px;
  height: 20px;
  margin: 0 10px 0 0;
  stroke: currentColor;
`;
export const ChainText = styled.div`
  width: 100%;
  padding: 0 0 0 30px;
`;
export const IconChainCreated = styled(SvgCreated)`
  ${ChainIconStyle}
`;
export const IconChainInProcess = styled(SvgCircleEmpty)`
  ${ChainIconStyle}
`;
export const IconChainInCheck = styled(SvgCircleCheck)`
  ${ChainIconStyle}
`;
