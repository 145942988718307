import { FC } from 'react';
import * as S from '../styled';

interface CardSetValueProps {
  value: any;
}

export const CardSetValue: FC<CardSetValueProps> = ({ value }) => {
  const isSet = Boolean(value);

  return (
    <S.CardSetWrapper
      style={{ color: isSet ? 'var(--success)' : 'var(--object-secondary)' }}
    >
      {isSet ? <S.IconCheck /> : <S.IconUncheck />}
      {isSet ? 'SET' : 'UNSET'}
    </S.CardSetWrapper>
  );
};
