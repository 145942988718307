import React, { useState, useEffect } from 'react';
import { AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useSearchParams } from 'react-router-dom';

import { getSafeApps } from '@gnosis.pm/safe-react-gateway-sdk';

import * as M from './styled/main';
import * as A from './styled/apps';
import * as Modal from 'shared/components/Modal';
import * as Form from 'shared/components/Form';
import * as Checkbox from 'shared/components/Checkbox';
import Circle from 'shared/components/Сircle';
import { AppFrame } from 'features/SafeApp';
import { useSafeApps } from 'features/SafeApp/hooks/useSafeApps';
import { useInitSafeCoreSDK } from 'features/SafeApp/coreSDK/useInitSafeCoreSDK';

import { useRemoteSafeApps } from './hooks/useRemoteSafeApps';
import { createPortal } from 'react-dom';
import useSafeInfo from 'features/SafeApp/hooks/useSafeInfo';

const AddAppSchema = Yup.object().shape({
  appUrl: Yup.string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      'Enter correct url',
    )
    .min(2, 'Too Short!')
    .required('Required'),
  appName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  appTerms: Yup.boolean().oneOf([true], 'Must Accept Terms and Conditions'),
});

const HIDDEN_APPS = [
  2, // ENS - Ethereum Name Service
  82, // Juicebox
];

const Apps = () => {
  const [isDisclaimer, setIsDisclaimer] = useState(false);
  //const [disclaimerTerms, setDisclaimerTerms] = useState(false);
  const [isAddModal, setIsAddModal] = useState(false);
  const [showFeatured, setShowFeatured] = useState<any>(
    JSON.parse(localStorage.getItem('apps_show_featured') || 'false') || false,
  );
  const [featuredListIdsIds, setFeaturedListIdsIds] = useState<any>(
    JSON.parse(localStorage.getItem('featuredAppsStorage') || '[]') || false,
  );

  const { addCustomApp } = useSafeApps();

  const [searchAppsInput, setSearchAppsInput] = useState('');

  const [remoteSafeApps] = useRemoteSafeApps();
  const [safeApps, setSafeApps] = useState(remoteSafeApps || []);
  const [searchParams, setSearchParams] = useSearchParams();
  const appUrl = searchParams.get('appUrl') || '';

  useEffect(() => {
    if (remoteSafeApps?.length) {
      setSafeApps(remoteSafeApps);
    }
  }, [remoteSafeApps]);

  useInitSafeCoreSDK();

  const featuredList =
    featuredListIdsIds.length && remoteSafeApps
      ? remoteSafeApps
          .filter((app) => featuredListIdsIds.includes(app.id))
          .filter((app) => !HIDDEN_APPS.includes(app.id))
      : null;

  function handleBookmarkToggle() {
    localStorage.setItem('apps_show_featured', JSON.stringify(!showFeatured));
    setShowFeatured(!showFeatured);
  }

  function handleToggleBookmarks(
    event: React.MouseEvent<HTMLElement>,
    idx: number,
  ) {
    event.stopPropagation();

    if (featuredListIdsIds && !featuredListIdsIds?.includes(idx)) {
      const updatedFeaturedAppsList = [...featuredListIdsIds, idx];

      localStorage.setItem(
        'featuredAppsStorage',
        JSON.stringify(updatedFeaturedAppsList),
      );
      setFeaturedListIdsIds(updatedFeaturedAppsList);
    } else {
      const updatedFeaturedAppsList =
        featuredListIdsIds &&
        featuredListIdsIds.filter((id: number): boolean => id !== idx);

      localStorage.setItem(
        'featuredAppsStorage',
        JSON.stringify(updatedFeaturedAppsList),
      );
      setFeaturedListIdsIds(updatedFeaturedAppsList);
    }
  }

  function handleAddNewApp(values: any) {
    setIsAddModal(false);
    // addCustomApp()
  }

  const navigate = useNavigate();

  if (appUrl) {
    return <AppFrame appUrl={appUrl} />;
  }

  const searchApps = (value: string) => {
    if (!remoteSafeApps) return;
    setSearchAppsInput(value);
    if (value === '') {
      setSafeApps(remoteSafeApps);
    }
    if (value.length) {
      setSafeApps(
        remoteSafeApps.filter((app) =>
          app.name?.toLowerCase().includes(value?.toLowerCase()),
        ),
      );
    }
  };

  const disclamerModal = // TODO: вынести в отдельный компонент, отрефакторить, в идеале написать компонент модалки
    (
      <AnimatePresence>
        {isDisclaimer ? (
          <Modal.Main
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.5 } }}
            exit={{ opacity: 0, transition: { duration: 0.5 } }}
          >
            <Modal.Wrap>
              <Modal.Header>
                <Modal.Title>Disclaimer</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>
                  You are now accessing third-party apps, which we do not own,
                  control, maintain or audit. We are not liable for any loss you
                  may suffer in connection with interacting with the apps, which
                  is at your own risk. You must read our Terms, which contain
                  more detailed provisions binding on you relating
                  to&nbsp;the&nbsp;apps.
                </p>
                <Formik
                  initialValues={{
                    acceptDisclaimer: false,
                  }}
                  validationSchema={Yup.object().shape({
                    acceptDisclaimer: Yup.bool().oneOf(
                      [true],
                      'Accept Terms & Conditions is required',
                    ),
                  })}
                  onSubmit={(values) => {
                    setIsDisclaimer(false);
                    setIsAddModal(true);
                  }}
                >
                  {({ errors, touched }) => (
                    <Form.MainForm pt={20}>
                      <Checkbox.Wrap
                        error={
                          (errors.acceptDisclaimer &&
                            touched.acceptDisclaimer) ||
                          false
                        }
                      >
                        <Checkbox.Label htmlFor="acceptDisclaimer">
                          <Checkbox.Input
                            type="checkbox"
                            name="acceptDisclaimer"
                            id="acceptDisclaimer"
                          />
                          <Checkbox.Text>
                            I have read and understood the Terms and this
                            Disclaimer, and agree to be bound by them.
                          </Checkbox.Text>
                        </Checkbox.Label>
                      </Checkbox.Wrap>
                      <A.FormFooter>
                        <div></div>
                        <A.Buttons>
                          <M.CancelButton
                            type="button"
                            onClick={() => setIsDisclaimer(false)}
                          >
                            Cancel
                          </M.CancelButton>
                          <M.Button type="submit" disabled={false}>
                            Confirm
                          </M.Button>
                        </A.Buttons>
                      </A.FormFooter>
                    </Form.MainForm>
                  )}
                </Formik>
              </Modal.Body>
            </Modal.Wrap>
            <Modal.BackDrop onClick={() => setIsDisclaimer(false)} />
          </Modal.Main>
        ) : null}
      </AnimatePresence>
    );

  const addCustomAppModal = (
    <AnimatePresence>
      {isAddModal ? (
        <Modal.Main
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.5 } }}
          exit={{ opacity: 0, transition: { duration: 0.5 } }}
        >
          <Modal.Wrap>
            <Modal.Header>
              <Modal.Title>Add custom app</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Safe Apps are third-party extensions.</p>
              <p>
                <a href="#">Learn more about building Safe Apps</a>.
              </p>

              <Formik
                initialValues={{
                  appUrl: '',
                  appName: '',
                  appTerms: false,
                }}
                validationSchema={AddAppSchema}
                onSubmit={(values) => {
                  handleAddNewApp(values);
                }}
              >
                {({ errors, touched }) => (
                  <Form.MainForm pt={20}>
                    <Form.Fieldset
                      error={(errors.appUrl && touched.appUrl) || false}
                    >
                      <Form.LabelWrap>
                        <Form.Label>App url</Form.Label>
                        {errors.appUrl && touched.appUrl ? (
                          <Form.Notice>{errors.appUrl}</Form.Notice>
                        ) : null}
                      </Form.LabelWrap>
                      <Form.Input
                        type="text"
                        name="appUrl"
                        placeholder="https://"
                      />
                    </Form.Fieldset>
                    <Form.Fieldset
                      error={(errors.appName && touched.appName) || false}
                    >
                      <Form.LabelWrap>
                        <Form.Label>App name</Form.Label>
                        {errors.appName && touched.appName ? (
                          <Form.Notice>{errors.appName}</Form.Notice>
                        ) : null}
                      </Form.LabelWrap>
                      <Form.Input
                        type="text"
                        name="appName"
                        placeholder="Name"
                      />
                    </Form.Fieldset>
                    <Checkbox.Wrap
                      error={(errors.appTerms && touched.appTerms) || false}
                    >
                      <Checkbox.Label htmlFor="appTerms">
                        <Checkbox.Input
                          type="checkbox"
                          name="appTerms"
                          id="appTerms"
                        />
                        <Checkbox.Text>
                          This app is not a Crypto Treasury product and I agree
                          to use this app at my own risk.
                        </Checkbox.Text>
                      </Checkbox.Label>
                    </Checkbox.Wrap>
                    <A.FormFooter>
                      <div></div>
                      <A.Buttons>
                        <M.CancelButton
                          type="button"
                          onClick={() => setIsAddModal(false)}
                        >
                          Cancel
                        </M.CancelButton>
                        <M.Button type="submit">Add</M.Button>
                      </A.Buttons>
                    </A.FormFooter>
                  </Form.MainForm>
                )}
              </Formik>
            </Modal.Body>
          </Modal.Wrap>
          <Modal.BackDrop onClick={() => setIsAddModal(false)} />
        </Modal.Main>
      ) : null}
    </AnimatePresence>
  );

  const disclaimerModalPortal = createPortal(disclamerModal, document.body);
  const addCustomAppModalPortal = createPortal(
    addCustomAppModal,
    document.body,
  );

  return (
    <A.Wrap
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      exit={{ opacity: 0, transition: { duration: 0.5 } }}
    >
      <M.ContentWrap>
        <A.SearchWrap>
          <A.IconSearch />
          <A.SearchForm>
            <A.SearchInput
              placeholder="Search"
              onChange={(e) => searchApps(e.target.value)}
            />
          </A.SearchForm>
        </A.SearchWrap>
        <A.Bookmarks>
          <A.Header onClick={handleBookmarkToggle} opened={showFeatured}>
            <A.HeaderTitle>Bookmarked apps</A.HeaderTitle>
            <A.IconArrow />
          </A.Header>

          {showFeatured ? (
            <>
              {featuredList && featuredList.length ? (
                <A.AppWrap>
                  {featuredList.map((item, i) => {
                    return (
                      <A.AppItem
                        key={i}
                        featured={featuredListIdsIds.includes(item.id)}
                        onClick={() => setSearchParams(`?appUrl=${item.url}`)}
                      >
                        <Circle
                          size={40}
                          seed={item.name}
                          image={item.iconUrl}
                        />
                        <A.AppName>{item.name}</A.AppName>
                        <A.AppDescription>{item.description}</A.AppDescription>
                        <A.AppFeatured
                          onClick={(event) =>
                            handleToggleBookmarks(event, item.id)
                          }
                        >
                          <A.IconBookmark />
                        </A.AppFeatured>
                        <A.AppsTags>
                          {item.tags.map((tag, i) => (
                            <A.Tag key={i}>{tag}</A.Tag>
                          ))}
                        </A.AppsTags>
                      </A.AppItem>
                    );
                  })}
                </A.AppWrap>
              ) : (
                <M.NoAvailable>
                  Hover over an app and click on the <A.IconTextBookmark /> to
                  bookmark the app here for convenient access
                </M.NoAvailable>
              )}
            </>
          ) : null}
        </A.Bookmarks>
        <A.AllApps>
          <A.Header>
            <A.HeaderTitle>All apps</A.HeaderTitle>
          </A.Header>
          {safeApps.length > 0 ? (
            <A.AppWrap>
              {/*
            <A.AddAppItem>
              <A.IconAddApp />
              <M.Button onClick={() => setIsDisclaimer(true)}>
                Add custom app
              </M.Button>
            </A.AddAppItem>
            */}
              {[...safeApps]
                .filter((app) => !HIDDEN_APPS.includes(app.id))
                .sort((a, b) => (a.name > b.name ? 1 : -1))
                .map((item, i) => {
                  return (
                    <A.AppItem
                      key={i}
                      onClick={() => setSearchParams(`?appUrl=${item.url}`)}
                      featured={featuredListIdsIds.includes(item.id)}
                    >
                      <Circle size={40} seed={item.name} image={item.iconUrl} />
                      <A.AppName>{item.name}</A.AppName>
                      <A.AppDescription>{item.description}</A.AppDescription>
                      <A.AppFeatured
                        onClick={(event) =>
                          handleToggleBookmarks(event, item.id)
                        }
                      >
                        <A.IconBookmark />
                      </A.AppFeatured>
                      <A.AppsTags>
                        {item.tags.map((tag, i) => (
                          <A.Tag key={i}>{tag}</A.Tag>
                        ))}
                      </A.AppsTags>
                    </A.AppItem>
                  );
                })}
            </A.AppWrap>
          ) : (
            <A.AppItemEmpty>No apps found</A.AppItemEmpty>
          )}
        </A.AllApps>
        <A.AppsNotice>
          These are third-party apps, which means they are not owned,
          controlled, maintained or audited by Crypto Treasury. Interacting with
          the apps is at your own risk. Any communication within the Apps is for
          informational purposes only and must not be construed as investment
          advice to engage in any transaction.
        </A.AppsNotice>
      </M.ContentWrap>

      {disclaimerModalPortal}
      {addCustomAppModalPortal}
    </A.Wrap>
  );
};

export default Apps;
