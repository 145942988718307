import { useContext, useMemo, useState } from 'react';
import Tippy from '@tippyjs/react';
import { Formik } from 'formik';
import { format, parseISO, isToday } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import TextareaAutosize from 'react-textarea-autosize';

import { getExchangeInfoById } from 'shared/constants/exchanges';
import {
  AccountType,
  CurrencyType,
  ExchangeAccountsType,
  PermissionType,
  WalletType,
  tagColor,
} from 'shared/types';
import styled from 'styled-components/macro';

import AppContext from 'shared/contexts/AppContext';

import { ExchangeIconMin, IconArrowWrap, IconArrow } from '../ExchangeAbstract';
import { SubAccount } from '../SubAccount';

import { API_URL } from 'shared/constants/app';
import { SMALL_BALANCE_VALUE } from '../../../constants';
import { TransferFiltersType } from '../../../types';

import * as S from './styled';
import { formatAccount } from 'shared/helpers/format';
import { toJS } from 'mobx';
import AddresBar from 'shared/components/AddresBar';
import Copy from 'shared/components/Copy';
import { LimitIcon } from '../SubAccount/styled';

const balanceTimeFormat = 'HH:mm';
const balanceDayFormat = 'dd.MM.yyyy';

const TooltipMainAddressWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TooltipMainAddress = styled.div`
  margin-right: -5px;
`;

function Account({
  account,
  accounts,
  availableCurrencies,
  chosenCurrency,
  chosenSecondWallet,
  isFromWallet,
  chosenWallet,
  couldAddTransaction,
  couldAddTransactionProposal,
  exchange,
  filteredAccountIds,
  filters,
  handleOpenAccounts,
  handleOpenSubAccounts,
  handleOpenWalletTypes,
  isShouldFilterByCurrency,
  onClick,
  openAccounts,
  openSubAccounts,
  openWalletTypes,
  side,
}: {
  account: AccountType;
  isFromWallet: boolean;
  accounts: AccountType[];
  availableCurrencies: CurrencyType[];
  chosenCurrency: string;
  chosenSecondWallet: WalletType | null;
  chosenWallet: WalletType | null;
  couldAddTransaction: boolean;
  couldAddTransactionProposal: boolean;
  exchange: ExchangeAccountsType;
  filteredAccountIds: number[];
  filters: TransferFiltersType;
  handleOpenAccounts: any;
  handleOpenSubAccounts: any;
  handleOpenWalletTypes: any;
  isShouldFilterByCurrency: boolean;
  onClick: any;
  openAccounts: number[];
  openSubAccounts: number[];
  openWalletTypes: string[];
  side: 'from' | 'to';
}) {
  const [editable, setEditable] = useState(false);
  const [tooltipError, setTooltipError] = useState<string>('');

  const { appToken, refetchAccounts, user } = useContext(AppContext);

  const TEST_HEADERS = {
    headers: {
      authorization: `Token ${appToken}`,
    },
  };

  async function handleSubmitHint(accountId: number, hint: string) {
    try {
      const rawData = await fetch(`${API_URL}/accounts/${accountId}/`, {
        ...TEST_HEADERS,
        method: 'PATCH',
        headers: {
          ...TEST_HEADERS.headers,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          hint,
        }),
      });

      if (rawData.ok) {
        await refetchAccounts();
        setEditable(false);
      } else {
        setEditable(true);
        setTooltipError('Can&#39;t save a hint');
        setTimeout(() => setTooltipError(''), 1000);
      }
    } catch (err) {
      setEditable(true);
      setTooltipError('Can&#39;t save a hint');
      setTimeout(() => setTooltipError(''), 1000);
    }
  }

  const exchangeInfo = getExchangeInfoById(exchange.exchange);

  const subAccounts = exchange.accounts
    .filter((acc: AccountType) =>
      isShouldFilterByCurrency || (filters.isHideSmallBalances && side !== 'to')
        ? filteredAccountIds.includes(acc.id)
        : true,
    )
    .filter((acc) => acc.master_id === account.id);

  const hasSubAccounts = subAccounts.length > 0;

  const isOpen = openAccounts.includes(account.id);

  const filteredCurrencies = account.wallets
    .filter((wallet: WalletType) =>
      filters.isHideSmallBalances &&
      side !== 'to' &&
      chosenWallet?.id !== wallet.id
        ? Number(wallet.available) >= SMALL_BALANCE_VALUE
        : true,
    )
    .map((wallet: WalletType) => wallet.currency as string);

  const isShowMain = isShouldFilterByCurrency
    ? filteredCurrencies.filter((currency) =>
        filters.currencies.includes(currency as CurrencyType),
      ).length > 0 &&
      (side === 'to' && chosenCurrency
        ? filteredCurrencies.includes(chosenCurrency)
        : true)
    : true;

  const fullAccountPermissions: PermissionType[] = [
    'accounts.view_account',
    'accounts.change_account',
  ];

  const couldEditHint = fullAccountPermissions.every((item) =>
    user?.permissions.includes(item),
  );

  const isTodayDate = isToday(new Date(String(account.balance_updated_at)));

  const currentBalanceFormat = !isTodayDate
    ? balanceDayFormat
    : balanceTimeFormat;

  const firstTags = account.tags?.slice(0, 4);
  const otherTags = account.tags?.slice(4, account.tags.length);

  const formattedBalanceDate = account.balance_updated_at
    ? format(
        utcToZonedTime(parseISO(account.balance_updated_at), 'UTC'),
        currentBalanceFormat,
      )
    : '';

  // @ts-ignore
  const wallet = account?.wallets?.find(
    ({ deposit_address }) => !!deposit_address,
  );
  const depositAddress = wallet ? wallet.deposit_address : '';

  const violatedCurrencies = useMemo(() => {
    const thresholds = account.thresholds;
    const totalThreshold = thresholds?.total;
    const specificThreshold = thresholds?.specific;

    if (!totalThreshold && !specificThreshold) return [];

    const allThresholds = { ...totalThreshold, ...specificThreshold };
    const thresholdCurrencies = [
      // @ts-ignore
      ...Object.keys(totalThreshold),
      // @ts-ignore
      ...Object.keys(specificThreshold),
    ];

    return thresholdCurrencies.filter(
      // @ts-ignore
      (curr) => allThresholds[curr].is_violated,
    );
  }, [account]);

  return (
    <S.AccountWrap opened={isOpen}>
      <S.AccountHeader isViolated={violatedCurrencies?.length > 0}>
        <S.AccountHeaderTop onClick={() => handleOpenAccounts(account.id)}>
          <IconArrowWrap rotateIcon={isOpen}>
            <IconArrow />
          </IconArrowWrap>
          {exchange.exchange ? (
            <ExchangeIconMin
              style={{
                backgroundImage: `url(${exchangeInfo?.logo_url})`,
              }}
              disabled={false}
            />
          ) : null}
          <S.AccountTitle>
            <S.AccountName>
              <S.AccountNameLabel title={account?.name}>
                {account.name || '<no name>'}
              </S.AccountNameLabel>
              {account.sgx_aliases?.length ? (
                <Tippy
                  theme="transparent"
                  trigger="mouseenter focus click"
                  placement="right"
                  delay={[0, 800]}
                  zIndex={9999999}
                  interactive={true}
                  content={
                    <>
                      {depositAddress && (
                        <TooltipMainAddressWrapper>
                          <TooltipMainAddress>
                            {depositAddress}
                          </TooltipMainAddress>
                          <Copy
                            width="10px"
                            height="10px"
                            text={depositAddress}
                          />
                        </TooltipMainAddressWrapper>
                      )}
                      {account?.sgx_aliases?.map((alias, idx) => (
                        <S.AccountSpoilerWrap key={idx}>
                          <S.AccountSpoilerItem key={idx}>
                            {alias}
                          </S.AccountSpoilerItem>
                        </S.AccountSpoilerWrap>
                      ))}
                    </>
                  }
                >
                  <S.AccountSpoiler>
                    <S.SpoilerIcon />
                  </S.AccountSpoiler>
                </Tippy>
              ) : null}
              {violatedCurrencies && violatedCurrencies.length ? (
                <Tippy
                  theme="transparent"
                  content={
                    <>
                      Total value of {violatedCurrencies.join(', ')} in your
                      account exceeds the threshold
                    </>
                  }
                >
                  <LimitIcon />
                </Tippy>
              ) : null}
            </S.AccountName>
            {!hasSubAccounts ? (
              <S.AccountBalanceStatus>
                {account.is_balance_outdated || !account.balance_updated_at ? (
                  <S.IconBalanceWarning />
                ) : null}
                <S.AccountBalanceUpdated
                  isOutdated={
                    account.is_balance_outdated || !account.balance_updated_at
                  }
                >
                  {account.balance_updated_at ? (
                    <span>
                      {formattedBalanceDate} {isTodayDate && 'UTC'}
                    </span>
                  ) : (
                    <span>Never updated</span>
                  )}
                </S.AccountBalanceUpdated>
              </S.AccountBalanceStatus>
            ) : null}
          </S.AccountTitle>
        </S.AccountHeaderTop>
        <S.AccountTags>
          {firstTags?.map(({ type, value }) => (
            <S.AccountTag color={tagColor[type] || 'var(--object-primary)'}>
              {value}
            </S.AccountTag>
          ))}
          {otherTags?.length ? (
            <Tippy
              trigger="mouseenter"
              placement="bottom"
              hideOnClick={true}
              theme="transparent"
              content={
                <>
                  {otherTags.map(({ type, value }) => (
                    <S.AccountTag
                      color={tagColor[type] || 'var(--object-primary)'}
                    >
                      {value}
                    </S.AccountTag>
                  ))}
                </>
              }
            >
              <S.AccountTag color={'var(--object-secondary)'}>
                {otherTags.length} more
              </S.AccountTag>
            </Tippy>
          ) : null}
        </S.AccountTags>
        {couldEditHint || account.hint ? (
          <S.AccountHintLabel>
            {couldEditHint && editable ? (
              <Formik
                initialValues={{ hint: account.hint }}
                onSubmit={async (values, { setSubmitting }) => {
                  setSubmitting(true);
                  await handleSubmitHint(account.id, values.hint);
                  setSubmitting(false);
                }}
              >
                {({
                  isSubmitting,
                  resetForm,
                  setFieldValue,
                  submitForm,
                  values,
                }) => (
                  <S.StyledForm>
                    <S.HintTextWrap>
                      <S.HintTextArea
                        name="hint"
                        autoComplete="off"
                        maxRows={2}
                        autoFocus={true}
                        placeholder="Type hint text here"
                        maxLength={68}
                        value={values.hint}
                        disabled={isSubmitting}
                        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                          setFieldValue('hint', e.target.value)
                        }
                        onKeyDown={(
                          e: React.KeyboardEvent<HTMLInputElement>,
                        ) => {
                          if (isSubmitting) {
                            return;
                          }

                          if (e.key === 'Enter') {
                            e.preventDefault();
                            submitForm();
                          }

                          if (e.key === 'Escape') {
                            e.preventDefault();
                            resetForm();
                            setEditable(false);
                          }
                        }}
                        component={TextareaAutosize}
                      />
                    </S.HintTextWrap>
                    <S.ActionIconsWrap>
                      <S.HintButton
                        type="button"
                        disabled={isSubmitting}
                        onClick={() => submitForm()}
                      >
                        <S.IconSave title="Save hint" />
                      </S.HintButton>
                      <S.HintButton
                        type="button"
                        onClick={() => {
                          resetForm();
                          setEditable(false);
                        }}
                      >
                        <S.IconCancel title="Cancel" />
                      </S.HintButton>
                      <S.HintText>
                        {isSubmitting ? 'Saving…' : tooltipError}
                      </S.HintText>
                    </S.ActionIconsWrap>
                  </S.StyledForm>
                )}
              </Formik>
            ) : couldEditHint ? (
              <>
                {account.hint ? (
                  <S.HintTextWrap
                    onClick={() => setEditable(true)}
                    couldEdit={true}
                  >
                    <S.AccountHint>{account.hint}</S.AccountHint>
                    <S.HintEditButton>
                      <S.IconEdit />
                    </S.HintEditButton>
                  </S.HintTextWrap>
                ) : (
                  <S.AddTooltipLabel onClick={() => setEditable(true)}>
                    + Add hint
                  </S.AddTooltipLabel>
                )}
              </>
            ) : (
              <>
                <S.HintTextWrap>
                  <S.TooltipSavedText>
                    {account.hint || (
                      <S.NoHintPermission>
                        You can't modify this hint
                      </S.NoHintPermission>
                    )}
                  </S.TooltipSavedText>
                </S.HintTextWrap>
              </>
            )}
          </S.AccountHintLabel>
        ) : null}
      </S.AccountHeader>
      {isOpen ? (
        <S.AccountsRow key={account.id}>
          {isShowMain ? (
            <SubAccount
              isFromWallet={isFromWallet}
              account={{ ...account, name: 'MAIN' }}
              accounts={accounts}
              availableCurrencies={availableCurrencies}
              chosenCurrency={chosenCurrency}
              chosenSecondWallet={chosenSecondWallet}
              chosenWallet={chosenWallet}
              couldAddTransaction={couldAddTransaction}
              couldAddTransactionProposal={couldAddTransactionProposal}
              exchange={exchange}
              filters={filters}
              handleOpenAccounts={handleOpenAccounts}
              handleOpenSubAccounts={handleOpenSubAccounts}
              handleOpenWalletTypes={handleOpenWalletTypes}
              hasSubAccounts={hasSubAccounts}
              isShouldFilterByCurrency={isShouldFilterByCurrency}
              onClick={onClick}
              openAccounts={openAccounts}
              openSubAccounts={openSubAccounts}
              openWalletTypes={openWalletTypes}
              side={side}
            />
          ) : null}
          {hasSubAccounts
            ? subAccounts.map((subAccount) => (
                <SubAccount
                  isFromWallet={isFromWallet}
                  account={subAccount}
                  accounts={accounts}
                  availableCurrencies={availableCurrencies}
                  chosenCurrency={chosenCurrency}
                  chosenSecondWallet={chosenSecondWallet}
                  chosenWallet={chosenWallet}
                  couldAddTransaction={couldAddTransaction}
                  couldAddTransactionProposal={couldAddTransactionProposal}
                  exchange={exchange}
                  filters={filters}
                  handleOpenAccounts={handleOpenAccounts}
                  handleOpenSubAccounts={handleOpenSubAccounts}
                  handleOpenWalletTypes={handleOpenWalletTypes}
                  hasSubAccounts={hasSubAccounts}
                  isShouldFilterByCurrency={isShouldFilterByCurrency}
                  onClick={onClick}
                  openAccounts={openAccounts}
                  openSubAccounts={openSubAccounts}
                  openWalletTypes={openWalletTypes}
                  side={side}
                />
              ))
            : null}
        </S.AccountsRow>
      ) : null}
    </S.AccountWrap>
  );
}

export { Account };
