import {
  max as maxDateFns,
  min as minDateFns,
  parseISO,
  formatISO,
} from 'date-fns';
import moment from 'moment';
import { Transaction } from 'services/Transactions/types';
import { isUndefined } from './strings';

export const formattedDate = (d: number | Date, f: string) => {
  const iso = formatISO(d);
  const date = moment(iso).format(f);
  return date;
};

export const dateAgo = (date: number | Date) => {
  const f = formatISO(date);
  const d = moment(f).fromNow();
  return d;
};

export const findMinMaxDates = (transactions: Transaction[]) => {
  const min = null;
  const max = null;

  if (!transactions.length) {
    return { min, max };
  }

  const dates = transactions
    // @ts-ignore
    .filter(({ updated_at }: any) => !isUndefined(updated_at))
    .map((item: Transaction) => parseISO(item.updated_at));

  return {
    min: minDateFns(dates),
    max: maxDateFns(dates),
  };
};
