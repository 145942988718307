import { useEffect } from 'react';
import { useFormikContext } from 'formik';

export const AutoSubmit = () => {
  const formik = useFormikContext();

  useEffect(() => {
    if (formik.values !== formik.initialValues) {
      formik.submitForm();
    }
  }, [formik.values]);

  return null;
};
