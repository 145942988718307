import { useInfiniteQuery } from '@tanstack/react-query';
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useFilters } from 'shared/hooks/useFilters';
import {
  getNextPageParam,
  parseDates,
  stringifyUrl,
} from 'shared/helpers/query';
import { requestGet } from 'shared/axios';
import { StatusType } from 'shared/types';
import {
  TransactionDisplayStatus,
  TransactionDisplayStatusType,
} from 'services/Transactions/types';

const limits = {
  history: 50,
  queue: 20,
};

const allowedStatuses = {
  history: [
    StatusType.COMPLETED,
    StatusType.CLOSED,
    StatusType.FAILED,
    StatusType.CANCELED,
    StatusType.REJECTED,
  ],
  queue: [
    StatusType.QUEUED,
    StatusType.INITIATED,
    StatusType.UNCONFIRMED,
    StatusType.PENDING,
    StatusType.SENT,
    StatusType.RECEIVED,
    StatusType.AWAITING_EXECUTE,
  ],
};

const mapStatus = (s: TransactionDisplayStatusType) => {
  if (s === TransactionDisplayStatus.AWAITING_CONFIRMATIONS) {
    return [StatusType.QUEUED];
  } else if (s === TransactionDisplayStatus.AWAITING_APPROVAL) {
    return [StatusType.UNCONFIRMED];
  }else if (s === TransactionDisplayStatus.AWAITING_EXECUTE) {
    return [StatusType.AWAITING_EXECUTE];
  } else if (s === TransactionDisplayStatus.PROCESSING) {
    return [
      StatusType.INITIATED,
      StatusType.PENDING,
      StatusType.SENT,
      StatusType.RECEIVED,
    ];
  } else {
    return s;
  }
};

const parseStatuses = (
  status: TransactionDisplayStatusType[] = [],
  tab: TransactionsTab,
) => {
  const mappedStatus = status?.map(mapStatus).flat();
  const allowedList = mappedStatus.filter((s) =>
    allowedStatuses[tab]?.includes(s),
  );
  const restrictedList = mappedStatus.filter(
    (s) => !allowedStatuses[tab]?.includes(s),
  );

  if (allowedList.length) {
    return allowedList;
  } else if (restrictedList.length) {
    return ['NONE'];
  } else {
    return allowedStatuses[tab];
  }
};

const parseFilters = (
  { updated_at_after, updated_at_before, status, ...rest }: any,
  tab: TransactionsTab,
) => {
  const { atBefore, atAfter } = parseDates(updated_at_after, updated_at_before);
  const parsedStatuses = parseStatuses(status, tab);

  return {
    ...rest,
    status: parsedStatuses,
    updated_at_after: atAfter,
    updated_at_before: atBefore,
  };
};

const getTransactions = async (
  filters: any,
  page: number,
  tab: TransactionsTab,
) => {
  return await requestGet(
    stringifyUrl('/transactions/', filters, page, limits[tab]),
  );
};

type TransactionsTab = 'history' | 'queue';

export const useTransactions = (tab: TransactionsTab) => {
  const [getFilters] = useFilters('transactions_filters');
  const location = useLocation();
  const navigate = useNavigate();
  const { data, ...rest } = useInfiniteQuery(
    [`transactions_${tab}`],
    async ({ pageParam = 0 }) => {
      const filters = getFilters();
      const parsedFilters = parseFilters(filters, tab);
      if (parsedFilters?.status?.includes('NONE')) {
        return {
          count: 0,
          next: null,
          previous: null,
          results: [],
        };
      }

      const url = new URLSearchParams(window.location.search);
      const extended = url.get('extended');

      navigate({
        pathname: location.pathname,
        search: queryString.stringify(
          { ...filters, ...(extended ? { extended } : {}) },
          {
            arrayFormat: 'separator',
          },
        ),
      });

      const { data } = await getTransactions(parsedFilters, pageParam, tab);
      return data;
    },
    {
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      refetchInterval: 15000,
      getNextPageParam: ({ next }) =>
        getNextPageParam({ next, limit: limits[tab] }),
    },
  );

  const rows = data?.pages.map(({ results }) => results).flat().sort((a, b) => {
    const property = tab === 'history' ? 'updated_at' : 'created_at';
    if (a[property] < b[property]) {
      return 1;
    }
    if (a[property] > b[property]) {
      return -1;
    }
    return 0;
  });

  return {
    ...rest,
    rows,
    filters_data: data?.pages[data?.pages?.length - 1]?.filters_data,
    data,
  };
};
