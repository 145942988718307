import { observable, action } from 'mobx';
import { validateAddress } from 'features/SafeApp/helpers/validation';
import { pickBy } from 'lodash';

interface AddressBook {
[address: string]: string;
}

interface AddressBookState {
[chainId: string]: AddressBook;
}

class AddressBookStore {
@observable addressBooks: AddressBookState = {};

@action
migrate = (newState: AddressBookState) => {
    if (Object.keys(this.addressBooks).length > 0) return;
    this.addressBooks = newState;
};

@action
setAddressBook = (newState: AddressBookState) => {
    this.addressBooks = newState;
};

@action
upsertAddressBookEntry = (chainId: string, address: string, name: string) => {
    if (!this.addressBooks[chainId]) this.addressBooks[chainId] = {};
    this.addressBooks[chainId][address] = name;
};

@action
removeAddressBookEntry = (chainId: string, address: string) => {
    if (!this.addressBooks[chainId]) return;
    delete this.addressBooks[chainId][address];
};

selectAddressBookByChain = (chainId: string): AddressBook => {
    const chainAddresses = this.addressBooks[chainId];
    const validAddresses = pickBy(chainAddresses, (_, key) => validateAddress(key) === undefined);
    return chainId ? validAddresses || {} : {};
};

}

const addressBookStore = new AddressBookStore();
export default addressBookStore;
