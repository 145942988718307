import styled, { css } from 'styled-components';
import { WHITELISTS_TABS } from '../../constants';

interface WhitelistTabsProps {
  tab: string;
  setTab: (tab: WhitelistTab) => void;
  onSubmit: () => void;
}

export const TabWrap = styled.nav`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 0 10px 20px;
  background-color: var(--base);
  border-left: 1px solid var(--border);
  border-right: 1px solid var(--border);
  box-sizing: border-box;
`;
export const TabItem = styled.div<{ active: boolean }>`
  padding: 8px 0;
  margin: 0 30px 0 0;
  font-weight: 700;
  font-family: var(--font-main);
  font-size: 16px;
  line-height: 22px;
  color: var(--object-secondary);
  cursor: pointer;
  position: relative;
  user-select: none;
  text-align: center;

  &:last-child {
    margin: 0;
  }

  @media (max-width: 765px) {
    max-width: 145px;
  }

  ${(props) =>
    props.active &&
    css`
      color: var(--object-primary);

      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 2px;
        background: var(--accent-primary);
      }
    `};
`;

export const WhitelistTabs = ({ tab, setTab, onSubmit }: WhitelistTabsProps) => {
  return (
    <TabWrap style={{ paddingLeft: '20px' }}>
      {WHITELISTS_TABS.map((item) => {
        const onClick = () => {
          setTab(item?.tab_alias);
          onSubmit();
        };

        return (
          <TabItem
            key={item?.tab_alias}
            active={String(tab) === item?.tab_alias}
            onClick={onClick}
          >
            {item.title}
          </TabItem>
        );
      })}
    </TabWrap>
  );
};
