import { DepositAddressType } from 'shared/types';

export const whitelist: DepositAddressType[] = [
  {
    currency: 'BTC',
    deposit_address: '0xBITFINEXxBTCx000000000000000000000000001',
    sgx_id: 'Account 1 BTC',
    network: 'BITCOIN',
  },
  {
    currency: 'USDT',
    deposit_address: '0xERRORxUSDTx00000000000000000000000000001',
    sgx_id: 'Unbound 1 USDT',
    network: 'ETHEREUM',
  },
];
