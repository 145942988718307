import styled, { css, keyframes } from 'styled-components/macro';

const appearAnimation = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const CurrencySelectWrap = styled.div<{
  opened?: boolean;
  disabled?: boolean;
  isMobile?: boolean
}>`
  width: 100%;
  text-align: center;
  color: var(--accent-primary);
  cursor: pointer;
  opacity: 0;
  animation: ${appearAnimation} 0.3s forwards;
  animation-delay: 0.1s;

  ${(props) =>
    props.opened &&
    css`
      color: var(--base);

      .icon {
        transform: rotate(-90deg);
      }
    `}
  
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.3;
      pointer-events: none;
    `}

  ${(prop) =>
    prop.isMobile &&
    css`
      display: none;
      overflow: hidden;
    `}
`;

export const CurrencyOptions = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const CurrencyOption = styled.div<{
  active?: boolean;
  fontsizeMin?: boolean;
}>`
  margin: 0 5px 5px 0;
  padding: 10px;
  border-radius: 50px;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 10px;
  background-color: var(--border);
  color: var(--object-primary);
  user-select: none;
  display: flex;
  align-items: center;
  transition: all 0.2s;

  &:hover {
    background-color: var(--placeholder);
  }

  ${(props) =>
    props.fontsizeMin &&
    css`
      font-size: 9px;
    `}
  ${(props) =>
    props.active &&
    css`
      background-color: var(--accent-primary);
      color: var(--dark-bg);

      &:hover {
        background-color: var(--accent-primary);
      }
    `}
`;

export const CurrencyLogo = styled.div`
  width: 10px;
  height: 10px;
  margin: 0 5px 0 0;
  background-position: 50%;
  background-size: 10px auto;
`;
