import { action, computed, makeObservable, observable } from 'mobx';
import { IAuthStore } from '../models';
import { requestPost } from 'shared/axios';
import { CredentialResponse, googleLogout } from '@react-oauth/google';
import { ErrorCodes } from 'shared/helpers/errors';
import { SettingsStoreInstance } from 'services/Settings';

class AuthStore implements IAuthStore {
  constructor() {
    makeObservable(this);
    this.appToken = localStorage.getItem('appToken') ?? null;
  }

  @observable appToken: Nullable<string> = null;

  @computed public get isAuth() {
    return this.appToken !== null;
  }

  @observable googleAccessToken: string | undefined = undefined;

  @action.bound public async fetchAppTokenWithOtp(
    googleAccessToken: string | undefined,
    otp: string,
  ) {
    try {
      const response = await requestPost(
        '/auth/token/',
        { id_token: googleAccessToken },
        {
          headers: { noToken: true, ...(otp && { 'X-AUTH-HOTP': otp }) },
        },
      );
      this.appToken = response.data.token;
      localStorage.setItem('appToken', response.data.token);
    } catch (e) {
      console.log('fetch app token with otp error:', e);
      throw e;
    }
  }

  @action.bound public async fetchAppToken(
    googleAccessToken: string | undefined,
    openModal: any,
  ) {
    try {
      const response = await requestPost(
        '/auth/token/',
        { id_token: googleAccessToken },
        {
          headers: { noToken: true },
        },
      );
      this.appToken = response.data.token;
      localStorage.setItem('appToken', response.data.token);
    } catch (e) {
      if (
        SettingsStoreInstance.appSettings.is_hotp_enabled &&
        // @ts-ignore
        (e?.response?.data?.error_code === ErrorCodes.OTP_ERROR ||
          // @ts-ignore
          e?.response?.status === 403)
      ) {
        openModal(googleAccessToken);
      }
    }
  }

  @action.bound public async onSignInSuccess(
    { credential }: CredentialResponse,
    openModal: any,
  ): Promise<void> {
    this.googleAccessToken = credential;
    await this.fetchAppToken(this.googleAccessToken, openModal);
  }

  public async onSignOutSuccess(): Promise<void> {}

  @action.bound public signOut() {
    localStorage.removeItem('appToken');
    this.appToken = null;
    googleLogout();
  }
}

export const AuthStoreInstance = new AuthStore();
