import { FEATURES } from '@gnosis.pm/safe-react-gateway-sdk';
import { hasFeature } from 'features/Multisig/helpers';
import { getGnosisSafeInstanceAt } from './safeContracts';
import { generateSignaturesFromTxConfirmations } from '../../safeTxSigner';
import { List } from 'immutable';
import { Confirmation } from '../../models';
import { calculateGasOf } from './ethTransactions';
import { SAFE_LAST_VERSION } from '@gnosis.pm/safe-core-sdk/dist/src/contracts/config';

export type SafeTxGasEstimationProps = {
  safeAddress: string;
  txData: string;
  txRecipient: string;
  txAmount: string;
  operation: number;
};

export type TxParameters = {
  safeNonce?: string;
  setSafeNonce: (safeNonce?: string) => void;
  safeTxGas?: string;
  setSafeTxGas: (gas?: string) => void;
  ethNonce?: string;
  setEthNonce: (ethNonce?: string) => void;
  ethGasLimit?: string;
  setEthGasLimit: (ethGasLimit?: string) => void;
  ethGasPrice?: string;
  setEthGasPrice: (ethGasPrice?: string) => void;
  ethMaxPrioFee?: string;
  setEthMaxPrioFee: (maxPrioFee?: string) => void;
  ethGasPriceInGWei?: string;
  ethMaxPrioFeeInGWei?: string;
};

type TransactionExecutionEstimationProps = {
  txData: string
  safeAddress: string
  safeVersion: string
  txRecipient: string
  txConfirmations?: List<Confirmation>
  txAmount: string
  operation: number
  gasPrice: string
  gasToken: string
  gasLimit?: string
  refundReceiver: string // Address of receiver of gas payment (or 0 if tx.origin).
  safeTxGas: string
  from: string
  approvalAndExecution?: boolean
};

export const isMaxFeeParam = (): boolean => {
  return hasFeature(FEATURES.EIP1559);
};

export const createSendParams = (
  from: string,
  txParams: Pick<
    TxParameters,
    'ethGasLimit' | 'ethNonce' | 'ethMaxPrioFeeInGWei' | 'ethGasPriceInGWei'
  >,
): any => {
  const sendParams: any = {
    from,
    value: 0,
    gas: txParams.ethGasLimit,
    nonce: txParams.ethNonce,
  };

  if (isMaxFeeParam()) {
    sendParams.maxPriorityFeePerGas = txParams.ethMaxPrioFeeInGWei;
    sendParams.maxFeePerGas = txParams.ethGasPriceInGWei;
  } else {
    sendParams.gasPrice = txParams.ethGasPriceInGWei;
  }

  return sendParams;
};

export const estimateGasForTransactionExecution = async (props: TransactionExecutionEstimationProps): Promise<number> => {
  const {
    safeAddress,
    safeVersion,
    txRecipient,
    txConfirmations,
    txAmount,
    txData,
    operation,
    from,
    gasPrice,
    gasToken,
    refundReceiver,
    safeTxGas,
    approvalAndExecution,
  } = props;
  const safeInstance = await getGnosisSafeInstanceAt(safeAddress, SAFE_LAST_VERSION);
  // If it's approvalAndExecution we have to add a preapproved signature else we have all signatures
  const sigs = generateSignaturesFromTxConfirmations(txConfirmations, approvalAndExecution ? from : undefined);
  const estimationData = safeInstance.methods
    .execTransaction(txRecipient, txAmount, txData, operation, safeTxGas, 0, gasPrice, gasToken, refundReceiver, sigs)
    .encodeABI();

  return await calculateGasOf({
    data: estimationData,
    from,
    to: txRecipient,
  });
};

export const checkTransactionExecution = async ({
    safeAddress,
    safeVersion,
    txRecipient,
    txConfirmations,
    txAmount,
    txData,
    operation,
    from,
    gasPrice,
    gasToken,
    gasLimit,
    refundReceiver,
    safeTxGas,
    approvalAndExecution,
  }: TransactionExecutionEstimationProps): Promise<boolean> => {
  const safeInstance = getGnosisSafeInstanceAt(safeAddress, safeVersion);
  // If it's approvalAndExecution we have to add a preapproved signature else we have all signatures
  const sigs = generateSignaturesFromTxConfirmations(txConfirmations, approvalAndExecution ? from : undefined);

  return safeInstance.methods
    .execTransaction(txRecipient, txAmount, txData, operation, safeTxGas, 0, gasPrice, gasToken, refundReceiver, sigs)
    .call({
      from,
      gas: gasLimit,
    })
    .then(() => true)
    .catch((e: any) => {
      console.warn('Transaction will fail\n\n', e);
      return false;
    });
};
