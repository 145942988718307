import { toast } from 'react-toastify';

import SuccessIcon from './icons/successIcon.svg';
import InfoIcon from './icons/infoIcon.svg';
import WarningIcon from './icons/warningIcon.svg';
import ErrorIcon from './icons/errorIcon.svg';

import * as S from './styled';

interface NotifyProps {
  type: string;
  header?: string;
  text?: any;
  onClick?: any;
}

const icons: Record<string, string> = {
  success: SuccessIcon,
  info: InfoIcon,
  warning: WarningIcon,
  error: ErrorIcon,
};

export const notify = ({
  type = 'success',
  header,
  text,
  onClick,
}: NotifyProps) => {
  const ToastView = (
    <S.NotifyView>
      {header && <S.Header>{header}</S.Header>}
      {text && <S.Text>{text}</S.Text>}
    </S.NotifyView>
  );

  toast(ToastView, {
    onClick,
    style: { cursor: 'pointer' },
    icon: () => <img src={icons[type]} />,
  });
};
