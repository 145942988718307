import { useIsOpen } from 'features/Transfer/context/AccountContext';
import { useTransfer } from 'features/Transfer/context/TransferContext';
import {
  AccountType,
  ColumnSide,
  CurrencyType,
  ExchangeInfoType,
  WalletType,
} from 'shared/types';
import { WalletItem } from './WalletItem';
import * as S from './WalletTypeItemStyled';
import {
  ExchangeIconMin,
  IconArrow,
  IconArrowWrap,
} from '../../components/ExchangeAbstract';

interface WalletTypeItemProps {
  account: AccountType;
  subAccountType: string;
  side: ColumnSide;
  exchangeInfo?: ExchangeInfoType;
}

export const WalletTypeItem = ({
  account,
  subAccountType,
  side,
  exchangeInfo,
}: WalletTypeItemProps) => {
  const { availableCurrencies, filters } = useTransfer();
  const [opened, toggle] = useIsOpen(
    side,
    'walletType',
    account.id,
    subAccountType,
  );

  return (
    <S.Wallet key={`${account.id}_${subAccountType}`}>
      {subAccountType ? (
        <S.WalletHeader onClick={toggle} opened={opened}>
          <IconArrowWrap rotateIcon={opened}>
            <IconArrow />
          </IconArrowWrap>
          <ExchangeIconMin
            style={{
              backgroundImage: `url(${exchangeInfo?.logo_url})`,
            }}
            disabled={false}
          />

          <S.WalletTypeName>
            {subAccountType ? `${subAccountType}` : 'MAIN'}
          </S.WalletTypeName>
        </S.WalletHeader>
      ) : null}
      <S.WalletWrap>
        {opened &&
          availableCurrencies
            .filter((currency) => filters.currencies.includes(currency))
            .map((currency: string) => {
              const accWallets = account.wallets
                .filter((item: WalletType) => item.currency === currency)
                .filter((item: WalletType) => item.type === subAccountType);
              return accWallets.map((wallet: WalletType) => {
                return (
                  <WalletItem
                    key={wallet.id}
                    side={side}
                    wallet={wallet}
                    account={account}
                    currency={currency as CurrencyType}
                  />
                );
              });
            })}
      </S.WalletWrap>
    </S.Wallet>
  );
};
