import type { ReactElement } from 'react';
import { useCallback, useEffect, useContext } from 'react';
import { observer } from 'mobx-react';

import { _getChainId } from 'features/Multisig/helpers';

import {
  getBalances,
  getTransactionDetails,
} from '@gnosis.pm/safe-react-gateway-sdk';

import { trackSafeAppOpenCount } from './helpers/track-app-usage-count';
import { useSafeAppFromManifest } from './hooks/useSafeAppFromManifest';
import useSafeInfo from './hooks/useSafeInfo';
import { useSafeAppFromBackend } from './hooks/useSafeAppFromBackend'; // есть
import { SafeStoreInstance } from 'features/Multisig/mobx/SafeStore';
import { useSafePermissions } from './permissions';

import useIsGranted from './hooks/useIsGranted';
import { isSameUrl } from './helpers/url';
import { getLegacyChainName } from './helpers/utils';
import useAppIsLoading from './hooks/useAppIsLoading';
import useAppCommunicator, {
  CommunicatorMessages,
} from './hooks/useAppCommunicator';
import useTxModal from './SafeAppsTxModal/hooks/useTxModal';

import AppContext from 'shared/contexts/AppContext';

import { Loader } from 'shared/components';

import * as S from './styled';

import useSignMessageModal from './SafeAppsTxModal/hooks/useSignMessageModal';
import SafeAppsTxModal from './SafeAppsTxModal';
import { txSubscribe, TxEvent } from './SafeAppsTxModal/services/txEvents';
import { notify } from 'shared/components/Notification/notify';

import type {
  BaseTransaction,
  RequestId,
  SendTransactionRequestParams,
} from '@gnosis.pm/safe-apps-sdk';

import { useNavigate } from 'react-router-dom';

import { toJS } from 'mobx';

type AppFrameProps = {
  appUrl: string;
};

// see sandbox mdn docs for more details https://developer.mozilla.org/en-US/docs/Web/HTML/Element/iframe#attr-sandbox
const IFRAME_SANDBOX_ALLOWED_FEATURES =
  'allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox allow-forms allow-downloads allow-orientation-lock';

const betterBackgroundColorApps = [
  'Sablier',
  'Gnosis Auction Starter',
  'Token Approval Manager',
];

const AppFrame = ({ appUrl }: AppFrameProps): ReactElement => {
  const chainId = _getChainId();
  const [txModalState, openTxModal, closeTxModal] = useTxModal();
  const { selectedChain: chain } = SafeStoreInstance;
  const { safe, safeAddress } = useSafeInfo();
  const granted = useIsGranted();
  const [signMessageModalState, openSignMessageModal, closeSignMessageModal] =
    useSignMessageModal();
  const [remoteApp] = useSafeAppFromBackend(appUrl, safe.chainId);
  const { safeApp: safeAppFromManifest } = useSafeAppFromManifest(
    appUrl,
    safe.chainId,
  );
  const { iframeRef, appIsLoading, isLoadingSlow, setAppIsLoading } =
    useAppIsLoading();
  const { getPermissions, setPermissionsRequest } = useSafePermissions();

  const { openModal } = useContext(AppContext);

  const modalHandler = (
    txs: BaseTransaction[],
    requestId: RequestId,
    params?: SendTransactionRequestParams,
  ) => {
    openTxModal(txs, requestId, params);

    openModal({
      width: '560px',
      component: () => (
        <SafeAppsTxModal
          onClose={onSafeAppsModalClose}
          initialData={[
            {
              app: safeAppFromManifest,
              appId: remoteApp?.id,
              requestId,
              txs,
              params,
            },
          ]}
        />
      ),
    });
  };

  const navigate = useNavigate();

  const communicator = useAppCommunicator(
    iframeRef,
    remoteApp || safeAppFromManifest,
    chain,
    {
      onConfirmTransactions: modalHandler,
      onGetPermissions: getPermissions,
      onSetPermissions: setPermissionsRequest,
      onGetTxBySafeTxHash: (safeTxHash) =>
        getTransactionDetails(chainId, safeTxHash),
      onGetEnvironmentInfo: () => ({
        origin: document.location.origin,
      }),
      onGetSafeInfo: () => ({
        safeAddress,
        chainId: parseInt(chainId, 10),
        owners: safe.owners.map((owner) => owner.value),
        threshold: safe.threshold,
        isReadOnly: !granted,
        network: getLegacyChainName(
          chain?.chainName || '',
          chainId,
        )?.toUpperCase(),
      }),
      onGetSafeBalances: (currency) =>
        getBalances(chainId, safeAddress, currency, {
          exclude_spam: true,
          trusted: false,
        }),
      onGetChainInfo: () => {
        if (!chain) return;

        const {
          nativeCurrency,
          chainName,
          chainId,
          shortName,
          blockExplorerUriTemplate,
        } = toJS(chain);

        return {
          chainName,
          chainId,
          shortName,
          nativeCurrency,
          blockExplorerUriTemplate,
        };
      },
    },
  );

  useEffect(() => {
    if (!remoteApp) return;

    trackSafeAppOpenCount(remoteApp.id);
  }, [remoteApp]);

  useEffect(() => {
    setAppIsLoading(true);
    setTimeout(() => {
      setAppIsLoading(false);
    }, 0);
  }, [safeAddress]);

  const onIframeLoad = useCallback(() => {
    const iframe = iframeRef.current;
    if (!iframe || !isSameUrl(iframe.src, appUrl)) {
      return;
    }

    setAppIsLoading(false);
  }, [appUrl, iframeRef, setAppIsLoading]);

  useEffect(() => {
    const unsubscribe = txSubscribe(
      TxEvent.SAFE_APPS_REQUEST,
      async ({ safeAppRequestId, safeTxHash }) => {
        const currentSafeAppRequestId =
          signMessageModalState.requestId || txModalState.requestId;

        if (currentSafeAppRequestId === safeAppRequestId) {
          communicator?.send({ safeTxHash }, safeAppRequestId);
          notify({
            type: 'success',
            header: 'Your transaction was successfully proposed.',
            onClick: () => {
              navigate('/multisig-wallets');
            },
          });

          if (txModalState.isOpen) {
            closeTxModal();
            openModal(null);
          } else {
            closeSignMessageModal();
          }
        }
      },
    );

    return unsubscribe;
  }, [
    chainId,
    closeSignMessageModal,
    closeTxModal,
    communicator,
    signMessageModalState,
    txModalState,
  ]);

  const onSafeAppsModalClose = () => {
    if (txModalState.isOpen) {
      communicator?.send(
        CommunicatorMessages.REJECT_TRANSACTION_MESSAGE,
        txModalState.requestId,
        true,
      );
      closeTxModal();
      openModal(null);
    } else {
      communicator?.send(
        CommunicatorMessages.REJECT_TRANSACTION_MESSAGE,
        signMessageModalState.requestId,
        true,
      );
      closeSignMessageModal();
      openModal(null);
    }
  };

  const betterBackgroundColor = betterBackgroundColorApps.includes(
    safeAppFromManifest?.name || '',
  )
    ? '#ebeff5'
    : 'var(--background)';

  return (
    <>
      {safeAddress && (
        <S.Wrapper>
          {appIsLoading && (
            <S.LoaderContainer>
              {isLoadingSlow && (
                <S.LoaderText>
                  The Safe App is taking too long to load, consider refreshing.
                </S.LoaderText>
              )}
              <Loader />
            </S.LoaderContainer>
          )}

          {!appIsLoading && (
            <S.IFrame
              id={`iframe-${appUrl}`}
              ref={iframeRef}
              src={appUrl}
              title={safeAppFromManifest?.name}
              onLoad={onIframeLoad}
              sandbox={IFRAME_SANDBOX_ALLOWED_FEATURES}
              style={{
                display: appIsLoading ? 'none' : 'block',
                backgroundColor: betterBackgroundColor,
              }}
            />
          )}
        </S.Wrapper>
      )}
    </>
  );
};

const C = observer(AppFrame);

export { C as AppFrame };
