import styled, { css } from 'styled-components/macro';
import { Form, Field } from 'formik';

export const MainForm = styled(Form)<{ pt?: number; pb?: number }>`
  padding: 0 0;
  width: 100%;

  ${(prop) =>
    prop.pt &&
    css`
      padding-top: ${prop.pt}px;
    `}
  ${(prop) =>
    prop.pb &&
    css`
      padding-bottom: ${prop.pt}px;
    `}
`;
export const LabelWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 10px 0;
`;
export const Label = styled.label`
  font-size: 9px;
  display: block;
  line-height: 15px;
  text-transform: uppercase;
  color: var(--object-secondary);
`;
export const Notice = styled.div`
  font-size: 9px;
  text-transform: uppercase;
  color: var(--error);
`;
const InputCss = css`
  padding: 10px 20px;
  line-height: 20px;
  border: 1px solid var(--border);
  background: var(--highlight);
  color: var(--object-primary);
  border-radius: 5px;
  width: 100%;
  appearance: none;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    appearance: textfield;
  }
`;
export const Input = styled(Field)`
  ${InputCss}
`;
export const StyledInput = styled.input`
  ${InputCss}
  background: var(--base);
  height: 40px;
`;
export const InputWrap = styled.div`
  position: relative;
`;
export const Fieldset = styled.fieldset<{ error?: boolean }>`
  width: 100%;
  margin: 0 0 20px 0;

  &:last-child {
    margin: 0;
  }

  ${(prop) =>
    prop.error &&
    css`
      ${Input} {
        border-color: var(--error);
      }
    `}
`;
export const FieldsetRow = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;

  ${Fieldset} {
    margin: 0;
  }
`;
export const Buttons = styled.div`
  display: flex;
`;
export const FormFooter = styled.footer`
  display: flex;
  position: relative;
  padding: 20px 0 0 0;
  margin: 20px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid var(--border);
`;
export const FooterText = styled.div`
  padding: 0 20px 0 0;
  font-size: 12px;
`;
export const Usemax = styled.button`
  border: none;
  background: none;
  padding: 0;
  color: var(--accent-primary);
  text-transform: uppercase;
  font-size: 9px;
  font-weight: 700;
  cursor: pointer;
`;
export const FieldAlt = styled.div`
  position: absolute;
  top: 50%;
  right: 10px;
  text-transform: uppercase;
  color: var(--object-secondary);
  line-height: 20px;
  font-size: 12px;
  margin-top: -10px;
`;
export const InputError = styled.div`
  color: var(--error);
  position: absolute;
  bottom: -18px;
`;
export const Table = styled.section`
  margin: 20px 0;
`;
export const TableRow = styled.div`
  display: flex;
  padding: 5px 0;
  align-items: stretch;
  justify-content: space-between;
`;
export const TableKey = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  midth: 50%;
  min-width: 50%;
`;
export const TableValue = styled.div`
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  midth: 50%;
  min-width: 50%;
`;
