import { AppSettingsType } from 'shared/types';
import { Pattern, TransferFiltersType } from './types';

export const SMALL_BALANCE_VALUE = 0.01;

export const EXCHANGES = [
  'BINANCE',
  'BITSTAMP',
  'BITFINEX',
  'DERIBIT',
  'GLOBITEX',
  'HITBTC',
  'GOZO',
  'KRAKEN',
  'LMAX',
  'OKCOIN',
  'FTX',
  'DYDX',
];

export const FILTERS = ['ALL', 'WALLETS', 'EXCHANGES'];

export const PATTERNS: Record<string, Pattern> = {
  DERIBIT: {
    pattern: /^\d{0,24}(\.\d{0,6})?$/,
    digitsLength: 6,
  },
  default: {
    pattern: /^\d{0,24}(\.\d{0,8})?$/,
    digitsLength: 8,
  },
};

export const DEFAULT_FROM = {
  exchange_api_id: 'bitfinex',
  account_id: 0,
  wallet_id: 0,
};

export const GET_DEFAULT_FILTERS = (
  appSettings: AppSettingsType,
): TransferFiltersType => {
  return {
    currencies: [...appSettings.currencies_primary],
    isHideSmallBalances: false,
    isSelectAll: false,
  };
};

export const DEFAULT_TO = {
  exchange_api_id: 'deribit',
  account_id: 0,
  wallet_id: 0,
};

export const DEFAULT_AMOUNT = 0.1;

export const CROSS_WALLET_SUPPORTED = ['BINANCE', 'BITFINEX'];

export const DEBOUNCE_DELAY = 800;
