import type { SafeAppData } from '@safe-global/safe-gateway-typescript-sdk';
import type { BaseTransaction, RequestId, SendTransactionRequestParams } from '@gnosis.pm/safe-apps-sdk';
import type { TxStepperProps } from '../hooks/useTxStepper';
import ReviewSafeAppsTx from './ReviewSafeAppsTx';
import InvalidTransaction from './InvalidTransaction';
import SafeAppsTxModalLabel from './SafeAppsModalLabel';
import { isTxValid } from '../helpers/utils';

import { Step } from 'features/SafeApp/hooks/useTxStepper';

import TxStepper from './TxStepper';

export type SafeAppsTxParams = {
  appId?: string
  app?: SafeAppData
  requestId: RequestId
  txs: BaseTransaction[]
  params?: SendTransactionRequestParams
  steps: Array<Step>
  initialData?: unknown[]
  initialStep?: number
  onClose: () => void
};

export type TxModalProps = {
  onClose: () => void
  initialData?: TxStepperProps['initialData']
};

const SafeAppsTxSteps: TxStepperProps['steps'] = [
  {
    label: (data) => {
      const { app } = data as SafeAppsTxParams;

      return <SafeAppsTxModalLabel app={app} />;
    },
    render: (data, onSubmit, onBack, setStep) => {

      if (!isTxValid((data as SafeAppsTxParams).txs)) {
        return <InvalidTransaction />;
      }

      return <ReviewSafeAppsTx steps={SafeAppsTxSteps} onClose={onBack} safeAppsTx={data as SafeAppsTxParams} />;
    },
  },
];

const SafeAppsTxModal = ({ onClose, initialData, ...props }: TxModalProps) => {
  return <TxStepper {...props} steps={SafeAppsTxSteps} initialData={initialData} onClose={onClose} />;
};

export default SafeAppsTxModal;
