import { addDays, addMinutes, addSeconds, format } from 'date-fns';
import queryString from 'query-string';

interface GetNextPageParams {
  next: string;
  limit: number;
}

export const getNextPageParam = ({ next, limit }: GetNextPageParams) => {
  const params = new URLSearchParams(next);
  const offset = params.get('offset');
  if (!offset) return;
  return Number(offset) / limit;
};

export const stringifyUrl = (
  url: string,
  filters: any,
  page: number,
  limit = 50,
) => {
  return queryString.stringifyUrl(
    {
      url,
      query: {
        ...filters,
        ...{
          limit: limit,
          offset: page * limit,
        },
      },
    },
    { arrayFormat: 'separator' },
  );
};

const dateFormat = 'yyyy-MM-dd HH:mm:ss';
export const parseDates = (atAfter: string, atBefore: string) => {
  const dateAtAfter = new Date(atAfter);
  const dateAtBefore = new Date(atBefore);
  const minutesDifference = dateAtAfter.getTimezoneOffset();
  const parsedUpdatedAtAfter = atAfter
    ? {
        atAfter: format(
          addMinutes(dateAtAfter, minutesDifference * 2),
          dateFormat,
        ),
      }
    : undefined;
  const parsedUpdatedAtBefore = atBefore
    ? {
        atBefore: format(
          addSeconds(
            addMinutes(addDays(dateAtBefore, 1), minutesDifference * 2),
            -1,
          ),
          dateFormat,
        ),
      }
    : undefined;

  return {
    atAfter: parsedUpdatedAtAfter?.atAfter,
    atBefore: parsedUpdatedAtBefore?.atBefore,
  };
};
