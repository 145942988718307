// @ts-nocheck

import { FC, useContext, useMemo } from 'react';
import { TransactionDetails, TransactionStatus, TransactionSummary } from '@gnosis.pm/safe-react-gateway-sdk';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';

import AppContext from 'shared/contexts/AppContext';
import { SafeStoreInstance } from '../../../mobx/SafeStore';
import * as T from '../../styled/transactions';
import { MultisigTransactionsStoreInstance } from '../../../mobx/MultisigTransactionsStore';
import { useTransactionButtonsState } from '../../hooks/useTransactionButtonsState';

import { ApproveTransaction } from '../ApproveTransaction';
import { ExecuteTransaction } from '../ExecuteTransaction';
import { RejectTransaction } from '../RejectTransaction';

interface Props {
  tx: TransactionSummary;
  details: TransactionDetails;
}

export const TransastionButtons: FC<Props> = observer(({ tx, details, item }) => {
  const { userAccount, selectedSafe } = SafeStoreInstance;
  const { setActiveTransaction, transactionQueue, getLastQueueNonce } = MultisigTransactionsStoreInstance;
  const {
    setConfirmExecuteMultisigTransactionModal,
    openModal,
  } = useContext(AppContext);

  const { executionInfo } = tx;

  const {
    amIOwner,
    isTheFirstExecution,
    canIConfirm,
    canIExecute,
    shouldBeConfirmed,
    shouldBeExecuted,
    isCancellation,
    isBeingCancelled,
    canICancel,
    canBeCancelled,
  } = useTransactionButtonsState({ tx, details, userAccount, transactionQueue, lastNonce: getLastQueueNonce() });

  if (!amIOwner || ['PENDING', 'WILL_BE_REPLACED'].includes(tx?.txStatus)) {
    return null;
  }

  const onExecuteOpenModal = () => {
    setActiveTransaction(item, details);

    openModal({
      title: 'Execute Transaction',
      component: () => (
        <ExecuteTransaction />
      ),
    });
  };

  const onRejectOpenModal = () => {
    openModal({
      title: 'Reject Transaction',
      width: '560px',
      component: () => (
        <RejectTransaction />
      ),
    });
  };

  const onOpenConfirmModal = () => {
    setActiveTransaction(item, details);
    setConfirmExecuteMultisigTransactionModal(isTheFirstExecution);

    openModal({
      title: 'Approve Transaction',
      width: '630px',
      component: () => (
        <ApproveTransaction />
      ),
    });
  };

  return (
    <T.Buttons>
      {shouldBeExecuted && (
        <T.ActionButton
          disabled={!canIExecute}
          onClick={onExecuteOpenModal}
          type="submit"
        >
          Execute
        </T.ActionButton>
      )}
      {shouldBeConfirmed && (
        <T.ActionButton
          disabled={!canIConfirm}
          onClick={onOpenConfirmModal}
          type="submit"
        >
          Confirm {isTheFirstExecution && 'and execute'}
        </T.ActionButton>
      )}
      {(!isCancellation && !isBeingCancelled && canBeCancelled) && (
        <T.ActionButton
          disabled={!canICancel}
          onClick={onRejectOpenModal}
          type="reset"
        >
          Reject
        </T.ActionButton>
      )}
    </T.Buttons>
  );
});
