import styled, { css } from 'styled-components/macro';

import { ReactComponent as SvgQr } from 'shared/assets/icons/icon-qr-min.svg';
import { ReactComponent as SvgTrash } from 'shared/assets/icons/icon-trash.svg';
import { ReactComponent as SvgValid } from 'shared/assets/icons/icon-whitelist-yes.svg';
import { ReactComponent as SvgCopy } from 'shared/assets/icons/icon-copy-stroke.svg';
import { ReactComponent as SvgExternal } from 'shared/assets/icons/icon-external-link.svg';
import { ReactComponent as SvgTransfer } from 'shared/assets/icons/icon-transfer.svg';

export const Form = styled.form`
  width: 100%;
  padding: 20px 0 0 0;
`;
export const Label = styled.label`
  display: inline-block;
  text-transform: uppercase;
  font-weight: 100;
  font-size: 9px;
  margin: 0 0 5px 0;
  color: var(--object-secondary);
`;
export const Input = styled.input`
  width: 100%;
  padding: 10px;
  line-height: 20px;
  border: 1px solid var(--border);
  font-size: 12px;
  border-radius: 5px;
  max-width: 100%;

  &::placeholder {
    color: var(--object-secondary);
  }
`;
export const InputWrap = styled.div`
  position: relative;
  width: 100%;
`;
export const IconValid = styled(SvgValid)`
  position: absolute;
  top: 50%;
  right: 20px;
  width: 10px;
  height: 10px;
  z-index: 2;
  stroke: var(--success);
  margin-top: -5px;
`;
export const Row = styled.section`
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 20px 0;

  &:first-child {
    margin-bottom: 0;
  }
`;
export const LabelCol = styled.div`
  width: 200px;
  min-width: 200px;
  max-width: 200px;
`;
export const OwnerRow = styled.div`
  width: 100%;
  display: flex;
  padding: 15px 0;
  align-items: center;
`;
export const InputCol = styled.div`
  flex: 1;
  min-width: 0;
  padding: 0 20px;

  ${OwnerRow} {
    border-top: 1px solid var(--border);
    &:first-of-type {
      border-top: none;
    }
  }
`;
export const ToolsCol = styled.div`
  width: auto;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  min-width: 50px;
  max-width: 50px;
`;
export const Button = styled.button`
  border: 0;
  background: none;
  margin: 0;
  color: var(--object-secondary);
  transition: all 0.2s;
  cursor: pointer;

  &:hover {
    color: var(--object-primary);
  }

  & + & {
    margin: 0 0 0 10px;
  }
`;
const IconCss = css`
  width: 20px;
  height: 20px;
  fill: currentColor;
`;
export const IconQr = styled(SvgQr)`
  ${IconCss}
`;
export const IconCopy = styled(SvgCopy)`
  ${IconCss}
  stroke: currentColor;
  fill: none;
`;
export const IconExternal = styled(SvgExternal)`
  ${IconCss}
  stroke: currentColor;
  fill: none;
`;
export const IconTrash = styled(SvgTrash)`
  ${IconCss}
  stroke: currentColor;
  fill: none;
`;
export const ColTitle = styled.h3`
  font-size: 18px;
  margin: 0 0 20px 0;
`;
export const WalletName = styled.div`
  font-size: 12px;
  font-weight: 700;
  margin: 0 0 20px 0;
`;
export const AssetWrap = styled.div`
  display: flex;
`;
export const BalanceWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 0 10px;
  font-size: 12px;
`;
export const BalanceTitle = styled.div`
  font-weight: 700;
  width: 100%;
`;
export const AssetValue = styled.div``;
export const Hr = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 1px;
    background: var(--border);
  }
`;
export const IconTransfer = styled(SvgTransfer)`
  width: 40px;
  height: 40px;
  padding: 10px;
  stroke: var(--border);
  background-color: var(--base);
  z-index: 2;
  transform: rotate(90deg);
`;

export const RightHeaderTitle = styled.span`
  position: absolute;
  top: 20px;
  right: 50px;
  color: var(--object-secondary);
  font-size: 18px;
  line-height: 21px;
`;
