// @ts-nocheck

import { useState, useContext, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Formik } from 'formik';

import Dropdown from 'shared/components/Dropdown';
import AppContext from 'shared/contexts/AppContext';
import { getCryptoValue } from 'shared/helpers/currencies';
import { getByteLength } from 'shared/helpers/getByteLength';
import useAsync from '../hooks/useAsync';

import type { SafeTransaction } from '@safe-global/safe-core-sdk-types';

import * as Form from 'shared/components/Form';
import { SafeStoreInstance } from '../../mobx/SafeStore';
import { MultisigTransactionsStoreInstance } from '../../mobx/MultisigTransactionsStore';
import {
  calculateGasPrice,
  getFeesPerGas,
} from '../../mobx/logic/ethTransactions';

import useTxSender from 'features/SafeApp/SafeAppsTxModal/hooks/useTxSender';

import * as M from '../styled/main';
import * as T from '../styled/transactions';
import { _getChain } from 'features/Multisig/helpers';

const ADVANCED_INIT: {
  nonce: number;
} = {
  nonce: 1,
};
function ApproveTransaction() {
  const [wasSubmitted, setWasSubmitted] = useState(false);
  const [amount, setAmount] = useState(0.01);
  const [recipient] = useState('');
  // const web3React = useWeb3React();
  const {
    confirmExecuteMultisigTransactionModal,
    openModal,
  } = useContext(AppContext);
  const [gas, setGas] = useState('');
  const { createMultiSendCallOnlyTx, dispatchSafeAppsTx } = useTxSender();

  const { selectedSafe, isHardwareWallet, wallet } = SafeStoreInstance;
  const {
    confirmTransaction,
    activeTransaction,
    getTxParameters,
  } = MultisigTransactionsStoreInstance;

  const [safeTx, safeTxError] = useAsync<SafeTransaction | undefined>(async () => {
    try {
      const tx = await createMultiSendCallOnlyTx(activeTransaction?.transaction?.txInfo);
      return tx;

    } catch (e) {
      console.log('error', e);
    }
  }, []);

  useEffect(() => {
    const setGasPrice = async () => {
      const { ethGasLimit: gasLimit } = await getTxParameters({
        to:
          (activeTransaction?.transaction?.txInfo?.recipient?.value ?? activeTransaction?.transaction?.txInfo?.to?.value) ||
          selectedSafe?.address.value,
        amount:
          (activeTransaction?.transaction?.txInfo?.transferInfo?.value ?? activeTransaction?.transaction?.txInfo?.value) /
          1000000000000000000,
        providedApprovalAndExecution: confirmExecuteMultisigTransactionModal,
      });

      const gasPrice = await calculateGasPrice();
      const { maxPriorityFeePerGas: gasMaxPrioFee } = await getFeesPerGas();
      const totalPricePerGas =
        parseInt(gasPrice, 10) + parseInt(gasMaxPrioFee, 10);
      const estimatedGasCosts = parseInt(gasLimit, 10) * totalPricePerGas;
      setGas(estimatedGasCosts);
    };

    if (confirmExecuteMultisigTransactionModal) {
      setGasPrice();
    }
  }, []);

  return (
    <Formik
      initialValues={{
        data: '',
        recipient,
        amount,
        execute: true,
      }}
      onSubmit={(values) => {
        console.log(values);
      }}
    >
      {({ errors, touched }) => (
        <Form.MainForm>
          <>
            <Form.Label>
              This action will approve this transaction. A separate
              Transaction will be performed to submit the approval.
            </Form.Label>
            <p />
            <p />
            <Form.Label>
              <b>
                Transaction Nonce:{' '}
                {
                  activeTransaction?.detailedExecutionInfo
                    ?.detailedExecutionInfo?.nonce
                }
              </b>
            </Form.Label>
            {confirmExecuteMultisigTransactionModal && gas !== '' && (
              <Form.Table>
                <Form.TableRow>
                  <Form.TableKey>Estimated fee price:</Form.TableKey>
                  <Form.TableValue>
                    {parseFloat(getCryptoValue(+gas)).toFixed(5)}{' '}
                    {_getChain().nativeCurrency.symbol}
                  </Form.TableValue>
                </Form.TableRow>
              </Form.Table>
            )}
            <Dropdown>
              <T.Table>
                <T.RowSmall>
                  <T.ColLabel>Nonce</T.ColLabel>
                  <T.ColValue>
                    {activeTransaction?.detailedExecutionInfo?.nonce}
                  </T.ColValue>
                </T.RowSmall>
                <T.RowSmall>
                  <T.ColLabel>Value</T.ColLabel>
                  <T.ColValue>
                    {getCryptoValue(activeTransaction?.txData?.value)}
                  </T.ColValue>
                </T.RowSmall>
                <T.RowSmall>
                  <T.ColLabel>To</T.ColLabel>
                  <T.ColValue>
                    {activeTransaction?.txData?.to.value}
                  </T.ColValue>
                </T.RowSmall>
                <T.RowSmall>
                  <T.ColLabel>safeTxHash</T.ColLabel>
                  <T.ColValue>
                    {activeTransaction?.detailedExecutionInfo?.safeTxHash}
                  </T.ColValue>
                </T.RowSmall>
                <T.RowSmall>
                  <T.ColLabel>Operation</T.ColLabel>
                  <T.ColValue>
                    {activeTransaction?.txData?.operation}
                  </T.ColValue>
                </T.RowSmall>
                <T.RowSmall>
                  <T.ColLabel>baseGas</T.ColLabel>
                  <T.ColValue>0</T.ColValue>
                </T.RowSmall>
                <T.RowSmall>
                  <T.ColLabel>gasPrice</T.ColLabel>
                  <T.ColValue>0</T.ColValue>
                </T.RowSmall>
                <T.RowSmall>
                  <T.ColLabel>gasToken</T.ColLabel>
                  <T.ColValue>0</T.ColValue>
                </T.RowSmall>
                <T.RowSmall>
                  <T.ColLabel>refundReceiver</T.ColLabel>
                  <T.ColValue>0</T.ColValue>
                </T.RowSmall>
                {activeTransaction?.detailedExecutionInfo.confirmations
                  ?.filter(({ signature }) => signature)
                  .map(({ signature }, i) => (
                    <T.RowSmall key={`signature-${i}`}>
                      <T.ColLabel>Signature {i + 1}</T.ColLabel>
                      <T.ColValue>
                        {signature ? getByteLength(signature) : 0} bytes
                      </T.ColValue>
                    </T.RowSmall>
                  ))}
                {activeTransaction?.detailedExecutionInfo?.txData
                  ?.hexData && (
                    <T.RowSmall>
                      <T.ColLabel>hexData</T.ColLabel>
                      <T.ColValue>
                        {
                          activeTransaction?.detailedExecutionInfo?.txData
                            ?.hexData
                        }
                      </T.ColValue>
                    </T.RowSmall>
                  )}
              </T.Table>
            </Dropdown>
          </>
          <Form.FormFooter>
            <Form.FooterText>
              You're about to execute a transaction and will have to
              confirm it with your currently connected wallet.
            </Form.FooterText>
            <Form.Buttons>
              <M.CancelButton
                type="button"
                onClick={() => openModal(null)}
              >
                Cancel
              </M.CancelButton>
              <M.SignButton
                type="button"
                disabled={wasSubmitted}
                onClick={() => {
                  try {
                    setWasSubmitted(true);
                    confirmTransaction(
                      {
                        confirm: true,
                        execute: confirmExecuteMultisigTransactionModal,
                        safeTx,
                      },
                      () => {
                        openModal(null);
                      },
                    );
                  } catch (error) {
                    setWasSubmitted(false);
                  }
                }}
              >
                {wasSubmitted
                  ? `Sign on ${wallet.name}`
                  : `Confirm${confirmExecuteMultisigTransactionModal ?
                    ' and execute' : ''
                  }`}
              </M.SignButton>
            </Form.Buttons>
          </Form.FormFooter>
        </Form.MainForm>
      )}
    </Formik>);
}

const C = observer(ApproveTransaction);

export { C as ApproveTransaction };
