import useSafeInfo from './useSafeInfo';
import useWallet from './useWallet';
import { isOwner } from '../helpers/transaction-guards';
import { toJS } from 'mobx';
import { SafeStoreInstance } from 'features/Multisig';

const useIsSafeOwner = () => {
  const { safe } = useSafeInfo();
  const wallet = useWallet();
  const { userAccount } = SafeStoreInstance;

  //@ts-ignore
  return isOwner(safe.owners, userAccount);
};

export default useIsSafeOwner;
