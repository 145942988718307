import { useMemo, useCallback } from 'react';
import type { SafeAppData } from '@gnosis.pm/safe-react-gateway-sdk';
import { useRemoteSafeApps } from 'features/Multisig/view/hooks/useRemoteSafeApps';
import { useCustomSafeApps } from './useCustomSafeApps';
import { useBrowserPermissions, useSafePermissions } from '../permissions';

type ReturnType = {
  addCustomApp: (app: SafeAppData) => void
  removeCustomApp: (appId: number) => void
};

const useSafeApps = (): ReturnType => {
  const { customSafeApps, updateCustomSafeApps } = useCustomSafeApps();
  const { removePermissions: removeSafePermissions } = useSafePermissions();
  const { removePermissions: removeBrowserPermissions } = useBrowserPermissions();

  const addCustomApp = useCallback(
    (app: SafeAppData) => {
      updateCustomSafeApps([...customSafeApps, app]);
    },
    [updateCustomSafeApps, customSafeApps],
  );

  const removeCustomApp = useCallback(
    (appId: number) => {
      updateCustomSafeApps(customSafeApps.filter((app) => app.id !== appId));
      const app = customSafeApps.find((app) => app.id === appId);

      if (app) {
        removeSafePermissions(app.url);
        removeBrowserPermissions(app.url);
      }
    },
    [updateCustomSafeApps, customSafeApps, removeSafePermissions, removeBrowserPermissions],
  );

  return {
    addCustomApp,
    removeCustomApp,
  };
};

export { useSafeApps };
