import { useCallback, useContext, useRef, useState } from 'react';
import { format, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import Tippy from '@tippyjs/react';

import { getExchangeInfoById } from 'shared/constants/exchanges';
import {
  AccountType,
  CurrencyType,
  ExchangeAccountsType,
  isCurrencyFiat,
  tagColor,
  WalletType,
} from 'shared/types';
import { getCoinsInfoById } from 'shared/constants/coins';

import { ExchangeIconMin, IconArrowWrap, IconArrow } from '../ExchangeAbstract';

import { SMALL_BALANCE_VALUE } from '../../../constants';
import { TransferFiltersType } from '../../../types';

import AppContext from 'shared/contexts/AppContext';
import * as S from './styled';
import { AccRow } from './AccRow';

const balanceDateFormat = 'HH:mm';

function SubAccount({
  account,
  availableCurrencies,
  chosenCurrency,
  chosenSecondWallet,
  chosenWallet,
  couldAddTransaction,
  couldAddTransactionProposal,
  exchange,
  filters,
  handleOpenSubAccounts,
  handleOpenWalletTypes,
  hasSubAccounts,
  isShouldFilterByCurrency,
  onClick,
  openSubAccounts,
  openWalletTypes,
  side,
  isFromWallet,
}: {
  account: AccountType;
  isFromWallet: boolean;
  accounts: AccountType[];
  availableCurrencies: CurrencyType[];
  chosenCurrency: string;
  chosenSecondWallet: WalletType | null;
  chosenWallet: WalletType | null;
  couldAddTransaction: boolean;
  couldAddTransactionProposal: boolean;
  exchange: ExchangeAccountsType;
  filters: TransferFiltersType;
  handleOpenAccounts: any;
  handleOpenSubAccounts: any;
  handleOpenWalletTypes: any;
  hasSubAccounts: boolean;
  isShouldFilterByCurrency: boolean;
  onClick: any;
  openAccounts: number[];
  openSubAccounts: number[];
  openWalletTypes: string[];
  side: 'from' | 'to';
}) {
  const { getCurrencyValue } = useContext(AppContext);

  const exchangeInfo = getExchangeInfoById(exchange.exchange);

  const isOpenAccount = openSubAccounts.includes(account.id);
  const isOnlyMain = !hasSubAccounts && account.name === 'MAIN';

  const accountTypes = account.wallets
    .filter((item: WalletType) =>
      isShouldFilterByCurrency
        ? filters.currencies.includes(item.currency)
        : true,
    )
    .filter((item: WalletType) =>
      isShouldFilterByCurrency
        ? side === 'to' && chosenCurrency
          ? item.currency === chosenCurrency
          : true
        : true,
    )
    .filter((item: WalletType) =>
      filters.isHideSmallBalances &&
      side !== 'to' &&
      chosenWallet?.id !== item.id
        ? Number(item?.available) >= SMALL_BALANCE_VALUE
        : true,
    )
    .map((item: WalletType) => item.type)
    // @ts-expect-error
    .filter((v: string, i: number, a: string[]) => a.indexOf(v) === i);

  const formattedBalanceDate = account.balance_updated_at
    ? format(
        utcToZonedTime(parseISO(account.balance_updated_at), 'UTC'),
        balanceDateFormat,
      )
    : '';

  return (
    <S.AccountWrap opened={isOpenAccount}>
      {!isOnlyMain ? (
        <S.AccountHeader onClick={() => handleOpenSubAccounts(account.id)}>
          <IconArrowWrap rotateIcon={isOpenAccount}>
            <IconArrow />
          </IconArrowWrap>
          {exchange.exchange ? (
            <ExchangeIconMin
              style={{
                backgroundImage: `url(${exchangeInfo?.logo_url})`,
              }}
              disabled={false}
            />
          ) : null}

          <S.AccountTitle>
            <S.AccountName>{account.name}</S.AccountName>
            <S.AccountBalanceStatus>
              {account.is_balance_outdated || !account.balance_updated_at ? (
                <S.IconBalanceWarning />
              ) : null}
              <S.AccountBalanceUpdated
                isOutdated={
                  account.is_balance_outdated || !account.balance_updated_at
                }
              >
                {account.balance_updated_at ? (
                  <span>{formattedBalanceDate} UTC</span>
                ) : (
                  <span>Never updated</span>
                )}
              </S.AccountBalanceUpdated>
            </S.AccountBalanceStatus>
          </S.AccountTitle>
        </S.AccountHeader>
      ) : null}
      <S.Wallets hasSubAccounts={hasSubAccounts}>
        {isOpenAccount
          ? accountTypes
              // @ts-expect-error
              .map((subAccountType: string) => {
                const isOpenWalletType = openWalletTypes.includes(
                  `${account.id}_${subAccountType}`,
                );

                const firstTags = account.tags?.slice(0, 4);
                const otherTags = account.tags?.slice(4, account.tags.length);

                return (
                  <S.Wallet key={`${account.id}_${subAccountType}`}>
                    {subAccountType ? (
                      <S.WalletHeader
                        onClick={() =>
                          handleOpenWalletTypes(account.id, subAccountType)
                        }
                        opened={isOpenWalletType}
                      >
                        <IconArrowWrap rotateIcon={isOpenWalletType}>
                          <IconArrow />
                        </IconArrowWrap>
                        <ExchangeIconMin
                          style={{
                            backgroundImage: `url(${exchangeInfo?.logo_url})`,
                          }}
                          disabled={false}
                        />

                        <S.WalletTypeName>
                          {subAccountType ? `${subAccountType}` : 'MAIN'}
                        </S.WalletTypeName>
                      </S.WalletHeader>
                    ) : null}
                    <S.WalletWrap>
                      {isOpenWalletType
                        ? availableCurrencies
                            .filter((currency) =>
                              isShouldFilterByCurrency
                                ? filters.currencies.includes(currency)
                                : true,
                            )
                            .filter((currency) =>
                              isShouldFilterByCurrency
                                ? side === 'to' && chosenCurrency
                                  ? currency === chosenCurrency
                                  : true
                                : true,
                            )
                            .map((currency: string) => {
                              const accWallets = account.wallets
                                .filter(
                                  (item: WalletType) =>
                                    item.currency === currency,
                                )
                                .filter(
                                  (item: WalletType) =>
                                    item.type === subAccountType,
                                )
                                .filter((item: WalletType) =>
                                  filters.isHideSmallBalances &&
                                  side !== 'to' &&
                                  chosenWallet?.id !== item.id
                                    ? Number(item?.available) >
                                      SMALL_BALANCE_VALUE
                                    : true,
                                );
                              return accWallets.map((accWallet: WalletType) => {
                                return (
                                  <AccRow
                                    key={accWallet.id}
                                    accWallet={accWallet}
                                    chosenWallet={chosenWallet}
                                    getCoinsInfoById={getCoinsInfoById}
                                    currency={currency}
                                    couldAddTransaction={couldAddTransaction}
                                    couldAddTransactionProposal={
                                      couldAddTransactionProposal
                                    }
                                    isCurrencyFiat={isCurrencyFiat}
                                    side={side}
                                    isFromWallet={isFromWallet}
                                    chosenSecondWallet={chosenSecondWallet}
                                    account={account}
                                    exchange={exchange}
                                    onClick={onClick}
                                  />
                                );
                              });
                            })
                        : null}
                    </S.WalletWrap>
                  </S.Wallet>
                );
              })
          : null}
      </S.Wallets>
    </S.AccountWrap>
  );
}

export { SubAccount };
