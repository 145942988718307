const trimTrailingSlash = (url: string): string => {
  return url.replace(/\/$/, '');
};

const relativeFirstCharacters = ['.', '/'];
const isRelativeUrl = (url: string): boolean => {
  return relativeFirstCharacters.indexOf(url[0]) > -1;
};

const isSameUrl = (url1: string, url2: string): boolean => {
  return trimTrailingSlash(url1) === trimTrailingSlash(url2);
};

export { trimTrailingSlash, isRelativeUrl, isSameUrl };
