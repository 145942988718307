import { FC, memo } from 'react';
import { getExchangeInfoById } from 'shared/constants/exchanges';
import { AccountRequiredType } from 'shared/types';
import Tippy from '@tippyjs/react';
import * as S from '../../styled';

interface InfoProps {
  account: AccountRequiredType | null;
  currentTypeString: string;
}

export const AccountInfo: FC<InfoProps> = memo(
  ({ account, currentTypeString }) => {
    const attributes = [
      account?.name ?? '-',
      account?.exchange ?? '-',
      currentTypeString ?? '-',
    ].join(',');

    const exchangeInfo = getExchangeInfoById(account?.exchange ?? '-');

    return (
      <>
        <S.AccountData data-id={attributes}>
          {account && (
            <>
              <Tippy
                trigger="mouseenter"
                placement="top"
                hideOnClick={true}
                theme="transparent"
                content={exchangeInfo?.api_id}
              >
                <S.ToExchangeIcon
                  style={{ backgroundImage: `url(${exchangeInfo?.logo_url})` }}
                />
              </Tippy>
              <Tippy
                trigger="mouseenter"
                placement="top"
                hideOnClick={true}
                theme="transparent"
                content={account.name}
                disabled={account.name?.length < 17}
              >
                <S.ToAccountName>{account.name}</S.ToAccountName>
              </Tippy>
            </>
          )}
          {!account && <S.ToAccountName>-</S.ToAccountName>}
        </S.AccountData>
        {account && <S.ToExchangeName>{currentTypeString}</S.ToExchangeName>}
      </>
    );
  },
);
