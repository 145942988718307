// @ts-nocheck
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
  toJS,
  transaction,
} from 'mobx';
import {
  Operation,
  proposeTransaction,
  TransactionDetails,
  getTransactionHistory,
  getTransactionQueue,
  getTransactionDetails,
  TransactionStatus,
  TransactionListItem,
  SafeInfo,
} from '@gnosis.pm/safe-react-gateway-sdk';

import type { SafeTransaction } from '@safe-global/safe-core-sdk-types';

import { GnosisSafe } from 'shared/types/contracts/gnosis_safe';

import {
  ProposeTxBody,
  RequiredTxProps,
  SaveTxToHistoryTypes,
  TransactionListItemDetailed,
} from './models';
import {
  getApprovalTransaction,
  getExecutionTransaction,
  getUserNonce,
  tryOffChainSigning,
} from './logic/transactions';
import { getWeb3 } from './logic/getWeb3';
import {
  createSendParams,
  estimateGasForTransactionExecution,
  TxParameters,
} from './logic/gas';
import { getGnosisSafeInstanceAt } from './logic/safeContracts';
import { canExecuteCreatedTx, EMPTY_DATA } from './logic/ethTransactions';
import { ZERO_ADDRESS } from './logic/ethAddresses';
import { generateSafeTxHash } from './logic/transactionHelpers';
import {
  checkIfOffChainSignatureIsPossible,
  generateSignaturesFromTxConfirmations,
  getPreValidatedSignatures,
} from '../safeTxSigner';
import { LATEST_SAFE_VERSION } from '../constants';
import {
  _getChainId,
  checksumAddress,
  getNonce,
  isHardwareWallet,
} from '../helpers';
import { TxArgs } from '../models';
import { SafeStoreInstance } from './SafeStore';
import { List } from 'immutable';
import { makeConfirmation } from './logic/confirmation';
import { BigNumber, ethers } from 'ethers';
import { toTokenUnit } from '../view/utilities';
import axios from 'axios';
import { SettingsStoreInstance } from 'services';
import { notify } from 'shared/components/Notification/notify';

const axiosInstance = axios.create();

class MultisigTransactionsStore {
  constructor() {
    makeObservable(this);
  }

  protected txArgs: TxArgs = {} as TxArgs;
  protected txProps: RequiredTxProps = {} as RequiredTxProps;
  protected safeTxHash = '';
  protected txHash = '';
  protected txId: Nullable<string> = null;
  public isFinalization: Nullable<boolean> = null;
  protected safeInstance: GnosisSafe = {} as GnosisSafe;
  public confirm = false;
  public execute = false;

  @observable public nextQueue: Nullable<string>;
  @observable public nextHistory: Nullable<string>;
  protected fetchedMoreQueue = false;
  protected fetchedMoreHistory = false;
  @observable public fetchingMoreHistory = false;
  @observable public fetchingMoreQueue = false;
  @observable public errors = {};

  @observable public activeTransaction: Nullable<TransactionListItemDetailed> =
    null;

  @observable public transactionDetails: {
    [key: string]: TransactionListItemDetailed;
  } = {};

  @observable transactionQueue: TransactionListItemDetailed[] = [];
  @observable transactionHistory: TransactionListItemDetailed[] = [];

  @observable isLoadingQueue = false;
  @observable isLoadingHistory = false;
  @observable initiallyLoadedQueue = false;
  @observable initiallyLoadedHistory = false;

  @action.bound public resetPagination() {
    this.nextQueue = null;
    this.nextHistory = null;
    this.fetchedMoreHistory = false;
    this.fetchedMoreQueue = false;
  }

  @action.bound public resetTransactionsHistory() {
    this.transactionHistory = [];
  }

  @action.bound public resetTransactionsQueue() {
    this.transactionQueue = [];
  }

  @action.bound public setActiveTransaction(
    transaction: TransactionListItemDetailed | null,
    details: TransactionDetails | null,
  ) {
    if (!transaction && !details) {
      this.activeTransaction = null;
    } else {
      // @ts-ignore
      this.activeTransaction = { ...transaction, ...details };
    }
  }

  @action.bound public getLastQueueNonce() {
    return this.transactionQueue.find(({ type }) => type === 'TRANSACTION')
      ?.transaction?.executionInfo?.nonce;
  }

  @action.bound public isTheUserOwner(
    transaction: TransactionListItemDetailed,
  ): boolean {
    return (
      // @ts-ignore
      transaction?.detailedExecutionInfo?.safeAddress ===
      SafeStoreInstance.selectedSafe?.address.value
    );
  }

  @action.bound public canIConfirm(
    transaction: TransactionListItemDetailed,
  ): boolean {
    const isTheSigner = this.transactionDetails[
      // @ts-ignore
      transaction.transaction.id
      // @ts-ignore
    ]?.detailedExecutionInfo?.signers
      // @ts-ignore
      ?.some(({ value }) => value === SafeStoreInstance.userAccount);
    const hadSigned = this.transactionDetails[
      // @ts-ignore
      transaction.transaction.id
      // @ts-ignore
    ]?.detailedExecutionInfo?.confirmations
      // @ts-ignore
      ?.some(({ signer }) => signer.value === SafeStoreInstance.userAccount);
    return isTheSigner && !hadSigned;
  }

  @action.bound public async canSignOffchain(): Promise<boolean> {
    return new Promise((resolve) => resolve(!this.confirm && !this.execute));
  }

  @action.bound public async signThroughMetaMask(
    from: string,
    to: string,
  ): Promise<string | undefined> {
    const { txArgs, safeTxHash } = this;

    return await tryOffChainSigning(
      safeTxHash,
      {
        ...txArgs,
        safeAddress: SafeStoreInstance.selectedSafe?.address.value || '',
        sender: SafeStoreInstance.userAccount || '',
        to,
      },
      isHardwareWallet(SafeStoreInstance.wallet),
      LATEST_SAFE_VERSION,
    );
  }

  @action.bound public async calculateBodyFrom(props: ProposeTxBody) {
    const {
      safeInstance,
      to,
      value,
      data,
      operation,
      nonce,
      safeTxGas,
      baseGas,
      gasPrice,
      gasToken,
      refundReceiver,
      sender,
      origin,
      signature,
    } = props;
    const safeTxHash = await safeInstance.methods
      .getTransactionHash(
        to,
        value,
        data,
        operation,
        safeTxGas,
        baseGas,
        gasPrice,
        gasToken,
        refundReceiver || '',
        nonce,
      )
      .call();

    return {
      to: checksumAddress(to),
      value,
      data,
      operation,
      nonce: nonce.toString(),
      safeTxGas: safeTxGas.toString(),
      baseGas: baseGas.toString(),
      gasPrice,
      gasToken,
      refundReceiver,
      safeTxHash,
      sender: checksumAddress(sender),
      origin,
      signature,
    };
  }

  @action.bound public async saveTxToHistory({
    baseGas,
    data,
    gasPrice,
    gasToken,
    nonce,
    operation,
    origin,
    refundReceiver,
    safeInstance,
    safeTxGas,
    sender,
    signature,
    to,
    valueInWei,
  }: SaveTxToHistoryTypes): Promise<TransactionDetails> {
    const address = checksumAddress(safeInstance.options.address);

    const body = await this.calculateBodyFrom({
      safeInstance,
      to,
      value: valueInWei,
      data,
      operation,
      nonce: nonce.toString(),
      safeTxGas,
      baseGas,
      gasPrice,
      gasToken,
      refundReceiver,
      sender,
      origin: origin ? origin : null,
      signature,
    });
    return await proposeTransaction(_getChainId(), address, body);
  }

  @action.bound public async onComplete(
    signature?: string,
    onFinish?: (txId: Nullable<string>) => void,
  ) {
    const { txArgs, isFinalization } = this;
    if (!isFinalization && !this.txId) {
      await this.saveTxToHistory({
        ...txArgs,
        signature,
        origin: null,
      });
      return;
    }
    if (isFinalization && this.txId && this.txHash) {
      this.addPendingTransaction(this.activeTransaction);
    }
    await this.getTransactionQueue();
    if (onFinish) {
      onFinish(this.txId);
    }
  }

  @computed get pendingTransactions() {
    return (
      JSON.parse(
        window.localStorage.getItem(
          `pendingTransactions_${SafeStoreInstance.selectedSafe?.address?.value}`,
        ) || '[]',
      ) ?? []
    );
  }

  @action.bound public addPendingTransaction(transaction: any) {
    try {
      const list = this.pendingTransactions;
      transaction.transaction.txStatus = 'PENDING';
      list.push({
        ...transaction,
        initialStatus: transaction.transaction.txStatus,
        initialSubmitted:
          transaction.transaction.executionInfo.confirmationsSubmitted,
      });

      window.localStorage.setItem(
        `pendingTransactions_${SafeStoreInstance.selectedSafe?.address?.value}`,
        JSON.stringify(toJS(list)),
      );
    } catch (e) {
      console.error('Error while sign pending transaction', e);
      notify({
        type: 'error',
        header: 'Transaction failed',
        text: 'Error while sign pending transaction',
      });
    }
  }

  @action.bound public removePendingTransaction(id: string) {
    const list = this.pendingTransactions;
    const index = list.findIndex((item: any) => item?.transaction?.id === id);
    if (index !== -1) {
      list.splice(index, 1);
    }

    window.localStorage.setItem(
      `pendingTransactions_${SafeStoreInstance.selectedSafe?.address?.value}`,
      JSON.stringify(toJS(list)),
    );
  }

  @action.bound public async sendTx(
    from: string,
    to: string,
    onFinish: () => void,
    isReject?: boolean,
  ) {
    const { txArgs, execute, safeTxHash, txProps } = this;

    const tx = execute
      ? getExecutionTransaction({
          ...txArgs,
          // @ts-ignore
          nonce: this.activeTransaction.detailedExecutionInfo.nonce,
        })
      : getApprovalTransaction(this.safeInstance, safeTxHash);

    const sendParams = {
      from: SafeStoreInstance.userAccount || '',
      gas: txProps.ethParameters?.ethGasLimit,
      maxFeePerGas: '100000000000',
      maxPriorityFeePerGas: '1500000001',
      nonce: txProps.ethParameters?.ethNonce,
      value: 0,
    };

    await tx.send(sendParams).on('transactionHash', () => {
      this.addPendingTransaction(this.activeTransaction);
      this.getTransactionQueue();
      this.setActiveTransaction(null, null);
      onFinish();
    });
  }

  @action.bound public async getTxParameters({
    from,
    amount,
    to,
    providedApprovalAndExecution,
  }: {
    from: string;
    to: string;
    amount: number;
    providedApprovalAndExecution?: boolean;
  }): Promise<TxParameters> {
    const ethNonce = await getUserNonce(SafeStoreInstance.userAccount || '');
    const txConfirmations = List(
      // @ts-ignore
      this.activeTransaction.detailedExecutionInfo.confirmations.map(
        // @ts-ignore
        ({ signer, signature }) =>
          makeConfirmation({ owner: signer.value, signature }),
      ),
    );
    const { data, operation, safeTxGas, gasPrice, gasToken, refundReceiver } =
      this.txArgs;

    // @ts-ignore
    const ethGasLimit = await estimateGasForTransactionExecution({
      // @ts-ignore
      safeAddress: this.activeTransaction.safeAddress,
      txRecipient: to,
      txAmount: toTokenUnit(amount || 0, 18),
      from: SafeStoreInstance.userAccount || '',
      // @ts-ignore
      txConfirmations,
      txData: data || '0x',
      operation: operation || 0,
      safeTxGas,
      gasPrice: gasPrice || '0',
      gasToken: gasToken || '0x0000000000000000000000000000000000000000',
      refundReceiver:
        refundReceiver || '0x0000000000000000000000000000000000000000',
      safeVersion: LATEST_SAFE_VERSION,
      approvalAndExecution:
        providedApprovalAndExecution || (this.confirm && this.execute),
    });

    return new Promise((resolve) =>
      resolve({
        // @ts-ignore
        ethGasLimit: '91058',
        ethGasPriceInGWei: '24000000000',
        ethMaxPrioFeeInGWei: '1500000000',
        // @ts-ignore
        ethNonce,
      }),
    );
  }

  @action.bound public async createTransaction({
    from,
    to,
    amount,
    nonce: providedNonce,
    onFinish,
    isReject,
  }: {
    from: string;
    to: string;
    nonce: string;
    amount: number;
    onFinish: () => void;
    isReject?: boolean;
  }) {
    if (
      this.activeTransaction?.transaction &&
      this.errors[this.activeTransaction.transaction.id]
    ) {
      delete this.errors[this.activeTransaction.transaction.id];
    }

    this.safeInstance = await getGnosisSafeInstanceAt(
      from,
      LATEST_SAFE_VERSION,
    );
    const nonce = providedNonce || (await getNonce(from, LATEST_SAFE_VERSION));
    const safeTxGas = '27966';

    const confirmations = List(
      // @ts-ignore
      this.activeTransaction?.detailedExecutionInfo?.confirmations.map(
        // @ts-ignore
        ({ signer, signature }) =>
          makeConfirmation({ owner: signer.value, signature }),
      ),
    );
    if (this.isFinalization === null) {
      this.isFinalization = this.confirm && this.execute;
    }

    const sigs = this.activeTransaction
      ? generateSignaturesFromTxConfirmations(
          // @ts-ignore
          confirmations,
          !this.confirm && this.execute
            ? undefined
            : SafeStoreInstance.userAccount,
        )
      : getPreValidatedSignatures(from);

    this.txArgs = {
      safeInstance: this.safeInstance,
      to,
      // @ts-ignore
      valueInWei: toTokenUnit(amount, 18),
      // @ts-ignore
      value: toTokenUnit(amount, 18),
      data: '0xd0e30db0',
      operation: Operation.CALL,
      nonce: this.activeTransaction
        ? // @ts-ignore
          this.activeTransaction.detailedExecutionInfo.nonce
        : Number.parseInt(nonce),
      safeTxGas,
      baseGas: '0',
      gasPrice: '0',
      gasToken: ZERO_ADDRESS,
      refundReceiver: ZERO_ADDRESS,
      sender: SafeStoreInstance.userAccount || '',
      // We're making a new tx, so there are no other signatures
      // Just pass our own address for an unsigned execution
      // Contract will compare the sender address to this
      // @ts-ignore
      sigs,
    };
    if (this.isFinalization) {
      this.txArgs.sigs = sigs;
      const ethParameters = await this.getTxParameters({ from, amount, to });

      this.txProps = {
        navigateToTransactionsTab: false,
        notifiedTransaction: '',
        operation: Operation.CALL,
        origin: null,
        safeAddress: SafeStoreInstance.selectedSafe?.address.value || '',
        to,
        txData: EMPTY_DATA,
        txNonce: Number.parseInt(nonce),
        valueInWei: toTokenUnit(amount, 18),
        safeTxGas,
        // @ts-ignore
        ethParameters,
      };
    }
    this.safeTxHash = generateSafeTxHash(
      SafeStoreInstance.selectedSafe?.address?.value || '',
      LATEST_SAFE_VERSION,
      this.txArgs,
    );
    const isOffchain = await this.canSignOffchain();

    if (!this.isFinalization && isOffchain && !isReject) {
      try {
        const signature = await this.signThroughMetaMask(from, to);

        if (signature) {
          await this.onComplete(signature, onFinish);
        } else {
          throw Error('No signature received');
        }
      } catch (e) {
        console.error('Error while creation transaction: ', e);
        if (e.code === -32000) {
          runInAction(() => {
            this.errors[this.activeTransaction.transaction.id] =
              'Insufficient funds for gas price';
          });
        }
      }
    } else {
      try {
        await this.sendTx(
          SafeStoreInstance.userAccount || '',
          to,
          onFinish,
          isReject,
        );
      } catch (e) {
        console.error('Error while execute transaction: ', e);
        if (e.code === -32000) {
          runInAction(() => {
            this.errors[this.activeTransaction.transaction.id] =
              'Insufficient funds for gas price';
          });
        }
      }
    }

    onFinish();
  }

  @action.bound public async confirmTransaction(
    {
      execute,
      confirm,
      safeTx,
    }: {
      confirm?: boolean;
      execute?: boolean;
      safeTx?: SafeTransaction
    },
    onFinish = () => {},
  ) {
    if (this.activeTransaction === null) return;
    this.execute = !!execute;
    this.confirm = !!confirm;
    this.executeTransaction(this.activeTransaction, onFinish);
    return;
  }

  @action.bound public async rejectTransaction(onFinish = () => {}) {
    this.isFinalization = false;
    this.createTransaction({
      // @ts-ignore
      from: SafeStoreInstance.selectedSafe?.address.value,
      // @ts-ignore
      to: SafeStoreInstance.selectedSafe?.address?.value,
      // @ts-ignore
      amount: 0,
      onFinish: () => {
        onFinish();
        this.getTransactionQueue();
      },
    });
  }

  @action.bound public async executeTransaction(
    transaction: TransactionListItemDetailed,
    onFinish = () => {},
  ) {
    // @ts-ignore

    const isCancellation = transaction?.txInfo?.isCancellation;

    const txFromStore = SafeStoreInstance.selectedSafe?.address.value;
    const txFromTX = transaction?.safeAddress;

    const isFromApp = txFromStore !== txFromTX;
    const from = isFromApp ? txFromTX : txFromStore;

    this.createTransaction({
      // @ts-ignore
      from: SafeStoreInstance.selectedSafe?.address.value,
      // @ts-ignore
      to:
      // @ts-ignore
        transaction?.txInfo?.recipient?.value ?? transaction?.txInfo?.to?.value ??
        SafeStoreInstance.selectedSafe?.address.value,
      // @ts-ignore
      amount: isCancellation
        ? 0
        : // @ts-ignore
          (transaction?.txInfo?.transferInfo?.value / 1000000000000000000 ||
          transaction?.txInfo?.value / 1000000000000000000) ??
          // @ts-ignore
          transaction?.txInfo?.value,
      onFinish,
      // @ts-ignore
      isReject:
        isCancellation &&
        // @ts-ignore
        !transaction?.transaction?.executionInfo?.missingSigners?.length,
    });
    return;
  }

  private mapQueueResults(results: TransactionListItem, update = false) {
    const list = this.pendingTransactions;
    const processedItems = list.filter((item: any) => {
      // @ts-ignore
      const index = results.findIndex(
        // @ts-ignore
        (ri) => ri?.transaction?.id === item?.transaction?.id,
      );
      return index === -1;
    });

    const mappedResults = results.map((item) => {
      if (item?.type === 'TRANSACTION' && update) {
        // @ts-ignore
        const index = list.findIndex(
          // @ts-ignore
          (ptx: any) => ptx?.transaction?.id === item?.transaction?.id,
        );

        if (index !== -1) {
          if (
            list[index].initialSubmitted !==
            // @ts-ignore
            item?.transaction?.executionInfo?.confirmationsSubmitted
          ) {
            this.removePendingTransaction(item.transaction.id);
            SafeStoreInstance.updateSafesBalances();
          } else {
            item.transaction.txStatus = 'PENDING';
          }
        }

        const isBeingCancelled = list.some((li: any) => {
          const liNonce = li?.transaction?.executionInfo?.nonce;
          const itemNonce = item?.transaction?.executionInfo?.nonce;
          const hasSameNonce = liNonce === itemNonce;
          const isNextTransaction = ![...list, ...results].some(
            (gi) =>
              gi?.transaction?.executionInfo?.nonce &&
              gi?.transaction?.executionInfo?.nonce < liNonce,
          );
          const hasNoMissingSigners =
            !li?.transaction?.executionInfo?.missingSigners?.length;
          const isCancellation =
            li?.transaction?.txInfo?.isCancellation &&
            !item?.transaction?.txInfo?.isCancellation;
          const isConfirmation =
            !li?.transaction?.txInfo?.isCancellation &&
            item?.transaction?.txInfo?.isCancellation;

          return (
            hasSameNonce &&
            (isNextTransaction || hasNoMissingSigners) &&
            (isConfirmation || isCancellation)
          );
        });

        if (isBeingCancelled) {
          item.transaction.txStatus = 'WILL_BE_REPLACED';
        }
      }
      return {
        ...item,
      };
    });

    if (processedItems.length && update) {
      ['Next', 'Queued'].forEach((l) => {
        // @ts-ignore
        const index = mappedResults.findIndex(
          // @ts-ignore
          ({ type, label }) => type === 'LABEL' && label === l,
        );
        if (index === -1) return;
        mappedResults.splice(index, 1);
      });

      mappedResults.splice(
        0,
        0,
        {
          type: 'LABEL',
          // @ts-ignore
          label: 'Next',
        },
        processedItems[0],
        {
          type: 'LABEL',
          // @ts-ignore
          label: 'Queued',
        },
      );
    }

    if (!this.transactionQueue.length) {
      this.transactionQueue = mappedResults;
      this.addQueueSpacing();
      return;
    }

    if (update) {
      let idx;

      for (let i = this.transactionQueue.length - 1; i >= 0; i--) {
        const tqi = this.transactionQueue[i];
        if (tqi.type !== 'TRANSACTION') continue;
        const mridx = mappedResults.findIndex(
          (mri) =>
            mri.type === 'TRANSACTION' &&
            mri.transaction.id === tqi.transaction.id,
        );
        if (mridx !== -1) {
          idx = i;
          break;
        }
      }

      if (!mappedResults.length) {
        this.transactionQueue = mappedResults;
      } else {
        this.transactionQueue.splice(0, idx + 1, ...mappedResults);
      }
    } else {
      this.transactionQueue.push(...mappedResults);
    }

    this.addQueueSpacing();
  }

  private addQueueSpacing() {
    const spaceIndexes = [];
    for (let i = 2; i <= this.transactionQueue.length - 2; i++) {
      const label = this.transactionQueue[i - 2];
      const prev = this.transactionQueue[i - 1];
      const current = this.transactionQueue[i];
      const next = this.transactionQueue[i + 1];

      const labelIsConflictLabel = label?.type === 'CONFLICT_HEADER';
      const prevAndCurrentHasSameNonce =
        prev?.transaction?.executionInfo?.nonce ===
        current?.transaction?.executionInfo?.nonce;
      const nextHasDifferentNonce =
        next?.type === 'TRANSACTION' &&
        next?.transaction?.executionInfo?.nonce !==
          current?.transaction?.executionInfo?.nonce;

      if (
        labelIsConflictLabel &&
        prevAndCurrentHasSameNonce &&
        nextHasDifferentNonce
      ) {
        spaceIndexes.push(i);
      }
    }

    spaceIndexes.forEach((idx, counter) => {
      this.transactionQueue.splice(idx + counter + 1, 0, {
        type: 'LABEL',
        label: '',
      });
    });
  }

  @action.bound public async fetchMoreTransactionQueue() {
    if (!this.nextQueue) return;

    this.fetchingMoreQueue = true;

    const { data } = await axiosInstance.get(this.nextQueue);
    const { results, next } = data;

    this.nextQueue = next;
    this.fetchingMoreQueue = false;
    this.fetchedMoreQueue = true;

    this.mapQueueResults(results);
  }

  @action.bound public async getTransactionQueue(initial = false) {
    try {
      if (this.fetchingMoreQueue) return;
      if (!this.initiallyLoadedQueue) {
        this.isLoadingQueue = true;
      }
      if (!SafeStoreInstance.selectedChain) return;
      if (!SafeStoreInstance.selectedSafe) return;

      if (!this.transactionQueue.length) {
        this.fetchedMoreQueue = false;
      }

      if (initial) {
        this.transactionQueue = [];
      }

      const queueAmount = this.transactionQueue.filter(
        ({ type }) => type === 'TRANSACTION',
      ).length;

      const { data } = await axiosInstance.get(
        `${
          SettingsStoreInstance.appSettings.gnosis_node
        }/v1/chains/${_getChainId()}/safes/${
          SafeStoreInstance.selectedSafe.address.value
        }/transactions/queued?cursor=limit%3D${
          queueAmount || 4
        }%26offset%3D0&timezone_offset=0&trusted=true`,
      );
      const { results, next } = data;

      if (this.nextQueue && !next) {
        this.nextQueue = next;
      }

      if (next && !this.nextQueue && !this.fetchedMoreQueue) {
        this.nextQueue = `${
          SettingsStoreInstance.appSettings.gnosis_node
        }/v1/chains/${_getChainId()}/safes/${
          SafeStoreInstance.selectedSafe.address.value
        }/transactions/queued?cursor=limit%3D20%26offset%3D4&timezone_offset=0&trusted=true`;
      }

      this.mapQueueResults(results, true);
      this.isLoadingQueue = false;
    } catch (error) {
      //
    } finally {
      this.initiallyLoadedQueue = true;
    }
  }

  private mapHistoryResults(results: TransactionListItem[], update = false) {
    if (!this.transactionHistory.length) {
      this.transactionHistory = results;
      return;
    }

    if (update) {
      const lastTransaction: Transaction = this.transactionHistory.find(
        (hi) => hi.type === 'TRANSACTION',
      );
      const lastTransactionIndex = results.findIndex(
        (ri) =>
          ri.type === 'TRANSACTION' &&
          ri.transaction.id === lastTransaction.transaction.id,
      );
      const difference = results.slice(1, lastTransactionIndex);
      this.transactionHistory.splice(
        1,
        0,
        ...new Map(difference.map((v) => [v.id, v])).values(),
      );
    } else {
      this.transactionHistory.push(...results);
    }
  }

  @action.bound public async fetchMoreTransactionHistory() {
    if (!this.nextHistory) return;

    const { data } = await axiosInstance.get(this.nextHistory);
    const { results, next } = data;

    this.nextHistory = next;
    this.fetchedMoreHistory = true;
    this.mapHistoryResults(results);
  }

  @action.bound public async getTransactionHistory(initial = false) {
    try {
      if (this.fetchingMoreHistory) return;
      if (!this.initiallyLoadedHistory) {
        this.isLoadingHistory = true;
      }
      if (!SafeStoreInstance.selectedChain) return;
      if (!SafeStoreInstance.selectedSafe) return;

      if (initial) {
        this.transactionHistory = [];
      }

      if (!this.transactionHistory.length) {
        this.fetchedMoreHistory = false;
      }

      const { results, next } = await getTransactionHistory(
        SafeStoreInstance.selectedChain?.chainId,
        SafeStoreInstance.selectedSafe?.address.value,
      );

      if (next && !this.nextHistory && !this.fetchedMoreHistory) {
        this.nextHistory = next;
      }

      let pendingTransactionRemoved = false;

      this.pendingTransactions.forEach((tx: any) => {
        // @ts-ignore
        if (
          results.findIndex(
            // @ts-ignore
            (item) => item?.transaction?.id === tx?.transaction?.id,
          ) !== -1
        ) {
          this.removePendingTransaction(tx?.transaction?.id);
          SafeStoreInstance.updateSafesBalances();
          pendingTransactionRemoved = true;
        }
      });

      if (pendingTransactionRemoved) await this.getTransactionQueue();

      this.mapHistoryResults(results, true);
      this.isLoadingHistory = false;
    } catch (error) {
      //
    } finally {
      this.initiallyLoadedHistory = true;
    }
  }

  @action.bound public async getTransactionDetails(txId: string) {
    try {
      if (!SafeStoreInstance.selectedChain) return;
      if (!SafeStoreInstance.selectedSafe) return;
      if (!this.transactionQueue) return;

      const detailedExecutionInfo = await getTransactionDetails(
        SafeStoreInstance.selectedChain?.chainId,
        txId,
      );
      return detailedExecutionInfo;
    } catch (error) {
      //
    }
  }
}

export const MultisigTransactionsStoreInstance =
  new MultisigTransactionsStore();
